import {
  DefinedAmount,
  SimulationReportResults,
} from "../../../Finder/attack-utils";
import { getOurArmyLosses } from "./getOurArmyLosses";

export function getRemainingUnits(
  simulationLosses: SimulationReportResults,
  simulations: number
): Record<string, DefinedAmount> {
  const lostUnits = getOurArmyLosses(simulationLosses, simulations);

  return Object.entries(simulationLosses.usedUnits.units).reduce<
    Record<string, DefinedAmount>
  >(
    (prev, [id, unitDesc]) => ({
      ...prev,
      [id]: {
        min: unitDesc.originalAmount.min - (lostUnits[parseInt(id)]?.min ?? 0),
        max: unitDesc.originalAmount.max - (lostUnits[parseInt(id)]?.max ?? 0),
        avg: unitDesc.originalAmount.avg
          ? unitDesc.originalAmount.avg - (lostUnits[parseInt(id)]?.avg ?? 0)
          : 0, //  TODO: May be wrong!
      },
    }),
    {}
  );
}
