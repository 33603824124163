import { Combination } from 'ts-combinatorics';
import range from 'lodash.range';
import { General } from '../data-objects/units/Army';
import { BlockResult, createArmyCombinations, FinderUnit, fitUnitsToGeneral, toBlockResult } from '../Finder/utils';
import { getAverageRoundsFromResult, getTimeFromResult } from '../redux/result/resultUtil';
import { prepareForSimulation } from '../redux/simulationUtil';
import { getGeneralCapacity, ourArmyMap } from '../redux/unitUtil';
import { SearchBasePropsCamps } from '../types/search';
import { runSimulate } from './simulate';
import { SelectedUnits } from '../components/units/UnitSelection';

export enum FinderGoal {
  blockingTime,
  minLosses,
  minDuration
}

export interface SearchSingleBlock extends SearchBasePropsCamps {
  detailedSimulationRuns?: number;
}

export interface IndividualBlockResult {
  interessting: BlockResult[];
  detailed: BlockResult[];
  potentially: BlockResult[];
}

export interface PrepareSearchProps {
  finderGenerals: Array<General>;
  choosenUnits: Array<FinderUnit>;
  stepSize?: number;
}

export interface PreparedConfig {
  configs: Array<SelectedUnits>;
  usedStepSize: number;
}

export function testVariation(attributes: Array<Array<number>>, capacity: number, allCapacities: Array<number>) {
  function addVariationsToArray(base: Array<Array<number>>, variations: Array<number>) {
    const ret = [];
    for (const baseToExtend of base) {
      const baseSum = sumUpArray(baseToExtend);
      for (const variation of variations) {
        if (baseSum + variation <= capacity) {
          ret.push([...baseToExtend, variation]);
        }
      }
    }
    return ret;
  }

  //generate variations
  let variations: Array<Array<number>> = [];
  if (attributes.length) {
    if (attributes.length > 1) {
      variations = attributes[0].map(num => [num]);
      for (let i = 1; i < attributes.length; i++) {
        variations = addVariationsToArray(variations, attributes[i]);
      }
    }
    variations = variations.filter(combination => allCapacities.includes(sumUpArray(combination)));
  }
  return variations;
}

function sumUpArray(array: Array<number>) {
  return array.reduce((partialSum, num) => partialSum + num, 0);
}

interface CreateCombinationProps {
  finderUnits: Array<FinderUnit>;
  allCapacities: Array<number>;
  unitStepSize: number;
}

export function prepareRefinedConfig({
  finderUnits,
  allCapacities,
  unitStepSize
}: CreateCombinationProps): PreparedConfig {
  const UNIT_STEP_WIDTH = 5;

  const maxCapacity = Math.max(...allCapacities);
  const usedStepWidth = unitStepSize || UNIT_STEP_WIDTH;
  const army: Array<Array<number>> = [];
  for (const finderUnit of finderUnits) {
    var candidateAmounts = range(finderUnit.min ?? 0, (finderUnit.max ?? maxCapacity) + usedStepWidth, usedStepWidth);
    army.push(candidateAmounts);
  }

  const variationResult = testVariation(army, maxCapacity, allCapacities);

  return { usedStepSize: usedStepWidth, configs: mapCombinationToUnits(variationResult, finderUnits) };
}

function createCombinations(capacity: number, amountOfUnits: number, allCapacities: Array<number>) {
  const MAX_AMOUNT_DIFFERENT_UNITS = 4;
  const UNIT_STEP_WIDTH = 5;

  const army: Array<Array<number>> = [];
  for (var index = 0; index < Math.min(amountOfUnits, MAX_AMOUNT_DIFFERENT_UNITS); index++) {
    const offset = capacity % UNIT_STEP_WIDTH;
    var candidateAmounts = range(offset, capacity, UNIT_STEP_WIDTH);
    if (offset !== 0) {
      candidateAmounts = [...candidateAmounts, ...range(0, capacity, UNIT_STEP_WIDTH)];
    }
    army.push(candidateAmounts);
  }

  var result = testVariation(army, capacity, allCapacities);
  if (amountOfUnits > MAX_AMOUNT_DIFFERENT_UNITS) {
    for (var i = MAX_AMOUNT_DIFFERENT_UNITS + 1; i <= amountOfUnits; i++) {
      const amountsToAdd: Array<Array<number>> = [];
      const safeI = i;
      result.forEach(combination => {
        const basis = [...combination];
        for (var indexToAdd = 0; indexToAdd < safeI; indexToAdd++) {
          if (indexToAdd === 0) {
            combination.splice(indexToAdd, 0, 0);
          } else {
            amountsToAdd.push([...basis].splice(indexToAdd, 0, 0));
          }
        }
      });
      result = [...result, ...amountsToAdd];
    }
  }
  return result;
}

export function prepareAttackSearch({ finderGenerals, choosenUnits }: PrepareSearchProps): PreparedConfig {
  const finderEliteUnits = choosenUnits.filter(aUnit => ourArmyMap[aUnit.id]?.elite);

  const finderNormalUnits = choosenUnits.filter(aUnit => !ourArmyMap[aUnit.id]?.elite);

  const capacities = finderGenerals.map(general => getGeneralCapacity(general), 0);
  const maxCapacity = Math.max(...capacities);

  const usedStepSize = 5;

  if (finderEliteUnits.length <= 1 || finderNormalUnits.length <= 1) {
    if (finderEliteUnits.length <= 1 && finderNormalUnits.length <= 1) {
      return { configs: [], usedStepSize };
    } else if (finderNormalUnits.length <= 1) {
      // return configWithElites
      return { configs: createArmyConfig(finderEliteUnits, maxCapacity, capacities), usedStepSize };
    } else {
      // return configWithNormals
      return { configs: createArmyConfig(finderNormalUnits, maxCapacity, capacities), usedStepSize };
    }
  }
  // handle both unit types
  return {
    configs: [
      ...createArmyConfig(finderEliteUnits, maxCapacity, capacities),
      ...createArmyConfig(finderNormalUnits, maxCapacity, capacities)
    ],
    usedStepSize
  };
}

export function createArmyConfig(searchUnits: Array<FinderUnit>, maxCapacity: number, allCapacities: Array<number>) {
  // Will ever be 2 or 3
  const numberOfUnitsToUse = Math.min(3, searchUnits.length);
  const genericConfig = createCombinations(maxCapacity, numberOfUnitsToUse, allCapacities);
  const unitsCombinations = new Combination(searchUnits, numberOfUnitsToUse).toArray();

  return unitsCombinations.flatMap(combination => mapCombinationToUnits(genericConfig, combination)).flat();
}

function mapCombinationToUnits(
  genericConfig: Array<Array<number>>,
  combination: Array<FinderUnit>
): Array<SelectedUnits> {
  const startingSelection: SelectedUnits = {};
  return genericConfig.map(config =>
    config.reduce(
      (combined, currentConfig, configIndex) =>
        currentConfig > 0 ? { ...combined, [combination[configIndex].id]: currentConfig } : combined,
      startingSelection
    )
  );
}

export function prepareSearch({ finderGenerals, choosenUnits, stepSize }: PrepareSearchProps): PreparedConfig {
  const finderEliteUnits = choosenUnits.filter(aUnit => ourArmyMap[aUnit.id]?.elite);
  const finderNormalUnits = choosenUnits.filter(aUnit => !ourArmyMap[aUnit.id]?.elite);

  const maxCapacity = finderGenerals.reduce((prevMax, general) => {
    const thisCapacity = getGeneralCapacity(general);
    return thisCapacity > prevMax ? thisCapacity : prevMax;
  }, 0);

  const eliteStepSize = getStepSizeFromUnits(finderEliteUnits, stepSize);
  const normalUnitStepSize = getStepSizeFromUnits(finderEliteUnits, stepSize);
  const usedStepSize = Math.max(eliteStepSize, normalUnitStepSize);
  const allEliteConfigs = createArmyCombinations(maxCapacity, finderEliteUnits, usedStepSize);

  const allNormalConfigs = createArmyCombinations(maxCapacity, finderNormalUnits, usedStepSize);

  return { configs: [...allEliteConfigs, ...allNormalConfigs], usedStepSize };
}

function getStepSizeFromUnits(units: unknown[], stepSize?: number) {
  if (stepSize) {
    return stepSize;
  }
  let recommendedStepSize = 20;
  if (units.length === 1 || units.length === 2) {
    recommendedStepSize = units.length;
  } else if (units.length === 3) {
    recommendedStepSize = 5;
  } else if (units.length === 4) {
    recommendedStepSize = 10;
  } else if (units.length < 8) {
    recommendedStepSize = 15;
  }
  return recommendedStepSize;
}

export function searchSingleBlock({
  general,
  unit,
  banditCamps,
  weatherToUse,
  currentStepSize = 1,
  detailedSimulationRuns,
  simulationRuns,
  filterResultByTime,
  generalCapacity,
  timePerRound
}: SearchSingleBlock) {
  const ourUnit = fitUnitsToGeneral(unit, generalCapacity, currentStepSize);
  if (ourUnit === null) {
    return null;
  }

  const simConfig = prepareForSimulation({
    units: ourUnit,
    general: general,
    skills: general.skills,
    camp: banditCamps[0],
    weather: weatherToUse
  });

  if (!simConfig) {
    return null;
  }

  const result = runSimulate(
    simConfig.general,
    simConfig.units,
    simConfig.enemyCamp,
    simConfig.enemies,
    simulationRuns,
    true
  );
  if (filterResultByTime && getTimeFromResult(result, timePerRound) >= filterResultByTime) {
    if (detailedSimulationRuns) {
      const detailedResult = runSimulate(
        simConfig.general,
        simConfig.units,
        simConfig.enemyCamp,
        simConfig.enemies,
        detailedSimulationRuns,
        true
      );

      if (getAverageRoundsFromResult(detailedResult) * timePerRound >= filterResultByTime) {
        return {
          detailed: toBlockResult(detailedResult)
        };
      } else {
        return {
          interessting: toBlockResult(detailedResult)
        };
      }
    }
    return {
      interessting: toBlockResult(result)
    };
  }
  if (getTimeFromResult(result, 10) > 20) {
    return {
      potentially: toBlockResult(result)
    };
  }
  return null;
}
