import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BaseProps } from '../../types/basics';
import { useUpdatingCallback } from 'use-updating-callbacks';

interface Props extends BaseProps {
  value: number;
  onValidChange: (newValue: number) => void;
  minValue?: number;
  maxValue?: number;
  toSmallValueMsg?: string;
  toBigValueMsg?: string;
}

enum ValidationResult {
  TO_BIG,
  TO_SMALL,
  OK
}

function ValidatingInput({
  value,
  onValidChange,
  minValue,
  maxValue,
  toBigValueMsg,
  toSmallValueMsg,
  ...otherProps
}: Props) {
  const [userInput, setUserInput] = useState(`${value}`);
  const [error, setError] = useState(ValidationResult.OK);

  const updateValue = useUpdatingCallback((number: number) => onValidChange(number));

  const errorMessage = useMemo(() => {
    switch (error) {
      case ValidationResult.TO_BIG:
        return toBigValueMsg ?? `Bitte einen Wert kleiner als ${maxValue} eingeben`;
      case ValidationResult.TO_SMALL:
        return toSmallValueMsg ?? `Bitte einen Wert größer ${minValue} eingeben`;
      case ValidationResult.OK:
      default:
        return null;
    }
  }, [error, maxValue, minValue, toBigValueMsg, toSmallValueMsg]);

  function getFallbackValue() {
    switch (error) {
      case ValidationResult.TO_BIG:
        return `${maxValue}`;
      case ValidationResult.TO_SMALL:
        return `${minValue ?? 0}`;
      case ValidationResult.OK:
      default:
        return userInput;
    }
  }

  useEffect(() => {
    if (userInput.length) {
      try {
        const inputNumber = parseInt(userInput);
        if (minValue !== undefined && inputNumber < minValue) {
          setError(ValidationResult.TO_SMALL);
        } else if (maxValue !== undefined && inputNumber > maxValue) {
          setError(ValidationResult.TO_BIG);
        } else {
          setError(ValidationResult.OK);
          updateValue(inputNumber);
        }
      } catch (error) {
        // Ignore
      }
    } else {
      setError(ValidationResult.OK);
      updateValue(minValue ?? 0);
    }
  }, [userInput, minValue, maxValue, updateValue]);

  return (
    <div {...otherProps}>
      <input
        type='number'
        className='the-value'
        placeholder={`${value}`}
        value={value || userInput}
        min={minValue}
        onChange={e => setUserInput(e.target.value)}
        onBlur={() => setUserInput(getFallbackValue())}
      />
      <div className='input-validation-msg'>{errorMessage}</div>
    </div>
  );
}

export default styled(ValidatingInput)`
  position: relative;

  & > .input-validation-msg {
    position: absolute;
    top: 100%;
    width: 100%;
  }
`;
