import React from "react";
import styled from "styled-components";
import { BaseProps } from "../../types/basics";

interface Props extends BaseProps {
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
function SelectableElement({
  selected,
  onClick,
  className,
  children,
  ...otherProps
}: React.PropsWithChildren<Props>) {
  return (
    <div
      className={`${className} selectable-element ${
        selected ? "selectable-element-selected" : ""
      }`}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default styled(SelectableElement)``;
