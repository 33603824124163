import {
  MASTER_OF_MARTIAL_ARTS,
  BATTLE_HARDENED_GENERAL,
  GENERAL_BORIS,
  TAV_GENERAL,
  MARY_CHRISTMAS,
  VETERAN,
  MAJOR,
  VERMUMMTER_GENERAL,
  STEADFAST_GENERAL,
  MAD_SCIENTIST,
  LORD_DRACUL,
  GeneralId,
  DEFAULT_GENERAL_ICON,
  GENERAL_ZITTERBART,
  GEISTER_GENERAL,
  CHAMPION_VARGUS,
  MASTER_OF_DEFENSE,
  JUNGER_GEMINI_GENERAL,
  CHAMPION_ANSLEM,
  CHAMPION_NUSALA,
  FIELD_MEDIC_GENERAL,
  OLDER_GEMINI_GENERAL,
  GENERAL_LOG,
  FROST_GENERAL,
  Generals
} from '../data-objects/units/Army';
import { getAllGenerals } from './unitUtil';

const mdkIcons = [
  '/icons/generals/mdk.png',
  '/icons/custom_generals/mdk_1.png',
  '/icons/custom_generals/mdk_2.png',
  '/icons/custom_generals/mdk_3.png',
  '/icons/custom_generals/mdk_4.png',
  '/icons/custom_generals/mdk_5.png',
  '/icons/custom_generals/mdk_6.png'
];

const dracuIcons = [
  '/icons/generals/dracul.png',
  '/icons/custom_generals/dracul_1.png',
  '/icons/custom_generals/dracul_2.png',
  '/icons/custom_generals/dracul_3.png',
  '/icons/custom_generals/dracul_4.png',
  '/icons/custom_generals/dracul_5.png',
  '/icons/custom_generals/dracul_6.png',
  '/icons/custom_generals/dracul_7.png'
];

const stgIcons = [
  '/icons/generals/standhafte_icon.png',
  '/icons/custom_generals/standhafte_1.png',
  '/icons/custom_generals/standhafte_2.png',
  '/icons/custom_generals/standhafte_3.png',
  '/icons/custom_generals/standhafte_4.png',
  '/icons/custom_generals/standhafte_5.png',
  '/icons/custom_generals/standhafte_6.png'
];

const wissenschaftlerIcons = [
  '/icons/generals/wissenschaftler.png',
  '/icons/custom_generals/wissenschaftler_1.png',
  '/icons/custom_generals/wissenschaftler_2.png',
  '/icons/custom_generals/wissenschaftler_3.png',
  '/icons/custom_generals/wissenschaftler_4.png',
  '/icons/custom_generals/wissenschaftler_5.png',
  '/icons/custom_generals/wissenschaftler_6.png',
  '/icons/custom_generals/wissenschaftler_7.png'
];

const gmIcons = [
  '/icons/generals/major_general_icon.png',
  '/icons/custom_generals/major_general_1.png',
  '/icons/custom_generals/major_general_2.png',
  '/icons/custom_generals/major_general_3.png',
  '/icons/custom_generals/major_general_4.png',
  '/icons/custom_generals/major_general_5.png',
  '/icons/custom_generals/major_general_6.png',
  '/icons/custom_generals/major_general_7.png',
  '/icons/custom_generals/major_general_8.png',
  '/icons/custom_generals/major_general_9.png'
];

const vetIcons = [
  '/icons/generals/vet.png',
  '/icons/custom_generals/vet_1.png',
  '/icons/custom_generals/vet_2.png',
  '/icons/custom_generals/vet_3.png',
  '/icons/custom_generals/vet_4.png',
  '/icons/custom_generals/vet_5.png',
  '/icons/custom_generals/vet_6.png',
  '/icons/custom_generals/vet_7.png',
  '/icons/custom_generals/vet_8.png',
  '/icons/custom_generals/vet_9.png'
];

const vermummteIcons = [
  '/icons/generals/assassingeneral.png',
  '/icons/custom_generals/vermummter_1.png',
  '/icons/custom_generals/vermummter_2.png',
  '/icons/custom_generals/vermummter_3.png',
  '/icons/custom_generals/vermummter_4.png',
  '/icons/custom_generals/vermummter_5.png',
  '/icons/custom_generals/vermummter_6.png',
  '/icons/custom_generals/vermummter_7.png',
  '/icons/custom_generals/vermummter_8.png'
];

const tavIcons = [
  '/icons/generals/tav.png',
  '/icons/custom_generals/Tav_1.png',
  '/icons/custom_generals/Tav_2.png',
  '/icons/custom_generals/Tav_3.png',
  '/icons/custom_generals/Tav_4.png',
  '/icons/custom_generals/Tav_5.png',
  '/icons/custom_generals/Tav_6.png',
  '/icons/custom_generals/Tav_7.png',
  '/icons/custom_generals/Tav_8.png'
];

const maryIcons = [
  '/icons/generals/mary.png',
  '/icons/custom_generals/mary_1.png',
  '/icons/custom_generals/mary_2.png',
  '/icons/custom_generals/mary_3.png',
  '/icons/custom_generals/mary_4.png',
  '/icons/custom_generals/mary_5.png',
  '/icons/custom_generals/mary_6.png',
  '/icons/custom_generals/mary_7.png'
];

const senseIcons = [
  '/icons/generals/kampfgestaehlter.png',
  '/icons/custom_generals/kampfgestaehlter_1.png',
  '/icons/custom_generals/sense.png',
  '/icons/custom_generals/sense_1.png',
  '/icons/custom_generals/sense_2.png',
  '/icons/custom_generals/sense_3.png',
  '/icons/custom_generals/sense_4.png',
  '/icons/custom_generals/sense_5.png',
  '/icons/custom_generals/sense_6.png',
  '/icons/custom_generals/grosshelm.png',
  '/icons/custom_generals/grosshelm_1.png',
  '/icons/custom_generals/grosshelm_2.png',
  '/icons/custom_generals/grosshelm_3.png',
  '/icons/custom_generals/grosshelm_4.png',
  '/icons/custom_generals/grosshelm_5.png',
  '/icons/custom_generals/grosshelm_6.png',
  '/icons/custom_generals/grosshelm_7.png',
  '/icons/custom_generals/kampfgestaehlter_2.png',
  '/icons/custom_generals/kampfgestaehlter_3.png',
  '/icons/custom_generals/kampfgestaehlter_4.png',
  '/icons/custom_generals/kampfgestaehlter_5.png',
  '/icons/custom_generals/kampfgestaehlter_6.png'
];

const borisIcons = [
  '/icons/custom_generals/sylvana.png',
  '/icons/custom_generals/sylvana_1.png',
  '/icons/custom_generals/sylvana_2.png',
  '/icons/custom_generals/sylvana_3.png',
  '/icons/custom_generals/sylvana_4.png',
  '/icons/custom_generals/sylvana_5.png',
  '/icons/custom_generals/sylvana_6.png',
  '/icons/custom_generals/sylvana_7.png',
  '/icons/custom_generals/sylvana_8.png',
  '/icons/generals/boris_icon.png'
];

const zitterIcons = [
  '/icons/generals/zitterbart.png',
  '/icons/custom_generals/zitter_1.png',
  '/icons/custom_generals/zitter_2.png',
  '/icons/custom_generals/zitter_3.png',
  '/icons/custom_generals/zitter_4.png',
  '/icons/custom_generals/zitter_5.png',
  '/icons/custom_generals/zitter_6.png',
  '/icons/custom_generals/zitter_7.png',
  '/icons/custom_generals/zitter_8.png'
];

const anslemIcons = [
  '/icons/generals/anslem.png',
  '/icons/custom_generals/anslem_1.png',
  '/icons/custom_generals/anslem_2.png',
  '/icons/custom_generals/anslem_3.png',
  '/icons/custom_generals/anslem_4.png',
  '/icons/custom_generals/anslem_5.png',
  '/icons/custom_generals/anslem_6.png'
];

const klotzIcons = [
  '/icons/generals/klotz.png',
  '/icons/custom_generals/klotz_1.png',
  '/icons/custom_generals/klotz_2.png',
  '/icons/custom_generals/klotz_3.png',
  '/icons/custom_generals/klotz_4.png',
  '/icons/custom_generals/klotz_5.png',
  '/icons/custom_generals/klotz_6.png',
  '/icons/custom_generals/klotz_7.png'
];

const nusalaIcons = [
  '/icons/generals/nusala.png',
  '/icons/custom_generals/nusala_1.png',
  '/icons/custom_generals/nusala_2.png',
  '/icons/custom_generals/nusala_3.png',
  '/icons/custom_generals/nusala_4.png',
  '/icons/custom_generals/nusala_5.png',
  '/icons/custom_generals/nusala_6.png'
];

const jungGeminiIcons = [
  '/icons/generals/jung_gemini.png',
  '/icons/custom_generals/jung_gemini_1.png',
  '/icons/custom_generals/jung_gemini_2.png',
  '/icons/custom_generals/jung_gemini_3.png',
  '/icons/custom_generals/jung_gemini_4.png',
  '/icons/custom_generals/jung_gemini_5.png',
  '/icons/custom_generals/jung_gemini_6.png'
];
const oldGeminiIcons = [
  '/icons/generals/old_gemini.png',
  '/icons/custom_generals/old_gemini_1.png',
  '/icons/custom_generals/old_gemini_2.png',
  '/icons/custom_generals/old_gemini_3.png',
  '/icons/custom_generals/old_gemini_4.png',
  '/icons/custom_generals/old_gemini_5.png',
  '/icons/custom_generals/old_gemini_6.png'
];

const vargusIcons = [
  '/icons/generals/vargus.png',
  '/icons/custom_generals/vargus_1.png',
  '/icons/custom_generals/vargus_2.png',
  '/icons/custom_generals/vargus_3.png',
  '/icons/custom_generals/vargus_4.png',
  '/icons/custom_generals/vargus_5.png',
  '/icons/custom_generals/vargus_6.png'
];
const fieldMedicIcons = [
  '/icons/generals/field_medic_general.png',
  '/icons/custom_generals/field_medic_1.png',
  '/icons/custom_generals/field_medic_2.png',
  '/icons/custom_generals/field_medic_3.png',
  '/icons/custom_generals/field_medic_4.png',
  '/icons/custom_generals/field_medic_5.png',
  '/icons/custom_generals/field_medic_6.png'
];

const geistIcons = [
  '/icons/generals/geistergen.png',
  '/icons/custom_generals/geist_1.png',
  '/icons/custom_generals/geist_2.png',
  '/icons/custom_generals/geist_3.png',
  '/icons/custom_generals/geist_4.png',
  '/icons/custom_generals/geist_5.png',
  '/icons/custom_generals/geist_6.png',
  '/icons/custom_generals/geist_7.png'
];

const verteidigungsmeisterIcons = [
  '/icons/generals/vm.png',
  '/icons/custom_generals/Verteidigungsmeister_1.png',
  '/icons/custom_generals/Verteidigungsmeister_2.png',
  '/icons/custom_generals/Verteidigungsmeister_3.png',
  '/icons/custom_generals/Verteidigungsmeister_4.png',
  '/icons/custom_generals/Verteidigungsmeister_5.png'
];

const frostGeneralIcons = [
  '/icons/generals/frosti.png',
  '/icons/custom_generals/frosti_1.png',
  '/icons/custom_generals/frosti_2.png',
  '/icons/custom_generals/frosti_3.png',
  '/icons/custom_generals/frosti_4.png'
];

const einsamerGenIcons = ['/icons/generals/einzelgaenger.png', '/icons/custom_generals/eg_1.png'];

const grossmaulIcons = [
  '/icons/generals/loudmouthgeneral.png',
  '/icons/custom_generals/grossmaul_1.png',
  '/icons/custom_generals/grossmaul_2.png',
  '/icons/custom_generals/grossmaul_3.png',
  '/icons/custom_generals/grossmaul_4.png',
  '/icons/custom_generals/grossmaul_5.png',
  '/icons/custom_generals/grossmaul_6.png',
  '/icons/custom_generals/grossmaul_7.png',
  '/icons/custom_generals/grossmaul_8.png',
  '/icons/custom_generals/grossmaul_9.png',
  '/icons/custom_generals/grossmaul_10.png',
  '/icons/custom_generals/grossmaul_11.png',
  '/icons/custom_generals/grossmaul_12.png'
];

const nussknackerIcons = [
  '/icons/generals/nussknacker.png',
  '/icons/custom_generals/Nussknacker_1.png',
  '/icons/custom_generals/Nussknacker_2.png',
  '/icons/custom_generals/Nussknacker_3.png',
  '/icons/custom_generals/Nussknacker_4.png',
  '/icons/custom_generals/Nussknacker_5.png'
];

const miraculousIcons = [
  '/icons/generals/mg.png',
  '/icons/custom_generals/mg_1.png',
  '/icons/custom_generals/mg_2.png',
  '/icons/custom_generals/mg_3.png',
  '/icons/custom_generals/mg_4.png',
  '/icons/custom_generals/mg_5.png'
];

const resoluteIcons = [
  '/icons/generals/rg.png',
  '/icons/custom_generals/rg_1.png',
  '/icons/custom_generals/rg_2.png',
  '/icons/custom_generals/rg_3.png',
  '/icons/custom_generals/rg_4.png',
  '/icons/custom_generals/rg_5.png',
  '/icons/custom_generals/rg_6.png'
];

const juanIcons = ['/icons/generals/juan.png'];

const availableGenerals = getAllGenerals();

export function getIconsForGeneralId(id: GeneralId): string[] {
  switch (id) {
    case CHAMPION_VARGUS:
      return vargusIcons;
    case CHAMPION_NUSALA:
      return nusalaIcons;
    case MASTER_OF_DEFENSE:
      return verteidigungsmeisterIcons;
    case FIELD_MEDIC_GENERAL:
      return fieldMedicIcons;
    case OLDER_GEMINI_GENERAL:
      return oldGeminiIcons;
    case JUNGER_GEMINI_GENERAL:
      return jungGeminiIcons;
    case CHAMPION_ANSLEM:
      return anslemIcons;
    case FROST_GENERAL:
      return frostGeneralIcons;
    case GENERAL_LOG:
      return klotzIcons;
    case MASTER_OF_MARTIAL_ARTS:
      return mdkIcons;
    case LORD_DRACUL:
      return dracuIcons;
    case STEADFAST_GENERAL:
      return stgIcons;
    case MAD_SCIENTIST:
      return wissenschaftlerIcons;
    case TAV_GENERAL:
      return tavIcons;
    case MARY_CHRISTMAS:
      return maryIcons;
    case VETERAN:
      return vetIcons;
    case MAJOR:
      return gmIcons;
    case VERMUMMTER_GENERAL:
      return vermummteIcons;
    case BATTLE_HARDENED_GENERAL:
      return senseIcons;
    case GENERAL_BORIS:
      return borisIcons;
    case GENERAL_ZITTERBART:
      return zitterIcons;
    case GEISTER_GENERAL:
      return geistIcons;
    case Generals.LONER_GENERAL:
      return einsamerGenIcons;
    case Generals.GENERAL_GROSSMAUL:
      return grossmaulIcons;
    case Generals.NUSSKNACKER_GENERAL:
      return nussknackerIcons;
    case Generals.MIRACULOUS_GENERAL:
      return miraculousIcons;
    case Generals.RESOLUTE_GENERAL:
      return resoluteIcons;
    case Generals.JUAN:
      return juanIcons;
    default: {
      const generalClass = availableGenerals.find(gen => gen.id === id);
      let theIcon = DEFAULT_GENERAL_ICON;

      if (generalClass) {
        theIcon = generalClass.icon;
      }
      return [1, 1, 1, 1, 1, 1].map(() => theIcon);
    }
  }
}
