import styled from 'styled-components';
import { Amount } from '../../redux/simulationUtil';
import { BaseProps } from '../../types/basics';
import { roundNicely } from '../results/utils';

interface Props extends BaseProps {
  amount: Amount;
}

function DisplayAmount({ amount, ...otherProps }: Props) {
  const { min, max, avg, unit } = amount;
  const displayAvg = min !== max && typeof avg === 'number';
  const displayUnit = unit ?? '';
  const displayMinUnit = min === max ? displayUnit : '';

  return (
    <span {...otherProps}>
      <span>{`${min.toLocaleString()}${displayMinUnit}`}</span>
      {`${min !== max ? ' - ' : ''}`}
      {min !== max && <span>{`${max.toLocaleString()}${displayUnit}`}</span>}
      {displayAvg && <br />}
      {displayAvg && <span>{`(Ø\u00a0${roundNicely(Math.max(0, avg!)).toLocaleString()}${displayUnit})`}</span>}
    </span>
  );
}

export default styled(DisplayAmount)`
  display: inline-block;
  text-align: center;
`;
