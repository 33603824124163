import { SimulationState } from '../app/store';
import { initialState } from '../app/storeUtil';
import { GlobalEffects } from '../data-objects/GlobalEffects';
import { EnemyId } from '../data-objects/units/Units';
import { getEnemiesFromCamp, getEnemyTypesOfAdventureId } from './adventureUtil';
import {
  abortChangeCamp,
  changeAdventure,
  changeCamp,
  changeEnemyWave,
  changeGeneral,
  changeWave,
  deleteEnemyWave,
  deleteWave,
  showCustomGenerals,
  SimulationActions,
  toggleKantine,
  toggleLazarett,
  updateSelectedEnemies,
  updateSelectedUnits,
  updateSkills
} from './simulationActions';
import {
  ABORT_CHANGE_CAMP,
  CHANGE_ADVENTURE,
  CHANGE_CAMP,
  CHANGE_ENEMY_WAVE,
  CHANGE_GENERAL,
  CHANGE_SKILLS,
  CHANGE_WAVE,
  DELETE_ENEMY_WAVE,
  DELETE_WAVE,
  SHOW_CUSTOM_GENERALS,
  TOGGLE_BK_ARMORY,
  TOGGLE_BK_COLD,
  TOGGLE_BK_EP,
  TOGGLE_BK_HEADQUARTER,
  TOGGLE_BK_ICE,
  TOGGLE_BK_RECOVERY,
  TOGGLE_BK_SNOW,
  TOGGLE_BK_STORM,
  TOGGLE_FIRE,
  TOGGLE_FROST,
  TOGGLE_KANTINE,
  TOGGLE_LAZARETT,
  TOGGLE_METAL,
  TOGGLE_NATURE,
  TOGGLE_NEBEL,
  TOGGLE_SHOW_ELITE_UNITS,
  TOGGLE_SONNENSCHEIN,
  TOGGLE_STONE,
  TOGGLE_WATER,
  TOGGLE_WIND,
  TOGGLE_WIRBELSTURM,
  UPDATE_SELECTED_ENEMIES,
  UPDATE_SELECTED_UNITS
} from './simulationActionTypes';

function handleToggleWeather(state: SimulationState, effect: keyof GlobalEffects) {
  return {
    ...state,
    weatherEffects: {
      ...state.weatherEffects,
      [effect]: !state.weatherEffects[effect]
    }
  };
}

function handleToggleNebel(state: SimulationState) {
  return handleToggleWeather(state, 'nebel');
}

function handleToggleSonnenschein(state: SimulationState) {
  return handleToggleWeather(state, 'sonnenschein');
}

function handleToggleWirbelsturm(state: SimulationState) {
  return handleToggleWeather(state, 'wirbelsturm');
}

function handleToggleFrost(state: SimulationState) {
  return handleToggleWeather(state, 'frost');
}

function handleToggleBkCold(state: SimulationState) {
  return handleToggleWeather(state, 'bk_cold');
}

function handleToggleBkIce(state: SimulationState) {
  return handleToggleWeather(state, 'bk_ice');
}

function handleToggleBkStorm(state: SimulationState) {
  return handleToggleWeather(state, 'bk_storm');
}

function handleToggleBkSnow(state: SimulationState) {
  return handleToggleWeather(state, 'bk_snow');
}

function handleToggleBkArmory(state: SimulationState) {
  return handleToggleWeather(state, 'bk_armory');
}

function handleToggleBkHeadquarter(state: SimulationState) {
  return handleToggleWeather(state, 'bk_headquarter');
}

function handleToggleBkRecovery(state: SimulationState) {
  return handleToggleWeather(state, 'bk_recovery');
}

function handleToggleBkEp(state: SimulationState) {
  return handleToggleWeather(state, 'bk_ep');
}
function handleToggleWind(state: SimulationState) {
  return handleToggleWeather(state, 'city_wind');
}

function handleToggleStone(state: SimulationState) {
  return handleToggleWeather(state, 'city_stone');
}

function handleToggleFire(state: SimulationState) {
  return handleToggleWeather(state, 'city_fire');
}

function handleToggleMetal(state: SimulationState) {
  return handleToggleWeather(state, 'city_metal');
}

function handleToggleWater(state: SimulationState) {
  return handleToggleWeather(state, 'city_water');
}

function handleToggleNature(state: SimulationState) {
  return handleToggleWeather(state, 'city_nature');
}

function handleToggleLazarett(state: SimulationState, { stufe, value }: ReturnType<typeof toggleLazarett>) {
  const unsetCurrent = state.weatherEffects.lazarett?.stufe === stufe && state.weatherEffects.lazarett.value === value;

  if (unsetCurrent) {
    return {
      ...state,
      weatherEffects: {
        ...state.weatherEffects,
        lazarett: undefined
      }
    };
  }
  return {
    ...state,
    weatherEffects: {
      ...state.weatherEffects,
      lazarett: { stufe, value }
    }
  };
}

function handleToggleKantine(state: SimulationState, { stufe }: ReturnType<typeof toggleKantine>) {
  const unsetCurrent = state.weatherEffects.kantine === stufe;

  if (unsetCurrent) {
    return {
      ...state,
      weatherEffects: {
        ...state.weatherEffects,
        kantine: undefined
      }
    };
  }
  return {
    ...state,
    weatherEffects: {
      ...state.weatherEffects,
      kantine: stufe
    }
  };
}

function handleToggleEliteUnits(state: SimulationState) {
  return {
    ...state,
    showEliteUnits: {
      ...state.showEliteUnits,
      [state.currentWave]: !state.showEliteUnits[state.currentWave]
    },
    selectedUnits: state.selectedUnits.map((units, idx) => (idx === state.currentWave ? {} : units))
  };
}

function handleChangeGeneral(state: SimulationState, action: ReturnType<typeof changeGeneral>) {
  const { general, index } = action;

  let myState = state;
  if (general && general.skills) {
    myState = handleUpdateSkills(myState, {
      type: CHANGE_SKILLS,
      skills: general.skills
    });
  }

  const currentGeneral = general && general.id ? general.id : null;
  const newState = {
    ...myState,
    highlightedGenerals: [...myState.highlightedGenerals],
    selectedGenerals: [...myState.selectedGenerals]
  };

  newState.highlightedGenerals[myState.currentWave] = index;
  newState.selectedGenerals[myState.currentWave] = currentGeneral;

  return newState;
}

function handleChangeAdventure(state: SimulationState, action: ReturnType<typeof changeAdventure>) {
  const { adventure } = action;

  const enemies = adventure === undefined ? [] : getEnemyTypesOfAdventureId(adventure);

  return {
    ...state,
    selectedAdventure: adventure ?? null,
    selectedCamps: [],
    availableEnemies: [enemies],
    selectedEnemies: [],
    currentEnemyWave: 0
  };
}

function handleUpdateSelectedUnits(
  state: SimulationState,
  action: ReturnType<typeof updateSelectedUnits> | ReturnType<typeof updateSelectedEnemies>
) {
  const { units } = action;

  const newUnits = Object.entries(units)
    .filter(([_unit, amount]) => amount && amount.length && amount !== '0' && !amount.startsWith('-'))
    .reduce((prev, [unit, amount]) => ({ ...prev, [unit]: amount }), {});

  const stateKey = action.type === 'update.selected.enemies' ? 'selectedEnemies' : 'selectedUnits';
  const waveKey = action.type === 'update.selected.enemies' ? 'currentEnemyWave' : 'currentWave';

  const newState = {
    ...state,
    [stateKey]: [...state[stateKey]]
  };
  newState[stateKey][state[waveKey]] = newUnits;
  return newState;
}

function handleAbortCampChange(state: SimulationState, action: ReturnType<typeof abortChangeCamp>) {
  return handleDeleteEnemyWave(state, {
    type: DELETE_ENEMY_WAVE,
    wave: state.currentEnemyWave
  });
}

function handleChangeSelectedCamp(state: SimulationState, action: ReturnType<typeof changeCamp>) {
  const { sector, number } = action;
  const selectedAdventure = state.selectedAdventure;
  if (selectedAdventure === null) {
    return state;
  }
  const enemiesFromCamp = getEnemiesFromCamp(selectedAdventure, sector, number);
  const newState = {
    ...state,
    selectedCamps: [...state.selectedCamps],
    selectedEnemies: [...state.selectedEnemies],
    availableEnemies: [...state.availableEnemies]
  };

  newState.selectedCamps[newState.currentEnemyWave] = sector !== undefined ? { sector, number } : null;
  newState.selectedEnemies[newState.currentEnemyWave] = { ...enemiesFromCamp };
  newState.availableEnemies[newState.currentEnemyWave] = Object.keys(enemiesFromCamp).map(
    stringKey => parseInt(stringKey) as EnemyId
  );

  return newState;
}

function handleChangeEnemyWave(state: SimulationState, action: ReturnType<typeof changeEnemyWave>) {
  const { wave } = action;
  if (wave === state.currentEnemyWave) {
    return state;
  }

  return { ...state, currentEnemyWave: parseInt(`${wave}`) };
}

function handleDeleteWave(state: SimulationState, action: ReturnType<typeof deleteWave>) {
  const { wave } = action;

  const attributesToReset = [
    'selectedGenerals',
    'selectedSkills',
    'selectedUnits',
    'showCustomGenerals',
    'highlightedGenerals'
  ] as const;

  const newState = attributesToReset.reduce((prevState, current) => {
    const someArray = [...prevState[current]];
    someArray.splice(parseInt(`${wave}`), 1);
    return {
      ...prevState,
      [current]: [...someArray]
    };
  }, state);
  if (newState.currentWave >= wave) {
    newState.currentWave = Math.max(0, state.currentWave - 1);
  }

  return newState;
}

function handleDeleteEnemyWave(state: SimulationState, action: ReturnType<typeof deleteEnemyWave>) {
  const { wave } = action;

  const enemyAttributes = ['selectedCamps', 'selectedEnemies', 'availableEnemies'] as const;

  const newState = enemyAttributes.reduce((prevState, current) => {
    const someArray = [...prevState[current]];
    someArray.splice(parseInt(`${wave}`), 1);
    return {
      ...prevState,
      [current]: [...someArray]
    };
  }, state);
  if (newState.currentEnemyWave >= wave) {
    newState.currentEnemyWave = Math.max(0, state.currentEnemyWave - 1);
  }

  return newState;
}

function handleChangeWave(state: SimulationState, action: ReturnType<typeof changeWave>) {
  const { wave: waveAttribute } = action;

  const wave = parseInt(`${waveAttribute}`);
  if (wave === state.currentWave) {
    return state;
  }
  const newState = {
    ...state,
    currentWave: wave,
    showCustomGenerals: [...state.showCustomGenerals]
  };

  if (wave >= newState.showCustomGenerals.length) {
    newState.showCustomGenerals[wave] = newState.showCustomGenerals[state.currentWave];
  }

  return newState;
}

function handleUpdateSkills(state: SimulationState, action: ReturnType<typeof updateSkills>) {
  const { skills } = action;
  let newState = state;
  if (skills) {
    newState = {
      ...state,
      selectedSkills: [...state.selectedSkills]
    };
    newState.selectedSkills[newState.currentWave] = { ...skills };
  }
  return newState;
}

function handleShowCustomGenerals(state: SimulationState, action: ReturnType<typeof showCustomGenerals>) {
  const { index } = action;

  if (state.showCustomGenerals[state.currentWave] === index) {
    return state;
  }

  return {
    ...state,
    showCustomGenerals: state.showCustomGenerals.map((gen, idx) => (idx === state.currentWave ? index : gen)),
    selectedSkills: state.selectedSkills.map((skills, idx) => (idx === state.currentWave ? null : skills)),
    highlightedGenerals: state.highlightedGenerals.map((gen, idx) => (idx === state.currentWave ? null : gen)),
    selectedGenerals: state.selectedGenerals.map((gen, idx) => (idx === state.currentWave ? null : gen))
  };
}

export default function simulationReducer(
  state: SimulationState | undefined = initialState.simulation,
  action: SimulationActions
): SimulationState {
  try {
    switch (action.type) {
      case TOGGLE_NEBEL:
        return handleToggleNebel(state);
      case TOGGLE_SONNENSCHEIN:
        return handleToggleSonnenschein(state);
      case TOGGLE_WIRBELSTURM:
        return handleToggleWirbelsturm(state);
      case TOGGLE_FROST:
        return handleToggleFrost(state);
      case CHANGE_ADVENTURE:
        return handleChangeAdventure(state, action);
      case CHANGE_CAMP:
        return handleChangeSelectedCamp(state, action);
      case CHANGE_GENERAL:
        return handleChangeGeneral(state, action);
      case TOGGLE_SHOW_ELITE_UNITS:
        return handleToggleEliteUnits(state);
      case UPDATE_SELECTED_ENEMIES:
        return handleUpdateSelectedUnits(state, action);
      case UPDATE_SELECTED_UNITS:
        return handleUpdateSelectedUnits(state, action);
      case TOGGLE_BK_ICE:
        return handleToggleBkIce(state);
      case TOGGLE_BK_COLD:
        return handleToggleBkCold(state);
      case TOGGLE_BK_STORM:
        return handleToggleBkStorm(state);
      case TOGGLE_BK_SNOW:
        return handleToggleBkSnow(state);
      case TOGGLE_BK_ARMORY:
        return handleToggleBkArmory(state);
      case TOGGLE_BK_HEADQUARTER:
        return handleToggleBkHeadquarter(state);
      case TOGGLE_BK_RECOVERY:
        return handleToggleBkRecovery(state);
      case TOGGLE_BK_EP:
        return handleToggleBkEp(state);
      case TOGGLE_WIND:
        return handleToggleWind(state);
      case TOGGLE_STONE:
        return handleToggleStone(state);
      case TOGGLE_WATER:
        return handleToggleWater(state);
      case TOGGLE_FIRE:
        return handleToggleFire(state);
      case TOGGLE_METAL:
        return handleToggleMetal(state);
      case TOGGLE_NATURE:
        return handleToggleNature(state);
      case CHANGE_WAVE:
        return handleChangeWave(state, action);
      case CHANGE_ENEMY_WAVE:
        return handleChangeEnemyWave(state, action);
      case ABORT_CHANGE_CAMP:
        return handleAbortCampChange(state, action);
      case SHOW_CUSTOM_GENERALS:
        return handleShowCustomGenerals(state, action);
      case CHANGE_SKILLS:
        return handleUpdateSkills(state, action);
      case DELETE_WAVE:
        return handleDeleteWave(state, action);
      case DELETE_ENEMY_WAVE:
        return handleDeleteEnemyWave(state, action);
      case TOGGLE_LAZARETT:
        return handleToggleLazarett(state, action);
      case TOGGLE_KANTINE:
        return handleToggleKantine(state, action);
      default:
        return state;
    }
  } catch (error) {
    console.error(error);
    return state;
  }
}
