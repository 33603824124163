import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BaseProps } from '../../types/basics';

interface Props extends BaseProps {
  text: string;
  placeholder?: string;
  enabled?: boolean;
  commit?: (content: string) => void;
}

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
function EditableUnstyled({ text, enabled = true, placeholder, commit = () => {}, ...otherProps }: Props) {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props outside the component
  const [isEditing, setEditing] = useState(false);
  const [content, setContent] = useState('');
  const childRef = useRef<HTMLInputElement>(null);

  /* 
    using use effect, when isEditing state is changing, check whether it is set to true, if true, then focus on the reference element
  */

  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  // Event handler while pressing any key while editing
  const handleKeyDown = (eventKey: string) => {
    // Handle when key is pressed
    if (eventKey === 'Enter') {
      setEditing(false);
      commit(content);
      setContent('');
    }
    if (eventKey === 'Escape') {
      setContent('');
    }
  };

  /*
- It will display a label is `isEditing` is false
- It will display the children (input or textarea) if `isEditing` is true
- when input `onBlur`, we will set the default non edit mode
Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
*/
  return (
    <div {...otherProps}>
      {isEditing ? (
        <div
          onBlur={() => {
            setEditing(false);
            commit(content);
            setContent('');
          }}
          onKeyDown={e => handleKeyDown(e.key)}>
          <input
            ref={childRef}
            type='text'
            name='content'
            value={content}
            onChange={e => setContent(e.target.value.substring(0, 10))}
          />
        </div>
      ) : (
        <div onClick={() => enabled && setEditing(true)}>
          <span>{text || placeholder || 'Editable content'}</span>
        </div>
      )}
    </div>
  );
}

const Editable = styled(EditableUnstyled)`
  & input {
    width: 12ch;
  }
`;

export default Editable;
