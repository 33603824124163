import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  isVisible: boolean;
  onHide: React.MouseEventHandler<HTMLDivElement>;
}

function SidePanel({
  isVisible,
  children,
  onHide,
  ...otherProps
}: React.PropsWithChildren<Props>) {
  return (
    <div {...otherProps}>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className="panel"
            initial={{ x: "100%", opacity: 0 }}
            animate={{ x: "0%", opacity: 1 }}
            exit={{ x: "100%", opacity: 0 }}
          >
            <div className="panel-header">
              <div className="title">
                <span className="title-text">Kampfdetails</span>
                <div className="close-button primary-button" onClick={onHide}>
                  <div>Close</div>
                </div>
              </div>
            </div>
            <div className="panel-content">{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default styled(SidePanel)`
  position: fixed;

  top: 0;
  right: 0;

  contain: stict;

  display: grid;
  grid-template-columns: [start] 100% [end];
  grid-template-rows: auto;

  .panel {
    height: 100vh;
    width: 32vw;
    grid-column: start;
    grid-row: 1;
    position: relative;
    background-color: var(--background-color-light);
    box-shadow: -10px 0px 10px 1px var(--background-color-dark);
    padding: 28px;

    display: flex;
    flex-direction: column;

    .panel-header {
      display: contents;

      .title {
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;

        .title-text {
          font-weight: bold;
          font-size: 1.2em;
          margin: auto 8px auto 0;
        }
      }
    }

    .panel-content {
      overflow: auto;
      flex: 1 0 0;
      margin: 18px 0 0 0;
    }
  }
`;
