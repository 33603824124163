import { v4 } from 'uuid';
import {
  getBattleTimeFromResult,
  getGeneralFromResult,
  getLostBanditsFromResult,
  getOurUnitsFromResult,
  toAmount
} from '.';
import { AttackSearchResult, DetailedResult } from '../../../app/store';
import { GeneralId, getUnitById, UnitId } from '../../../data-objects/units/Army';
import { AttackSearchSingleReport, calculateLossValueForLosses } from '../../../Finder/attack-utils';
import { Amount } from '../../../redux/simulationUtil';
import { allGeneralIds, calculateRecoveredUnits, isGeneral, unitDefaultValues } from '../../../redux/unitUtil';
import { convertToNumber } from '../../Basics/util';
import { ResultTableRow } from '../DetailedResult';
import { getOurArmyLosses } from './getOurArmyLosses';
import { getOurUnitLossesFromResult } from './getOurUnitLossesFromResult';

function isDetailedResult(
  result: DetailedResult | AttackSearchResult | AttackSearchSingleReport
): result is DetailedResult {
  return 'lossesMap' in result;
}

interface Props {
  result: DetailedResult | AttackSearchResult | AttackSearchSingleReport;
  unitValues: typeof unitDefaultValues;
}

export function computeLossData({ result, unitValues }: Props): ResultTableRow {
  var singleResult = result;
  var theEp: Amount | undefined;
  var resimulate;

  if ('ep' in result) {
    theEp = { ...result.ep };
  }
  if ('details' in result && result.details.length === 1) {
    singleResult = result.details[0];
  }

  const winPercent = isDetailedResult(singleResult) ? singleResult.boolResult : singleResult.battleResult;
  const weather = 'weather' in singleResult ? singleResult.weather : {}; // Base result has no weather!
  const waveInfo = 'attackerWave' in singleResult ? `${singleResult.attackerWave} 🠖 ${singleResult.enemyWave}` : '';
  const ourGeneral = getGeneralFromResult(singleResult);
  const timePerRound = ourGeneral.timePerRound || 10;
  const displayUnits = getOurUnitsFromResult(singleResult);

  const { rounds, battleRounds, abriss } = getBattleTimeFromResult(singleResult);
  const { gainedExperience, lostBandits } = getLostBanditsFromResult(singleResult, ourGeneral);
  const ourArmyLosses = getOurUnitLossesFromResult(singleResult);

  if (!theEp) {
    theEp = gainedExperience;
  }

  var lostGeneral;
  var lostUnits: Array<{ amount: Amount; icon: string; rang: number }> = [];
  var reallyLostUnits: Array<{ amount: Amount; icon: string; rang: number; unitId: UnitId }> = [];
  var recoveredUnits: Array<{ amount: Amount; icon: string; rang: number }> = [];
  lostGeneral =
    (ourArmyLosses[ourGeneral.id]?.max ?? 0) > 0
      ? {
          amount: ourArmyLosses[ourGeneral.id]!,
          icon: ourGeneral.icon
        }
      : undefined;

  lostUnits = Object.entries(ourArmyLosses)
    .filter(([unitId]) => parseInt(unitId) !== ourGeneral.id)
    .map(([unitId, amount]) => {
      const ourUnit = getUnitById(parseInt(unitId) as UnitId);
      return {
        amount: amount,
        icon: ourUnit.icon,
        rang: ourUnit.rang
      };
    });

  if ('losses' in singleResult || isDetailedResult(singleResult)) {
    const losses = isDetailedResult(singleResult) ? singleResult.lossesMap : singleResult.losses;
    const usedUnits = losses.usedUnits;

    const ourArmyLosses = getOurArmyLosses(losses, singleResult.simulations);
    const ourRealArmyLosses = getOurArmyLosses(losses, singleResult.simulations, 'detailsRecover');

    // All IDs of units we've lost, except the general
    const ourArmyLossIds = Object.keys(losses.units.combined)
      .filter(id => !allGeneralIds.includes(parseInt(id) as GeneralId) && ourArmyLosses[parseInt(id) as UnitId]?.max)
      .map(theId => parseInt(theId) as UnitId);

    const totalAmountOfMinLosses = ourArmyLossIds.map(id => {
      const usedUnit = usedUnits.units[id]?.unit;
      const rang = usedUnit && 'rang' in usedUnit ? usedUnit.rang : undefined;
      return {
        id,
        loss: ourArmyLosses[id]?.min,
        rang,
        recovery: usedUnit.recovery ?? 0
      };
    });
    const totalAmountOfMaxLosses = ourArmyLossIds.map(id => {
      const usedUnit = usedUnits.units[id]?.unit;
      const rang = usedUnit && 'rang' in usedUnit ? usedUnit.rang : undefined;
      return {
        id,
        loss: ourArmyLosses[id]?.max,
        rang,
        recovery: usedUnit.recovery ?? 0
      };
    });

    const minRecovered = calculateRecoveredUnits(totalAmountOfMinLosses);
    const maxRecovered = calculateRecoveredUnits(totalAmountOfMaxLosses);

    recoveredUnits = maxRecovered
      ? maxRecovered
          .filter(unit => unit.recovered)
          .map(unit => ({
            amount: toAmount(minRecovered?.find(recovered => recovered.id === unit.id)?.recovered ?? 0, unit.recovered),
            icon: usedUnits.units[unit.id]?.unit.icon,
            rang: usedUnits.units[unit.id]?.unit.rang
          }))
      : [];

    lostGeneral =
      (ourArmyLosses[ourGeneral.id]?.max ?? 0) > 0
        ? {
            amount: ourArmyLosses[ourGeneral.id]!,
            icon: ourGeneral.icon
          }
        : undefined;

    lostUnits = ourArmyLossIds.map(unitId => ({
      amount: ourArmyLosses[unitId]!,
      icon: usedUnits.units[unitId]?.unit.icon!,
      rang: usedUnits.units[unitId]?.unit.rang
    }));

    reallyLostUnits = Object.entries(ourRealArmyLosses)
      .filter(([id]) => !isGeneral(convertToNumber(id)))
      .map(([unitIdKey, amount]) => {
        const unitId = convertToNumber(unitIdKey) as UnitId;
        return {
          amount,
          unitId,
          icon: usedUnits.units[unitId]?.unit.icon!,
          rang: usedUnits.units[unitId]?.unit.rang
        };
      });
  }
  const lossValueUnits = reallyLostUnits.reduce((prev, { amount, unitId }) => ({ ...prev, [unitId]: amount }), {});
  const lossValue = calculateLossValueForLosses(lossValueUnits, unitValues);
  return {
    key: v4(),
    win: `${parseFloat(`${winPercent}`).toFixed(2)}%`,
    weather,
    simulations: singleResult.simulations.toLocaleString(),
    wave: waveInfo,

    rounds: toAmount(rounds[0], rounds[rounds.length - 1]),
    ep: theEp,
    time: toAmount(rounds[0] * timePerRound, rounds[rounds.length - 1] * timePerRound, 's'),
    battle: toAmount(battleRounds[0] * timePerRound, battleRounds[battleRounds.length - 1] * timePerRound, 's'),
    building: toAmount(abriss[0] * timePerRound, abriss[abriss.length - 1] * timePerRound, 's'),

    attacker: {
      general: {
        icon: ourGeneral.icon,
        amount: { min: 1, max: 1 }
      },
      units: displayUnits
    },
    enemy: { units: lostBandits },
    own: { general: lostGeneral, units: lostUnits },
    real: { units: reallyLostUnits },
    resurrected: { units: recoveredUnits },
    value: lossValue,
    details:
      'details' in singleResult
        ? singleResult.details.map(detailResult => computeLossData({ result: detailResult, unitValues }))
        : []
  };
}
