import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { SkillTree } from '../../data-objects/units/BaseUnitTypes';
import Popup from '../layouts/Popup';
import GeneralSkillTree from './GeneralSkillTree';

interface SkillPopupProps {
  saveSkills: (skills: SkillTree | null) => void;
  onClose: () => void;
  initialSkills: SkillTree;
  visible: boolean;
}

const SkillsPopup = styled(function SkillsPopup({
  saveSkills,
  onClose,
  initialSkills = {},
  visible,
  ...otherProps
}: SkillPopupProps) {
  const [skills, setSkills] = useState<SkillTree | null>(initialSkills);
  useEffect(() => {
    setSkills(initialSkills);
    // Update internal state when visible becomes visible
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Popup
      visible={visible}
      onClose={() => {
        saveSkills(skills);
        onClose();
      }}
      {...otherProps}>
      <GeneralSkillTree skills={skills} updateSkills={setSkills} />
    </Popup>
  );
})``;

export default SkillsPopup;
