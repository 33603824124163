import { convertToNumber } from '../components/Basics/util';
import { CustomGeneralEntry, MappedCustomGeneral } from '../components/Configurations/GeneralConfiguration';
import { GlobalEffects, LazarettBuff } from '../data-objects/GlobalEffects';
import {
  ARMORED_MARKSMAN,
  ArmyId,
  BESIEGER,
  BOWMAN,
  CANNONEER,
  CAVALRY,
  CHAMPION_NUSALA,
  CHAMPION_VARGUS,
  CROSS_BOWMAN,
  ELITE_SOLDIER,
  FROST_GENERAL,
  General,
  GeneralId,
  GENERAL_BORIS,
  getUnitByExpression,
  getUnitById,
  JUNGER_GEMINI_GENERAL,
  KNIGHT,
  LONG_BOWMAN,
  MARKSMAN,
  MILITIA,
  MOUNTED_MARKSMAN,
  MOUNTED_SWORDSMAN,
  RECRUIT,
  RESOLUTE_GENERAL,
  SOLDIER,
  STEADFAST_GENERAL,
  SWORDMAN,
  Unit,
  UnitId,
  VERMUMMTER_GENERAL
} from '../data-objects/units/Army';
import { BossType, GeneralType, Skill, SkillTree, UnitBaseType } from '../data-objects/units/BaseUnitTypes';
import {
  Enemy,
  EnemyId,
  getEnemyUnitById,
  UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID,
  UNIT_DÜNENSCHÜTZE_DÜS_ID,
  UNIT_KLINGENRASSLER_KRA_ID,
  UNIT_REITER_HM_ID,
  UNIT_SCHWERTMEISTER_SM_ID,
  UNIT_SÄBELSCHWINGER_SÄS_ID,
  UNIT_WÜSTENSCHÜTZE_WÜS_ID
} from '../data-objects/units/Units';
import { getIconsForGeneralId } from './configurationUtil';

export const unitDefaultValues = {
  [RECRUIT]: 1,
  [MILITIA]: 4,
  [SOLDIER]: 7,
  [ELITE_SOLDIER]: 12,
  [CAVALRY]: 7,
  [BOWMAN]: 2,
  [LONG_BOWMAN]: 4,
  [CROSS_BOWMAN]: 7,
  [CANNONEER]: 12,
  [SWORDMAN]: 40,
  [MOUNTED_SWORDSMAN]: 80,
  [KNIGHT]: 40,
  [MARKSMAN]: 60,
  [ARMORED_MARKSMAN]: 180,
  [MOUNTED_MARKSMAN]: 180,
  [BESIEGER]: 180
};

export const unitTimeValues = {
  [RECRUIT]: 180,
  [MILITIA]: 480,
  [SOLDIER]: 720,
  [ELITE_SOLDIER]: 1200,
  [CAVALRY]: 720,
  [BOWMAN]: 240,
  [LONG_BOWMAN]: 480,
  [CROSS_BOWMAN]: 720,
  [CANNONEER]: 1200
} as const;

export const unitEliteTimeValues = {
  [SWORDMAN]: 900,
  [MOUNTED_SWORDSMAN]: 1800,
  [KNIGHT]: 1200,
  [MARKSMAN]: 900,
  [ARMORED_MARKSMAN]: 1800,
  [MOUNTED_MARKSMAN]: 1800,
  [BESIEGER]: 1200
} as const;

interface ApplySkillsAndWeatherProps {
  general?: General | null;
  units: { id: UnitId }[];
  bandits: EnemyId[];
  skills?: SkillTree | null;
  weatherEffects?: GlobalEffects;
}

export const sortByRang = (a: { rang: number }, b: { rang: number }) => a.rang - b.rang;

export function applySkillsAndWeather({ general, units, bandits, skills, weatherEffects }: ApplySkillsAndWeatherProps):
  | {
      skilledGeneral: null;
      skilledUnits: Unit[];
      skilledBandits: Enemy[];
    }
  | {
      skilledGeneral: General;
      skilledUnits: Unit[];
      skilledBandits: Enemy[];
    } {
  if (!general) {
    return {
      skilledGeneral: null,
      skilledUnits: units.map(unit => getUnitById(unit.id)),
      skilledBandits: bandits.map(banditId => getEnemyUnitById(banditId))
    };
  }

  const myGeneral = general;
  const myUnits = units.map(unit => getUnitById(unit.id));
  const myBandits = bandits.map(banditId => getEnemyUnitById(banditId));

  const { skilledArmy, skilledBandits, skilledGeneral } = applyGeneralSkillsToUnits(myGeneral, myUnits, myBandits);

  const { updatedGeneral, updatedUnits, updatedBandits } = applySkills(
    skilledGeneral,
    skilledArmy,
    skilledBandits,
    skills
  );

  const { armyGlobalEffects, banditsGlobalEffects, generalGlobalEffects } = applyGlobalEffects(
    updatedGeneral,
    updatedUnits,
    updatedBandits,
    weatherEffects
  );
  return {
    skilledGeneral: generalGlobalEffects,
    skilledUnits: armyGlobalEffects,
    skilledBandits: banditsGlobalEffects
  };
}

function applySplash<T extends Unit | General | Enemy>(unit: T): T {
  return { ...unit, splash: 1 };
}

function applyFlanking<T extends Unit | General | Enemy>(unit: T): T {
  return { ...unit, flank: true };
}

function reduceHitpointsRelative<T extends Unit | General | Enemy>(unit: T, hpReduction: number): T {
  return increaseHitpointsRelative(unit, -hpReduction);
}

function increaseHitpointsRelative<T extends Unit | General | Enemy>(unit: T, hpIncrease: number): T {
  return {
    ...unit,
    hp: Math.max(1, Math.floor(unit.hp + unit.base.hp * hpIncrease))
  };
}

function reduceDamageRelative<T extends Unit | General | Enemy>(
  unit: T,
  minReduction: number,
  maxReduction: number
): T {
  return increaseDamageRelative(unit, -minReduction, -maxReduction);
}

function increaseDamageRelative<T extends Unit | General | Enemy>(
  unit: T,
  minIncrease: number,
  maxIncrease: number
): T {
  return {
    ...unit,
    min: Math.floor(unit.min + unit.base.min * minIncrease),
    max: Math.floor(unit.max + unit.base.max * maxIncrease)
  };
}

function increaseExperienceRelative<T extends Enemy>(unit: T, expIncrease: number): T {
  return {
    ...unit,
    ep: Math.floor(unit.ep + unit.base.ep * expIncrease)
  };
}

function applySkills(general: General, units: Unit[], bandits: Enemy[], skills?: SkillTree | null) {
  let updatedGeneral = { ...general, skills };
  let updatedUnits = units;
  let updatedBandits = bandits;

  if (skills) {
    Object.entries(skills).forEach(([key, skillValue]) => {
      // TODO: Kopfgeld und Feindl. Uebernahme
      if (key === Skill.KOPFGELD_ATTRIBUTE && skillValue) {
        // Ignore for now
      }
      if (key === Skill.FEINDLICHE_UEBERNAHME_ATTRIBUTE && skillValue) {
        // Ignore for now
      }

      if (key === Skill.BLITZANGRIFF_ATTRIBUTE && skillValue) {
        // already done by adding the skills to the general
      }
      if (key === Skill.MEISTERSTRATEGE_ATTRIBUTE && skillValue) {
        updatedGeneral = {
          ...updatedGeneral,
          accuracy: updatedGeneral.accuracy + 10
        };

        updatedUnits = updatedUnits.map(army => {
          return {
            ...army,
            accuracy: army.accuracy + 10
          };
        });
      }
      if (key === Skill.SPALTEN_ATTRIBUTE && skillValue) {
        updatedUnits = updatedUnits.map(army => {
          if (army.id !== ELITE_SOLDIER) {
            return army;
          }
          const extraDmg = skillValue * 4;
          const splash = skillValue / 3;
          return {
            ...army,
            splash,
            min: army.min + extraDmg,
            max: army.max + extraDmg
          };
        });
      }
      if (key === Skill.WOECHTENTLICHE_WARTUNG_ATTRIBUTE && skillValue) {
        updatedUnits = updatedUnits.map(army => {
          if (army.type !== UnitBaseType.HEAVY) {
            return army;
          }
          const extraDmg = skillValue * 10;
          return {
            ...army,
            min: army.min + extraDmg,
            max: army.max + extraDmg
          };
        });
      }
      if (key === Skill.GARNISONSAUSBAU_ATTRIBUTE && skillValue) {
        updatedGeneral = {
          ...updatedGeneral,
          capacity: updatedGeneral.capacity + 5 * skillValue
        };
      }
      if (key === Skill.SUVERAENER_ANFUEHRER_ATTRIBUTE && skillValue) {
        const bonus = skillValue * 15;
        const currentTime = updatedGeneral.timePerRound || 10;
        updatedGeneral = {
          ...updatedGeneral,
          timePerRound: currentTime * (1 - bonus / 100)
        };
      }
      if (key === Skill.UNAUFHALTSAMER_ANSTURM_ATTRIBUTE && skillValue) {
        updatedUnits = updatedUnits.map(army => {
          if (army.type !== UnitBaseType.SWIFT) {
            return army;
          }
          const extraDmg = skillValue;
          const splash = skillValue / 3;
          return {
            ...army,
            splash: Math.max(army.splash || 0, splash),
            min: army.min + extraDmg,
            max: army.max + extraDmg
          };
        });
      }
      if (key === Skill.KAMPFRAUSCH_ATTRIBUTE && skillValue) {
        // already done by adding the skills to the general
        const kampfrauschValue = skillValue * 10;
        const currentValue = updatedGeneral.kampfrausch ?? 0;
        updatedGeneral = {
          ...updatedGeneral,
          // Kampfrausch stapelt nicht, es gilt der hoechste Wert von allen Quellen
          // https://forum.diesiedleronline.de/threads/146880-Entwicklertagebuch-Halloween-Event-2022?p=1859210&viewfull=1#post1859210
          kampfrausch: Math.max(currentValue, kampfrauschValue)
        };
      }
      if (key === Skill.BLITZDENKER_ATTRIBUTE && skillValue) {
        const bonus = skillValue * 10;
        const currentBonus = updatedGeneral.bonusExp ?? 0;
        updatedGeneral = {
          ...updatedGeneral,
          bonusExp: currentBonus + bonus
        };
        updatedBandits = updatedBandits.map(army => increaseExperienceRelative(army, bonus / 100));
      }
      if (key === Skill.SCHARFSCHUETZENAUSBILDUNG_ATTRIBUTE && skillValue) {
        updatedUnits = updatedUnits.map(army => {
          if (army.id !== LONG_BOWMAN && army.id !== MARKSMAN) {
            return army;
          }
          const points = skillValue;
          const extraMinDmg = points === 1 ? 45 : points * 45 - 5;
          const extraMaxDmg = points * 5;
          return increaseDamageRelative(army, extraMinDmg / 100, extraMaxDmg / 100);
        });
      }
      if (key === Skill.UEBERRENNEN_ATTRIBUTE && skillValue) {
        const reduceHpPercent = Math.floor(8.4 * skillValue);
        updatedBandits = updatedBandits.map(army => {
          if (army.type === BossType.BOSS) {
            return reduceHitpointsRelative(army, reduceHpPercent / 100);
          }
          return { ...army };
        });
      }
      if (key === Skill.SCHNELLFEUER_ATTRIBUTE && skillValue) {
        updatedUnits = updatedUnits.map(army => {
          if (army.id !== BOWMAN) {
            return army;
          }
          const extraDmg = skillValue * 5;
          return {
            ...army,
            max: army.max + extraDmg
          };
        });
      }
      if (key === Skill.MOLOCH_ATTRIBUTE && skillValue) {
        const bonus = skillValue * 20;
        const splash = skillValue / 3;
        updatedGeneral = {
          ...updatedGeneral,
          splash: Math.max(updatedGeneral.splash || 0, splash),
          min: updatedGeneral.min + bonus,
          max: updatedGeneral.max + bonus
        };
      }
      if (key === Skill.ERSTE_HILFE_ATTRIBUTE && skillValue) {
        const bonus = skillValue * 3;
        updatedUnits = updatedUnits.map(army => ({ ...army, recovery: (army.recovery ?? 0) + bonus }));
        const currentRecovery = updatedGeneral.recovery || 0;
        updatedGeneral = {
          ...updatedGeneral,
          recovery: currentRecovery + bonus
        };
      }
    });
  } else {
    updatedGeneral.skills = {};
  }

  return { updatedGeneral, updatedUnits, updatedBandits };
}

function applyGeneralSkillsToUnits(general: General, units: Unit[], bandits: Enemy[]) {
  let skilledGeneral = general;
  let skilledBandits = bandits;
  let skilledArmy = units;

  switch (general.id) {
    case CHAMPION_NUSALA: {
      skilledArmy = skilledArmy.map(army => {
        if (army.type !== UnitBaseType.OFF) {
          return { ...army };
        }
        return applyFlanking(applySplash(army));
      });
      break;
    }
    case GENERAL_BORIS: {
      skilledArmy = skilledArmy.map(army => {
        if (army.type !== UnitBaseType.SWIFT) {
          return { ...army };
        }
        return increaseDamageRelative(army, 0.5, 0.5);
      });
      skilledBandits = skilledBandits.map(army => {
        if (army.type !== UnitBaseType.SWIFT) {
          return { ...army };
        }
        return reduceDamageRelative(army, 0.5, 0.5);
      });
      break;
    }
    case JUNGER_GEMINI_GENERAL: {
      skilledArmy = skilledArmy.map(army => {
        if (army.type !== UnitBaseType.DEF) {
          return { ...army };
        }
        const newUnit = applyFlanking(army);
        return {
          ...newUnit,
          accuracy: newUnit.accuracy + 10
        };
      });
      break;
    }
    case STEADFAST_GENERAL: {
      skilledArmy = skilledArmy.map(army => {
        if (army.type !== UnitBaseType.SWIFT) {
          return { ...army };
        }
        const newUnit = applyFlanking(applySplash(army));
        return {
          ...increaseDamageRelative(newUnit, 0.2, 0.2),
          accuracy: newUnit.accuracy + 20
        };
      });
      break;
    }
    case FROST_GENERAL: {
      skilledArmy = skilledArmy.map(army => {
        if (army.type !== UnitBaseType.DEF) {
          return { ...army };
        }
        return applySplash(army);
      });
      break;
    }
    case VERMUMMTER_GENERAL: {
      skilledBandits = skilledBandits.map(army => {
        if (army.type === UnitBaseType.DEF) {
          return reduceHitpointsRelative(army, 0.25);
        }
        if (army.type === BossType.BOSS) {
          return reduceHitpointsRelative(reduceDamageRelative(army, 0.25, 0.25), 0.25);
        }
        return { ...army };
      });
      break;
    }
    case RESOLUTE_GENERAL: {
      skilledArmy = skilledArmy.map(army => {
        if ([SOLDIER, ELITE_SOLDIER, SWORDMAN].includes(army.id)) {
          return reduceDamageRelative(increaseHitpointsRelative(applySplash(army), 1), 0.3, 0.3);
        }
        return { ...army };
      });
      skilledBandits = skilledBandits.map(army => {
        if (army.type === UnitBaseType.OFF || army.type === UnitBaseType.HEAVY) {
          return { ...reduceDamageRelative(army, 0.3, 0.3), flank: false };
        }
        return { ...army, flank: false };
      });
      break;
    }
  }
  if (general.suppressFlank) {
    skilledBandits = skilledBandits.map(army => ({
      ...army,
      flank: false
    }));
  }
  if (general.vargus && general.vargus > 0) {
    const dmgReduction = general.vargus / 100;
    skilledBandits = skilledBandits.map(army => ({
      ...reduceDamageRelative(army, dmgReduction, dmgReduction),
      flank: false
    }));
  }
  if (general.enemyAccuracyMalus && general.enemyAccuracyMalus > 0) {
    const myBonus = general.enemyAccuracyMalus;
    skilledBandits = skilledBandits.map(army => ({
      ...army,
      accuracy: Math.max(0, army.accuracy - Math.floor((army.base.accuracy * myBonus) / 100))
    }));
  }
  if (general.bonusHp && general.bonusHp > 0) {
    const myBonus = general.bonusHp;
    skilledGeneral = increaseHitpointsRelative(skilledGeneral, myBonus / 100);
    skilledArmy = skilledArmy.map(army => increaseHitpointsRelative(army, myBonus / 100));
  }
  if (general.bonusHeavy && general.bonusHeavy > 0) {
    const myBonus = general.bonusHeavy / 100;
    skilledArmy = skilledArmy.map(army => {
      if (army.type !== UnitBaseType.HEAVY) {
        return { ...army };
      }
      return increaseDamageRelative(army, myBonus, myBonus);
    });
  }
  if (general.bonusDef && general.bonusDef > 0) {
    const myBonus = general.bonusDef / 100;
    skilledArmy = skilledArmy.map(army => {
      if (army.type !== UnitBaseType.DEF) {
        return { ...army };
      }
      return increaseDamageRelative(army, myBonus, myBonus);
    });
  }
  if (general.bonusOff && general.bonusOff > 0) {
    const myBonus = general.bonusOff / 100;
    skilledArmy = skilledArmy.map(army => {
      if (army.type !== UnitBaseType.OFF) {
        return { ...army };
      }
      return increaseDamageRelative(army, myBonus, myBonus);
    });
  }
  if (general.bonusExp && general.bonusExp > 0) {
    const myBonus = general.bonusExp;
    skilledBandits = skilledBandits.map(army => increaseExperienceRelative(army, myBonus / 100));
  }
  if (general.recovery) {
    skilledArmy = skilledArmy.map(army => ({ ...army, recovery: (army.recovery ?? 0) + general.recovery! }));
  }

  return { skilledArmy, skilledBandits, skilledGeneral };
}

function applyGlobalEffects(general: General, units: Unit[], bandits: Enemy[], globalEffects?: GlobalEffects) {
  let updatedGeneral = general;
  let updatedArmy = units;
  let updatedBandits = bandits;
  if (globalEffects) {
    if (globalEffects.frost) {
      updatedArmy = updatedArmy.map(unit => {
        if (unit.type === UnitBaseType.DEF) {
          return applySplash(unit);
        }
        return unit;
      });

      updatedBandits = updatedBandits.map(unit => {
        if (unit.type === UnitBaseType.DEF) {
          return applySplash(unit);
        }
        return unit;
      });
    }
    if (globalEffects.sonnenschein) {
      updatedGeneral = increaseHitpointsRelative(updatedGeneral, 0.2);
      updatedArmy = updatedArmy.map(army => increaseHitpointsRelative(army, 0.2));
      updatedBandits = updatedBandits.map(army => increaseHitpointsRelative(army, 0.2));
    }
    if (globalEffects.nebel) {
      updatedGeneral = applyFlanking(updatedGeneral);
      updatedArmy = updatedArmy.map(army => applyFlanking(army));
      if (!updatedGeneral.suppressFlank) {
        updatedBandits = updatedBandits.map(army => applyFlanking(army));
      }
    }

    if (globalEffects.wirbelsturm) {
      updatedGeneral = increaseDamageRelative(updatedGeneral, 0.2, 0.2);

      updatedArmy = updatedArmy.map(army => increaseDamageRelative(army, 0.2, 0.2));
      updatedBandits = updatedBandits.map(army => increaseDamageRelative(army, 0.2, 0.2));
    }

    // Bergklan - Wetter
    if (globalEffects.bk_cold) {
      updatedGeneral = reduceHitpointsRelative(updatedGeneral, 0.1);
      updatedArmy = updatedArmy.map(army => reduceHitpointsRelative(army, 0.1));
    }
    if (globalEffects.bk_ice) {
      updatedGeneral = reduceDamageRelative(updatedGeneral, 0.15, 0.15);

      updatedArmy = updatedArmy.map(army =>
        army.type === UnitBaseType.SWIFT || army.type === UnitBaseType.DEF
          ? reduceDamageRelative(army, 0.15, 0.15)
          : army
      );
    }
    if (globalEffects.bk_storm) {
      updatedGeneral.accuracy = updatedGeneral.accuracy - 15;
      updatedArmy = updatedArmy.map(army => ({
        ...army,
        accuracy: army.accuracy - 15
      }));
    }
    if (globalEffects.bk_headquarter) {
      updatedBandits = updatedBandits.map(army =>
        army.type === BossType.BOSS ? reduceHitpointsRelative(army, 0.05) : army
      );
    }
    if (globalEffects.bk_armory) {
      // Increase only max damage by 5%
      updatedGeneral = increaseDamageRelative(updatedGeneral, 0, 0.05);
      updatedArmy = updatedArmy.map(army => increaseDamageRelative(army, 0, 0.05));
    }
    if (globalEffects.bk_recovery) {
      const currentRecovery = updatedGeneral.recovery || 0;
      updatedGeneral.recovery = currentRecovery + 2;
    }
    if (globalEffects.bk_ep) {
      const currentEpBonus = updatedGeneral.bonusExp || 0;
      updatedGeneral.bonusExp = currentEpBonus + 5;
      updatedBandits = updatedBandits.map(army => increaseExperienceRelative(army, 0.05));
    }

    // Lost city buffs
    if (globalEffects.city_wind) {
      updatedBandits = updatedBandits.map(army =>
        army.id === UNIT_DÜNENSCHÜTZE_DÜS_ID ||
        army.id === UNIT_WÜSTENSCHÜTZE_WÜS_ID ||
        army.id === UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID
          ? {
              ...army,
              accuracy: 0
            }
          : army
      );
    }
    if (globalEffects.city_stone) {
      updatedBandits = updatedBandits.map(army =>
        army.id === UNIT_KLINGENRASSLER_KRA_ID ||
        army.id === UNIT_SÄBELSCHWINGER_SÄS_ID ||
        army.id === UNIT_SCHWERTMEISTER_SM_ID
          ? {
              ...army,
              accuracy: 0
            }
          : army
      );
    }
    if (globalEffects.city_fire) {
      updatedArmy = updatedArmy.map(army =>
        army.id === MARKSMAN || army.id === MOUNTED_MARKSMAN || army.id === ARMORED_MARKSMAN
          ? {
              ...army,
              accuracy: 100
            }
          : army
      );
    }
    if (globalEffects.city_water) {
      updatedArmy = updatedArmy.map(army =>
        army.id === KNIGHT || army.id === MOUNTED_MARKSMAN || army.id === MOUNTED_SWORDSMAN || army.id === CAVALRY
          ? {
              ...army,
              accuracy: 100
            }
          : army
      );
    }
    if (globalEffects.city_metal) {
      updatedArmy = updatedArmy.map(army =>
        army.type === UnitBaseType.DEF || army.id === ARMORED_MARKSMAN || army.id === BESIEGER || army.id === CANNONEER
          ? {
              ...army,
              accuracy: 100
            }
          : army
      );
    }
    if (globalEffects.city_nature) {
      updatedBandits = updatedBandits.map(army =>
        army.id === UNIT_REITER_HM_ID || army.id === UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID
          ? {
              ...army,
              accuracy: 0
            }
          : army
      );
    }

    const { lazarett, kantine } = globalEffects;
    if (lazarett) {
      const { value: effectLevel, stufe } = lazarett;
      const affectedArmyIds = getAffectedLazarettUnits(stufe);
      const bonus = effectLevel * 3;
      updatedArmy = updatedArmy.map(army =>
        affectedArmyIds.includes(army.id)
          ? {
              ...army,
              recovery: (army.recovery ?? 0) + bonus
            }
          : army
      );
    }

    if (kantine) {
      const hpBonus = (kantine * 4) / 100;
      const dmgBonus = Math.max(0, (kantine - 2) * 4) / 100;
      updatedArmy = updatedArmy.map(army =>
        increaseHitpointsRelative(increaseDamageRelative(army, dmgBonus, dmgBonus), hpBonus)
      );
    }
  }
  return {
    armyGlobalEffects: updatedArmy,
    banditsGlobalEffects: updatedBandits,
    generalGlobalEffects: updatedGeneral
  };
}

function getAffectedLazarettUnits(stufe: LazarettBuff['stufe']) {
  const basisLazarettAffectedArmyIds = [RECRUIT, BOWMAN, MILITIA, CAVALRY];
  const enhancedLazarettAffectedArmyIds = [
    ...basisLazarettAffectedArmyIds,
    LONG_BOWMAN,
    SOLDIER,
    ELITE_SOLDIER,
    CROSS_BOWMAN,
    CANNONEER
  ];

  if (stufe === 'basis') {
    return basisLazarettAffectedArmyIds;
  }
  if (stufe === 'enhanced') {
    return enhancedLazarettAffectedArmyIds;
  }
  return [
    ...enhancedLazarettAffectedArmyIds,
    SWORDMAN,
    MOUNTED_SWORDSMAN,
    KNIGHT,
    MARKSMAN,
    ARMORED_MARKSMAN,
    MOUNTED_MARKSMAN,
    BESIEGER
  ];
}

export interface LossEntry {
  loss: number | undefined;
  rang: number | undefined;
  recovery: number;
  id: ArmyId;
}

export function calculateRecoveredUnits(allLosses: Array<LossEntry>): Array<{
  recovered: number;
  id: ArmyId;
}> | null {
  const unitsWithRecovery = allLosses.filter(unit => unit.recovery);
  if (!unitsWithRecovery.length) {
    return null;
  }

  const maxNumberOfRecoverableUnits = getNumberOfRecoverableUnits(unitsWithRecovery);

  const recoveredMax = unitsWithRecovery.map(unit => ({
    ...unit,
    recovered: Math.floor(((unit.loss ?? 0) * unit.recovery) / 100),
    fraction: parseInt(((((unit.loss ?? 0) * unit.recovery) / 100) % 1).toFixed(4).substring(2))
  }));

  const noOfRecoveredUnits = recoveredMax.reduce((total, unit) => total + unit.recovered, 0);

  if (maxNumberOfRecoverableUnits - noOfRecoveredUnits > 0) {
    const remainingRecoveries = maxNumberOfRecoverableUnits - noOfRecoveredUnits;
    recoveredMax.sort((entryA, entryB) =>
      entryA.fraction === entryB.fraction
        ? (entryA.rang ?? -1) - (entryB.rang ?? -1)
        : entryB.fraction - entryA.fraction
    );

    for (let i = 0; i < remainingRecoveries; i++) {
      recoveredMax[i % recoveredMax.length].recovered = recoveredMax[i % recoveredMax.length].recovered + 1;
    }
  }
  return recoveredMax.map(({ id, recovered }) => ({ id, recovered }));
}

function getNumberOfRecoverableUnits(units: Array<LossEntry>) {
  const recoveryPercentages = units.reduce<Record<number, number>>((percentages, unit) => {
    // Sum up losses per recovery percent value
    return { ...percentages, [unit.recovery]: (percentages[unit.recovery] ?? 0) + (unit.loss ?? 0) };
  }, {});

  const recoverableUnits = Object.entries(recoveryPercentages).reduce(
    (amount, [percent, noOfLosses]) => amount + (convertToNumber(percent) * noOfLosses) / 100,
    0
  );

  return Math.max(0, Math.ceil(recoverableUnits));
}

export function getGeneralCapacity(gen: { capacity: number; skills?: SkillTree | null }) {
  const capacityBonus = (gen.skills?.garnisonsausbau ?? 0) * 5;
  return gen.capacity + capacityBonus;
}

export const generalMap = getAllGenerals().reduce(
  (prev, gen) => ({ ...prev, [gen.id]: gen }),
  {} as { [x: number]: General }
);

export function mapToCombatGeneral(general: General | MappedCustomGeneral): General {
  if ('baseId' in general) {
    const baseGeneral = generalMap[general.baseId];
    return {
      ...baseGeneral,
      name: general.name,
      skills: general.skills,
      icon: general.icon,
      abr: general.abr,
      uuid: general.uuid
    };
  } else {
    return general;
  }
}

export function getAllGenerals(): General[] {
  return getUnitByExpression((unit): unit is Unit | General => unit.type === GeneralType.GENERAL) as General[];
}

export function createMappedGenerals(generals?: CustomGeneralEntry[]): MappedCustomGeneral[] {
  if (!generals) {
    return [];
  }
  return generals.map(gen => ({
    ...getUnitById(gen.baseId),
    icon: getIconsForGeneralId(gen.baseId)[gen.icon],
    abr: gen.name,
    uuid: gen.id,
    id: gen.baseId,
    skills: { ...gen.skills },
    baseId: gen.baseId,
    iconIndex: gen.icon,
    prefersAttack: gen.prefersAttack,
    prefersBlock: gen.prefersBlock
  }));
}

export function isGeneral(id: number) {
  return allGeneralIds.includes(id as GeneralId);
}

export const ourDefaultArmy: Unit[] = (
  getUnitByExpression((unit): unit is Unit => unit.type !== GeneralType.GENERAL && !unit.elite) as Unit[]
).sort(sortByRang);

export const ourEliteArmy: Unit[] = (
  getUnitByExpression((unit): unit is Unit => unit.type !== GeneralType.GENERAL && unit.elite === true) as Unit[]
).sort(sortByRang);

export const availableGenerals = getAllGenerals();
export const allGeneralIds = getAllGenerals().map(general => general.id);

export const ourArmyIds = [...ourDefaultArmy.map(unit => unit.id), ...ourEliteArmy.map(unit => unit.id)];

export const ourArmyMap = [...ourDefaultArmy, ...ourEliteArmy].reduce(
  (prev, unit) => ({ ...prev, [unit.id]: unit }),
  {} as Record<number, Unit>
);
