import { useSelector } from 'react-redux';
import { AppState } from '../app/store';
import { SearchStatus } from './search-army/SearchStatus';

const generalsListSelector = (state: AppState) => state.configuration.generalsList;
const currentDarkMode = (state: AppState) => state.configuration.darkMode;
const unitValuesSelector = (state: AppState) => state.configuration.unitValues;
const configurationSelector = (state: AppState) => state.configuration;
const selectedGeneralsListSelector = (state: AppState) => state.configuration.selectedGeneralsList;

const currentWave = (state: AppState) => state.simulation.currentWave;
const currentEnemyWave = (state: AppState) => state.simulation.currentEnemyWave;

const highlightedGeneralSelector = (state: AppState) => state.simulation.highlightedGenerals;
const showCustomGeneralsSelector = (state: AppState) => state.simulation.showCustomGenerals;
const selectedSkillsSelector = (state: AppState) => state.simulation.selectedSkills;
const selectedUnitsSelector = (state: AppState) => state.simulation.selectedUnits;
const showEliteSelector = (state: AppState) => state.simulation.showEliteUnits;
const selectedGeneralsSelector = (state: AppState) => state.simulation.selectedGenerals;

const currentHighlightedGeneralSelector = (state: AppState) => highlightedGeneralSelector(state)[currentWave(state)];
const currentShowCustomGeneralsSelector = (state: AppState) => showCustomGeneralsSelector(state)[currentWave(state)];
const currentSelectedSkillsSelector = (state: AppState) => selectedSkillsSelector(state)[currentWave(state)];
const currentSelectedUnitsSelector = (state: AppState) => selectedUnitsSelector(state)[currentWave(state)];
const currentShowEliteSelector = (state: AppState) => showEliteSelector(state)[currentWave(state)];
const currentSelectedGeneralSelector = (state: AppState) => selectedGeneralsSelector(state)[currentWave(state)];

const selectedEnemiesSelector = (state: AppState) => state.simulation.selectedEnemies;
const selectedCampsSelector = (state: AppState) => state.simulation.selectedCamps;
const availableEnemiesSelector = (state: AppState) => state.simulation.availableEnemies;

const currentSelectedEnemiesSelector = (state: AppState) => selectedEnemiesSelector(state)[currentEnemyWave(state)];
const currentAvailableEnemiesSelector = (state: AppState) => availableEnemiesSelector(state)[currentEnemyWave(state)];

const globalEffectsSelector = (state: AppState) => state.simulation.weatherEffects;
const simulationResultSelector = (state: AppState) => state.result.waveResult;

const currentAdventureSelector = (state: AppState) => state.simulation.selectedAdventure;
const currentSelectedCampSelector = (state: AppState) => selectedCampsSelector(state)[currentEnemyWave(state)];
const currentLockSearchArmyResults = (state: AppState) => state.searchArmy.found;
const currentAttackSearchArmyResults = (state: AppState) => state.searchArmy.attack;
const currentSearchArmySearchSpace = (state: AppState) => state.ui.search.maxSearchSpace;
const currentSearchArmyProgress = (state: AppState) => state.ui.search.progress;
const currentSearchStatus = (state: AppState) => state.ui.search.status;

const currentAttackSearchInput = (state: AppState) => state.ui.attackSearchInput;
const currentLockSearchInput = (state: AppState) => state.ui.lockSearchInput;

const displayedResults = (state: AppState) => state.ui.shownResult;

const blockPreferences = (state: AppState) => state.configuration.blockPreferences;

const showLockResultSelectable = (state: AppState) =>
  Object.entries(state.searchArmy.found).length > 0 ||
  (state.searchArmy.currentSearch !== undefined && !state.searchArmy.currentSearch.isAttack);

const showAttackResultSelectable = (state: AppState) =>
  Object.entries(state.searchArmy.attack).length > 0 ||
  (state.searchArmy.currentSearch !== undefined && state.searchArmy.currentSearch.isAttack);

const showSimulationResultSelectable = (state: AppState) =>
  state.result.waveResult.length > 0 || state.ui.simulationInProgress;

const isSearchRunning = (state: AppState) => state.ui.search.status !== SearchStatus.READY;

export function useShowLockResultSelectable() {
  return useSelector(showLockResultSelectable);
}

export function useShowAttackResultSelectable() {
  return useSelector(showAttackResultSelectable);
}

export function useShowSimulationResultSelectable() {
  return useSelector(showSimulationResultSelectable);
}

export function useGeneralsList() {
  return useSelector(generalsListSelector);
}

export function useBlockPreferences() {
  return useSelector(blockPreferences);
}

export function useSearchStatus() {
  return useSelector(currentSearchStatus);
}
export function useAttackSearchInput() {
  return useSelector(currentAttackSearchInput);
}
export function useLockSearchInput() {
  return useSelector(currentLockSearchInput);
}

export function useMaxSearchSpace() {
  return useSelector(currentSearchArmySearchSpace);
}

export function useSearchProgress() {
  return useSelector(currentSearchArmyProgress);
}

export function useHighlightedGeneral() {
  return useSelector(highlightedGeneralSelector);
}
export function useShowCustomGenerals() {
  return useSelector(showCustomGeneralsSelector);
}
export function useSelectedSkills() {
  return useSelector(selectedSkillsSelector);
}
export function useSelectedUnits() {
  return useSelector(selectedUnitsSelector);
}
export function useShowEliteUnits() {
  return useSelector(showEliteSelector);
}
export function useSelectedGenerals() {
  return useSelector(selectedGeneralsSelector);
}
export function useCurrentWave() {
  return useSelector(currentWave);
}
export function useCurrentEnemyWave() {
  return useSelector(currentEnemyWave);
}

export function useCurrentHighlightedGeneral() {
  return useSelector(currentHighlightedGeneralSelector);
}
export function useCurrentShowCustomGenerals() {
  return useSelector(currentShowCustomGeneralsSelector);
}
export function useCurrentSelectedSkills() {
  return useSelector(currentSelectedSkillsSelector);
}
export function useCurrentSelectedUnits() {
  return useSelector(currentSelectedUnitsSelector);
}
export function useCurrentShowEliteUnits() {
  return useSelector(currentShowEliteSelector);
}
export function useCurrentSelectedGeneral() {
  return useSelector(currentSelectedGeneralSelector);
}

export function useSelectedEnemies() {
  return useSelector(selectedEnemiesSelector);
}
export function useSelectedCamps() {
  return useSelector(selectedCampsSelector);
}
export function useAvailableEnemies() {
  return useSelector(availableEnemiesSelector);
}

export function useCurrentSelectedEnemies() {
  return useSelector(currentSelectedEnemiesSelector);
}
export function useCurrentAvailableEnemies() {
  return useSelector(currentAvailableEnemiesSelector);
}

export function useGlobalEffects() {
  return useSelector(globalEffectsSelector);
}

export function useCustomgenerals() {
  return useSelector(generalsListSelector);
}
export function useUnitValues() {
  return useSelector(unitValuesSelector);
}

export function useSimulationResult() {
  return useSelector(simulationResultSelector);
}

export function useCurrentSelectedCamp() {
  return useSelector(currentSelectedCampSelector);
}

export function useCurrentSelectedAdventure() {
  return useSelector(currentAdventureSelector);
}

export function useCurrentLockSearchArmyResults() {
  return useSelector(currentLockSearchArmyResults);
}

export function useCurrentAttackSearchArmyResults() {
  return useSelector(currentAttackSearchArmyResults);
}

export function useDarkMode() {
  return useSelector(currentDarkMode);
}
export function useSelectedGeneralsList() {
  return useSelector(selectedGeneralsListSelector);
}

export function useConfiguration() {
  return useSelector(configurationSelector);
}

export function useDisplayedResults() {
  return useSelector(displayedResults);
}

export function useIsSimulationRunning() {
  return useSelector(isSearchRunning);
}
