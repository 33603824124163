import { Amount } from "../../../redux/simulationUtil";
import { roundNicely } from "./roundNicely";

export function toAmount(
  valueOne: number,
  valueTwo: number,
  unit?: string
): Amount {
  const minValue = roundNicely(Math.min(valueOne, valueTwo));
  const maxValue = roundNicely(Math.max(valueOne, valueTwo));
  return { min: minValue, max: maxValue, unit };
}
