// Configurations
export enum ConfigurationActionTypes {
  CHANGE_UNIT_VALUE = 'change.unit.value',
  ADD_CUSTOM_GENERAL = 'add.custom.general',
  UPDATE_CUSTOM_GENERAL = 'update.custom.general',
  DELETE_CUSTOM_GENERAL = 'delete.custom.general',
  CREATE_GENERAL_LIST = 'create.general.list',
  SELECT_GENERAL_LIST = 'select.general.list',
  DELETE_GENERAL_LIST = 'delete.general.list',
  IMPORT_CONFIGURATION = 'import.configuration',
  TOGGLE_DARK_MODE = 'toggle.dark.mode',
  CHANGE_BLOCK_GENERAL = 'change.block.defaultgeneral',
  TOGGLE_BLOCK_NORMAL_UNIT = 'change.block.normal.unit',
  TOGGLE_BLOCK_ELITE_UNIT = 'change.block.elite.unit',
  CHANGE_MAX_FOUND_BLOCKS = 'change.max.blocks',
  CHANGE_SIMULATIONS_PER_BLOCK = 'change.blocks.simulations',
  CHANGE_DEFAULT_BLOCK_LENGTH = 'change.default.blocklength',
  CHANGE_PREFERED_HORSESHOE = 'change.prefered.horseshoe',
  UPDATE_GENERAL_LIST = 'update.custom.general.order'
}
