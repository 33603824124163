import { BlockPreferences, SearchResult } from "../../app/store";
import { GlobalEffects } from "../../data-objects/GlobalEffects";
import { General } from "../../data-objects/units/Army";
import { getGeneralCapacity } from "../../redux/unitUtil";
import {
  PrepareSearchProps,
  searchSingleBlock,
  SearchSingleBlock,
} from "../../worker/finderWorker";
import { SearchCamp } from "./convertToSearchableCamp";
import { getMinLockTime } from "./getMinLockTime";

export async function searchLock(
  finderGenerals: Array<General>,
  selectedUnits: number[],
  selectedCamp: SearchCamp,
  value: number,
  clearPreviousResults: boolean,
  selectedGlobalEffects: GlobalEffects[] = [{}],
  preferences: BlockPreferences,
  searchLock: (
    props: SearchSingleBlock
  ) => Promise<ReturnType<typeof searchSingleBlock>>,
  startNewSearch: (
    camps: SearchCamp[],
    clearPrevious: boolean,
    isAttack?: true
  ) => void,
  prepareSearch: (
    args: PrepareSearchProps
  ) => Promise<{ configs: any; usedStepSize: number }>,
  registerSearchAmount: (value: number) => void,
  shouldAbort: () => boolean,
  doAbort: () => void,
  updateProgress: (value: number) => void,
  addSearchResult: (general: General, result: SearchResult) => void,
  addMultipleResults: (general: General, results: SearchResult[]) => void,
  finishSearch: () => void
) {
  if (value < 20) {
    return;
  }

  startNewSearch([selectedCamp], clearPreviousResults);

  const finderGlobalEffects = selectedGlobalEffects;
  const choosenUnits = selectedUnits.map((unitId) => ({
    id: unitId,
  }));

  const { configs: allConfigs, usedStepSize: initialStepSize } =
    await prepareSearch({
      finderGenerals,
      choosenUnits,
    });
  registerSearchAmount(
    allConfigs.length * finderGlobalEffects.length * finderGenerals.length
  );

  for (let gen = 0; gen < finderGenerals.length; gen++) {
    let genResultCounter = 0;
    const fallbacks = [];
    const anyResult = [];
    const general = finderGenerals[gen];
    const timePerRound = general.timePerRound ?? 10;
    const generalCapacity = getGeneralCapacity(general);
    for (
      let weatherIndex = 0;
      weatherIndex < finderGlobalEffects.length;
      weatherIndex++
    ) {
      const effect = finderGlobalEffects[weatherIndex];
      let resultCounter = 0;
      for (
        let configIndex = 0;
        configIndex < allConfigs.length;
        configIndex++
      ) {
        if (shouldAbort()) {
          doAbort();
          return;
        }
        if (resultCounter >= preferences.maxResults) {
          updateProgress(allConfigs.length - configIndex);
          break;
        }
        const currentArmy = allConfigs[configIndex];
        const singleResult = await searchLock({
          general,
          generalCapacity,
          unit: currentArmy,
          simulationRuns: 5,
          detailedSimulationRuns: preferences.detailedBlockSimulations,
          timePerRound,
          filterResultByTime: value,
          banditCamps: [selectedCamp],
          weatherToUse: effect,
          currentStepSize: initialStepSize,
        });
        if (singleResult?.detailed) {
          if (getMinLockTime(singleResult.detailed) * timePerRound >= value) {
            resultCounter++;
          }
          addSearchResult(general, {
            weather: effect,
            ...singleResult.detailed,
          });
        }
        if (singleResult?.interessting) {
          fallbacks.push({
            ...singleResult.interessting,
            weather: effect,
          });
        }
        if (singleResult?.potentially) {
          anyResult.push({
            ...singleResult.potentially,
            weather: effect,
          });
        }
        updateProgress(1);
      }
      genResultCounter += resultCounter;
    }

    if (genResultCounter < preferences.maxResults) {
      if (fallbacks.length < preferences.maxResults) {
        addMultipleResults(general, anyResult);
      }
      addMultipleResults(general, fallbacks);
    }
  }

  finishSearch();
  return;

  // interessting results are results with block times near the current target
  // They may be handled similar like the potential results.
  /*
    if (interesstingResults.length) {
      const furtherResults = await searchFurtherBlocks(
        searchEngine,
        interesstingResults,
        value,
        selectedCamp
      );
      furtherResults.forEach((newResult) => {
        dispatch(
          actions.addSearchResult(
            newResult.general,
            newResult.weather,
            newResult.results
          )
        );
      });
    }
    setSearchStatus(SearchStatus.READY);
    */
}
