import { sum } from 'lodash';
import { convertToNumber } from '../components/Basics/util';
import { SelectedUnits, UserInputUnits } from '../components/units/UnitSelection';
import { CampBuilding } from '../data-objects/CampTypes';
import { GlobalEffects } from '../data-objects/GlobalEffects';
import { General, GeneralId, getUnitById, UnitId } from '../data-objects/units/Army';
import { SkillTree } from '../data-objects/units/BaseUnitTypes';
import { EnemyId } from '../data-objects/units/Units';
import { SearchCamp } from '../Finder/utils';
import { useSimulationEngine } from '../hooks/useExampleWorker';
import { AllUnits, LossMap } from '../worker/simulate';
import { applySkillsAndWeather } from './unitUtil';

export type LossesMap = Record<number | string, Amount>;

export interface BanditLosses {
  combined: LossesMap;
  details: Record<number, SelectedUnits>;
  min: LossMap;
  max: LossMap;
}

export interface UnitLosses extends BanditLosses {
  detailsRecover: Record<number, SelectedUnits>;
}

export interface SimulationLosses {
  bandits: BanditLosses;
  units: UnitLosses;
  abriss: { min: number; max: number; details: LossMap };
  rounds: { min: number; max: number; details: LossMap };
  battleRounds: { min: number; max: number; details: LossMap };
  usedUnits: AllUnits;
}

export function validateSetup(
  generals: Array<GeneralId | null>,
  units: Array<UserInputUnits>,
  bandits: Array<UserInputUnits>
) {
  if (!generals.length || !generals.some(gen => gen)) {
    return 'Kein General ausgewählt.';
  }
  if (
    !units.some(selectedUnits => {
      if (selectedUnits === undefined) {
        return true;
      }

      const counted = Object.values(selectedUnits).reduce((prev, current) => prev + parseInt(`${current}`), 0);

      return counted > 0;
    })
  ) {
    return 'Keine Einheiten ausgewählt zum kämpfen.';
  }
  return null;
}

export interface Amount {
  min: number;
  max: number;
  avg?: number;
  unit?: string;
}

export function getLosses(amount: Amount, details: SelectedUnits, simulationRuns: number): Amount {
  let minLosses = amount.max; //'max' indicated, the maximum dmg of our units has been dealt
  let maxLosses = amount.min; // 'min' accordingly - only min dmg has been dealt by our units
  let averageLosses = undefined;

  const losses = Object.keys(details).map(key => parseInt(key));
  averageLosses = losses.reduce((prev, current) => prev + current * (details[current] ?? 0), 0) / simulationRuns;

  losses.sort((a, b) => a - b);
  minLosses = losses[0];
  maxLosses = losses[losses.length - 1];

  return { min: minLosses, max: maxLosses, avg: averageLosses };
}

export interface PrepareSimulationProps {
  units: SelectedUnits;
  general?: General | null;
  skills?: SkillTree | null;
  enemies: SelectedUnits;
  camp: CampBuilding;
  weather?: GlobalEffects;
}
export interface PrepareSimulationCampProps {
  units: SelectedUnits;
  general?: General | null;
  skills?: SkillTree | null;
  camp: SearchCamp;
  weather?: GlobalEffects;
}

export function prepareForSimulation({
  units,
  general,
  skills,
  weather,
  ...campProps
}: PrepareSimulationProps | PrepareSimulationCampProps) {
  var enemies: SelectedUnits;
  var camp: CampBuilding;

  if ('enemies' in campProps) {
    camp = campProps.camp;
    enemies = campProps.enemies;
  } else {
    camp = campProps.camp.camp;
    enemies = campProps.camp.enemies.reduce<SelectedUnits>(
      (prev, enemy) => ({
        ...prev,
        [enemy.typeId]: enemy.amount
      }),
      {}
    );
  }

  const ourUnits = Object.entries(units)
    .filter(([_key, amount]) => convertToNumber(`${amount}`) > 0)
    .map(([key]) => ({
      id: parseInt(key) as UnitId
    }));

  // For each wave, apply skills and weather
  const { skilledUnits, skilledBandits, skilledGeneral } = applySkillsAndWeather({
    general,
    units: ourUnits,
    bandits: Object.entries(enemies)
      .filter(([_key, value]) => convertToNumber(`${value}`) > 0)
      .map(([key]) => parseInt(key) as EnemyId),
    skills,
    weatherEffects: weather
  });
  if (skilledGeneral === null) {
    return;
  }

  const simUnits = skilledUnits.map(unit => ({
    amount: convertToNumber(`${units[unit.id]}`),
    unit
  }));
  const simBandits = skilledBandits.map(bandit => ({
    amount: convertToNumber(`${enemies[bandit.id]}`),
    unit: bandit
  }));

  return {
    general: skilledGeneral,
    units: simUnits,
    enemies: simBandits,
    enemyCamp: camp
  };
}

export interface StartAttackProps {
  generals: Array<GeneralId>;
  skills: Array<SkillTree | null>;
  attacker: Array<SelectedUnits>;
  defender: Array<SelectedUnits>;
  camps: CampBuilding[];
  weather: GlobalEffects;
  setSimResult: (
    battleResult: number,
    losses: SimulationLosses,
    simulations: number,
    attackerWave: number,
    enemyWave: number
  ) => void;
  onAttackFinished?: () => void;
  attackerWave?: number;
  enemyWave?: number;
  simulationRuns?: number;
  simulationEngine: ReturnType<typeof useSimulationEngine>;
}

export async function startAttack({
  generals,
  skills,
  attacker,
  defender,
  camps,
  weather,
  simulationEngine,
  setSimResult,
  onAttackFinished = () => {},
  attackerWave = 0,
  enemyWave = 0,
  simulationRuns = 10000
}: StartAttackProps) {
  if (attacker[attackerWave] && defender[enemyWave]) {
    const generalId = generals[attackerWave];
    const simulatableConfig = prepareForSimulation({
      units: attacker[attackerWave],
      general: generalId ? getUnitById(generalId) : undefined,
      enemies: defender[enemyWave],
      camp: camps[enemyWave],
      skills: skills[attackerWave],
      weather
    });

    if (!simulatableConfig) {
      return;
    }

    const result = await simulationEngine.attack({
      ...simulatableConfig,
      simulationRuns
    });

    const { win: battleResult, agrLosses: losses, simulations } = result;
    setSimResult(battleResult, result.agrLosses, result.simulations, attackerWave + 1, enemyWave + 1);

    const { newAttacker, newDefender, newAttackerWave, newEnemyWave } = getNextWaveConfig(
      battleResult,
      losses,
      simulations,
      defender,
      enemyWave,
      attackerWave,
      attacker
    );
    await startAttack({
      generals,
      skills,
      attacker: newAttacker,
      defender: newDefender,
      camps,
      weather,
      setSimResult,
      onAttackFinished,
      attackerWave: newAttackerWave,
      enemyWave: newEnemyWave,
      simulationRuns,
      simulationEngine
    });
  } else {
    onAttackFinished();
  }
}

const ARMY_PROBABILITY_VALUE = 0.6;
const ENEMY_PROBABILITY_VALUE = 0.5;

function getNextWaveConfig(
  battleResult: number,
  losses: SimulationLosses,
  simulations: number,
  defender: Array<SelectedUnits>,
  enemyWave: number,
  attackerWave: number,
  attacker: Array<SelectedUnits>
) {
  if (battleResult < 100) {
    // TODO update defender-army
    const newDefender = extractRemainingDefender(losses.bandits.details, simulations, defender, enemyWave);
    const newAttackerWave = attackerWave + 1;
    return { newDefender, newAttackerWave, newAttacker: attacker, newEnemyWave: enemyWave };
  } else {
    // Include ressurected units here, by using detailsRecover
    // Not sure if we need to make the waves explicit here, so we can use the information in the result table
    const newAttacker = extractSurvivingArmy(losses.units.detailsRecover, simulations, attacker, attackerWave);
    const newEnemyWave = enemyWave + 1;
    return { newDefender: defender, newAttackerWave: attackerWave, newAttacker, newEnemyWave };
  }
}

export function extractSurvivingArmy(
  detailsRecover: Record<number, SelectedUnits>,
  simulations: number,
  attackers: Array<SelectedUnits>,
  attackerWave: number
): Array<SelectedUnits> {
  const threshold = Math.floor(simulations * ARMY_PROBABILITY_VALUE);
  return attackers.map((attacker, wave) => {
    if (wave !== attackerWave) {
      return attacker;
    }
    return getNewUnits(attacker, detailsRecover, threshold);
  });
}

export function extractRemainingDefender(
  detailsMap: Record<number, SelectedUnits>,
  simulations: number,
  defenders: Array<SelectedUnits>,
  enemyWave: number
): Array<SelectedUnits> {
  const threshold = Math.floor(simulations * ENEMY_PROBABILITY_VALUE);

  return defenders.map((defender, index) => {
    if (index !== enemyWave) {
      return defender;
    }
    const newDefender = getNewUnits(defender, detailsMap, threshold);
    const numberOfDefenerUnits = sum(Object.values(newDefender));
    // We now use median to calculate the remaining enemies for the next wave.
    // If in the median case all enemies are defeted, we want to repeat a battle here to be sure the next wave
    // is able to handle this corner case.
    if (numberOfDefenerUnits > 0) {
      return newDefender;
    }

    // Simply go for the worst case...
    return getNewUnits(defender, detailsMap, 0);
  });
}

function getNewUnits(units: SelectedUnits, detailsMap: Record<number, SelectedUnits>, threshold: number) {
  return Object.entries(units).reduce<SelectedUnits>(
    (prev, [unitId, amount]) =>
      amount === undefined
        ? prev
        : {
            ...prev,
            [unitId]: convertToNumber(amount) - getLostUnitsForNextWave(detailsMap[convertToNumber(unitId)], threshold)
          },
    {}
  );
}

function getLostUnitsForNextWave(lostUnits: SelectedUnits | undefined, threshold: number) {
  if (!lostUnits) {
    return 0;
  }
  const sortedKeys = Object.keys(lostUnits).map(convertToNumber).sort();
  let sum = 0;

  for (const key of sortedKeys) {
    const newSum = sum + lostUnits[key];
    if (newSum > threshold) {
      return key;
    }
    sum = newSum;
  }
  return sum;
}
