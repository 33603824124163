import { AttackSearchResult } from '../../app/store';
import { computeEpForGeneral, getLostBanditsFromResult, getRemainingUnits } from '../../components/results/utils';
import { SelectedUnits } from '../../components/units/UnitSelection';
import { GlobalEffects } from '../../data-objects/GlobalEffects';
import { General } from '../../data-objects/units/Army';
import { getEnemyUnitById } from '../../data-objects/units/Units';
import { SearchCamp } from '../utils';
import { DefinedAmount } from './calculateLossValue';
import { AttackSearchSingleReport } from './simulateAttackConfigs';

export function combineAttackBaseResults(
  general: General,
  usedUnits: SelectedUnits,
  attackCamps: Array<SearchCamp>,
  weather: GlobalEffects,
  attackResults: Array<AttackSearchSingleReport>
) {
  const simResult: AttackSearchResult = {
    battleResult: 100,
    general,
    units: usedUnits,
    banditCamps: attackCamps,
    weather,
    unitLosses: {},
    simulations: 0,
    ep: { min: 0, max: 0 },
    details: attackResults
  };
  const lastResult = attackResults.length ? attackResults[attackResults.length - 1] : null;
  if (lastResult) {
    simResult.details.forEach(result => (result.losses.usedUnits.general.unit.icon = general.icon));
    simResult.ep = attackCamps.reduce<DefinedAmount>(
      (summedEp, camp, index) => {
        if (index < attackResults.length && attackResults[index].battleResult === 100) {
          const usedGeneral = attackResults[index].losses.usedUnits.general.unit;
          const baseCampEp = camp.enemies.reduce(
            (summed, unitType) => summed + unitType.amount * getEnemyUnitById(unitType.typeId).ep,
            0
          );

          const campEp = computeEpForGeneral(baseCampEp, usedGeneral);
          return { min: summedEp.min + campEp, max: summedEp.max + campEp, avg: summedEp.avg + campEp };
        } else if (index === attackResults.length - 1) {
          const { gainedExperience } = getLostBanditsFromResult(attackResults[index], general);
          return {
            min: gainedExperience.min + summedEp.min,
            avg: gainedExperience.avg + summedEp.avg,
            max: gainedExperience.max + summedEp.max
          };
        }
        return summedEp;
      },
      { min: 0, max: 0, avg: 0 }
    );

    simResult.battleResult = lastResult.battleResult;
    simResult.simulations = lastResult.simulations;
    // get remaining units from result.
    const remainingUnits = getRemainingUnits(lastResult.losses, lastResult.simulations);
    // calculate losses from here, compare to initialy used units
    simResult.unitLosses = Object.entries(usedUnits).reduce<Record<number, DefinedAmount>>((prev, [id, number]) => {
      const lossAmount = {
        min: number - (remainingUnits[id]?.min ?? number),
        max: number - (remainingUnits[id]?.max ?? number),
        avg: number - (remainingUnits[id]?.avg ?? number)
      };
      // Filter units without losses
      if (lossAmount.min === 0 && lossAmount.max === 0) {
        return prev;
      }
      return {
        ...prev,
        [id]: lossAmount
      };
    }, {});
  } else {
    simResult.battleResult = 0;
  }
  return simResult;
}
