import styled from 'styled-components';
import { BaseProps } from '../../types/basics';
import Editable from './Editable';

interface TabProps extends BaseProps {
  label: string;
  selected: boolean;
  onSelect?: () => void;
  onClose?: () => void;
  onEdit?: (name: string) => void;
}

function TabRaw({ selected, label, onSelect, onClose, onEdit, ...otherProps }: TabProps) {
  return (
    <div {...otherProps} onClick={onSelect ? onSelect : () => {}}>
      {onEdit ? <Editable text={label} commit={onEdit} /> : <div>{label}</div>}
      {onClose && (
        <div
          className='tab-close-icon'
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}>
          Ⓧ
        </div>
      )}
    </div>
  );
}

export default styled(TabRaw)`
  position: relative;
  display: inline-flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  background-color: var(${props => (props.selected ? '--primary-color' : '--background-color-light')});
  color: var(${props => (props.selected ? '--text-on-primary' : '--color')});

  border-radius: 2px 2px 0 0;
  border: var(--neutral-border);
  border-bottom: unset;
  padding: 8px 16px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  :not(:first-child) {
    margin-left: 2px;
  }

  .tab-close-icon {
    grid-column: 1;
    grid-row: 1;
    display: none;
    opacity: 0;
    transition: opacity 200ms;
  }

  :hover {
    background-color: var(--hover-background-color);
    color: var(--hover-foreground-color);

    .tab-close-icon {
      opacity: 1;
      display: unset;
      position: absolute;
      top: 0;
      right: 0;
      height: 16px;
      width: 16px;
      transition: opacity 200ms;
    }
  }
`;
