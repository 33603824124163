import { memo, useMemo, useState } from "react";
import { Chart } from "react-charts";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { v4 } from "uuid";
import { SearchResult } from "../app/store";
import FoldableElement from "../components/Basics/FoldableElement";
import LabeledImage from "../components/LabeledImage";
import { toRange } from "../components/results/utils/toRange";
import { AdventureId, getAdventureById } from "../data-objects/adventures";
import { GlobalEffects } from "../data-objects/GlobalEffects";
import hufeisen from "../data-objects/hufeisen";
import { General } from "../data-objects/units/Army";
import { getEnemyUnitById } from "../data-objects/units/Units";
import { getAverageRoundsFromResult } from "../redux/result/resultUtil";
import { useBlockPreferences, useDarkMode } from "../redux/selectors";
import { BaseProps } from "../types/basics";
import { getImageForEffect } from "./FinderBuffs";
import {
  BlockResult,
  globalEffectToLabel,
  SearchCamp,
  sortSearchResults,
} from "./utils";

const FAHNEN_ICON = "/icons/misc/fahnen.jpg";

interface ResultEntriesProps extends BaseProps {
  general: General;
  results: SearchResult[];
  adventureId: AdventureId;
  bandits: SearchCamp[];
}

interface GeneralSearchResultProps extends BaseProps {
  result: BlockResult & { weather?: GlobalEffects };
  timePerRound: number;
}

const GeneralSearchResultEntry = styled(memo(GeneralSearchResultEntryUnstyled))`
  & > div {
    display: flex;
    align-items: center;
  }

  ${LabeledImage} {
    height: 40px;
  }

  .search-resulte-fahnen {
    height: 40px;
  }
  .search-fahne-icon {
    height: 30px;
    padding-left: 8px;
  }
`;

interface ChartDatum {
  primary: number;
  secondary: number;
}

interface ChartData {
  label: string;
  data: ChartDatum[];
}

const weatherKeys: (keyof GlobalEffects)[] = [
  "nebel",
  "wirbelsturm",
  "sonnenschein",
  "frost",
];
function GeneralSearchResultEntryUnstyled({
  result,
  timePerRound,
  ...otherProps
}: GeneralSearchResultProps) {
  const [showChart, setShowChart] = useState(false);
  const rounds = Object.keys(result.rounds)
    .map((key) => parseInt(key))
    .sort((a, b) => a - b);
  const avgRounds = getAverageRoundsFromResult(result);
  const preferences = useBlockPreferences();
  const darkMode = useDarkMode();

  const data: ChartData[] = useMemo(() => {
    const data2 = Object.entries(result.rounds).map(([amount, times]) => ({
      primary: parseInt(amount) * timePerRound,
      secondary: parseInt(times),
    }));
    return [
      {
        label: "Lauf 1",
        data: data2,
      },
    ];
  }, [result.rounds, timePerRound]);

  const series = useMemo(() => ({ type: "bar" }), []);

  const axes = useMemo(
    () => [
      { primary: true, position: "bottom", type: "ordinal" },
      { position: "left", type: "linear", hardMin: 0 },
    ],
    []
  );

  const tooltip = useMemo(
    () => ({
      render: ({ datum }: { datum: ChartDatum }) => {
        return (
          <CustomTooltip {...{ datum, simulations: result.simulations }} />
        );
      },
    }),
    [result.simulations]
  );

  const myBuff = hufeisen[preferences.hufeisen];
  const { weather } = result;
  return (
    <div {...otherProps} onClick={() => setShowChart((show) => !show)}>
      <div>
        {weather &&
          weatherKeys.map((key) =>
            weather[key] ? (
              <img src={getImageForEffect(key)} alt="weather" />
            ) : null
          )}
      </div>
      <div className="finder-result-used-units">
        {result.army.map((unit) => (
          <LabeledImage
            key={unit.unit.id}
            unit={unit.unit}
            text={`${unit.amount}`}
          />
        ))}
      </div>
      <div>
        {`${toRange(
          rounds[0] * timePerRound,
          rounds[rounds.length - 1] * timePerRound
        )}s (Ø ${(avgRounds * timePerRound).toFixed(2)})`}
      </div>
      <div>{result.simulations}</div>
      <div className="search-result-fahnen">
        <LabeledImage
          unit={{ icon: myBuff.icon }}
          text={`min ${(
            (rounds[0] * timePerRound) /
            myBuff.value(result.weather?.bk_snow)
          ).toFixed(1)} FA`}
          labelAfter
        />
        <img src={FAHNEN_ICON} alt="fahne" className="search-fahne-icon" />
      </div>
      {showChart && (
        <div className="details-diagram">
          <div className="chart-container">
            <Chart
              data={data}
              dark={darkMode}
              series={series}
              axes={axes}
              primaryCursor
              secondaryCursor
              tooltip={tooltip}
            />
          </div>
        </div>
      )}
    </div>
  );
}

interface TooltipProps extends BaseProps {
  datum: ChartDatum;
  simulations: number;
}
function CustomTooltip({ datum, simulations, ...otherProps }: TooltipProps) {
  return datum ? (
    <div {...otherProps}>
      <div>{`Ø ${(datum.secondary * 100) / simulations}%`}</div>
    </div>
  ) : null;
}

function FinderResultEntry({
  general,
  results,
  adventureId,
  bandits,
  ...otherProps
}: ResultEntriesProps) {
  const { t } = useTranslation();

  const wetterLabel = t("Wetter");
  const einheitenLabel = t("Einheiten");
  const blockLabel = t("Lockzeit (sek)");
  const simulationLabel = t("Simulationen");
  const fahnenLabel = t("Fahnenabstand");

  const timePerRound = general.timePerRound ?? 10;

  const sortedResults = [...results].sort(sortSearchResults);
  return (
    <FoldableElement
      initiallyOpen
      renderCustomHeader={() => (
        <FinderResultEntryHeader
          general={general}
          adventureId={adventureId}
          bandits={bandits}
        />
      )}
      {...otherProps}
    >
      <div className="bold-table-header">{wetterLabel}</div>
      <div className="bold-table-header">{einheitenLabel}</div>
      <div className="bold-table-header">{blockLabel}</div>
      <div className="bold-table-header">{simulationLabel}</div>
      <div className="bold-table-header">{fahnenLabel}</div>
      {sortedResults.map((result) =>
        "army" in result ? (
          <GeneralSearchResultEntry
            data-key={result.army.reduce(
              (prev, entry) => `${prev}, ${entry.amount}${entry.unit.abr}`,
              globalEffectToLabel(result.weather)
            )}
            key={result.army.reduce(
              (prev, entry) => `${prev}, ${entry.amount}${entry.unit.abr}`,
              globalEffectToLabel(result.weather)
            )}
            result={result}
            timePerRound={timePerRound}
          />
        ) : null
      )}
    </FoldableElement>
  );
}

interface HeaderProps extends BaseProps {
  general: General;
  adventureId: AdventureId;
  bandits: SearchCamp[];
}

function FinderResultEntryHeaderUnstyled({
  general,
  adventureId,
  bandits,
  ...otherProps
}: HeaderProps) {
  return (
    <div {...otherProps}>
      <div className="header-search-meta-information">
        <div className="search-header-adventure">
          {getAdventureById(adventureId).name}
        </div>
        <div className="search-header-camps">
          {/* TODO: Add wave switcher here, if multiple waves are present */}
          {bandits.map((wave) => (
            <div key={v4()} className="one-camp">
              {wave.enemies.map((campUnits) => (
                <LabeledImage
                  key={campUnits.typeId}
                  unit={getEnemyUnitById(campUnits.typeId)}
                  text={`${campUnits.amount}`}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <LabeledImage unit={general} text={general.abr} />
    </div>
  );
}

const FinderResultEntryHeader = styled(memo(FinderResultEntryHeaderUnstyled))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-search-meta-information {
    display: flex;
    flex-direction: column;
    padding-left: 8px;

    .search-header-adventure {
      font-weight: 600;
      margin: auto;
    }

    .search-header-camps {
      font-size: 0.8em;

      .one-camp {
        display: flex;
        flex-direction: row;
        height: 35px;
      }
    }
  }
`;

export default styled(FinderResultEntry)`
  border-color: var(--primary-color);

  .foldable-table {
    display: grid;
    grid-template-columns: 60px 2fr 2fr 1fr 1fr;
    max-height: 25vh;
    overflow-x: hidden;
    overflow-y: auto;

    .bold-table-header {
      font-size: 1.2em;
      font-weight: 800;
      padding-bottom: 8px;
    }

    ${GeneralSearchResultEntry} {
      display: contents;

      .details-diagram {
        height: 120px;
        width: 100%;
        grid-column: 1 / span 5;
        padding: 0 25px;

        .chart-container {
          width: 100%;
          height: 100%;
        }
      }

      .finder-result-used-units {
        display: flex;

        & img {
          margin-right: 1em;
        }
      }
    }
    ${GeneralSearchResultEntry}:nth-child(2n) {
      display: contents;
      & > * {
        background-color: var(--background-color-light);
      }
    }
  }
`;
