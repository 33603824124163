// eslint-disable-next-line import/no-webpack-loader-syntax
import createWorker from "workerize-loader!../worker/simulationWorker";
// eslint-disable-next-line import/no-webpack-loader-syntax
import createSearch from "workerize-loader!../worker/finderWorker";
// eslint-disable-next-line import/no-webpack-loader-syntax
import createAttackSearch from "workerize-loader!../worker/attackSearchWorker";
import * as SimulationEngine from "../worker/simulationWorker";
import * as SearchEngine from "../worker/finderWorker";
import * as AttackSearch from "../worker/attackSearchWorker";

// worker hooks
const simulation = createWorker<typeof SimulationEngine>();
export const useSimulationEngine = () => simulation;

const searcher = createSearch<typeof SearchEngine>();
export const useSearchEngine = () => searcher;

const attackSearcher = createAttackSearch<typeof AttackSearch>();
export const useAttackSearchEngine = () => attackSearcher;
