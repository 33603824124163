import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Enemy } from '../../data-objects/units/Units';
import { General, Unit } from '../../data-objects/units/Army';

const LpIcon = '/icons/misc/hp.png';
const DmgIcon = '/icons/misc/damage.png';
const AccuracyIcon = '/icons/misc/accuracy.png';
const InitiativeIcon = '/icons/misc/initiative.png';
const FlankIcon = '/icons/misc/flank.png';
const SplashIcon = '/icons/misc/splash.png';
const AbreissenIcon = '/icons/misc/abreissen.png';
const ExpIcon = '/icons/misc/icon_xp.png';

interface UnitDetailsProps {
  unit: Unit | Enemy | General;
  hideEp?: boolean;
}

function UnitDetails({ unit, hideEp, ...otherProps }: UnitDetailsProps) {
  const { t } = useTranslation();
  return (
    <div {...otherProps}>
      <div className='attributes'>
        <div className='tupel'>
          <img src={LpIcon} alt='lp' />
          <div>{unit.hp.toLocaleString()}</div>
        </div>
        <div className='tupel'>
          <img src={DmgIcon} alt='dmg' />
          <div>{`${unit.min.toLocaleString()}-${unit.max.toLocaleString()}`}</div>
        </div>
        <div className='tupel'>
          <img src={AccuracyIcon} alt='acc' />
          <div>{`${unit.accuracy}%`}</div>
        </div>
        <div className='tupel'>
          <img src={InitiativeIcon} alt='ini' />
          <div>{t(unit.initiative || 'NORMAL')}</div>
        </div>
      </div>
      {!hideEp && 'ep' in unit && unit.ep && (
        <div className='unit-ep'>
          <div className='tupel'>
            <img src={ExpIcon} alt='ep' />
            <div>{`${unit.ep.toLocaleString()}`}</div>
          </div>
        </div>
      )}
      <div className='skills'>
        {unit.flank && (
          <div className='tupel'>
            <img src={FlankIcon} alt='flank' />
            <div>{t('flank')}</div>
          </div>
        )}
        {unit.splash !== undefined && unit.splash >= 1 && (
          <div className='tupel'>
            <img src={SplashIcon} alt='splash' />
            <div>{t('splash')}</div>
          </div>
        )}
        {'buildingBonus' in unit && (
          <div className='tupel'>
            <img src={AbreissenIcon} alt='building' />
            <div>{t('abreissen')}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default styled(UnitDetails)`
  display: flex;
  flex-direction: column;
  padding: 4px 0 0 0;
  font-size: 0.7em;

  .tupel {
    display: flex;

    img {
      padding: 0 4px 0 0;
    }

    & div {
      display: flex;
      align-items: center;
    }
  }
  .attributes,
  .skills,
  .unit-ep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 1em;
  }

  .skills,
  .unit-ep {
    padding: 8px 0 0 0;
  }
`;
