import {
  BOWMAN,
  CAVALRY,
  ELITE_SOLDIER,
  KNIGHT,
  LONG_BOWMAN,
  MARKSMAN,
  MASTER_OF_DEFENSE,
  MOUNTED_MARKSMAN,
  MOUNTED_SWORDSMAN,
  SOLDIER,
  STEADFAST_GENERAL,
  TAV_GENERAL
} from '../data-objects/units/Army';
import { ShownResult } from '../redux/result/util';
import { SearchStatus } from '../redux/search-army/SearchStatus';
import { unitDefaultValues } from '../redux/unitUtil';
import { AppState, ConfigurationState } from './store';

const STORAGE_KEY = 'DSO-Configuration';
const SELECTED_ELITE_UNITS = [MOUNTED_SWORDSMAN, KNIGHT, MOUNTED_MARKSMAN, MARKSMAN];
const SELECTED_NORMAL_UNITS = [CAVALRY, SOLDIER, ELITE_SOLDIER, BOWMAN, LONG_BOWMAN];
const USUAL_BLOCK_GENERALS = [TAV_GENERAL, STEADFAST_GENERAL, MASTER_OF_DEFENSE];

const initialConfigurationState: ConfigurationState = {
  darkMode: false,
  unitValues: { ...unitDefaultValues },
  selectedGeneralsList: 0,
  generalsList: [{ name: 'Eigene', id: 'kalf30-9jrff.dlfk', generals: [] }],
  blockPreferences: {
    defaultBlockUnits: {
      elite: SELECTED_ELITE_UNITS,
      normal: SELECTED_NORMAL_UNITS
    },
    defaultBlockGenerals: USUAL_BLOCK_GENERALS,
    detailedBlockSimulations: 1000,
    searchValue: 70,
    maxResults: 25,
    hufeisen: 'platin'
  }
};

export const initialState: AppState = {
  ui: {
    search: {
      progress: 0,
      locksFound: 0,
      status: SearchStatus.READY
    },
    lockSearchInput: {
      keepPreviousResults: false,
      selectedEffects: { none: true },
      value: 70,
      selectedGenerals: [],
      selectedUnits: []
    },
    attackSearchInput: {
      keepPreviousResults: false,
      selectedEffects: { none: true },
      value: 70,
      selectedGenerals: [],
      selectedUnits: []
    },
    shownResult: ShownResult.NONE,
    simulationInProgress: false
  },
  configuration: initialConfigurationState,
  simulation: {
    selectedGenerals: [], // 1.
    selectedSkills: [],
    selectedUnits: [], // 3.
    showEliteUnits: [],
    availableEnemies: [],
    selectedEnemies: [], // 4
    showCustomGenerals: [-1],
    highlightedGenerals: [], // 6
    currentWave: 0,
    currentEnemyWave: 0,
    weatherEffects: {
      frost: false,
      nebel: false,
      sonnenschein: false,
      wirbelsturm: false
    },
    selectedAdventure: null,
    selectedCamps: []
  },
  result: { waveResult: [] },
  searchArmy: {
    found: {},
    attack: {}
  }
  // history: {},
};

export function loadState(): AppState {
  try {
    const serialisedState = localStorage.getItem(STORAGE_KEY);
    if (serialisedState === null) {
      return { ...initialState };
    }
    const serializedConfig = JSON.parse(serialisedState);
    const initState = {
      ...initialState,
      configuration: { ...initialConfigurationState, ...serializedConfig }
    };
    if (
      serializedConfig.generalsList &&
      serializedConfig.generalsList[0] &&
      serializedConfig.generalsList[0].generals.length
    ) {
      initState.simulation.showCustomGenerals[0] = 0;
    }
    return initState;
  } catch (err) {
    return { ...initialState };
  }
}

export function saveState(state: AppState) {
  try {
    const { configuration } = state;
    const serialized = JSON.stringify(configuration);
    localStorage.setItem(STORAGE_KEY, serialized);
  } catch (err) {
    // No need to handle write errors right now.
  }
}
