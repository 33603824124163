import {
  UNIT_CHUCK_CK_ID,
  UNIT_METALLGEBISS_MG_ID,
  UNIT_SCHLÄGER_SL_ID,
  UNIT_WACHHUND_WH_ID,
  UNIT_RAUFBOLD_RB_ID,
  UNIT_WALDLÄUFER_WL_ID,
  UNIT_AUSGEBURTDERNACHT_ADN_ID,
  UNIT_KULTIST_KT_ID,
  UNIT_SUMPFHEXE_SH_ID,
  UNIT_SCHATTENSCHLEICHER_SS_ID,
  UNIT_FANATIKER_FA_ID,
  UNIT_DUNKLERPRIESTER_DP_ID,
  UNIT_FEUERTÄNZER_FT_ID,
  UNIT_TANZENDERDERWISCH_TD_ID,
  UNIT_WILDSCHWEIN_WS_ID,
  UNIT_BÄR_BÄR_ID,
  UNIT_WOLF_WO_ID,
  UNIT_ALPHAWOLF_AW_ID,
  UNIT_FUCHS_FU_ID,
  UNIT_RIESE_RI_ID,
  UNIT_KÖNIGLICHERREKRUT_KR_ID,
  UNIT_KÖNIGLICHEMILIZ_KM_ID,
  UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID,
  UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID,
  UNIT_KÖNIGLICHEREITEREI_KC_ID,
  UNIT_KÖNIGLICHERKANONIER_KK_ID,
  UNIT_KLINGENRASSLER_KRA_ID,
  UNIT_SÄBELSCHWINGER_SÄS_ID,
  UNIT_SCHWERTMEISTER_SM_ID,
  UNIT_REITER_HM_ID,
  UNIT_DÜNENSCHÜTZE_DÜS_ID,
  UNIT_WÜSTENSCHÜTZE_WÜS_ID,
  UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID,
  UNIT_STEINKANONE_STK_ID,
  UNIT_MATROSE_MTR_ID,
  UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID,
  UNIT_BERITTENERSEEFAHRER_BSF_ID,
  UNIT_BOOTSMANN_BM_ID,
  UNIT_OBERMAAT_OM_ID,
  UNIT_KANONIERMEISTER_KME_ID,
  UNIT_STOCKSCHWINGENDERAFFE_SSA_ID,
  UNIT_STEINWERFENDERAFFE_SWA_ID,
  UNIT_ALPHA_AFFE_AA_ID,
  UNIT_NERVÖSERAFFE_NA_ID,
  UNIT_EINÄUGIGERBERT_EB_ID,
  UNIT_DUNKLERHOHEPRIESTER_DHP_ID,
  UNIT_RIESEBOGOR_RBO_ID,
  UNIT_RIESEGOGOR_RGO_ID,
  UNIT_EINHORN_EH_ID,
  UNIT_RASENDESWILDSCHWEIN_RWS_ID,
  UNIT_BÖSERKÖNIG_BK_ID,
  UNIT_EISENFAUST_EF_ID,
  UNIT_RIESIGERBÄR_RBÄ_ID,
  UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ID,
  UNIT_SCHWARZERSTIER_SST_ID,
  UNIT_DUNKLERZAUBERER_DZ_ID,
  UNIT_VERLOGENEZIEGE_VZ_ID,
  UNIT_KNÜPPEL_KLAUS_KKL_ID,
  UNIT_ASSASSINE_AS_ID,
  UNIT_GIERIGERGASTWIRT_GG_ID,
  UNIT_GEWITZTERRÄUBER_WITZR_ID,
  UNIT_KLUGERRÄUBER_KLUR_ID,
  UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID,
  UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID,
  UNIT_HOCHNÄSIGERRÄUBER_HNR_ID,
  UNIT_ERGRAUTERRÄUBER_GRAUR_ID,
  UNIT_HINTERLISTIGERRÄUBER_HR_ID,
  UNIT_VERNARBTERRÄUBER_NAR_ID,
  UNIT_GIERIGERRÄUBER_GIER_ID,
  UNIT_EINFÄLTIGERRÄUBER_EINR_ID,
  UNIT_STEUERMANN_STM_ID,
  UNIT_MEUTERER_MEU_ID,
  UNIT_KAPITÄN_CAPT_ID,
  UNIT_RÄUBERLORD_RL_ID,
  UNIT_MÜRRISCHERPAPAGEI_MP_ID,
  UNIT_OASEN_HÜTER_OH_ID,
  UNIT_FELSENWURM_FW_ID,
  UNIT_RIESENFLEDERMAUS_RFM_ID,
  UNIT_HUNGRIGEBLÜTE_HUB_ID,
  UNIT_GRIMMIGEBLÜTE_GRB_ID,
  UNIT_KNOLLIGEBLÜTE_KNB_ID,
  UNIT_BÜCHEREI_GOLEM_BG_ID,
  UNIT_GROSSESTEINSTATUE_GSTS_ID,
  UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ID,
  UNIT_RIESENKANONE_RKA_ID,
  UNIT_INFORMANT_INF_ID,
  UNIT_RIESENSEESCHLANGE_RSS_ID,
  UNIT_MÜRRISCHEWACHE_MÜW_ID,
  UNIT_WAHNSINNIGEWACHE_WSW_ID,
} from "../units/Units";
import {
  CAMP_TYPE_N,
  CAMP_TYPE_W,
  CAMP_TYPE_S,
  CAMP_TYPE_H,
} from "../CampTypes";
import {
  BaseAdventure as Adventure,
  MISSION_TYPE,
  MÄRCHEN_THEME,
} from "./BaseAdventure";

export const AT_ALADINUNDDIESCHÖNEPRINZESSIN_ID = 500;
export const AT_ALADINUNDDIEÖLLAMPE_ID = 501;
export const AT_ALIBABADERJUNGEHOLZFÄLLER_ID = 502;
export const AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ID = 503;
export const AT_ALIBABAUNDDERSCHATZDESWISSENS_ID = 504;
export const AT_ALIBABAUNDDERDRITTEDIEB_ID = 505;
export const AT_ALIBABAUNDDERERSTEDIEB_ID = 506;
export const AT_ALIBABAUNDDERZWEITEDIEB_ID = 507;
export const AT_SINDBADUNDDIESEESCHLANGE_ID = 573;
export const AT_SINDBADUNDDIEBELAGERTESTADT_ID = 574;
export const AT_DASBETROGENESCHNEIDERLEIN_ID = 519;
export const AT_DASHELDENHAFTESCHNEIDERLEIN_ID = 521;
export const AT_DASKLUGESCHNEIDERLEIN_ID = 522;
export const AT_DASTAPFERESCHNEIDERLEIN_ID = 523;
export const AT_DIESÖHNEDESSCHNEIDERLEINS_ID = 543;

export type MaerchenId =
  | typeof AT_ALADINUNDDIESCHÖNEPRINZESSIN_ID
  | typeof AT_ALADINUNDDIEÖLLAMPE_ID
  | typeof AT_ALIBABADERJUNGEHOLZFÄLLER_ID
  | typeof AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ID
  | typeof AT_ALIBABAUNDDERSCHATZDESWISSENS_ID
  | typeof AT_ALIBABAUNDDERDRITTEDIEB_ID
  | typeof AT_ALIBABAUNDDERERSTEDIEB_ID
  | typeof AT_ALIBABAUNDDERZWEITEDIEB_ID
  | typeof AT_SINDBADUNDDIESEESCHLANGE_ID
  | typeof AT_SINDBADUNDDIEBELAGERTESTADT_ID
  | typeof AT_DASBETROGENESCHNEIDERLEIN_ID
  | typeof AT_DASHELDENHAFTESCHNEIDERLEIN_ID
  | typeof AT_DASKLUGESCHNEIDERLEIN_ID
  | typeof AT_DASTAPFERESCHNEIDERLEIN_ID
  | typeof AT_DIESÖHNEDESSCHNEIDERLEINS_ID;

const AT_ALADINUNDDIESCHÖNEPRINZESSIN_ICON = "/icons/adventures/prinzessin.png";
const AT_ALADINUNDDIEÖLLAMPE_ICON = "/icons/adventures/oellampe.png";
const AT_ALIBABADERJUNGEHOLZFÄLLER_ICON = "/icons/adventures/holzfaeller.png";
const AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ICON = "/icons/adventures/weisheit.png";
const AT_ALIBABAUNDDERSCHATZDESWISSENS_ICON = "/icons/adventures/wissen.png";
const AT_ALIBABAUNDDERDRITTEDIEB_ICON = "/icons/adventures/dritterDieb.png";
const AT_ALIBABAUNDDERERSTEDIEB_ICON = "/icons/adventures/ersterDieb.png";
const AT_ALIBABAUNDDERZWEITEDIEB_ICON = "/icons/adventures/zweiterDieb.png";
const AT_SINDBADUNDDIESEESCHLANGE_ICON = "/icons/adventures/seeschlange.png";
const AT_SINDBADUNDDIEBELAGERTESTADT_ICON =
  "/icons/adventures/belagerteStadt.png";
const AT_DASHELDENHAFTESCHNEIDERLEIN_ICON =
  "/icons/adventures/the_heroic_little_tailor.png";
const AT_DASKLUGESCHNEIDERLEIN_ICON =
  "/icons/adventures/the_clever_little_tailor.png";
const AT_DASTAPFERESCHNEIDERLEIN_ICON =
  "/icons/adventures/the_valiant_little_tailor.png";
const AT_DASBETROGENESCHNEIDERLEIN_ICON =
  "/icons/adventures/the_betrayed_little_tailor.png";
const AT_DIESÖHNEDESSCHNEIDERLEINS_ICON =
  "/icons/adventures/the_sons_of_the_little_tailor.png";

// Maps
const AT_ALADINUNDDIESCHÖNEPRINZESSIN_MAP = "/maps/aladin_schoen.jpg";
const AT_ALADINUNDDIEÖLLAMPE_MAP = "/maps/aladin_oellampe.jpg";
const AT_ALIBABADERJUNGEHOLZFÄLLER_MAP = "/maps/ali_holz.jpg";
const AT_ALIBABAUNDDERSCHATZDERWEISHEIT_MAP = "/maps/ali_weisheit.jpg";
const AT_ALIBABAUNDDERSCHATZDESWISSENS_MAP = "/maps/ali_wissen.jpg";
const AT_ALIBABAUNDDERDRITTEDIEB_MAP = "/maps/ali_dieb3.jpg";
const AT_ALIBABAUNDDERERSTEDIEB_MAP = "/maps/ali_dieb1.jpg";
const AT_ALIBABAUNDDERZWEITEDIEB_MAP = "/maps/ali_dieb2.jpg";

const AT_SINDBADUNDDIESEESCHLANGE_MAP = "/maps/sindbad_seeschlange.jpg";
const AT_SINDBADUNDDIEBELAGERTESTADT_MAP = "/maps/sindbad_belagerteStadt.jpg";

const AT_DASBETROGENESCHNEIDERLEIN_MAP = "/maps/schneider_betrogen.jpg";
const AT_DASHELDENHAFTESCHNEIDERLEIN_MAP = "/maps/schneider_held.jpg";
const AT_DASKLUGESCHNEIDERLEIN_MAP = "/maps/schneider_klug.jpg";
const AT_DASTAPFERESCHNEIDERLEIN_MAP = "/maps/schneider_tapfer.jpg";
const AT_DIESÖHNEDESSCHNEIDERLEINS_MAP = "/maps/schneider_soehne.jpg";

const Ja = true;
const Nein = false;

export interface Maerchen extends Adventure {
  id: MaerchenId;
}

const AladinunddieschÖnePrinzessin = {
  name: "Aladin und die schöne Prinzessin",
  image: AT_ALADINUNDDIESCHÖNEPRINZESSIN_MAP,
  icon: AT_ALADINUNDDIESCHÖNEPRINZESSIN_ICON,
  id: AT_ALADINUNDDIESCHÖNEPRINZESSIN_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 12,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BOOTSMANN_BM_ID }],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 170, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "A",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 160, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 200, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 30, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 40, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 270, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 80, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 40, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 2, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BOOTSMANN_BM_ID }],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 220, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 180, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 150, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 40, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 30, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 220, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "C",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 190, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 30, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 120, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 160, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "D",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 2, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 250, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_OBERMAAT_OM_ID }],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 40, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 270, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 3, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 190, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 220, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "E",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 180, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 2, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 12,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 13,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "E",
      number: 14,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "E",
      number: 15,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 150, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 190, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 40, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 2, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 30, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 3, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "F",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "F",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "F",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 150, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 2, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 40, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "G",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 30, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 2, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "G",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 170, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "G",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 2, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "G",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 10, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 10, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 130, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 110, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 130, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "H",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 60, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "H",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 30, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "H",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 4, typeId: UNIT_MÜRRISCHEWACHE_MÜW_ID },
        { amount: 4, typeId: UNIT_WAHNSINNIGEWACHE_WSW_ID },
      ],
    },
  ],
} as Maerchen;
const AladinunddieÖllampe = {
  name: "Aladin und die Öllampe",
  image: AT_ALADINUNDDIEÖLLAMPE_MAP,
  icon: AT_ALADINUNDDIEÖLLAMPE_ICON,
  id: AT_ALADINUNDDIEÖLLAMPE_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 12,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 80, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 50, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 40, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 210, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "A",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 130, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 130, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 240, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 80, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 160, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "B",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "B",
      number: 12,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 13,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 110, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 10,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 11,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 80, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 2, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 11,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "D",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 250, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 13,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 270, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 210, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_RIESENKANONE_RKA_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
        { amount: 1, typeId: UNIT_RIESENKANONE_RKA_ID },
      ],
    },
    {
      sector: "E",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 90, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 20, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "E",
      number: 10,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 11,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
      ],
    },
    {
      sector: "E",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_INFORMANT_INF_ID },
      ],
    },
  ],
} as Maerchen;
const AliBabaderjungeHolzfÄller = {
  name: "Ali Baba der junge Holzfäller",
  image: AT_ALIBABADERJUNGEHOLZFÄLLER_MAP,
  icon: AT_ALIBABADERJUNGEHOLZFÄLLER_ICON,
  id: AT_ALIBABADERJUNGEHOLZFÄLLER_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_GEWITZTERRÄUBER_WITZR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_SCHWERTMEISTER_SM_ID }],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 20, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 40, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_RÄUBERLORD_RL_ID },
      ],
    },
  ],
} as Maerchen;
const AliBabaundderSchatzderWeisheit = {
  name: "Ali Baba und der Schatz der Weisheit",
  image: AT_ALIBABAUNDDERSCHATZDERWEISHEIT_MAP,
  icon: AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ICON,
  id: AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 110, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 10,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_EINFÄLTIGERRÄUBER_EINR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_ERGRAUTERRÄUBER_GRAUR_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 110, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 110, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID },
      ],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 150, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 200, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 210, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_SCHWERTMEISTER_SM_ID }],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 130, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 200, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_STEINKANONE_STK_ID }],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 230, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
        { amount: 1, typeId: UNIT_EINFÄLTIGERRÄUBER_EINR_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 110, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
        { amount: 1, typeId: UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ID },
      ],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
        { amount: 1, typeId: UNIT_GROSSESTEINSTATUE_GSTS_ID },
      ],
    },
  ],
} as Maerchen;
const AliBabaundderSchatzdesWissens = {
  name: "Ali Baba und der Schatz des Wissens",
  image: AT_ALIBABAUNDDERSCHATZDESWISSENS_MAP,
  icon: AT_ALIBABAUNDDERSCHATZDESWISSENS_ICON,
  id: AT_ALIBABAUNDDERSCHATZDESWISSENS_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 13,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 120, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 40, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 120, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 10,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 130, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID },
      ],
    },
    {
      sector: "A",
      number: 13,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 14,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 15,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 110, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 16,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 120, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 17,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 18,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 120, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 19,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "A",
      number: 20,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 30, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 21,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 120, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "A",
      number: 22,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 23,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_GEWITZTERRÄUBER_WITZR_ID },
      ],
    },
    {
      sector: "A",
      number: 24,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 25,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 30, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_GEWITZTERRÄUBER_WITZR_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 120, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KLINGENRASSLER_KRA_ID }],
    },
    {
      sector: "B",
      number: 11,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 40, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 40, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID },
      ],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "C",
      number: 10,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 11,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 260, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID }],
    },
    {
      sector: "C",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "C",
      number: 13,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_EINFÄLTIGERRÄUBER_EINR_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 230, typeId: UNIT_SCHWERTMEISTER_SM_ID }],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 3, typeId: UNIT_HUNGRIGEBLÜTE_HUB_ID },
        { amount: 3, typeId: UNIT_GRIMMIGEBLÜTE_GRB_ID },
        { amount: 3, typeId: UNIT_KNOLLIGEBLÜTE_KNB_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_ERGRAUTERRÄUBER_GRAUR_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 120, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 130, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 220, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 120, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
        { amount: 1, typeId: UNIT_BÜCHEREI_GOLEM_BG_ID },
      ],
    },
  ],
} as Maerchen;
const AliBabaundderdritteDieb = {
  name: "Ali Baba und der dritte Dieb",
  image: AT_ALIBABAUNDDERDRITTEDIEB_MAP,
  icon: AT_ALIBABAUNDDERDRITTEDIEB_ICON,
  id: AT_ALIBABAUNDDERDRITTEDIEB_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 50, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 50, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_FELSENWURM_FW_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 120, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 90, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 80, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 90, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WOLF_WO_ID },
        { amount: 90, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 60, typeId: UNIT_WOLF_WO_ID },
        { amount: 60, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 80, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 1, typeId: UNIT_RIESENFLEDERMAUS_RFM_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 10, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 40, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 1, typeId: UNIT_GEWITZTERRÄUBER_WITZR_ID },
        { amount: 1, typeId: UNIT_EINFÄLTIGERRÄUBER_EINR_ID },
      ],
    },
  ],
} as Maerchen;
const AliBabaundderersteDieb = {
  name: "Ali Baba und der erste Dieb",
  image: AT_ALIBABAUNDDERERSTEDIEB_MAP,
  icon: AT_ALIBABAUNDDERERSTEDIEB_ICON,
  id: AT_ALIBABAUNDDERERSTEDIEB_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 40, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 40, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 30, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 60, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 120, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 40, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 40, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 60, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 20, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_EINFÄLTIGERRÄUBER_EINR_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 30, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 230, typeId: UNIT_KLINGENRASSLER_KRA_ID }],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 60, typeId: UNIT_REITER_HM_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_ERGRAUTERRÄUBER_GRAUR_ID },
      ],
    },
  ],
} as Maerchen;
const AliBabaundderzweiteDieb = {
  name: "Ali Baba und der zweite Dieb",
  image: AT_ALIBABAUNDDERZWEITEDIEB_MAP,
  icon: AT_ALIBABAUNDDERZWEITEDIEB_ICON,
  id: AT_ALIBABAUNDDERZWEITEDIEB_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_MÜRRISCHERPAPAGEI_MP_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 80, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_GEWITZTERRÄUBER_WITZR_ID },
        { amount: 1, typeId: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
        { amount: 90, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 120, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
        { amount: 1, typeId: UNIT_ERGRAUTERRÄUBER_GRAUR_ID },
        { amount: 1, typeId: UNIT_VERNARBTERRÄUBER_NAR_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 30, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 100, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_STEINKANONE_STK_ID },
        { amount: 1, typeId: UNIT_ERGRAUTERRÄUBER_GRAUR_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 220, typeId: UNIT_KLINGENRASSLER_KRA_ID }],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 80, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 90, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_OASEN_HÜTER_OH_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
      ],
    },
  ],
} as Maerchen;
const DasbetrogeneSchneiderlein = {
  name: "Das betrogene Schneiderlein",
  image: AT_DASBETROGENESCHNEIDERLEIN_MAP,
  icon: AT_DASBETROGENESCHNEIDERLEIN_ICON,
  id: AT_DASBETROGENESCHNEIDERLEIN_ID,
  campaign: "Das Schneiderlein",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 60, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 80, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_ALPHAWOLF_AW_ID }],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 79, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_ASSASSINE_AS_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 30, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_ALPHAWOLF_AW_ID }],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 70, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
        { amount: 80, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_WOLF_WO_ID },
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 40, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_RAUFBOLD_RB_ID }],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WACHHUND_WH_ID }],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "D",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 139, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_GIERIGERGASTWIRT_GG_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_RIESE_RI_ID }],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 130, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "E",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "E",
      number: 9,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "E",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 110, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 230, typeId: UNIT_RAUFBOLD_RB_ID }],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 280, typeId: UNIT_WALDLÄUFER_WL_ID }],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 200, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_GIERIGERGASTWIRT_GG_ID },
      ],
    },
    {
      sector: "F",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 190, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 90, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "F",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 170, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
  ],
} as Maerchen;
const DasheldenhafteSchneiderlein = {
  name: "Das heldenhafte Schneiderlein",
  image: AT_DASHELDENHAFTESCHNEIDERLEIN_MAP,
  icon: AT_DASHELDENHAFTESCHNEIDERLEIN_ICON,
  id: AT_DASHELDENHAFTESCHNEIDERLEIN_ID,
  campaign: "Das Schneiderlein",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 130, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 50, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 120, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_WOLF_WO_ID },
        { amount: 75, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_SCHWARZERSTIER_SST_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FUCHS_FU_ID },
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 80, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 80, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 80, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 140, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 50, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 120, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_KULTIST_KT_ID },
        { amount: 160, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 90, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_H,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_AUSGEBURTDERNACHT_ADN_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 100, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_KULTIST_KT_ID }],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID }],
    },
    {
      sector: "F",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 1, typeId: UNIT_DUNKLERHOHEPRIESTER_DHP_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KULTIST_KT_ID },
        { amount: 80, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 40, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KULTIST_KT_ID },
        { amount: 100, typeId: UNIT_FEUERTÄNZER_FT_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KULTIST_KT_ID },
        { amount: 90, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
      ],
    },
    {
      sector: "G",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 90, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "G",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 60, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
      ],
    },
    {
      sector: "G",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
        { amount: 80, typeId: UNIT_TANZENDERDERWISCH_TD_ID },
      ],
    },
    {
      sector: "G",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_DUNKLERPRIESTER_DP_ID }],
    },
    {
      sector: "G",
      number: 8,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_KULTIST_KT_ID },
        { amount: 60, typeId: UNIT_FANATIKER_FA_ID },
        { amount: 1, typeId: UNIT_SUMPFHEXE_SH_ID },
        { amount: 1, typeId: UNIT_DUNKLERZAUBERER_DZ_ID },
      ],
    },
  ],
} as Maerchen;
const DasklugeSchneiderlein = {
  name: "Das kluge Schneiderlein",
  image: AT_DASKLUGESCHNEIDERLEIN_MAP,
  icon: AT_DASKLUGESCHNEIDERLEIN_ICON,
  id: AT_DASKLUGESCHNEIDERLEIN_ID,
  campaign: "Das Schneiderlein",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_WALDLÄUFER_WL_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 140, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 60, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 60, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 90, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 89, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 110, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_RAUFBOLD_RB_ID }],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 90, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 99, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_EISENFAUST_EF_ID },
      ],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 60, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_ALPHAWOLF_AW_ID }],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 40, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 30, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 130, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 40, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 60, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WOLF_WO_ID },
        { amount: 70, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
        { amount: 110, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 20, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_ALPHAWOLF_AW_ID }],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 70, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 69, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 220, typeId: UNIT_RAUFBOLD_RB_ID }],
    },
    {
      sector: "G",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
      ],
    },
    {
      sector: "G",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_WALDLÄUFER_WL_ID }],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 190, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "H",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 160, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "H",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "H",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 200, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ID },
      ],
    },
  ],
} as Maerchen;
const DastapfereSchneiderlein = {
  name: "Das tapfere Schneiderlein",
  image: AT_DASTAPFERESCHNEIDERLEIN_MAP,
  icon: AT_DASTAPFERESCHNEIDERLEIN_ICON,
  id: AT_DASTAPFERESCHNEIDERLEIN_ID,
  campaign: "Das Schneiderlein",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_WOLF_WO_ID }],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FUCHS_FU_ID },
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "A",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 1, typeId: UNIT_RIESEBOGOR_RBO_ID },
        { amount: 1, typeId: UNIT_RIESEGOGOR_RGO_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 170, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 140, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 140, typeId: UNIT_WOLF_WO_ID }],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 60, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 50, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 80, typeId: UNIT_WOLF_WO_ID },
        { amount: 1, typeId: UNIT_EINHORN_EH_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_FUCHS_FU_ID },
        { amount: 60, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 20, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 60, typeId: UNIT_WOLF_WO_ID },
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 100, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 160, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 190, typeId: UNIT_WOLF_WO_ID }],
    },
    {
      sector: "C",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_FUCHS_FU_ID },
        { amount: 69, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 70, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 60, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 60, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 30, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID }],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 170, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID }],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "D",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 40, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "D",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 40, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 80, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "D",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 40, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 80, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 1, typeId: UNIT_BÖSERKÖNIG_BK_ID },
      ],
    },
  ],
} as Maerchen;
const DieSÖhnedesSchneiderleins = {
  name: "Die Söhne des Schneiderleins",
  image: AT_DIESÖHNEDESSCHNEIDERLEINS_MAP,
  icon: AT_DIESÖHNEDESSCHNEIDERLEINS_ICON,
  id: AT_DIESÖHNEDESSCHNEIDERLEINS_ID,
  campaign: "Das Schneiderlein",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 9,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 110, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 130, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 180, typeId: UNIT_WALDLÄUFER_WL_ID }],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 20, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 119, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 70, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
        { amount: 40, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 30, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 40, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 110, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 60, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 30, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 120, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 20, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 90, typeId: UNIT_ALPHAWOLF_AW_ID }],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 70, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_WOLF_WO_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WOLF_WO_ID },
        { amount: 60, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 90, typeId: UNIT_FUCHS_FU_ID },
        { amount: 20, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "M",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "M",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "M",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 1, typeId: UNIT_VERLOGENEZIEGE_VZ_ID },
      ],
    },
    {
      sector: "M",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 60, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "M",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "M",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "M",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "M",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 140, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "M",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 20, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "N",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 70, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "N",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "N",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 90, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "N",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 190, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "N",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 90, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_RIESEGOGOR_RGO_ID },
      ],
    },
    {
      sector: "X",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 80, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "X",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 40, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "X",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "X",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 70, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "X",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 100, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "X",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FUCHS_FU_ID },
        { amount: 40, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "Y",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 50, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "Y",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "Y",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_RAUFBOLD_RB_ID }],
    },
    {
      sector: "Y",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 130, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "Y",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
      ],
    },
    {
      sector: "Y",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 180, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 40, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "Z",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "Z",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 80, typeId: UNIT_WALDLÄUFER_WL_ID },
      ],
    },
    {
      sector: "Z",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_RAUFBOLD_RB_ID }],
    },
    {
      sector: "Z",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_WACHHUND_WH_ID },
        { amount: 119, typeId: UNIT_RAUFBOLD_RB_ID },
        { amount: 1, typeId: UNIT_KNÜPPEL_KLAUS_KKL_ID },
      ],
    },
  ],
} as Maerchen;
const SindbadunddieSeeschlange = {
  name: "Sindbad und die Seeschlange",
  image: AT_SINDBADUNDDIESEESCHLANGE_MAP,
  icon: AT_SINDBADUNDDIESEESCHLANGE_ICON,
  id: AT_SINDBADUNDDIESEESCHLANGE_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 13,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 180, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 40, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 30, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 40, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 70, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 20, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "A",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 50, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "A",
      number: 10,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 30, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "A",
      number: 12,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "A",
      number: 13,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 150, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 240, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "B",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 11,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 12,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 13,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 170, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 100, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "D",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "D",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "D",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 30, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 40, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 120, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 250, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 90, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BOOTSMANN_BM_ID }],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 20, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 150, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 30, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "F",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 190, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "F",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "F",
      number: 9,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KLINGENRASSLER_KRA_ID }],
    },
    {
      sector: "F",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
        { amount: 1, typeId: UNIT_RIESENSEESCHLANGE_RSS_ID },
      ],
    },
  ],
} as Maerchen;
const SindbadunddiebelagerteStadt = {
  name: "Sindbad und die belagerte Stadt",
  image: AT_SINDBADUNDDIEBELAGERTESTADT_MAP,
  icon: AT_SINDBADUNDDIEBELAGERTESTADT_ICON,
  id: AT_SINDBADUNDDIEBELAGERTESTADT_ID,
  campaign: "Arabische Nächte",
  type: MISSION_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 13,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 110, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 120, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 80, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 120, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 150, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 120, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "B",
      number: 9,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 60, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 60, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 60, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "B",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 120, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "B",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 40, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 70, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 40, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 110, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 110, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "C",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 20, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "C",
      number: 10,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 11,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 70, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "C",
      number: 13,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 60, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 60, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 120, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 80, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 260, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_OBERMAAT_OM_ID }],
    },
    {
      sector: "D",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 8,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }],
    },
    {
      sector: "D",
      number: 9,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 90, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "D",
      number: 10,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 260, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 270, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 90, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 70, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 60, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_REITER_HM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "E",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 200, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 30, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 80, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 270, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "F",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 70, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "F",
      number: 8,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 130, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 130, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "F",
      number: 9,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 60, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "F",
      number: 10,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 60, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "F",
      number: 11,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 50, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "F",
      number: 12,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 60, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "F",
      number: 13,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 60, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "F",
      number: 14,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 50, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 60, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "F",
      number: 15,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 100, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
  ],
} as Maerchen;

export function getMaerchenAdventureById(id: MaerchenId): Maerchen {
  const myId = id;
  return getMaerchenAdventureByExpression(
    (at): at is Maerchen => at.id === myId
  )[0];
}

export function getAllMaerchenAdventures(): Maerchen[] {
  return getMaerchenAdventureByExpression();
}

export function getMaerchenAdventureByExpression(
  expr: (at: Maerchen) => at is Maerchen = (at): at is Maerchen => true
): Maerchen[] {
  const ats = [
    AliBabaderjungeHolzfÄller,
    AliBabaundderersteDieb,
    AliBabaundderzweiteDieb,
    AliBabaundderdritteDieb,
    AliBabaundderSchatzderWeisheit,
    AliBabaundderSchatzdesWissens,
    AladinunddieÖllampe,
    SindbadunddieSeeschlange,
    SindbadunddiebelagerteStadt,
    AladinunddieschÖnePrinzessin,

    DastapfereSchneiderlein,
    DieSÖhnedesSchneiderleins,
    DasklugeSchneiderlein,
    DasbetrogeneSchneiderlein,
    DasheldenhafteSchneiderlein,
  ];
  return ats.filter(expr).map((at) => ({
    ...at,
    camps: at.camps.map((camp) => ({
      ...camp,
      units: camp.units.map((unit) => ({ ...unit })),
    })),
  }));
}
