import { SimpleCampType } from "../app/store";
import {
  getAdventureById,
  AdventureId,
  Adventure,
} from "../data-objects/adventures";
import { CAMP_TYPE_N } from "../data-objects/CampTypes";
import { EnemyId } from "../data-objects/units/Units";

export function getEnemyTypesOfAdventureId(
  adventureId: AdventureId
): EnemyId[] {
  return getAdventureById(adventureId).camps.reduce((prev, camp) => {
    return [
      ...prev,
      ...camp.units.reduce((campUnits, entry) => {
        if (prev.includes(entry.typeId)) {
          return campUnits;
        }
        return [...campUnits, entry.typeId];
      }, [] as EnemyId[]),
    ];
  }, [] as EnemyId[]);
}

type CampEnemies = Record<EnemyId, string>;
export function getEnemiesFromCamp(
  adventureId: AdventureId,
  sector: string | undefined,
  number: number | undefined
): CampEnemies {
  const adventure = getAdventureById(adventureId);
  const camp = adventure.camps.find(
    (camp) => camp.sector === sector && camp.number === number
  );
  if (!camp) {
    return getEnemyTypesOfAdventureId(adventureId).reduce(
      (prev, id) => ({ ...prev, [id]: 0 }),
      {} as CampEnemies
    );
  }
  return camp.units.reduce<CampEnemies>(
    (prev, current) => ({
      ...prev,
      [current.typeId]: `${current.amount}`,
    }),
    {} as CampEnemies
  );
}
export function getCampFromAdventure(
  adventure?: Adventure,
  camp?: SimpleCampType | null
) {
  if (adventure && camp) {
    const { sector, number } = camp;
    const myCamp = adventure.camps.find(
      (camp) => camp.sector === sector && camp.number === number
    );
    if (myCamp && myCamp.type) {
      return myCamp.type;
    }
  }
  return CAMP_TYPE_N;
}
