import { Dictionary } from '../../app/types';

export enum UnitBaseType {
  DEF = 'DEFENSIVE',
  SWIFT = 'SWIFT',
  OFF = 'OFFENSIVE',
  HEAVY = 'HEAVY'
}

export enum GeneralType {
  GENERAL = 'GENERAL'
}

export enum BossType {
  BOSS = 'BOSS'
}

export enum InitiativeType {
  FIRST = 'FIRST',
  NORMAL = 'NORMAL',
  LAST = 'LAST'
}

export enum Skill {
  BLITZANGRIFF_ATTRIBUTE = 'blitzAngriff',
  MEISTERSTRATEGE_ATTRIBUTE = 'meisterstratege',
  KOPFGELD_ATTRIBUTE = 'kopfgeld',
  EXTRALEBEN_ATTRIBUTE = 'extraleben',
  SPALTEN_ATTRIBUTE = 'spalten',
  WOECHTENTLICHE_WARTUNG_ATTRIBUTE = 'wartung',
  GARNISONSAUSBAU_ATTRIBUTE = 'garnisonsausbau',
  SUVERAENER_ANFUEHRER_ATTRIBUTE = 'anfuehrer',
  UNAUFHALTSAMER_ANSTURM_ATTRIBUTE = 'ansturm',
  KAMPFRAUSCH_ATTRIBUTE = 'kampfrausch',
  BLITZDENKER_ATTRIBUTE = 'blitzdenker',
  NUR_EINE_FLEISCHWUNDE_ATTRIBUTE = 'fleischwunde',
  SCHARFSCHUETZENAUSBILDUNG_ATTRIBUTE = 'scharfschuetze',
  UEBERRENNEN_ATTRIBUTE = 'ueberrennen',
  FEINDLICHE_UEBERNAHME_ATTRIBUTE = 'uebernahme',
  NAVIGATIONSSCHNELLKURS_ATTRIBUTE = 'navigation',
  SCHNELLFEUER_ATTRIBUTE = 'schnellfeuer',
  MOLOCH_ATTRIBUTE = 'moloch',
  ERSTE_HILFE_ATTRIBUTE = 'ersteHilfe',
  TRAB_ATTRIBUTE = 'trab'
}

export interface BaseUnitBase {
  hp: number;
  min: number;
  max: number;
  accuracy: number;
}

export interface BaseUnit {
  name: string;
  abr: string;
  hp: number;
  hpLeft: number;
  min: number;
  max: number;
  accuracy: number;
  base: BaseUnitBase;
  icon: string;
  flank?: boolean;
  splash?: number;
  recovery?: number;
  initiative?: InitiativeType;
}

export type UnitType = UnitBaseType | GeneralType;
export type EnemyType = UnitBaseType | BossType;

export type SkillTree = Dictionary<Skill, number>;
