import React from "react";
import styled from "styled-components";
import handleSaveToPC from "../downloader/startDownload";

function ErrorFallback({
  error,
  resetErrorBoundary,
  ...otherProps
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <div {...otherProps} role="alert">
      <span role="img" aria-label="boom icon">
        💥 💥 💥 💥 💥 💥 💥 💥 💥 💥
      </span>
      <p>Ein Fehler ist aufgetreten!</p>
      <pre>Poste deinen Fehlerbericht gerne im Siedler-Forum unter:</pre>
      <pre>
        <a href="https://forum.diesiedleronline.de/threads/141734">Euler-Sim</a>
      </pre>
      <button
        className="primary-button reload-button"
        onClick={() => handleSaveToPC(error, "Euler-Sim.error")}
      >
        Download der Fehlermeldung
      </button>
      <button
        className="primary-button reload-button"
        onClick={resetErrorBoundary}
      >
        Seite neu laden
      </button>
    </div>
  );
}

export default styled(ErrorFallback)`
  display: flex;
  flex-direction: column;
  text-align: center;

  .reload-button {
    margin: auto;
  }
`;
