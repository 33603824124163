import { AttackSearchResult, BlockPreferences, SearchResult } from '../../app/store';
import { SelectedUnits } from '../../components/units/UnitSelection';
import { CampUnitType } from '../../data-objects/adventures';
import { CampBuilding } from '../../data-objects/CampTypes';
import { GlobalEffects } from '../../data-objects/GlobalEffects';
import { General } from '../../data-objects/units/Army';
import { getGeneralCapacity, unitDefaultValues } from '../../redux/unitUtil';
import { AttackSearchProps, StartAttackResult } from '../../worker/attackSearchWorker';
import { PreparedConfig, PrepareSearchProps } from '../../worker/finderWorker';
import { FinderUnit } from '../utils';
import { combineAttackBaseResults } from './combineAttackBaseResults';
import { resultIsPerfect } from './resultIsPerfect';
import { searchBestSingleUnitResult } from './searchBestSingleUnitResult';
import { AttackSearchSingleReport, ResultCache, simulateAttackConfigs } from './simulateAttackConfigs';

export async function searchAttack(
  finderGenerals: Array<General>,
  selectedUnits: number[],
  attackCamps: Array<{ enemies: Array<CampUnitType>; camp: CampBuilding }>,
  value: number,
  selectedGlobalEffects: GlobalEffects[] = [{}],
  preferences: BlockPreferences,
  unitValues: typeof unitDefaultValues,
  startSearch: (props: AttackSearchProps) => Promise<Array<StartAttackResult>>,
  prepareSearch: (args: PrepareSearchProps) => Promise<PreparedConfig>,
  registerSearchAmount: (value: number) => void,
  shouldAbort: () => boolean,
  doAbort: () => void,
  updateProgress: (value: number) => void,
  addSearchResult: (general: General, result: SearchResult) => void,
  addMultipleResults: (general: General, results: SearchResult[]) => void,
  finishSearch: () => void
) {
  const reportResult = (
    general: General,
    weather: GlobalEffects,
    attackResults: Array<AttackSearchSingleReport>,
    usedUnits: SelectedUnits
  ) => {
    const simResult: AttackSearchResult = combineAttackBaseResults(
      general,
      usedUnits,
      attackCamps,
      weather,
      attackResults
    );
    addSearchResult(general, simResult);
  };

  const choosenUnits: Array<FinderUnit> = selectedUnits.map(unitId => ({
    id: unitId
  }));

  const { configs: allConfigs, usedStepSize } = await prepareSearch({
    finderGenerals,
    choosenUnits
  });

  const singleUnitProgress = choosenUnits.length * 100;
  // TODO: Search amout is incorrect! allConfigs may contain more entries as used per general
  // Currently we just skip the config that's not fitting in the simulation run and update progress accordingly.
  registerSearchAmount((allConfigs.length + singleUnitProgress) * selectedGlobalEffects.length * finderGenerals.length);

  const wonLastBattleRate = (result: Array<AttackSearchSingleReport>, winThreshold: number) =>
    result.length === attackCamps.length && result[result.length - 1].battleResult >= winThreshold;
  const definitelyWon = (result: Array<AttackSearchSingleReport>) => wonLastBattleRate(result, 100);
  const almostWon = (result: Array<AttackSearchSingleReport>) => wonLastBattleRate(result, 80);

  for (let gen = 0; gen < finderGenerals.length; gen++) {
    let genResultCounter = 0;
    const general = finderGenerals[gen];
    const timePerRound = general.timePerRound ?? 10;
    const generalCapacity = getGeneralCapacity(general);

    for (let wIndex = 0; wIndex < selectedGlobalEffects.length; wIndex++) {
      const effect = selectedGlobalEffects[wIndex];
      const existingResults: ResultCache = {};
      const reportGeneralResult = (simResult: Array<AttackSearchSingleReport>, usedUnits: SelectedUnits) =>
        reportResult(general, effect, simResult, usedUnits);
      if (shouldAbort()) {
        doAbort();
        return;
      }

      const attackFunction = (unit: SelectedUnits, detailedSimulation?: true) =>
        startSearch({
          unit,
          bandits: null!,
          weatherToUse: effect,
          camps: null!,
          general,
          generalCapacity,
          simulationRuns: detailedSimulation ? 10000 : 30, // TODO: calc minimum sim depending on accuracy of enemy and own units
          timePerRound,
          currentStepSize: usedStepSize
        }).then(result =>
          result.map(singleResult => ({
            ...singleResult,
            losses: {
              ...singleResult.losses,
              usedUnits: {
                ...singleResult.losses.usedUnits,
                units: transformToAmountUnits(singleResult.losses.usedUnits.units),
                enemies: transformToAmountUnits(singleResult.losses.usedUnits.enemies)
              }
            }
          }))
        );

      const { bestSingleUnitResult, amountOfResults } = await searchBestSingleUnitResult({
        choosenUnits,
        generalCapacity,
        unitValues,
        attackFunction,
        reportResult: reportGeneralResult,
        definitelyWon,
        shouldAbort
      });

      updateProgress(singleUnitProgress);

      let resultCounter = amountOfResults;
      let bestWeatherResult = bestSingleUnitResult;

      if (resultIsPerfect(bestSingleUnitResult)) {
        // Single unit results already gave us perfekt results for this weather.
        // Try next effect / general
        updateProgress(allConfigs.length);
        continue;
      }

      await simulateAttackConfigs({
        allConfigs,
        bestWeatherResult,
        unitValues,
        existingResults,
        generalCapacity,
        stepSize: usedStepSize,
        attackFunction,
        reportResult: reportGeneralResult,
        progressFunction: updateProgress,
        definitelyWon,
        almostWon,
        shouldAbort
      });
      if (shouldAbort()) {
        doAbort();
        return;
      }
    }
  }
  finishSearch();
}

function transformToAmountUnits(
  units: StartAttackResult['losses']['usedUnits']['units'] | StartAttackResult['losses']['usedUnits']['enemies']
) {
  return Object.entries(units).reduce(
    (prev, [id, { amount, originalAmount, unit }]) => ({
      ...prev,
      [id]: {
        amount: { min: amount, max: amount, avg: amount },
        originalAmount: {
          min: originalAmount,
          max: originalAmount,
          avg: originalAmount
        },
        unit
      }
    }),
    {}
  );
}
