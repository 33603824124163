import React from "react";
import styled from "styled-components";

interface Props {
  size: string;
}

const Crown = styled(function Crown({ size, ...otherProps }: Props) {
  return (
    <div {...otherProps}>
      <div className="point"></div>
      <div className="point"></div>
      <div className="point"></div>
      <div className="point"></div>
      <div className="point"></div>
    </div>
  );
})`
  --icon-size: ${(props) => props.size};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--icon-size);
  height: calc(var(--icon-size) / 1.1);
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: var(--icon-size) / -8;
    left: 0;
    width: 100%;
    height: var(--icon-size) / 2;
    background-color: var(--boss-camp-color);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  .point {
    position: absolute;
    background-color: var(--boss-camp-color);
    transform: translate(-50%, 50%) rotate(45deg) skew(30deg, 30deg);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: var(--icon-size) / 5;
      height: var(--icon-size) / 5;
      border-radius: var(--icon-size) / 5;
      background-color: var(--boss-camp-color);
      transform: translate(-50%, -50%) skew(-30deg, -30deg);
    }

    &:first-child,
    &:last-child {
      top: var(--icon-size) / 2;
      width: var(--icon-size);
      height: var(--icon-size);
    }

    &:nth-child(2),
    &:nth-child(4) {
      top: var(--icon-size) / 3;
      width: var(--icon-size);
      height: var(--icon-size);
    }

    &:nth-child(3) {
      top: var(--icon-size) / 4;
      width: var(--icon-size);
      height: var(--icon-size);
    }

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      left: 25%;
    }

    &:nth-child(3) {
      left: 50%;
    }

    &:nth-child(4) {
      left: 75%;
    }

    &:last-child {
      left: 100%;
    }
  }
`;

export default Crown;
