import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';
import Configuration from './pages/Configuration';
import Simulator from './pages/Simulator';
import { useDarkMode } from './redux/selectors';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ConfigProvider } from 'antd';

import 'antd/dist/antd.variable.min.css';
interface StyleSwitchProps {
  darkMode?: boolean;
}

function App({ ...otherProps }) {
  const { t } = useTranslation();
  const isDarkMode = useDarkMode();

  ConfigProvider.config({
    theme: {
      primaryColor: '#25b864'
    }
  });

  return (
    <StyleSwitch darkMode={isDarkMode} {...otherProps}>
      <ConfigProvider>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <div className='app'>
              <div className='top-navigation'>
                <Link className='top-navigation-menu-entry' to='/'>
                  <label>{t('Simulator')}</label>
                </Link>
                <Link className='top-navigation-menu-entry' to='/config'>
                  <label>{t('Einstellungen')}</label>
                </Link>
              </div>

              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <div className='euler-sim-content'>
                <Switch>
                  <Route path='/config'>
                    <Configuration />
                  </Route>
                  <Route path='/'>
                    <Simulator />
                  </Route>
                </Switch>
              </div>
              <div className='meta-info'>
                Dieser Simulator ist eine Fanseite von dem Spiel:{' '}
                <a href='http://www.diesiedleronline.de'>Die Siedler Online</a> <br />
                <label>Alle Grafiken sind Copyright by Blue Byte</label>
              </div>
            </div>
          </Router>
          <ToastContainer
            position='bottom-right'
            autoClose={10000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
            draggable
          />
        </DndProvider>
      </ConfigProvider>
    </StyleSwitch>
  );
}

const darkMode = css`
  --layout-background-color: #000000;
  --background-color: #181818;
  --background-color-light: #2e2e2e;
  --background-color-dark: black;
  --color: rgba(255, 255, 255, 0.85);
  --border-color: var(--background-color-light);
  --navigation-background-color: var(--background-color);

  --primary-color: #42b36f;
  --primary-color-light: #93d8aa;
  --primary-color-dark: #208049;

  --secondary-color: #aa6215;
  --secondary-color-light: #e89a3c;
  --secondary-color-dark: #7c4a15;

  --input-background: lightgray;
  --input-color: black;
`;

const lightMode = css`
  --layout-background-color: #ffffff;
  --background-color: #fafafa;
  --background-color-light: #ffffff;
  --background-color-dark: #f0f0f0;
  --color: rgba(0, 0, 0, 0.85);
  --border-color: var(--background-color-dark);
  --navigation-background-color: black;

  --primary-color: #25b864;
  --primary-color-light: #6dd192;
  --primary-color-dark: #16914f;

  --secondary-color: #ffa940;
  --secondary-color-light: #ffd591;
  --secondary-color-dark: #fa8c16;

  --input-background: white;
  --input-color: black;
`;

const StyleSwitch = styled.div<StyleSwitchProps>`
  ${props => (props.darkMode ? darkMode : lightMode)};

  --tertiary-color: aliceblue;

  --hover-background-color: var(--primary-color-light);
  --hover-foreground-color: #000000;

  --text-on-primary: #000000;
  --text-on-secondary: #000000;
  --text-on-tertiary: darkgray;

  --boss-camp-color: gold;
  --warning-color: red;

  --neutral-border: 2px solid var(--border-color);
  --box-shadow-hover: 0 8px 8px 0 rgba(255, 255, 255, 0.16), 0 0 0 4px rgba(255, 255, 255, 0.08);

  --navigation-height: 64px;

  background-color: var(--layout-background-color);
  color: var(--color);
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & .app {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: auto;
  }

  .euler-sim-content {
    flex: auto;
    min-height: 0;
    margin-top: var(--navigation-height);
    padding: 0 50px;
    overflow-anchor: none;
  }

  .top-navigation {
    height: var(--navigation-height);
    line-height: var(--navigation-height);
    background-color: var(--navigation-background-color);
    padding: 0 50px;
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  .top-navigation-menu-entry {
    display: inline-block;
    position: relative;
    padding: 0 20px;
    line-height: inherit;
    top: 0;
    margin: 0;
    cursor: pointer;
    transition: background-color 200ms, color 200ms;
    color: #dfdfdf;
  }

  .top-navigation-menu-entry:hover {
    background-color: var(--hover-background-color);
    color: var(--hover-foreground-color);
  }

  .meta-info {
    text-align: center;
    padding: 24px 50px;

    .container {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  .top-navigation,
  .meta-info {
    flex: 0 0 auto;
  }

  input {
    background-color: var(--input-background);
    color: var(--input-color);
  }

  .input-validation-msg {
    color: var(--warning-color);
    text-align: center;
    font-size: 0.8em;
  }

  .primary-button {
    padding: 8px 16px 8px 16px;
    background-color: var(--primary-color);
    color: var(--text-on-primary);
    border-radius: 4px;
    display: flex;
    transition: background-color 200ms, color 200ms;
    cursor: pointer;

    & > * {
      margin: auto;
    }
  }

  .primary-button:hover {
    box-shadow: var(--box-shadow-hover);
    background-color: var(--hover-background-color);
    color: var(--hover-foreground-color);
    transition: background-color 200ms, color 200ms;
  }

  .button {
    padding: 8px 16px 8px 16px;
    background-color: var(--background-color-light);
    color: var(--color);
    border-radius: 4px;
    display: flex;
    transition: background-color 200ms, color 200ms;
    cursor: pointer;

    & > * {
      margin: auto;
    }
  }

  .button:hover {
    box-shadow: var(--box-shadow-hover);
    background-color: var(--hover-background-color);
    color: var(--text-on-secondary);
    transition: background-color 200ms, color 200ms;
  }

  .selectable-element {
    border: 2px solid transparent;
    border-radius: 4px;
    transition: background-color 200ms, color 200ms;
    cursor: pointer;
  }

  .selectable-element:hover {
    background-color: var(--hover-background-color);
    color: var(--hover-foreground-color);
    transition: background-color 200ms, color 200ms;
  }

  .selectable-element-selected {
    border: 2px solid var(--secondary-color);
  }

  .selected-list {
    color: var(--text-on-secondary);
    background-color: var(--secondary-color);
    font-weight: 800;
  }

  .selection-outline {
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
  }

  .card-entry {
    border: 4px solid transparent;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    color: var(--color);
    padding: 16px 0 16px 0;
    transition: background-color 200ms;
    cursor: default;
  }

  --effect-selection-border-color: var(--background-color-light);
  --effect-selection-background: var(--background-color); /*  #cbbd71; */
  --effect-selection-color: var(--color);
  --effect-selection-icon-background: lightgray;
  --selected-effects-header-background-color: var(--effect-selection-icon-background);
  --selected-effects-header-color: black;
`;

export default styled(App)`
  width: 100%;
  height: 100%;
`;
