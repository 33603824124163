import { getSortedRounds } from "./getSortedRounds";
import { BlockResult } from "./toBlockResult";

export function getMinLockTime(result: { rounds: BlockResult["rounds"] }) {
  const sortedRounds = getSortedRounds(result);
  if (sortedRounds.length) {
    return sortedRounds[0];
  }
  return 0;
}
