import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BlockPreferences } from '../../app/store';
import { General, GeneralId } from '../../data-objects/units/Army';
import { SkillTree } from '../../data-objects/units/BaseUnitTypes';
import { getIconsForGeneralId } from '../../redux/configurationUtil';
import { availableGenerals, createMappedGenerals } from '../../redux/unitUtil';
import SelectableElement from '../Basics/SelectableElement';
import Tab from '../Basics/Tab';
import LabeledImage from '../LabeledImage';
import ConfirmDialog from '../layouts/ConfirmDialog';
import GeneralSkillTree from '../skills/GeneralSkillTree';
import GeneralSelection from '../units/GeneralSelection';

const BLOCK_ICON = 'icons/misc/Block.jpg';
const ATTACK_ICON = 'icons/misc/swords.png';

export interface GeneralsList {
  name: string;
  id: string;
  generals: CustomGeneralEntry[];
}
export interface CustomGeneralEntry {
  name: string;
  id: string;
  icon: number;
  abr?: string;
  iconIndex?: number;
  baseId: GeneralId;
  skills: SkillTree | null;
  prefersAttack?: boolean;
  prefersBlock?: boolean;
}
export interface MappedCustomGeneral {
  name: string;
  id: GeneralId;
  uuid: string;
  icon: string;
  iconIndex: CustomGeneralEntry['icon'];
  abr: CustomGeneralEntry['name'];
  baseId: GeneralId;
  skills: SkillTree | null;
  capacity: number;
  prefersAttack?: boolean;
  prefersBlock?: boolean;
}

interface GeneralConfigurationProps {
  generalsList: GeneralsList[];
  currentSelectedEntry: number;
  blockPreferences: BlockPreferences;
  addNewList: (name: string) => void;
  deleteList: (id: string) => void;
  addGeneral: (
    generalId: GeneralId,
    skills: SkillTree | null,
    name: string,
    iconId: number,
    preferedBlock: boolean,
    preferedAttack: boolean
  ) => void;
  deleteGeneral: (id: string) => void;
  updateGeneral: (
    generalId: string,
    skills: SkillTree | null,
    name: string,
    iconId: number,
    preferedBlock: boolean,
    preferedAttack: boolean
  ) => void;
  selectList: (id: string) => void;
  updateGeneralOrder: (hoverGeneralId: string, dropGeneralId: string) => void;
}

function GeneralConfiguration({
  addNewList,
  generalsList,
  currentSelectedEntry,
  blockPreferences,
  deleteList = id => {},
  addGeneral = (generalId, skills, name, iconId) => {},
  deleteGeneral = () => {},
  updateGeneral = (generalId, skills, name, iconId) => {},
  selectList = id => {},
  updateGeneralOrder,
  ...otherProps
}: GeneralConfigurationProps) {
  const [selectedSkills, setSelectedSkills] = useState<SkillTree | null>(null);
  const [generalName, setGeneralName] = useState('');
  const [generalIcon, setGeneralIcon] = useState(0);
  const [showConfirm, setShowConfirm] = useState<string | undefined>(undefined);
  const [preferedBlock, setPreferedBlock] = useState(false);
  const [preferedAttack, setPreferedAttack] = useState(false);
  const [selectedGeneral, setSelectedGeneral] = useState<MappedCustomGeneral | General>(availableGenerals[0]);
  const { t } = useTranslation();
  const generalsListEntry = generalsList[currentSelectedEntry];

  const customGeneralsOnList = createMappedGenerals(generalsListEntry?.generals);

  const sharedSelectGeneral = useCallback(
    (gen: MappedCustomGeneral | General) => {
      setSelectedGeneral(gen);
      setSelectedSkills(gen.skills ?? null);
      if ('iconIndex' in gen) {
        setGeneralName(gen.abr);
        setGeneralIcon(gen.iconIndex ?? 0);
        setPreferedBlock(gen.prefersBlock ?? false);
        setPreferedAttack(gen.prefersAttack ?? false);
      } else {
        setSelectedSkills(null);
        setGeneralName('');
        setGeneralIcon(0);
        setPreferedBlock(blockPreferences.defaultBlockGenerals.includes(gen.id));
      }
    },
    [blockPreferences.defaultBlockGenerals]
  );

  return (
    <div {...otherProps}>
      <div className='custom-generals'>
        <div className='general-list-management'>
          {generalsList.map((entry, index) => (
            <Tab
              label={entry.name}
              selected={index === currentSelectedEntry}
              onSelect={() => selectList(entry.id)}
              key={entry.name}
              onClose={() => setShowConfirm(entry.id)}
            />
          ))}
          {generalsList.length < 4 && (
            <Tab label='+' selected={false} onEdit={name => name && name.length > 0 && addNewList(name)} />
          )}
          <ConfirmDialog
            visible={!!showConfirm}
            text={t('Soll die Liste wirklich gelöscht werden?')}
            explain={t('Es werden alle gespeicherten Generale dieser Liste ebenfalls entfernt.')}
            onAccept={() => {
              setShowConfirm(undefined);
              deleteList(showConfirm!);
            }}
            onDeny={() => setShowConfirm(undefined)}
          />
        </div>
        <GeneralSelection
          availableGens={customGeneralsOnList}
          selectedGeneralId={selectedGeneral.uuid ?? selectedGeneral.id}
          setGeneral={sharedSelectGeneral}
          enableDragDrop
          updateGeneralOrder={(hoverGeneralId, dropGeneralId) =>
            updateGeneralOrder(hoverGeneralId as string, dropGeneralId as string)
          }
        />
        <p className='general-sort-info'>{t('Sortierung der Generale via Drag&Drop möglich.')}</p>
      </div>
      <div className='gen-config'>
        <label>{t('Name')}:</label>
        <input
          type='text'
          value={generalName}
          onChange={e => {
            const newName = e.target.value;
            if (newName) {
              setGeneralName(newName.substring(0, 10));
            } else {
              setGeneralName('');
            }
          }}
        />
        <label>{t('Icon')}:</label>
        <div draggable={false}>
          {getIconsForGeneralId('baseId' in selectedGeneral ? selectedGeneral.baseId : selectedGeneral.id).map(
            (icon, index) => (
              <img
                draggable={false}
                className={`general-icon selectable-element ${
                  index === generalIcon ? 'selectable-element-selected' : ''
                }`}
                key={`${selectedGeneral.uuid ?? selectedGeneral.id}-${index}`}
                src={icon}
                style={{ userSelect: 'none' }}
                alt=''
                onClick={() => setGeneralIcon(index)}
              />
            )
          )}
        </div>
        <div className='config-skill-tree'>
          <GeneralSkillTree skills={selectedSkills} updateSkills={setSelectedSkills} />
        </div>
        <div className='generals-prefered-usage'>
          <SelectableElement onClick={() => setPreferedAttack(oldPrefered => !oldPrefered)} selected={preferedAttack}>
            <LabeledImage text='General ist ein Angriffsgeneral' labelAfter unit={{ icon: ATTACK_ICON }} />
          </SelectableElement>
          <SelectableElement onClick={() => setPreferedBlock(oldPrefered => !oldPrefered)} selected={preferedBlock}>
            <LabeledImage text='General ist ein Lockgeneral' labelAfter unit={{ icon: BLOCK_ICON }} />
          </SelectableElement>
        </div>
        <div className='generals-buttons'>
          <div className='generals-buttons-container'>
            {'baseId' in selectedGeneral ? (
              <div
                className='primary-button'
                onClick={() =>
                  updateGeneral(
                    selectedGeneral.uuid,
                    selectedSkills,
                    generalName,
                    generalIcon,
                    preferedBlock,
                    preferedAttack
                  )
                }>
                {t('Update General')}
              </div>
            ) : (
              <div
                className='primary-button'
                onClick={() =>
                  addGeneral(
                    selectedGeneral.id,
                    selectedSkills,
                    generalName,
                    generalIcon,
                    preferedBlock,
                    preferedAttack
                  )
                }>
                {t('Add General')}
              </div>
            )}
            {'baseId' in selectedGeneral ? (
              <div
                className='button'
                onClick={() => {
                  deleteGeneral(selectedGeneral.uuid);
                  setSelectedGeneral(availableGenerals[0]);
                }}>
                {t('Remove General')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className='default-generals'>
        <GeneralSelection
          selectedGeneralId={selectedGeneral.uuid ?? selectedGeneral.id}
          availableGens={availableGenerals}
          setGeneral={sharedSelectGeneral}
        />
      </div>
    </div>
  );
}

export default styled(GeneralConfiguration)`
  display: flex;

  ${GeneralSelection} {
    padding: 18px 4px;
    border: var(--neutral-border);
  }

  .default-generals,
  .custom-generals {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .general-sort-info {
    text-align: center;
  }
  .gen-config {
    padding: 0 28px;
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 1.5em max-content auto auto;
    grid-gap: 8px;
    width: 50%;
    min-width: 590px;

    .general-icon {
      width: 54px;
      height: 65px;
      margin-right: 8px;
    }

    .config-skill-tree {
      grid-column: 1 / span 2;
      display: flex;
      & > * {
        margin: auto;
      }
    }

    .generals-prefered-usage {
      display: flex;
      grid-column: 1 / span 2;
      justify-content: space-around;
      font-size: 0.8em;

      & > * {
        padding: 8px;
      }

      ${LabeledImage} {
        height: 35px;
      }
    }

    .generals-buttons {
      display: flex;
      grid-column: 1 / span 2;
      flex-direction: row;
      justify-content: space-around;
    }
    .generals-buttons-container {
      margin: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
`;
