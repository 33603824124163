import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Skill, SkillTree } from '../../data-objects/units/BaseUnitTypes';

const BLITZANGRIFF_ICON = '/icons/skills/LightningSlash.png';
const MEISTERSTRATEGE_ICON = '/icons/skills/MasterPlanner.png';
const KOPFGELD_ICON = '/icons/skills/BountyHunter.png';
const EXTRALEBEN_ICON = '/icons/skills/1up.png';

const SPALTEN_ICON = '/icons/skills/Cleave.png';
const WARTUNG_ICON = '/icons/skills/WeeklyMaintanance.png';
const GARNISONSAUSBAU_ICON = '/icons/skills/GarrisonAnnex.png';
const ANFUEHRER_ICON = '/icons/skills/ConfidentLeader.png';

const ANSTURM_ICON = '/icons/skills/UnstoppableCharge.png';
const KAMPFRAUSCH_ICON = '/icons/skills/BattleFrenzy.png';
const BLITZDENKER_ICON = '/icons/skills/FastLearner.png';
const FLEISCHWUNDE_ICON = '/icons/skills/JustAFleshWound.png';

const SCHARFSCHUETZEN_ICON = '/icons/skills/SniperTraining2.png';
const UEBERRENNEN_ICON = '/icons/skills/Overrun.png';
const UEBERNAHME_ICON = '/icons/skills/HostileTakeover.png';
const NAVIGATION_ICON = '/icons/skills/NavigationCrashCourse.png';

const SCHNELLFEUER_ICON = '/icons/skills/RapidFire.png';
const MOLOCH_ICON = '/icons/skills/Juggernaut.png';
const ERSTE_HILFE_ICON = '/icons/skills/FirstAid.png';
const TRAB_ICON = '/icons/skills/Jog2.png';

interface SkillButtonProps {
  image: string;
  counter: number;
  summedCount: number;
  setCounter: (count: number) => void;
  maxCount: number;
  enabled: boolean;
}
const SkillButton = styled(function SkillButton({
  image,
  counter,
  summedCount,
  setCounter,
  maxCount,
  enabled,
  ...otherProps
}: SkillButtonProps) {
  function handleOnClick() {
    if (enabled) {
      if (summedCount < 21) {
        setCounter((counter + 1) % (maxCount + 1));
      } else {
        if (counter > 0) {
          setCounter(0);
        }
      }
    }
  }

  return (
    <div {...otherProps} onClick={handleOnClick}>
      <img draggable={false} src={image} alt='skill' />
      <div className='counter'>{`${counter}/${maxCount}`}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  user-select: none;

  ${({ enabled, counter, summedCount }) => {
    if (enabled && (summedCount < 21 || (summedCount === 21 && counter > 0))) {
      return css`
        background-color: var(--primary-color);
        color: var(--text-on-primary);
        cursor: pointer;

        &:hover {
          background-color: var(--hover-background-color);
          color: var(--hover-foreground-color);
        }
      `;
    }
    return css`
      background-color: var(--tertiary-color);
      color: var(--text-on-tertiary);
      cursor: not-allowed;
    `;
  }}

  & > .counter {
    margin: auto;
  }
`;

const FIFTH_ROW: SkillIconGroup[] = [
  { icon: BLITZANGRIFF_ICON, attribute: Skill.BLITZANGRIFF_ATTRIBUTE },
  { icon: MEISTERSTRATEGE_ICON, attribute: Skill.MEISTERSTRATEGE_ATTRIBUTE },
  { icon: KOPFGELD_ICON, attribute: Skill.KOPFGELD_ATTRIBUTE },
  { icon: EXTRALEBEN_ICON, attribute: Skill.EXTRALEBEN_ATTRIBUTE }
];

const FOURTH_ROW: SkillIconGroup[] = [
  { icon: SPALTEN_ICON, attribute: Skill.SPALTEN_ATTRIBUTE },
  { icon: WARTUNG_ICON, attribute: Skill.WOECHTENTLICHE_WARTUNG_ATTRIBUTE },
  { icon: GARNISONSAUSBAU_ICON, attribute: Skill.GARNISONSAUSBAU_ATTRIBUTE },
  { icon: ANFUEHRER_ICON, attribute: Skill.SUVERAENER_ANFUEHRER_ATTRIBUTE }
];

const THIRD_ROW: SkillIconGroup[] = [
  { icon: ANSTURM_ICON, attribute: Skill.UNAUFHALTSAMER_ANSTURM_ATTRIBUTE },
  { icon: KAMPFRAUSCH_ICON, attribute: Skill.KAMPFRAUSCH_ATTRIBUTE },
  { icon: BLITZDENKER_ICON, attribute: Skill.BLITZDENKER_ATTRIBUTE },
  { icon: FLEISCHWUNDE_ICON, attribute: Skill.NUR_EINE_FLEISCHWUNDE_ATTRIBUTE }
];

const SECOND_ROW: SkillIconGroup[] = [
  {
    icon: SCHARFSCHUETZEN_ICON,
    attribute: Skill.SCHARFSCHUETZENAUSBILDUNG_ATTRIBUTE
  },
  { icon: UEBERRENNEN_ICON, attribute: Skill.UEBERRENNEN_ATTRIBUTE },
  { icon: UEBERNAHME_ICON, attribute: Skill.FEINDLICHE_UEBERNAHME_ATTRIBUTE },
  { icon: NAVIGATION_ICON, attribute: Skill.NAVIGATIONSSCHNELLKURS_ATTRIBUTE }
];

const FIRST_ROW: SkillIconGroup[] = [
  { icon: SCHNELLFEUER_ICON, attribute: Skill.SCHNELLFEUER_ATTRIBUTE },
  { icon: MOLOCH_ICON, attribute: Skill.MOLOCH_ATTRIBUTE },
  { icon: ERSTE_HILFE_ICON, attribute: Skill.ERSTE_HILFE_ATTRIBUTE },
  { icon: TRAB_ICON, attribute: Skill.TRAB_ATTRIBUTE }
];

interface SkillIconGroup {
  icon: string;
  attribute: Skill;
}

export const ALL_GENERAL_SKILLS: SkillIconGroup[] = [
  ...FIFTH_ROW,
  ...FOURTH_ROW,
  ...THIRD_ROW,
  ...SECOND_ROW,
  ...FIRST_ROW
];

function sumUpSkillPoints(row: SkillIconGroup[], skills: SkillTree) {
  return row.reduce((sum, skill) => sum + (skills[skill.attribute] || 0), 0);
}

function resetSkills(rowIsOk: boolean, skills: SkillIconGroup[], updateSkills: SkillTreeProps['updateSkills']) {
  if (!rowIsOk) {
    const resettedSkills = skills.reduce((prev, skill) => ({ ...prev, [skill.attribute]: 0 }), {} as SkillTree);
    updateSkills(oldSkills => ({
      ...oldSkills,
      ...resettedSkills
    }));
  }
}

interface SkillTreeProps {
  skills: SkillTree | null;
  updateSkills: React.Dispatch<React.SetStateAction<SkillTree | null>>;
}

function GeneralSkillTree({ skills, updateSkills, ...otherProps }: SkillTreeProps) {
  const secondRowOk = sumUpSkillPoints(FIRST_ROW, skills ?? {}) >= 5;
  const thirdRowOk = secondRowOk && sumUpSkillPoints([...FIRST_ROW, ...SECOND_ROW], skills ?? {}) >= 10;
  const fourthRowOk = thirdRowOk && sumUpSkillPoints([...FIRST_ROW, ...SECOND_ROW, ...THIRD_ROW], skills ?? {}) >= 15;
  const fifthRowOk =
    fourthRowOk && sumUpSkillPoints([...FIRST_ROW, ...SECOND_ROW, ...THIRD_ROW, ...FOURTH_ROW], skills ?? {}) >= 20;

  const usedSkillPoints =
    Object.values(skills ?? { a: 0 }).reduce((prev, current) => (prev ?? 0) + (current ?? 0), 0) ?? 0;

  useEffect(() => {
    resetSkills(fifthRowOk, FIFTH_ROW, updateSkills);
  }, [fifthRowOk, updateSkills]);
  useEffect(() => {
    resetSkills(fourthRowOk, FOURTH_ROW, updateSkills);
  }, [fourthRowOk, updateSkills]);
  useEffect(() => {
    resetSkills(thirdRowOk, THIRD_ROW, updateSkills);
  }, [thirdRowOk, updateSkills]);
  useEffect(() => {
    resetSkills(secondRowOk, SECOND_ROW, updateSkills);
  }, [secondRowOk, updateSkills]);

  return (
    <div {...otherProps}>
      {ALL_GENERAL_SKILLS.map((skill, index) => (
        <SkillButton
          key={skill.attribute}
          image={skill.icon}
          counter={(skills ?? {})[skill.attribute] || 0}
          maxCount={index < FIFTH_ROW.length ? 1 : 3}
          summedCount={usedSkillPoints}
          enabled={
            index < FIFTH_ROW.length
              ? fifthRowOk
              : index < FIFTH_ROW.length + FOURTH_ROW.length
              ? fourthRowOk
              : index < FIFTH_ROW.length + FOURTH_ROW.length + THIRD_ROW.length
              ? thirdRowOk
              : index < FIFTH_ROW.length + FOURTH_ROW.length + THIRD_ROW.length + SECOND_ROW.length
              ? secondRowOk
              : true
          }
          setCounter={newValue =>
            updateSkills(old => {
              if (old !== null) {
                return { ...old, [skill.attribute]: newValue };
              }
              return { [skill.attribute]: newValue };
            })
          }
        />
      ))}
    </div>
  );
}

export default styled(GeneralSkillTree)`
  display: grid;
  grid-template-columns: repeat(4, 100px);
  grid-gap: 25px;
  background-color: var(--background-color-light);
  padding: 28px;
`;
