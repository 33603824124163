import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SimpleCampType } from '../app/store';
import Tab from '../components/Basics/Tab';
import BuffSelection from '../components/BuffSelection';
import { MappedCustomGeneral } from '../components/Configurations/GeneralConfiguration';
import SidePanel from '../components/layouts/SidePanel';
import SimulationAndSearchResults from '../components/results/SimulationAndSearchResults';
import ExampleSimulation from '../components/simulation/ExampleSimulation';
import SimulationButton from '../components/SimulationButtons';
import SkillsPopup from '../components/skills/SkillsPopup';
import GeneralInfo from '../components/units/GeneralInfo';
import GeneralSelection from '../components/units/GeneralSelection';
import UnitSelection from '../components/units/UnitSelection';
import { AdventureId, getAdventureById } from '../data-objects/adventures/Adventures';
import { CampBuilding } from '../data-objects/CampTypes';
import { General } from '../data-objects/units/Army';
import { useCurrentAvailableGenerals, useCurrentSkilledUnits } from '../hooks/simulationHooks';
import { getCampFromAdventure } from '../redux/adventureUtil';
import {
  useCurrentEnemyWave,
  useCurrentHighlightedGeneral,
  useCurrentSelectedEnemies,
  useCurrentSelectedSkills,
  useCurrentSelectedUnits,
  useCurrentShowCustomGenerals,
  useCurrentShowEliteUnits,
  useCurrentWave,
  useGeneralsList,
  useSelectedEnemies,
  useShowCustomGenerals
} from '../redux/selectors';
import * as actions from '../redux/simulationActions';

const unitSwitchElite = '/icons/unitsSwitchElite.png';
const unitSwitchNormal = '/icons/unitsSwitchNormal.png';
const skillsIcon = '/icons/skillTree.png';
const detailsIcon = '/icons/misc/icon_magnifier.png';

const ArmyArea = styled.div`
  grid-area: units;
  width: 100%;
`;

interface SimulationProps {
  selectedAdventure: AdventureId;
  selectedCamps: (SimpleCampType | null)[];
  className?: string;
}

function Simulation({ selectedAdventure, selectedCamps, ...otherProps }: SimulationProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [showSkills, setShowSkills] = useState(false);

  const { t } = useTranslation();
  const adventure = getAdventureById(selectedAdventure);
  const dispatch = useDispatch();

  const highlightedGeneral = useCurrentHighlightedGeneral();
  const showCustomGens = useCurrentShowCustomGenerals();
  const currentSelectedSkills = useCurrentSelectedSkills() || {};
  const currentSelectedUnits = useCurrentSelectedUnits() || {};
  const currentShowEliteUnits = useCurrentShowEliteUnits();
  const currentSelectedEnemies = useCurrentSelectedEnemies();
  const currentWave = useCurrentWave();
  const currentEnemyWave = useCurrentEnemyWave();
  const showCustomGenerals = useShowCustomGenerals();
  const selectedEnemies = useSelectedEnemies();
  const generalsLists = useGeneralsList();

  const currentAvailableGenerals = useCurrentAvailableGenerals();

  const simulatableCamps = Object.values(selectedCamps).reduce(
    (prev, camp) => [...prev, getCampFromAdventure(adventure, camp)],
    [] as CampBuilding[]
  );

  const { skilledUnits, skilledBandits, skilledGeneral } = useCurrentSkilledUnits();

  return (
    <div {...otherProps}>
      <div className='general-selection-buttons'>
        <div className='the-buttons'>
          <Tab
            selected={showCustomGens < 0}
            onSelect={() => dispatch(actions.showStandardGenerals())}
            label={t('Standard')}
          />
          {generalsLists.map((entry, index) => (
            <Tab
              key={entry.id}
              selected={showCustomGens === index}
              label={entry.name}
              onSelect={() => {
                dispatch(actions.showCustomGenerals(index));
              }}
            />
          ))}
        </div>
        <GeneralSelection
          enableSelectNoGeneral={(showCustomGens < 0) as true}
          availableGens={currentAvailableGenerals}
          selectedGeneralIndex={highlightedGeneral ?? undefined}
          setGeneral={(gen?: General | MappedCustomGeneral, index?: number) => {
            dispatch(actions.changeGeneral(gen as General, index!));
          }}
        />
      </div>
      <GeneralInfo skilledGeneral={skilledGeneral} />
      <div className='player-buttons'>
        <img
          className='change-units'
          onClick={() => dispatch(actions.toggleEliteUnits())}
          src={currentShowEliteUnits ? unitSwitchNormal : unitSwitchElite}
          alt='unit switch'
        />
        <div className='small-label'>{currentShowEliteUnits ? t('Normal') : t('Elite')}</div>
        <img className='change-skills' src={skillsIcon} onClick={() => setShowSkills(true)} alt='skills' />
        <div className='small-label'>{t('Skills')}</div>
        <img
          className='show-details'
          style={{
            borderColor: showDetails ? 'var(--primary-color)' : 'transparent'
          }}
          src={detailsIcon}
          alt='Details'
          onClick={() => setShowDetails(prev => !prev)}
        />
        <div className='small-label'>{t('Show Details')}</div>
        {/*
          <label>
            <input
              type="checkbox"
              name="showExample"
              checked={showExample}
              onChange={() => setShowExample((prev) => !prev)}
            />
            {t("Kampfdetails")}
          </label>*/}
      </div>
      <div className='current-adventure-details'>
        <div style={{ fontWeight: 800 }}>{t(adventure.name)}</div>
        <div className='change-adventure primary-button' onClick={() => dispatch(actions.changeAdventure())}>
          <div>{t('Change Adventure')}</div>
        </div>
        <div className='change-camp primary-button' onClick={() => dispatch(actions.changeCamp())}>
          <div>{t('Change Camp')}</div>
        </div>
      </div>
      <ArmyArea>
        <UnitSelection
          availableUnits={skilledUnits}
          selectedUnits={currentSelectedUnits}
          setUserInputUnits={newSelected => dispatch(actions.updateSelectedUnits(newSelected))}
          general={skilledGeneral}
          isPlayer={true}
          availableWaves={Object.keys(showCustomGenerals)
            .map(key => parseInt(key))
            .sort((a, b) => a - b)}
          deleteWave={wave => dispatch(actions.deleteWave(wave))}
          selectWave={wave => dispatch(actions.changeWave(wave))}
          onNewWave={() => dispatch(actions.changeWave(showCustomGenerals.length))}
          {...{ showDetails, currentWave }}
        />
      </ArmyArea>
      <BuffSelection selectedAdventure={selectedAdventure} />
      <div className='enemy-selection'>
        <UnitSelection
          availableUnits={skilledBandits}
          selectedUnits={currentSelectedEnemies}
          setUserInputUnits={newSelected => dispatch(actions.updateSelectedEnemies(newSelected))}
          general={skilledGeneral}
          availableWaves={Object.keys(selectedEnemies)
            .map(key => parseInt(key))
            .sort((a, b) => a - b)}
          selectWave={wave => dispatch(actions.changeEnemyWave(wave))}
          deleteWave={wave => dispatch(actions.deleteEnemyWave(wave))}
          onNewWave={() => dispatch(actions.changeEnemyWave(Object.keys(selectedEnemies).length))}
          currentWave={currentEnemyWave}
          {...{ showDetails }}
        />
      </div>
      <div className='simulation-buttons'>
        <SimulationButton selectedCamps={simulatableCamps} />
      </div>
      <SimulationAndSearchResults />
      <SkillsPopup
        visible={showSkills}
        onClose={() => setShowSkills(false)}
        initialSkills={currentSelectedSkills}
        saveSkills={skills => dispatch(actions.updateSkills(skills))}
      />
      <SidePanel isVisible={showExample} onHide={() => setShowExample(false)}>
        {showExample && <ExampleSimulation selectedCamps={simulatableCamps} />}
      </SidePanel>
    </div>
  );
}

export default styled(Simulation)`
  display: grid;
  grid-template-columns: minmax(250px, 1fr) 380px 380px minmax(450px, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'generals unitManagement adventureInfo adventureInfo'
    'generals units enemies buffs'
    'genInfo units enemies buffs'
    'buttons units enemies buffs'
    '. units enemies buffs'
    'footer footer footer footer';
  grid-column-gap: 56px;
  grid-row-gap: 28px;
  justify-items: start;
  padding: 28px 0;

  .player-buttons {
    grid-area: unitManagement;
    display: grid;
    grid-template-rows: 1fr 0.7em;
    width: 100%;
    grid-auto-flow: column;
    align-items: center;
    justify-items: center;

    .show-details {
      border: 2px solid transparent;
      padding: 2px;
      border-radius: 4px;
    }

    .small-label {
      font-size: 0.7em;
    }
  }

  .current-adventure-details {
    grid-area: adventureInfo;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > * {
      border-radius: 10px;
      margin: auto 28px auto 0;
      text-align: center;
    }
  }

  ${BuffSelection} {
    grid-area: buffs;
  }

  ${SimulationAndSearchResults} {
    grid-area: footer;
    width: 100%;
  }

  .enemy-selection {
    width: 100%;
    grid-area: enemies;
  }

  .general-selection-buttons {
    grid-area: generals;
    width: 100%;

    ${GeneralSelection} {
      padding: 18px 4px 6px 4px;
      border: var(--neutral-border);
    }
  }

  ${GeneralInfo} {
    grid-area: genInfo;
  }

  .simulation-buttons {
    grid-area: buttons;
    display: grid;
    width: 100%;
  }
`;
