import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  initiallyOpen?: boolean;
  renderCustomHeader?: (params: { isOpen: boolean; toggleOpen: () => void }) => JSX.Element;
  className?: string;
}

function FoldableList({ initiallyOpen, renderCustomHeader, children, ...otherProps }: React.PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(initiallyOpen);

  const buttonProps = renderCustomHeader
    ? {}
    : {
        className: 'button',
        onClick: () => setVisible(visible => !visible)
      };
  return (
    <div {...otherProps}>
      <div {...buttonProps}>
        {renderCustomHeader ? (
          renderCustomHeader({
            isOpen: !!visible,
            toggleOpen: () => setVisible(visible => !visible)
          })
        ) : (
          <div>{visible ? t('Zuklappen') : t('Zeige Details')}</div>
        )}
      </div>
      <div
        className='foldable-table'
        style={
          visible
            ? undefined
            : {
                maxHeight: 0,
                padding: 0,
                transition: 'max-height 200ms, padding 10ms 200ms'
              }
        }>
        {children}
      </div>
    </div>
  );
}

export default styled(FoldableList)`
  display: flex;
  flex-direction: column;
  outline: var(--neutral-border);
  border-radius: 4px;
  font-size: 0.8em;

  & .foldable-table {
    padding: 8px 36px 8px 36px;
    border-radius: 4px;
    background-color: var(--background-color-dark);
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 200ms ease-out;

    .header {
      grid-column: 1 / span 2;
      font-size: 1.2em;
      font-weight: 600;
    }
  }
`;
