import { UserInputUnits } from '../components/units/UnitSelection';
import { AdventureId } from '../data-objects/adventures';
import { GlobalEffects, LazarettBuff } from '../data-objects/GlobalEffects';
import { General } from '../data-objects/units/Army';
import { SkillTree } from '../data-objects/units/BaseUnitTypes';
import {
  ABORT_CHANGE_CAMP,
  CHANGE_ADVENTURE,
  CHANGE_CAMP,
  CHANGE_ENEMY_WAVE,
  CHANGE_GENERAL,
  CHANGE_SKILLS,
  CHANGE_WAVE,
  DELETE_ENEMY_WAVE,
  DELETE_WAVE,
  SHOW_CUSTOM_GENERALS,
  TOGGLE_BK_ARMORY,
  TOGGLE_BK_COLD,
  TOGGLE_BK_EP,
  TOGGLE_BK_HEADQUARTER,
  TOGGLE_BK_ICE,
  TOGGLE_BK_RECOVERY,
  TOGGLE_BK_SNOW,
  TOGGLE_BK_STORM,
  TOGGLE_FIRE,
  TOGGLE_FROST,
  TOGGLE_KANTINE,
  TOGGLE_LAZARETT,
  TOGGLE_METAL,
  TOGGLE_NATURE,
  TOGGLE_NEBEL,
  TOGGLE_SHOW_ELITE_UNITS,
  TOGGLE_SONNENSCHEIN,
  TOGGLE_STONE,
  TOGGLE_WATER,
  TOGGLE_WIND,
  TOGGLE_WIRBELSTURM,
  UPDATE_SELECTED_ENEMIES,
  UPDATE_SELECTED_UNITS
} from './simulationActionTypes';

export function toggleNebelWeather() {
  return {
    type: TOGGLE_NEBEL
  } as const;
}
export function toggleSonnenscheinWeather() {
  return {
    type: TOGGLE_SONNENSCHEIN
  } as const;
}
export function toggleWirbelsturmWeather() {
  return {
    type: TOGGLE_WIRBELSTURM
  } as const;
}
export function toggleFrostWeather() {
  return {
    type: TOGGLE_FROST
  } as const;
}
export function toggleColdEffect() {
  return {
    type: TOGGLE_BK_COLD
  } as const;
}
export function toggleIceEffect() {
  return {
    type: TOGGLE_BK_ICE
  } as const;
}
export function toggleStormEffect() {
  return {
    type: TOGGLE_BK_STORM
  } as const;
}
export function toggleSnowEffect() {
  return {
    type: TOGGLE_BK_SNOW
  } as const;
}
export function toggleArmory() {
  return {
    type: TOGGLE_BK_ARMORY
  } as const;
}
export function toggleHeadquarter() {
  return {
    type: TOGGLE_BK_HEADQUARTER
  } as const;
}
export function toggleRecovery() {
  return {
    type: TOGGLE_BK_RECOVERY
  } as const;
}
export function toggleEp() {
  return {
    type: TOGGLE_BK_EP
  } as const;
}
export function toggleWind() {
  return {
    type: TOGGLE_WIND
  } as const;
}
export function toggleStone() {
  return {
    type: TOGGLE_STONE
  } as const;
}
export function toggleFire() {
  return {
    type: TOGGLE_FIRE
  } as const;
}
export function toggleWater() {
  return {
    type: TOGGLE_WATER
  } as const;
}
export function toggleMetal() {
  return {
    type: TOGGLE_METAL
  } as const;
}
export function toggleNature() {
  return {
    type: TOGGLE_NATURE
  } as const;
}

export function toggleLazarett(stufe: LazarettBuff['stufe'], value: LazarettBuff['value']) {
  return {
    type: TOGGLE_LAZARETT,
    stufe,
    value
  } as const;
}

export function toggleKantine(stufe: GlobalEffects['kantine']) {
  return {
    type: TOGGLE_KANTINE,
    stufe
  } as const;
}

export function changeEnemyWave(wave: string | number) {
  return {
    type: CHANGE_ENEMY_WAVE,
    wave
  } as const;
}

export function changeWave(wave: string | number) {
  return {
    type: CHANGE_WAVE,
    wave
  } as const;
}

export function deleteEnemyWave(wave: string | number) {
  return {
    type: DELETE_ENEMY_WAVE,
    wave
  } as const;
}

export function deleteWave(wave: string | number) {
  return {
    type: DELETE_WAVE,
    wave
  } as const;
}

export function showStandardGenerals() {
  return {
    type: SHOW_CUSTOM_GENERALS,
    index: -1
  } as const;
}

export function showCustomGenerals(index: number) {
  return {
    type: SHOW_CUSTOM_GENERALS,
    index
  } as const;
}

export function changeAdventure(adventure?: AdventureId) {
  return {
    type: CHANGE_ADVENTURE,
    adventure
  } as const;
}

export function changeCamp(sector?: string, number?: number) {
  return {
    type: CHANGE_CAMP,
    sector,
    number
  } as const;
}

export function abortChangeCamp(sector?: string, number?: number) {
  return {
    type: ABORT_CHANGE_CAMP,
    sector,
    number
  } as const;
}

export function changeGeneral(general: General, index: number) {
  return {
    type: CHANGE_GENERAL,
    general,
    index
  } as const;
}
export function toggleEliteUnits() {
  return {
    type: TOGGLE_SHOW_ELITE_UNITS
  } as const;
}

export function updateSelectedUnits(units: UserInputUnits) {
  return {
    type: UPDATE_SELECTED_UNITS,
    units
  } as const;
}

export function updateSelectedEnemies(units: UserInputUnits) {
  return {
    type: UPDATE_SELECTED_ENEMIES,
    units
  } as const;
}

export function updateSkills(skills: SkillTree | null) {
  return {
    type: CHANGE_SKILLS,
    skills
  } as const;
}

export type SimulationActions =
  | ReturnType<typeof toggleNebelWeather>
  | ReturnType<typeof toggleSonnenscheinWeather>
  | ReturnType<typeof toggleWirbelsturmWeather>
  | ReturnType<typeof toggleFrostWeather>
  | ReturnType<typeof toggleColdEffect>
  | ReturnType<typeof toggleIceEffect>
  | ReturnType<typeof toggleStormEffect>
  | ReturnType<typeof toggleSnowEffect>
  | ReturnType<typeof toggleArmory>
  | ReturnType<typeof toggleHeadquarter>
  | ReturnType<typeof toggleRecovery>
  | ReturnType<typeof toggleEp>
  | ReturnType<typeof toggleWind>
  | ReturnType<typeof toggleStone>
  | ReturnType<typeof toggleFire>
  | ReturnType<typeof toggleWater>
  | ReturnType<typeof toggleMetal>
  | ReturnType<typeof toggleNature>
  | ReturnType<typeof toggleLazarett>
  | ReturnType<typeof toggleKantine>
  | ReturnType<typeof changeEnemyWave>
  | ReturnType<typeof changeWave>
  | ReturnType<typeof deleteEnemyWave>
  | ReturnType<typeof deleteWave>
  | ReturnType<typeof showStandardGenerals>
  | ReturnType<typeof showCustomGenerals>
  | ReturnType<typeof changeAdventure>
  | ReturnType<typeof changeCamp>
  | ReturnType<typeof changeGeneral>
  | ReturnType<typeof toggleEliteUnits>
  | ReturnType<typeof updateSelectedUnits>
  | ReturnType<typeof updateSelectedEnemies>
  | ReturnType<typeof updateSkills>
  | ReturnType<typeof abortChangeCamp>;
