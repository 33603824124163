import { DetailedResult, ResultState } from "../../app/store";
import { initialState } from "../../app/storeUtil";
import { addWaveResult, ResultActions } from "./resultActions";
import { ResultActionTypes } from "./resultActionTypes";

function handleAddSimulationWaveResult(
  state: ResultState,
  action: ReturnType<typeof addWaveResult>
) {
  const {
    campNo,
    battleResult,
    losses,
    simulations,
    weather,
    attackerWave,
    enemyWave,
  } = action;

  const newResult: DetailedResult = {
    boolResult: battleResult,
    lossesMap: losses,
    simulations: simulations,
    campNo: parseInt(campNo),
    weather,
    attackerWave,
    enemyWave,
  };
  return {
    ...state,
    waveResult: [...state.waveResult, newResult],
  };
}

function handleClearWaveResults(state: ResultState) {
  return { ...state, waveResult: [] };
}

function handleClearSimulationResults(state: ResultState) {
  return { ...state, result: {} };
}

export default function resultReducer(
  state: ResultState | undefined = initialState.result,
  action: ResultActions
) {
  switch (action.type) {
    case ResultActionTypes.CLEAR_SIMULATION_RESULTS:
      return handleClearSimulationResults(state);
    case ResultActionTypes.ADD_WAVE_RESULT:
      return handleAddSimulationWaveResult(state, action);
    case ResultActionTypes.START_WAVE_SIMULATION:
      return handleClearWaveResults(state);
    default:
      return state;
  }
}
