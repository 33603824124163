import { ConfigurationState } from '../../app/store';
import hufeisen from '../../data-objects/hufeisen';
import { GeneralId, UnitId } from '../../data-objects/units/Army';
import { SkillTree } from '../../data-objects/units/BaseUnitTypes';
import { ConfigurationActionTypes } from './configurationActionTypes';

export function changeUnitValue(unitId: UnitId, newValue: number) {
  return {
    type: ConfigurationActionTypes.CHANGE_UNIT_VALUE,
    unitId,
    newValue
  } as const;
}

export function updateCustomGeneral(
  generalId: string,
  skills: SkillTree | null,
  name: string,
  icon: number,
  preferedBlock: boolean,
  preferedAttack: boolean
) {
  return {
    type: ConfigurationActionTypes.UPDATE_CUSTOM_GENERAL,
    generalId,
    skills,
    name,
    icon,
    preferedBlock,
    preferedAttack
  } as const;
}

export function addCustomGeneral(
  generalId: GeneralId,
  skills: SkillTree | null,
  name: string,
  icon: number,
  preferedBlock: boolean,
  preferedAttack: boolean
) {
  return {
    type: ConfigurationActionTypes.ADD_CUSTOM_GENERAL,
    generalId,
    skills,
    name,
    icon,
    preferedBlock,
    preferedAttack
  } as const;
}

export function updateGeneralOrder(hoverGeneralId: string, dropGeneralId: string) {
  return {
    type: ConfigurationActionTypes.UPDATE_GENERAL_LIST,
    hoverGeneralId,
    dropGeneralId
  } as const;
}

export function removeCustomGeneral(uuid: string) {
  return {
    type: ConfigurationActionTypes.DELETE_CUSTOM_GENERAL,
    uuid
  } as const;
}

export function createNewGeneralList(name: string) {
  return {
    type: ConfigurationActionTypes.CREATE_GENERAL_LIST,
    name
  } as const;
}

export function selectConfigurationList(id: string) {
  return {
    type: ConfigurationActionTypes.SELECT_GENERAL_LIST,
    id
  } as const;
}

export function deleteConfigurationList(uuid: string) {
  return {
    type: ConfigurationActionTypes.DELETE_GENERAL_LIST,
    uuid
  } as const;
}

export function importConfiguration(configuration: ConfigurationState) {
  return {
    type: ConfigurationActionTypes.IMPORT_CONFIGURATION,
    configuration
  } as const;
}

export function toggleDarkMode() {
  return {
    type: ConfigurationActionTypes.TOGGLE_DARK_MODE
  } as const;
}

export function toggleBlockUnitSelection(id: number) {
  return {
    type: ConfigurationActionTypes.TOGGLE_BLOCK_NORMAL_UNIT,
    id
  } as const;
}

export function toggleBlockEliteUnitSelection(id: number) {
  return {
    type: ConfigurationActionTypes.TOGGLE_BLOCK_ELITE_UNIT,
    id
  } as const;
}

export function toggleBlockGeneralSelection(id: number) {
  return {
    type: ConfigurationActionTypes.CHANGE_BLOCK_GENERAL,
    id
  } as const;
}

export function changeAmountOfMaxBlocks(value: number) {
  return {
    type: ConfigurationActionTypes.CHANGE_MAX_FOUND_BLOCKS,
    value
  } as const;
}

export function changeSimulationsPerBlock(value: number) {
  return {
    type: ConfigurationActionTypes.CHANGE_SIMULATIONS_PER_BLOCK,
    value
  } as const;
}

export function changeDefaultBlockLength(value: number) {
  return {
    type: ConfigurationActionTypes.CHANGE_DEFAULT_BLOCK_LENGTH,
    value
  } as const;
}

export function changePreferedHorseShoe(value: keyof typeof hufeisen) {
  return {
    type: ConfigurationActionTypes.CHANGE_PREFERED_HORSESHOE,
    value
  } as const;
}

export type ConfigurationActions =
  | ReturnType<typeof changeUnitValue>
  | ReturnType<typeof updateCustomGeneral>
  | ReturnType<typeof addCustomGeneral>
  | ReturnType<typeof removeCustomGeneral>
  | ReturnType<typeof createNewGeneralList>
  | ReturnType<typeof selectConfigurationList>
  | ReturnType<typeof deleteConfigurationList>
  | ReturnType<typeof toggleDarkMode>
  | ReturnType<typeof toggleBlockEliteUnitSelection>
  | ReturnType<typeof toggleBlockUnitSelection>
  | ReturnType<typeof toggleBlockGeneralSelection>
  | ReturnType<typeof changeAmountOfMaxBlocks>
  | ReturnType<typeof changeSimulationsPerBlock>
  | ReturnType<typeof changeDefaultBlockLength>
  | ReturnType<typeof changePreferedHorseShoe>
  | ReturnType<typeof updateGeneralOrder>
  | ReturnType<typeof importConfiguration>;
