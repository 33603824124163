import { getOurArmyLosses } from "../../components/results/utils";
import { GeneralId, UnitId } from "../../data-objects/units/Army";
import { Amount, SimulationLosses } from "../../redux/simulationUtil";
import { allGeneralIds, unitDefaultValues } from "../../redux/unitUtil";

export interface DefinedAmount extends Amount {
  avg: number;
}
interface UnitLossValues {
  allValues: DefinedAmount[];
  value: DefinedAmount;
}

export function calculateLossValue(
  simResults: Array<{
    losses: { units: SimulationLosses["units"] };
    simulations: number;
  }>,
  unitValues: typeof unitDefaultValues
): UnitLossValues {
  const allValues = simResults.map((result) =>
    calculateSingleLossValue(result, unitValues)
  );
  return {
    allValues,
    value: allValues.reduce(
      (prev, value) => ({
        min: prev.min + value.min,
        max: prev.max + value.max,
        avg: prev.avg + value.avg,
      }),
      { min: 0, max: 0, avg: 0 }
    ),
  };
}

function calculateSingleLossValue(
  simResult: {
    losses: { units: SimulationLosses["units"] };
    simulations: number;
  },
  unitValues: typeof unitDefaultValues
): DefinedAmount {
  const ourArmyLosses = getOurArmyLosses(
    simResult.losses,
    simResult.simulations
  );

  return Object.entries(ourArmyLosses).reduce(
    (total, [unitId, unitLosses]) => {
      if (allGeneralIds.includes(parseInt(unitId) as GeneralId)) {
        return total;
      }
      const ourUnitId = parseInt(unitId) as UnitId;
      const amount = unitLosses ?? {
        min: 0,
        max: 0,
      };
      const newMinValue = amount.min * unitValues[ourUnitId];
      const newMaxValue = amount.max * unitValues[ourUnitId];
      const newAvgValue = (amount.avg ?? 0) * unitValues[ourUnitId];
      return {
        min: total.min + newMinValue,
        max: total.max + newMaxValue,
        avg: total.avg + newAvgValue,
      };
    },
    { min: 0, max: 0, avg: 0 }
  );
}
