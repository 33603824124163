import { ArmyId } from '../../../data-objects/units/Army';
import { Amount, getLosses, SimulationLosses } from '../../../redux/simulationUtil';

export function getOurArmyLosses(
  lossesMap: { units: SimulationLosses['units'] },
  simulations: number,
  key: 'details' | 'detailsRecover' = 'details'
) {
  return Object.entries(lossesMap.units.combined).reduce((prev, [unitId, combined]) => {
    const ourUnitId = parseInt(unitId) as ArmyId;
    const ourLossesDetail = lossesMap.units[key][ourUnitId];
    if (combined === undefined || ourLossesDetail === undefined) {
      return prev;
    }
    return {
      ...prev,
      [unitId]: getLosses(combined, ourLossesDetail, simulations)
    };
  }, {} as Record<number, Amount>);
}
