import { SearchResult } from "../../app/store";
import { AdventureId } from "../../data-objects/adventures";
import { General } from "../../data-objects/units/Army";
import { SearchCamp } from "../../Finder/utils";
import { SearchArmyActionTypes } from "./searchArmyActionTypes";

export function addSearchResult(
  general: General,
  result: SearchResult,
  adventureId: AdventureId,
  banditCamps: SearchCamp[],
  isAttack?: true
) {
  return {
    type: SearchArmyActionTypes.ADD_SEARCH_RESULT,
    general,
    result,
    adventureId,
    banditCamps,
    isAttack,
  } as const;
}

export function addMultipleResults(
  general: General,
  results: SearchResult[],
  adventureId: AdventureId,
  banditCamps: SearchCamp[],
  isAttack?: true
) {
  return {
    type: SearchArmyActionTypes.ADD_MULTIPLE_RESULTS,
    general,
    results,
    adventureId,
    banditCamps,
    isAttack,
  } as const;
}

export function startNewSearch(
  adventureId: AdventureId,
  camps: SearchCamp[],
  clearPrevious?: boolean,
  isAttack?: true
) {
  return {
    type: SearchArmyActionTypes.START_NEW_SEARCH,
    clearPrevious,
    adventureId,
    camps,
    isAttack,
  } as const;
}

export function finishLockSearch(isAttack?: true) {
  return {
    type: SearchArmyActionTypes.FINISHED_SEARCH,
    isAttack,
  } as const;
}

export function abortLockSearch(isAttack?: true) {
  return {
    type: SearchArmyActionTypes.ABORT_SEARCH,
    isAttack,
  } as const;
}

export type SearchResultActions =
  | ReturnType<typeof addSearchResult>
  | ReturnType<typeof addMultipleResults>
  | ReturnType<typeof finishLockSearch>
  | ReturnType<typeof abortLockSearch>
  | ReturnType<typeof startNewSearch>;
