import isEqual from "lodash.isequal";
import { SearchResult } from "../../../app/store";

export function searchResultEquals(one: SearchResult, other: SearchResult) {
  if (isEqual(one.weather, other.weather)) {
    if ("army" in one && "army" in other) {
      return isEqual(one.army, other.army);
    }
    if ("battleResult" in one && "battleResult" in other) {
      // TODO: implement results are equal
      return (
        one.battleResult === other.battleResult &&
        one.details.length === other.details.length &&
        isEqual(one.units, other.units)
      );
    }
  }
  return false;
}
