import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Amount } from '../../redux/simulationUtil';
import { sortByRang } from '../../redux/unitUtil';
import LabeledImage from './LabeledImage';

interface DisplayUnitBase {
  amount: Amount;
  icon: string;
}

interface DisplayUnit extends DisplayUnitBase {
  rang: number;
}
export interface DisplayUnitsProps {
  units: Array<DisplayUnit>;
  general?: DisplayUnitBase;
  center?: true;
}

function DisplayUnitsUnstyled({ units, general, center, ...otherProps }: DisplayUnitsProps) {
  const { t } = useTranslation();
  const noneString = t('(keine)');
  const unitsToDisplay = units.filter(unit => unit.amount.max !== 0).sort(sortByRang);
  return (
    <div {...otherProps}>
      {!general && unitsToDisplay.length === 0 && <span>{noneString}</span>}
      {general && <LabeledImage amount={general.amount} icon={general.icon} />}
      {unitsToDisplay.map(unit => (
        <LabeledImage key={`labeled-${unit.icon}`} amount={unit.amount} icon={unit.icon} />
      ))}
    </div>
  );
}

export const DisplayUnits = styled(DisplayUnitsUnstyled)`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  justify-content: ${props => (props.center ? 'center' : 'left')};

  ${LabeledImage} {
    img {
      height: 35px;
    }
  }
  & > span:not(:last-child) {
    margin-right: 8px;
  }
`;
