import {
  getAllMaerchenAdventures,
  MaerchenId,
  Maerchen,
  getAllClassicAdventures,
  KlassischeIds,
  KlassischesAt,
  getAllExcelsiorAdventures,
  UnternehmungenIds,
  Unternehmung,
} from ".";

export type AdventureId = UnternehmungenIds | KlassischeIds | MaerchenId;
export type Adventure = Maerchen | Unternehmung | KlassischesAt;

export function getAdventureById(id: AdventureId): Adventure {
  return getAdventureByExpression((at): at is Adventure => at.id === id)[0];
}

export function getAllAdventures(): Adventure[] {
  return getAdventureByExpression();
}

export function getAdventureByExpression(
  expr: (at: Adventure) => at is Adventure = (at): at is Adventure => true
): Adventure[] {
  const ats = [
    ...getAllMaerchenAdventures(),
    ...getAllExcelsiorAdventures(),
    ...getAllClassicAdventures(),
  ];
  return ats.filter<Adventure>(expr);
}
