import { SelectedUnits } from '../../components/units/UnitSelection';
import { createUnitsString, getNumberOfUnits } from '../../components/units/utils';
import { General, Unit } from '../../data-objects/units/Army';
import { Enemy } from '../../data-objects/units/Units';
import { Amount, LossesMap, SimulationLosses } from '../../redux/simulationUtil';
import { unitDefaultValues } from '../../redux/unitUtil';
import { FinderUnit } from '../utils';
import { calculateLossValue, DefinedAmount } from './calculateLossValue';
import { createAttackRefinements } from './createRefinements';
import { resultIsBetter } from './resultIsBetter';
import { resultIsOkayish } from './resultIsOkayish';

export interface CacheEntry {
  units: Record<number, number>;
  result: DefinedAmount;
  losses: Array<LossesMap>;
}

export type ResultCache = Record<string, CacheEntry>;

export type AllUnitsReport = {
  general: { amount: number; originalAmount: 1; unit: General };
  units: {
    [x: number]: {
      amount: Amount;
      originalAmount: Amount;
      unit: Unit;
    };
  };
  enemies: {
    [x: number]: {
      amount: Amount;
      originalAmount: Amount;
      unit: Enemy;
    };
  };
};

export interface SimulationReportResults {
  bandits: SimulationLosses['bandits'];
  units: SimulationLosses['units'];
  abriss: SimulationLosses['abriss'];
  rounds: SimulationLosses['rounds'];
  battleRounds: SimulationLosses['battleRounds'];
  usedUnits: AllUnitsReport;
}

export interface AttackSearchSingleReport {
  battleResult: number;
  losses: SimulationReportResults;
  simulations: number;
  enemyWave: number;
  attackerWave: number;
}

interface SimulateProps {
  allConfigs: Array<SelectedUnits>;
  attackFunction: (units: SelectedUnits) => Promise<Array<AttackSearchSingleReport>>;
  reportResult: (result: Array<AttackSearchSingleReport>, usedUnits: SelectedUnits) => void;
  progressFunction: (toAdd: number) => void;
  definitelyWon: (result: Array<AttackSearchSingleReport>) => boolean;
  almostWon: (result: Array<AttackSearchSingleReport>) => boolean;
  shouldAbort: () => boolean;
  existingResults: ResultCache;
  generalCapacity: number;
  stepSize: number;
  unitValues: typeof unitDefaultValues;
  bestWeatherResult: DefinedAmount;
}

export async function simulateAttackConfigs({
  allConfigs,
  bestWeatherResult,
  unitValues,
  existingResults,
  generalCapacity,
  stepSize,
  attackFunction,
  reportResult,
  progressFunction,
  definitelyWon,
  almostWon,
  shouldAbort
}: SimulateProps) {
  const backupResults: {
    units: Record<number, number>;
    result: DefinedAmount;
    losses: Array<LossesMap>;
  }[] = [];
  for (let configIdx = 0; configIdx < allConfigs.length; configIdx++) {
    const ourUnits = allConfigs[configIdx];
    if (shouldAbort()) {
      return;
    }
    const numberOfUnits = getNumberOfUnits(ourUnits);

    const configForOtherGeneralCapacity = numberOfUnits !== generalCapacity;
    // if (resultCounter >= blockPreferences.maxResults) {
    //   dispatch(uiActions.updateProgress(allConfigs.length - configIndex));
    //   break;
    // }

    if (configForOtherGeneralCapacity) {
      progressFunction(1);
      continue;
    }
    const ourUnitsKey = createUnitsString(ourUnits);
    const simResult = await attackFunction(ourUnits);

    // check result
    if (definitelyWon(simResult)) {
      const simResultLossValue = calculateLossValue(simResult, unitValues);
      const currentResultLossValue = simResultLossValue.value;
      // Results which are close to each other regarding lossValue should be reported to the user
      // as it offers more possibilities which units are used
      if (resultIsOkayish(bestWeatherResult, currentResultLossValue)) {
        reportResult(simResult, ourUnits);
        if (resultIsBetter(bestWeatherResult, currentResultLossValue)) {
          bestWeatherResult = currentResultLossValue;
        }
      } else {
        // Won, but with higher loss as the current 'benchmark'
        // We don't need to report this, but store result locally to flag this combination
        // as 'already simulated'.
      }

      existingResults[ourUnitsKey] = {
        units: ourUnits,
        result: currentResultLossValue,
        // TODO: Is it okay to use combined here? We may want to use min/max instead!
        losses: simResult.map(result => result.losses.units.combined)
      };
    } else if (almostWon(simResult)) {
      const simResultLossValue = calculateLossValue(simResult, unitValues);
      const currentResultLossValue = simResultLossValue.value;
      // TODO: We may need to store these as well in case we cannot win the battle at all.
      // We could report that it was close instead of "No chance!"
      backupResults.push({
        units: ourUnits,
        result: currentResultLossValue,
        losses: simResult.map(result => result.losses.units.combined)
        // result: simResult,
      });
    }
    progressFunction(1);
  }

  if (stepSize === 1) {
    // We cannot further refine if the current stepSize already is 1
    return;
  }
  if (shouldAbort()) {
    return;
  }
  var toBeRefined = backupResults;
  if (Object.values(existingResults).length) {
    toBeRefined = Object.values(existingResults);
  }

  const maxResultsToReuse = stepSize < 5 ? 3 : 6;

  toBeRefined = toBeRefined
    .filter(result => Object.values(result.units).length > 1)
    .sort((one, other) => (resultIsBetter(one.result, other.result) ? 1 : -1))
    .slice(0, maxResultsToReuse);

  const usedUnitIds: Array<string> = [];
  toBeRefined.forEach(result => {
    Object.keys(result.units).forEach(key => !usedUnitIds.includes(key) && usedUnitIds.push(key));
  });

  const toRefineUnits = toBeRefined
    .map(refinement => refinement.units)
    .reduce<{ [x: string]: FinderUnit }>((newUnitRange, current) => {
      return usedUnitIds.reduce<{ [x: string]: FinderUnit }>(
        (previous, unitId) => ({
          ...previous,
          [unitId]: {
            id: parseInt(unitId),
            min: Math.min(current[parseInt(`${unitId}`)] || 0, previous[unitId]?.min ?? 1000),
            max: Math.max(current[parseInt(`${unitId}`)] || 0, previous[unitId]?.max ?? 0)
          }
        }),
        newUnitRange
      );
    }, {});

  const refineFinderUnits = Object.values(toRefineUnits);
  const { usedStepSize: newStepSize, configs: refinements } = createAttackRefinements(
    refineFinderUnits,
    generalCapacity,
    stepSize
  );

  // TODO: Find better handling of progress. E.g. Forsee progress for 1-3 refinement steps upfront and use this amount here
  progressFunction(-refinements.length);

  await simulateAttackConfigs({
    allConfigs: refinements,
    bestWeatherResult,
    unitValues,
    existingResults,
    generalCapacity,
    stepSize: newStepSize,
    attackFunction,
    reportResult,
    progressFunction,
    definitelyWon,
    almostWon,
    shouldAbort
  });
}
