import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import EffectSelection from "../components/layouts/EffectSelection";
import {
  AT_BERGLABYRINTH_ID,
  AT_DASBERGVOLK_ID,
  AT_DIEVERLORENESTADT_ID,
  AT_EINRIESENKAMPF_ID,
  AT_UNBEKANNTEREGIONEN_ID,
} from "../data-objects/adventures/Excelsior";
import { GlobalEffects } from "../data-objects/GlobalEffects";
import { useGlobalEffects } from "../redux/selectors";
import * as actions from "../redux/simulationActions";
import { BaseProps } from "../types/basics";

const FrostIcon = "/icons/buffs/icon_weather_condition_frost.png";
const SonnenscheinIcon = "/icons/buffs/icon_weather_condition_heat.png";
const WirbelsturmIcon = "/icons/buffs/icon_weather_condition_storm.png";
const NebelIcon = "/icons/buffs/icon_weather_condition_fog.png";

const ICON_RITUAL_WIND = "/icons/buffs/wind.jpg";
const ICON_RITUAL_STONE = "/icons/buffs/stone.jpg";
const ICON_RITUAL_WATER = "/icons/buffs/water.jpg";
const ICON_RITUAL_FIRE = "/icons/buffs/fire.jpg";
const ICON_RITUAL_METAL = "/icons/buffs/metal.jpg";
const ICON_RITUAL_NATURE = "/icons/buffs/nature.jpg";

const ICON_BK_COLD = "/icons/buffs/cold.png";
const ICON_BK_ICE = "/icons/buffs/ice.png";
const ICON_BK_STORM = "/icons/buffs/storm.png";
const ICON_BK_ARMORY = "/icons/buffs/armor.png";
const ICON_BK_HEADQUARTER = "/icons/buffs/head.png";
const ICON_BK_RECOVERY = "/icons/buffs/Suppe.png";
const ICON_BK_EP = "/icons/buffs/Wachturm.png";

const ICON_STATION = "/icons/buffs/Wetterkontrollstation.png";

const weatherStation = [
  { name: "Frost", icon: FrostIcon },
  { name: "Sonne", icon: SonnenscheinIcon },
  { name: "Nebel", icon: NebelIcon },
  { name: "Wirbelst.", icon: WirbelsturmIcon },
];

const selectedStyle = { borderColor: "var(--primary-color" };
const unselectedStyle = { borderColor: "transparent" };

const bergclan45 = [AT_DASBERGVOLK_ID, AT_EINRIESENKAMPF_ID];
const bergclan25 = [
  AT_UNBEKANNTEREGIONEN_ID,
  AT_BERGLABYRINTH_ID,
  ...bergclan45,
];
const lostCityId = AT_DIEVERLORENESTADT_ID;

interface Props extends BaseProps {
  selectedAdventure: number;
}

function FinderBuffs({ selectedAdventure, ...otherProps }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const weatherEffects = useGlobalEffects();

  const lostCity = lostCityId === selectedAdventure;

  return (
    <div {...otherProps}>
      <div className="wetterstation">
        <div className="wetter-titel">{t("Wetterstation")}</div>
        <EffectSelection
          effects={weatherStation}
          globalPicture={ICON_STATION}
        />
        <div className="wetter-infos">
          {weatherEffects.frost && (
            <div className="info">
              <div className="title">{`${t("Frost")}: `}</div>
              <div className="details">{t("Frost-details")}</div>
            </div>
          )}
          {weatherEffects.wirbelsturm && (
            <div className="info">
              <div className="title">{`${t("Wirbelsturm")}: `}</div>
              <div className="details">{t("Wirbelsturm-details")}</div>
            </div>
          )}
          {weatherEffects.sonnenschein && (
            <div className="info">
              <div className="title">{`${t("Sonnenschein")}: `}</div>
              <div className="details">{t("Sonnenschein-details")}</div>
            </div>
          )}
          {weatherEffects.nebel && (
            <div className="info">
              <div className="title">{`${t("Nebel")}: `}</div>
              <div className="details">{t("Nebel-details")}</div>
            </div>
          )}
        </div>
      </div>
      {bergclan25.includes(selectedAdventure) && (
        <div className="bk-abenteuer-buffs">
          <div className="wetter-titel padding-top">{t("Bergclan")}</div>
          <div className="wetterbuffs">
            <div
              onClick={() => dispatch(actions.toggleColdEffect())}
              style={weatherEffects.bk_cold ? selectedStyle : unselectedStyle}
            >
              <img src={ICON_BK_COLD} alt="kaelte-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleIceEffect())}
              style={weatherEffects.bk_ice ? selectedStyle : unselectedStyle}
            >
              <img src={ICON_BK_ICE} alt="ice-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleStormEffect())}
              style={weatherEffects.bk_storm ? selectedStyle : unselectedStyle}
            >
              <img src={ICON_BK_STORM} alt="storm-buff" />
            </div>
            {bergclan45.includes(selectedAdventure) && (
              <>
                <div
                  onClick={() => dispatch(actions.toggleArmory())}
                  style={
                    weatherEffects.bk_armory ? selectedStyle : unselectedStyle
                  }
                >
                  <img src={ICON_BK_ARMORY} alt="armory-buff" />
                </div>
                <div
                  onClick={() => dispatch(actions.toggleHeadquarter())}
                  style={
                    weatherEffects.bk_headquarter
                      ? selectedStyle
                      : unselectedStyle
                  }
                >
                  <img src={ICON_BK_HEADQUARTER} alt="headquarter-buff" />
                </div>
                <div
                  onClick={() => dispatch(actions.toggleRecovery())}
                  style={
                    weatherEffects.bk_recovery ? selectedStyle : unselectedStyle
                  }
                >
                  <img src={ICON_BK_RECOVERY} alt="recovery-buff" />
                </div>
                <div
                  onClick={() => dispatch(actions.toggleEp())}
                  style={weatherEffects.bk_ep ? selectedStyle : unselectedStyle}
                >
                  <img src={ICON_BK_EP} alt="ep-buff" />
                </div>
              </>
            )}
          </div>
          <div className="wetter-infos">
            {weatherEffects.bk_cold && (
              <div className="info">
                <div className="title">{`${t("BK_Cold")}: `}</div>
                <div className="details">{t("BK_Cold-details")}</div>
              </div>
            )}
            {weatherEffects.bk_ice && (
              <div className="info">
                <div className="title">{`${t("BK_Ice")}: `}</div>
                <div className="details">{t("BK_Ice-details")}</div>
              </div>
            )}
            {weatherEffects.bk_storm && (
              <div className="info">
                <div className="title">{`${t("BK_Storm")}: `}</div>
                <div className="details">{t("BK_Storm-details")}</div>
              </div>
            )}
            {weatherEffects.bk_armory && (
              <div className="info">
                <div className="title">{`${t("BK_Armory")}: `}</div>
                <div className="details">{t("BK_Armory-details")}</div>
              </div>
            )}
            {weatherEffects.bk_headquarter && (
              <div className="info">
                <div className="title">{`${t("BK_Headquarter")}: `}</div>
                <div className="details">{t("BK_Headquarter-details")}</div>
              </div>
            )}
            {weatherEffects.bk_recovery && (
              <div className="info">
                <div className="title">{`${t("BK_Recovery")}: `}</div>
                <div className="details">{t("BK_Recovery-details")}</div>
              </div>
            )}
            {weatherEffects.bk_ep && (
              <div className="info">
                <div className="title">{`${t("BK_Ep")}: `}</div>
                <div className="details">{t("BK_Ep-details")}</div>
              </div>
            )}
          </div>
        </div>
      )}
      {lostCity && (
        <div className="lost-city-abenteuer-buffs">
          <div className="wetter-titel padding-top">{t("TheLostCity")}</div>
          <div className="wetterbuffs">
            <div
              onClick={() => dispatch(actions.toggleWind())}
              style={weatherEffects.city_wind ? selectedStyle : unselectedStyle}
            >
              <img src={ICON_RITUAL_WIND} alt="wind-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleStone())}
              style={
                weatherEffects.city_stone ? selectedStyle : unselectedStyle
              }
            >
              <img src={ICON_RITUAL_STONE} alt="stone-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleFire())}
              style={weatherEffects.city_fire ? selectedStyle : unselectedStyle}
            >
              <img src={ICON_RITUAL_FIRE} alt="fire-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleWater())}
              style={
                weatherEffects.city_water ? selectedStyle : unselectedStyle
              }
            >
              <img src={ICON_RITUAL_WATER} alt="water-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleMetal())}
              style={
                weatherEffects.city_metal ? selectedStyle : unselectedStyle
              }
            >
              <img src={ICON_RITUAL_METAL} alt="metal-buff" />
            </div>
            <div
              onClick={() => dispatch(actions.toggleNature())}
              style={
                weatherEffects.city_nature ? selectedStyle : unselectedStyle
              }
            >
              <img src={ICON_RITUAL_NATURE} alt="nature-buff" />
            </div>
          </div>
          <div className="wetter-infos">
            {weatherEffects.city_wind && (
              <div className="info">
                <div className="title">{`${t("Wind")}: `}</div>
                <div className="details">{t("Wind-details")}</div>
              </div>
            )}
            {weatherEffects.city_stone && (
              <div className="info">
                <div className="title">{`${t("Stone")}: `}</div>
                <div className="details">{t("Stone-details")}</div>
              </div>
            )}
            {weatherEffects.city_fire && (
              <div className="info">
                <div className="title">{`${t("Fire")}: `}</div>
                <div className="details">{t("Fire-details")}</div>
              </div>
            )}
            {weatherEffects.city_water && (
              <div className="info">
                <div className="title">{`${t("Water")}: `}</div>
                <div className="details">{t("Water-details")}</div>
              </div>
            )}
            {weatherEffects.city_metal && (
              <div className="info">
                <div className="title">{`${t("Metal")}: `}</div>
                <div className="details">{t("Metal-details")}</div>
              </div>
            )}
            {weatherEffects.city_nature && (
              <div className="info">
                <div className="title">{`${t("Nature")}: `}</div>
                <div className="details">{t("Nature-details")}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default styled(FinderBuffs)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .padding-top {
    padding-top: 28px;
  }
  .wetterstation,
  .bk-abenteuer-buffs,
  .lost-city-abenteuer-buffs {
    width: 100%;
    display: flex;
    flex-direction: column;

    .wetter-titel {
      font-weight: 800;
      padding-bottom: 8px;
    }

    .wetterbuffs {
      display: flex;
      padding-bottom: 8px;

      & > * {
        display: flex;
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 2px;
        margin: auto 10px auto 0;
      }
    }

    .wetter-infos {
      .info {
        display: flex;
        flex-direction: row;

        .title {
          padding: 8px 0 0 16px;
          font-weight: 600;
        }
        .details {
          padding: 8px 0 0 8px;
        }
      }
    }
  }
`;

export function getImageForEffect(effectName: keyof GlobalEffects) {
  switch (effectName) {
    case "wirbelsturm":
      return WirbelsturmIcon;
    case "nebel":
      return NebelIcon;
    case "frost":
      return FrostIcon;
    case "sonnenschein":
      return SonnenscheinIcon;
    case "bk_armory":
      return ICON_BK_ARMORY;
    case "bk_cold":
      return ICON_BK_COLD;
    case "bk_ep":
      return ICON_BK_EP;
    case "bk_headquarter":
      return ICON_BK_HEADQUARTER;
    case "bk_ice":
      return ICON_BK_ICE;
    case "bk_recovery":
      return ICON_BK_RECOVERY;
    case "bk_storm":
      return ICON_BK_STORM;
    case "city_fire":
      return ICON_RITUAL_FIRE;
    case "city_metal":
      return ICON_RITUAL_METAL;
    case "city_nature":
      return ICON_RITUAL_NATURE;
    case "city_stone":
      return ICON_RITUAL_STONE;
    case "city_water":
      return ICON_RITUAL_WATER;
    case "city_wind":
      return ICON_RITUAL_WIND;
  }
}
