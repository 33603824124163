import styled from "styled-components";
import { BaseProps } from "../../types/basics";

interface Props extends BaseProps {
  completed: number;
}

function ProgressBar({ completed, ...otherProps }: Props) {
  return (
    <div {...otherProps}>
      <div
        className="progress-filler"
        style={{
          width: `${completed}%`,
        }}
      >
        <span>{`${completed.toFixed(2)}%`}</span>
      </div>
    </div>
  );
}

export default styled(ProgressBar)`
  height: 1.2em;
  width: 100%;
  background-color: var(--primary-color-light);
  border-radius: 50px;

  .progress-filler {
    height: 100%;
    background-color: var(--primary-color-dark);
    border-radius: inherit;
    text-align: right;
    transition: width 50ms ease-in-out;

    & > span {
      padding: 5px;
      font-weight: bold;
      color: var(--text-on-primary);
    }
  }
`;
