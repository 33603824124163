import { SearchResult } from "../../app/store";
import { getAverageRoundsFromResult } from "../../redux/result/resultUtil";
import { getSortedRounds } from "./getSortedRounds";

export function sortSearchResults(one: SearchResult, other: SearchResult) {
  if ("win" in one && "win" in other) {
    const lowestRoundsOne = getSortedRounds(one)[0];
    const lowestRoundsOther = getSortedRounds(other)[0];

    if (lowestRoundsOne === lowestRoundsOther) {
      const avgRoundsOther = getAverageRoundsFromResult(other);
      const avgRoundsOne = getAverageRoundsFromResult(one);
      if (avgRoundsOne === avgRoundsOther) {
        return one.army.length - other.army.length;
      }
      return avgRoundsOther - avgRoundsOne;
    }
    return lowestRoundsOther - lowestRoundsOne;
  }
  // implement sorting
  // by loss value
  // by used units
  return 0;
}
