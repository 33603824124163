import { SearchResult } from '../../../app/store';
import { convertToNumber } from '../../../components/Basics/util';
import { SelectedUnits } from '../../../components/units/UnitSelection';
import { AllUnitsReport, AttackSearchSingleReport, combineAttackBaseResults } from '../../../Finder/attack-utils';
import { LossMap } from '../../../worker/simulate';
import { Amount, BanditLosses, UnitLosses } from '../../simulationUtil';

export function mergeSearchResult(one: SearchResult, other: SearchResult): SearchResult {
  if ('win' in one || 'win' in other) {
    if ('win' in one && 'win' in other) {
      return {
        ...one,
        win: one.win + other.win,
        loss: one.loss + other.loss,
        simulations: one.simulations + other.simulations,
        abriss: mergeLossMap(one.abriss, other.abriss),
        battleRounds: mergeLossMap(one.battleRounds, other.battleRounds),
        rounds: mergeLossMap(one.rounds, other.rounds)
      };
    }
    // Cannot merge different result types. This should not happen
    return one;
  }
  return combineAttackBaseResults(
    one.general,
    one.units,
    one.banditCamps,
    one.weather,
    mergeAttackResultDetails(one.details, other.details)
  );
}

function mergeSimulationLosses(
  one: AttackSearchSingleReport['losses'],
  other: AttackSearchSingleReport['losses']
): AttackSearchSingleReport['losses'] {
  return {
    usedUnits: mergeUsedUnits(one.usedUnits, other.usedUnits),
    units: mergeUnitLosses(one.units, other.units),
    bandits: mergeUnitLosses(one.bandits, other.bandits),
    abriss: {
      min: Math.min(one.abriss.min, other.abriss.min),
      max: Math.max(one.abriss.max, other.abriss.max),
      details: mergeLossMap(one.abriss.details, other.abriss.details)
    },
    battleRounds: {
      min: Math.min(one.battleRounds.min, other.battleRounds.min),
      max: Math.max(one.battleRounds.max, other.battleRounds.max),
      details: mergeLossMap(one.battleRounds.details, other.battleRounds.details)
    },
    rounds: {
      min: Math.min(one.rounds.min, other.rounds.min),
      max: Math.max(one.rounds.max, other.rounds.max),
      details: mergeLossMap(one.rounds.details, other.rounds.details)
    }
  };
}

function mergeAttackResultDetails(one: Array<AttackSearchSingleReport>, other: Array<AttackSearchSingleReport>) {
  return one.map<AttackSearchSingleReport>((firstResult, index) => {
    const otherResult = other[index];

    return {
      attackerWave: firstResult.attackerWave,
      enemyWave: firstResult.enemyWave,
      simulations: firstResult.simulations + otherResult.simulations,
      losses: mergeSimulationLosses(firstResult.losses, otherResult.losses),
      battleResult:
        firstResult.battleResult === otherResult.battleResult
          ? firstResult.battleResult
          : (firstResult.battleResult * firstResult.simulations + otherResult.battleResult * otherResult.simulations) /
            (firstResult.simulations + otherResult.simulations)
    };
  });
}

function mergeLossMap(first: LossMap, second: LossMap) {
  return Object.entries(first).reduce<LossMap>(
    (prev, [key, value]) => ({
      ...prev,
      [key]: (prev[parseInt(key)] ?? 0) + value
    }),
    { ...second }
  );
}

function mergeUnitLosses(one: UnitLosses, other: UnitLosses): UnitLosses;
function mergeUnitLosses(one: BanditLosses, other: BanditLosses): BanditLosses;
function mergeUnitLosses(one: UnitLosses | BanditLosses, other: UnitLosses | BanditLosses): UnitLosses | BanditLosses {
  const detailKeys = Object.keys(one.details).map(detailsKey => convertToNumber(detailsKey));
  Object.keys(other.details).forEach(key =>
    detailKeys.includes(convertToNumber(key)) ? {} : detailKeys.push(convertToNumber(key))
  );

  const newDetails = mergeDetails(detailKeys, one.details, other.details);

  const newCombined = Object.entries(one.combined).reduce(
    (prev, [unitId, unitAmount]) => {
      return { ...prev, [unitId]: mergeAmount(unitAmount, prev[unitId]) };
    },
    { ...other.combined }
  );

  if ('detailsRecover' in one && 'detailsRecover' in other) {
    const mergedRecover = mergeDetails(detailKeys, one.detailsRecover, other.detailsRecover);
    return {
      combined: newCombined,
      details: newDetails,
      detailsRecover: mergedRecover,
      min: mergeLossMap(one.min, other.min),
      max: mergeLossMap(one.max, other.max)
    };
  }

  return {
    combined: newCombined,
    details: newDetails,
    min: mergeLossMap(one.min, other.min),
    max: mergeLossMap(one.max, other.max)
  };
}

function mergeDetails(detailKeys: number[], one: Record<number, SelectedUnits>, other: Record<number, SelectedUnits>) {
  return detailKeys.reduce<UnitLosses['details']>((prev, detailsKey) => {
    const oneDetails = one[detailsKey];
    const otherDetails = other[detailsKey];

    let mergedDetails;
    if (!oneDetails) {
      mergedDetails = otherDetails;
    } else if (!otherDetails) {
      mergedDetails = oneDetails;
    } else {
      mergedDetails = mergeLossMap(oneDetails, otherDetails);
    }
    return { ...prev, [detailsKey]: mergedDetails };
  }, {});
}

function mergeAmount(one: Amount, other: Amount): Amount {
  if (!one) {
    return other;
  }
  if (!other) {
    return one;
  }
  let newAverage = one.avg;
  if (!newAverage) {
    newAverage = other.avg;
  } else {
    if (other.avg) {
      newAverage += other.avg;
      newAverage /= 2;
    }
  }
  return { min: Math.min(one.min, other.min), max: Math.max(one.max, other.max), avg: newAverage };
}

function mergeUsedUnits(one: AllUnitsReport, other: AllUnitsReport): AllUnitsReport {
  return {
    ...one,
    units: mergeAmountUnits(one.units, other.units),
    enemies: mergeAmountUnits(one.enemies, other.enemies)
  };
}

interface AmountUnits<UnitType> {
  [x: number]: { amount: Amount; originalAmount: Amount; unit: UnitType };
}

function mergeAmountUnits<T>(mergeUnitsOne: AmountUnits<T>, mergeUnitsTwo: AmountUnits<T>): AmountUnits<T> {
  return Object.entries(mergeUnitsOne).reduce<AmountUnits<T>>(
    (prev, [unitId, unitAmount]) => {
      const otherUnits = prev[convertToNumber(unitId)];
      return {
        ...prev,
        [unitId]: {
          ...unitAmount,
          amount: mergeAmount(unitAmount.amount, otherUnits?.amount),
          originalAmount: mergeAmount(unitAmount.originalAmount, otherUnits?.originalAmount)
        }
      };
    },
    { ...mergeUnitsTwo }
  );
}
