import { SearchWeather } from '../../app/store';
import { GlobalEffects } from '../../data-objects/GlobalEffects';

function createGlobalEffectVariants(selectedEffects: SearchWeather): GlobalEffects[] {
  const resultingEffects: GlobalEffects[] = [];

  const { frost, nebel, none, wirbelsturm, sonnenschein, ...fixedEffects } = selectedEffects;

  if (none) {
    resultingEffects.push({ ...fixedEffects });
  }
  if (frost) {
    resultingEffects.push({ frost: true, ...fixedEffects });
  }
  if (nebel) {
    resultingEffects.push({ nebel: true, ...fixedEffects });
  }
  if (wirbelsturm) {
    resultingEffects.push({ wirbelsturm: true, ...fixedEffects });
  }
  if (sonnenschein) {
    resultingEffects.push({ sonnenschein: true, ...fixedEffects });
  }
  return resultingEffects;
}

export default createGlobalEffectVariants;
