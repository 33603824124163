import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LabeledImage from './LabeledImage';
import { getEnemyUnitById } from '../data-objects/units/Units';
import { getAdventureById } from '../data-objects/adventures/Adventures';
import { getCampDetailsById } from '../data-objects/CampData';
import Crown from './layouts/Crown';
import { AdventureId, CampType } from '../data-objects/adventures';
import { Dictionary } from '../app/types';
import { InitiativeType } from '../data-objects/units/BaseUnitTypes';
import { SimpleCampType } from '../app/store';
import { AnimatePresence, motion } from 'framer-motion';

const ALREADY_CHOOSEN_CAMP = 'var(--tertiary-color)';

function getCampSelectionStyle(details?: { mapSize?: { width: number } }): number {
  if (details && details.mapSize) {
    return details.mapSize.width;
  }
  return 200;
}

function isHighlighted(highlighted: Partial<CampType>, sector: CampType['sector'], number: CampType['number']) {
  return highlighted.sector === sector && highlighted.number === number;
}

function isAlreadySelected(selected: SelectedCampMarker, sector: CampType['sector'], number: CampType['number']) {
  return selected && selected[sector] && selected[sector]![number];
}

interface CampDetailsEntry {
  hp: number;
  ep: number;
  initiative: InitiativeType | undefined;
}

type SelectedCampMarker = Dictionary<string, Dictionary<number, typeof ALREADY_CHOOSEN_CAMP>>;

interface Props {
  selectedAdventure: AdventureId;
  changeCamp: (sector?: CampType['sector'], number?: CampType['number']) => void;
  abortCampChange: (sector?: CampType['sector'], number?: CampType['number']) => void;
  changeAdventure: () => void;
  selectedCamps: ({
    sector?: string;
    number?: number;
  } | null)[];
}

function CampSelection({
  selectedAdventure,
  changeCamp,
  abortCampChange,
  changeAdventure,
  selectedCamps = [],
  ...otherProps
}: Props) {
  const { t } = useTranslation();
  const [highlighted, setHighlighted] = useState<Partial<CampType>>({});

  const adventure = getAdventureById(selectedAdventure);
  const adventureDetails = getCampDetailsById(selectedAdventure);

  const adventureMapSize = getCampSelectionStyle(adventureDetails);

  const alreadySelectedCamps: SelectedCampMarker = useMemo(
    () =>
      selectedCamps
        .filter((camp): camp is SimpleCampType => camp !== undefined && camp !== null)
        .reduce(
          (prev, wave) =>
            wave.sector !== undefined && wave.number !== undefined
              ? {
                  ...prev,
                  [wave.sector]: {
                    ...prev[wave.sector],
                    [wave.number]: ALREADY_CHOOSEN_CAMP
                  }
                }
              : prev,
          {} as SelectedCampMarker
        ),
    [selectedCamps]
  );

  const handleChangeCamp = useCallback(
    (sector, number) => {
      const choosenSector = alreadySelectedCamps[sector];
      if (choosenSector && choosenSector[number]) {
        abortCampChange(sector, number);
      } else {
        changeCamp(sector, number);
      }
    },
    [alreadySelectedCamps, changeCamp, abortCampChange]
  );

  const campStr = t('Camp');
  const detailsStr = t('Camp details');
  const hpStr = t('HP');
  const epStr = t('EP');

  return (
    <div {...otherProps} style={{ '--adventure-map-width': `${adventureMapSize}px` } as React.CSSProperties}>
      <div className='left-layout-container'>
        <div className='adventure-info'>
          <div className='change-adventure primary-button' onClick={() => changeAdventure()}>
            <div>{t('Change Adventure')}</div>
          </div>
          <div className='all-camps primary-button' onClick={() => changeCamp('All camps')}>
            <div>{t('All Camps')}</div>
          </div>
        </div>
        <div className='camps'>
          <AnimatePresence>
            <motion.div
              layoutId={`${adventure.id}-${adventure.name}`}
              key={adventure.name}
              className='adventure-entry card-entry'>
              <img src={adventure.icon} alt={adventure.name} />
              <div className='adventure-name'>{t(adventure.name)}</div>
            </motion.div>
            {adventure.camps.map((camp, index) => {
              const allUnits: CampDetailsEntry[] = [];

              const campStyle = {
                borderColor: camp.boss ? 'var(--boss-camp-color)' : 'transparent',
                backgroundColor: isHighlighted(highlighted, camp.sector, camp.number)
                  ? 'var(--hover-background-color)'
                  : isAlreadySelected(alreadySelectedCamps, camp.sector, camp.number)
                  ? 'var(--primary-color-dark)'
                  : 'var(--background-color-light)',
                color: isHighlighted(highlighted, camp.sector, camp.number)
                  ? 'var(--hover-foreground-color)'
                  : isAlreadySelected(alreadySelectedCamps, camp.sector, camp.number)
                  ? 'var(--text-on-primary)'
                  : 'var(--color)'
              };
              return (
                <motion.div
                  transition={{ delay: index * 0.01 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onMouseEnter={() =>
                    setHighlighted({
                      sector: camp.sector,
                      number: camp.number
                    })
                  }
                  onMouseLeave={() => setHighlighted({})}
                  key={`${camp.sector}${camp.number}`}
                  className='camp-entry card-entry'
                  style={campStyle}
                  onClick={() => handleChangeCamp(camp.sector, camp.number)}>
                  <div className='camp-name'>{`${campStr} ${camp.sector}${camp.number}`}</div>
                  <div className='camp-units'>
                    {camp.units.map((entry, unitIndex) => {
                      const unit = getEnemyUnitById(entry.typeId);
                      allUnits.push({
                        hp: entry.amount * unit.hp,
                        ep: entry.amount * unit.ep ?? 0,
                        initiative: unit.initiative
                      });
                      return <LabeledImage key={`${index}-${unitIndex}`} unit={unit} text={`${entry.amount}`} />;
                    })}
                  </div>
                  <div className='camp-details'>
                    <div className='header'>{detailsStr}</div>
                    <div>{hpStr}</div>
                    <div>{allUnits.reduce((prev, current) => prev + current.hp, 0).toLocaleString()}</div>
                    <div>{epStr}</div>
                    <div>{allUnits.reduce((prev, current) => prev + current.ep, 0).toLocaleString()}</div>
                  </div>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
      <div className='right-layout-container'>
        <div className='adventure-preview'>
          <img
            src={adventure.image}
            alt='preview'
            style={{
              width: adventureDetails ? adventureDetails.mapSize.width : '100%',
              height: adventureDetails ? adventureDetails.mapSize.height : '100%'
            }}
          />
          {adventureDetails &&
            adventureDetails.camps.map(campData => {
              const selectionStyle = {
                left: `${campData.position.left}px`,
                top: `${campData.position.top}px`,
                borderColor: isHighlighted(highlighted, campData.sector, campData.number)
                  ? 'lime'
                  : isAlreadySelected(alreadySelectedCamps, campData.sector, campData.number)
                  ? 'yellow'
                  : 'white'
              };
              return (
                <div
                  key={`${campData.sector}${campData.number}`}
                  className='camp-marker'
                  onClick={() => handleChangeCamp(campData.sector, campData.number)}
                  onMouseEnter={() =>
                    setHighlighted({
                      sector: campData.sector,
                      number: campData.number
                    })
                  }
                  onMouseLeave={() => setHighlighted({})}
                  style={selectionStyle}>
                  {campData.isBoss && <Crown size='15px' />}
                </div>
              );
            })}
        </div>
        <div className='hovered-camp'>
          <div>
            {highlighted.sector &&
              adventure.camps
                .find(camp => camp.sector === highlighted.sector && camp.number === highlighted.number)!
                .units.map((entry, unitIndex) => {
                  const unit = getEnemyUnitById(entry.typeId);
                  return <LabeledImage key={`${unit.abr}-${unitIndex}`} unit={unit} text={`${entry.amount}`} />;
                })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default styled(CampSelection)`
  .left-layout-container {
    margin-right: calc(16px + var(--adventure-map-width));
    display: flex;
    flex-direction: column;

    .all-camps,
    .change-adventure {
      height: 55px;
    }

    .adventure-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 0 16px 0;
    }

    .camps {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      grid-auto-rows: auto;
      grid-column-gap: 15px;
      grid-row-gap: 10px;

      .camp-entry {
        padding: 16px;

        .all-camps {
          margin: auto;
          font-weight: 600;
          text-align: center;
        }

        .camp-name {
          font-weight: 600;
          text-align: center;
          margin: 0 0 10px 0;
        }

        .camp-units {
          display: flex;
          flex-direction: row;
          margin: auto;
          flex-wrap: wrap;

          ${LabeledImage} {
            padding: 0 8px;
            img {
              height: 25px;
            }
          }
        }

        .camp-details {
          display: grid;
          font-size: 0.8em;
          grid-template-columns: 1fr 1fr;

          .header {
            grid-column: 1 / span 2;
            font-weight: 600;
          }
        }
      }
    }
  }

  .right-layout-container {
    position: fixed;
    width: var(--adventure-map-size);
    top: var(--navigation-height);
    right: 50px;
    padding-top: 16px;

    & .hovered-camp {
      display: flex;
      height: 35px;
      flex-direction: row;

      & > div {
        margin: auto;
        display: flex;
        height: 25px;
        flex-direction: row;
      }
    }

    .adventure-preview {
      position: relative;

      .camp-marker {
        position: absolute;
        width: 31px;
        height: 31px;
        border: 2px solid white;
        border-radius: 50%;
      }
    }
  }
`;
