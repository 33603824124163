import { SelectedUnits } from '../../components/units/UnitSelection';
import { Amount } from '../../redux/simulationUtil';
import { unitDefaultValues } from '../../redux/unitUtil';
import { FinderUnit } from '../utils';
import { calculateLossValue, DEFINITLY_LOST_THE_BATTLE, resultIsBetter, resultIsPerfect } from './';
import { AttackSearchSingleReport } from './simulateAttackConfigs';

interface Props {
  choosenUnits: Array<FinderUnit>;
  generalCapacity: number;
  unitValues: typeof unitDefaultValues;
  attackFunction: (units: SelectedUnits, detailedSimulation?: true) => Promise<Array<AttackSearchSingleReport>>;
  reportResult: (result: Array<AttackSearchSingleReport>, units: SelectedUnits) => void;
  definitelyWon: (result: Array<AttackSearchSingleReport>) => boolean;
  shouldAbort: () => boolean;
}

export async function searchBestSingleUnitResult({
  choosenUnits,
  generalCapacity,
  unitValues,
  attackFunction,
  reportResult,
  definitelyWon,
  shouldAbort
}: Props) {
  const someBounded = choosenUnits.reduce(
    (unbounded, choosenUnit) => unbounded || 'max' in choosenUnit || 'min' in choosenUnit,
    false
  );
  let bestSingleUnitResult = DEFINITLY_LOST_THE_BATTLE;
  let amountOfResults = 0;
  // Try every seletected unit separatly in the general, if this unit type alone can
  // already defeat the camps.
  if (!someBounded) {
    for (let unitIdx = 0; unitIdx < choosenUnits.length; unitIdx++) {
      const choosenUnit = choosenUnits[unitIdx];
      // Use max capacity
      let amountOfUnits = generalCapacity;
      let incrementStepSize = 20;
      let thisUnitTypesBestResult = DEFINITLY_LOST_THE_BATTLE;
      const lossTable: { [x: number]: Amount } = {};
      // Avoid endless loops. GeneralCapacity is a very conservative number for max interations
      let exitCondition = generalCapacity;

      do {
        if (shouldAbort()) {
          return { bestSingleUnitResult, amountOfResults };
        }
        exitCondition -= 1;
        const lossTableIdx = amountOfUnits - 1;
        if (lossTable[lossTableIdx] !== undefined) {
          // The number of units already have been simulated.
          break;
        }
        const usedUnits = {
          [choosenUnit.id]: amountOfUnits
        };
        const simResult = await attackFunction(usedUnits);

        if (definitelyWon(simResult)) {
          // Dispatch result
          reportResult(simResult, usedUnits);
          amountOfResults++;

          const simResultLossValue = calculateLossValue(simResult, unitValues);
          let currentResultLossValue = simResultLossValue.value;
          // Also check for equality, as we want to find all combinations which produce e.g. 0 loss results
          if (resultIsBetter(bestSingleUnitResult, currentResultLossValue)) {
            if (resultIsPerfect(currentResultLossValue)) {
              // We won without loosing units. No further computations required!
              // Double check with more simulations, if it really is perfect.
              const newSimResult = await attackFunction(usedUnits, true);
              reportResult(newSimResult, usedUnits);
              // Make sure, we really win this battle
              if (definitelyWon(newSimResult)) {
                const newLossValue = calculateLossValue(newSimResult, unitValues);
                if (resultIsPerfect(newLossValue.value)) {
                  bestSingleUnitResult = newLossValue.value;
                  break;
                }
                if (resultIsBetter(bestSingleUnitResult, newLossValue.value)) {
                  currentResultLossValue = newLossValue.value;
                } else {
                  // After resimulation the result is worse than the previous best, thus do not use this loss value for reference!
                  currentResultLossValue = bestSingleUnitResult;
                }
              } else {
                // After resimulation we loose the battle. Do not use this loss value for reference!
                currentResultLossValue = bestSingleUnitResult;
              }
            }
            bestSingleUnitResult = currentResultLossValue;
          }
          if (amountOfUnits === generalCapacity) {
            thisUnitTypesBestResult = currentResultLossValue;
            // Start a new iteration, starting at 1 unit.
            amountOfUnits = 1;
          } else {
            // We've won a battle with less units!
            // Compare our loss value with the previous best result for this unit type
            if (resultIsBetter(thisUnitTypesBestResult, currentResultLossValue)) {
              // We're better than before!
              if (amountOfUnits - incrementStepSize < 0) {
                // Only 1 unit of this type was used. We're done here!
                break;
              } else {
                amountOfUnits = amountOfUnits - incrementStepSize + 1;
                incrementStepSize = 1;
              }
            } else {
              // The result is worse than before, but we won the battle. Thus, more units
              // are indeed required and we skip further calculations here.
              break;
            }
          }
          lossTable[lossTableIdx] = simResultLossValue.value;
        } else if (amountOfUnits < generalCapacity) {
          // We in second phase and trying to reduce loss value with less units
          // But we lost the battle. Thus, we mark this amount in our local table
          lossTable[lossTableIdx] = DEFINITLY_LOST_THE_BATTLE;
          // So, we increase the number of units again.
          if (amountOfUnits + incrementStepSize >= generalCapacity) {
            amountOfUnits++;
          } else {
            amountOfUnits = amountOfUnits + incrementStepSize;
          }
          if (amountOfUnits === generalCapacity) {
            amountOfUnits = amountOfUnits - incrementStepSize + 1;
            incrementStepSize = 1;
            if (amountOfUnits === generalCapacity) {
              // We lost every battle and lost every battle, even with one unit less
              break;
            }
          }
        } else {
          // We simply lost and there is nothing left to do here.
          break;
        }
      } while (exitCondition > 0);
    }
  }
  return { bestSingleUnitResult, amountOfResults };
}
