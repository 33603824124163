import { SearchResult } from "../../../app/store";
import { searchResultEquals } from "./searchResultsEquals";
import { mergeSearchResult } from "./mergeSearchResult";

export function mergeSearchResults(
  oldResults: SearchResult[],
  newResults: SearchResult[]
): SearchResult[] {
  const skipOld: number[] = [];
  const copyOldResults = [...oldResults];
  const resultsToAdd = newResults.map((result) => {
    const existingResult = copyOldResults.findIndex((old) =>
      searchResultEquals(old, result)
    );
    if (existingResult >= 0) {
      skipOld.push(existingResult);
      // merge
      return mergeSearchResult(result, copyOldResults[existingResult]);
    } else {
      return result;
    }
  });
  skipOld.sort((a, b) => b - a);
  skipOld.forEach((index) => copyOldResults.splice(index, 1));
  return resultsToAdd.concat(copyOldResults);
}
