import { AnimateSharedLayout } from 'framer-motion';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import AdventureSelection from '../components/AdventureSelection';
import CampSelection from '../components/CampSelection';
import { useCurrentSelectedAdventure, useCurrentSelectedCamp, useSelectedCamps } from '../redux/selectors';
import * as actions from '../redux/simulationActions';
import { BaseProps } from '../types/basics';
import ErrorFallback from './ErrorFallback';
import Simulation from './Simulation';

interface Props extends BaseProps {}

function Simulator({ ...otherProps }: Props) {
  const dispatch = useDispatch();
  const selectedAdventure = useCurrentSelectedAdventure();
  const selectedCamp = useCurrentSelectedCamp();
  const selectedCamps = useSelectedCamps();
  // Selection mode is, when no adventure is selected or no camp is selected. Camp 0 needs special
  //  handling, since !selectedCamp would still return true
  const selectionMode = !selectedAdventure || !selectedCamp;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
      <div {...otherProps}>
        <AnimateSharedLayout>
          {!selectedAdventure && (
            <AdventureSelection changeAdventure={adv => dispatch(actions.changeAdventure(adv && adv.id))} />
          )}
          {selectedAdventure && !selectedCamp && (
            <CampSelection
              changeCamp={(sector, number) => dispatch(actions.changeCamp(sector, number))}
              abortCampChange={(sector, number) => dispatch(actions.abortChangeCamp(sector, number))}
              changeAdventure={() => dispatch(actions.changeAdventure())}
              selectedAdventure={selectedAdventure}
              selectedCamps={selectedCamps}
            />
          )}
          {!selectionMode && <Simulation selectedAdventure={selectedAdventure!} selectedCamps={selectedCamps} />}
        </AnimateSharedLayout>
      </div>
    </ErrorBoundary>
  );
}

export default styled(Simulator)`
  .adventure-entry {
    img {
      margin: auto;
      width: 50%;
    }
    .adventure-name {
      margin: auto;
      font-weight: 600;
      text-align: center;
    }
  }
`;
