import { GeneralId, UnitId } from "../../data-objects/units/Army";
import { Amount } from "../../redux/simulationUtil";
import { allGeneralIds, unitDefaultValues } from "../../redux/unitUtil";

export function calculateLossValueForLosses(
  ourArmyLosses: Record<number, Amount>,
  unitValues: typeof unitDefaultValues
) {
  return Object.entries(ourArmyLosses).reduce(
    (total, [unitId, unitLosses]) => {
      if (allGeneralIds.includes(parseInt(unitId) as GeneralId)) {
        return total;
      }
      const ourUnitId = parseInt(unitId) as UnitId;
      const amount = unitLosses ?? {
        min: 0,
        max: 0,
      };
      const newMinValue = amount.min * unitValues[ourUnitId];
      const newMaxValue = amount.max * unitValues[ourUnitId];
      const newAvgValue = (amount.avg ?? 0) * unitValues[ourUnitId];
      return {
        min: total.min + newMinValue,
        max: total.max + newMaxValue,
        avg: total.avg + newAvgValue,
      };
    },
    { min: 0, max: 0, avg: 0 }
  );
}
