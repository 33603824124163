import {
  AT_ALTEBEKANNTE_ID,
  AT_ALTERUINEN_ID,
  AT_ARCHIPEL_ID,
  AT_ARKTISCHEEXPLOSION_ID,
  AT_BEUTELSCHNEIDER_ID,
  AT_DASENDEDERWELT_ID,
  AT_DASDUNKLETOR_ID,
  AT_DERSCHAMANE_ID,
  AT_DERWIRBELWIND_ID,
  AT_DERGESTOHLENESCHLITTEN_ID,
  AT_DERSCHLAFENDEVULKAN_ID,
  AT_DERVERSCHOLLENESCHÄDEL_ID,
  AT_DERÜBERRASCHUNGSANGRIFF_ID,
  AT_DIEBELAGERUNG_ID,
  AT_DIEDUNKLEBRUDERSCHAFT_ID,
  AT_DIEFREIBEUTER_RAZZIA_ID,
  AT_DIEINSELDERFREIBEUTER_ID,
  AT_DIEINVASIONDERNORDMÄNNER_ID,
  AT_DIENORDMÄNNER_ID,
  AT_DIESCHWARZENPRIESTER_ID,
  AT_DIESCHWARZENRITTER_ID,
  AT_DIESTÖRENFRIEDE_ID,
  AT_DIEWILDEWALTRAUT_ID,
  AT_DROHENDEGEFAHR_ID,
  AT_EINSAMEEXPERIMENTE_ID,
  AT_GARRUNDERFALLENSTELLER_ID,
  AT_GEFÄNGNISINSEL_ID,
  AT_GRABRÄUBER_ID,
  AT_HEIMATINSEL_ID,
  AT_JAGDAUFDIEJÄGER_ID,
  AT_KOPFGELDJÄGER_ID,
  AT_MEHREINSAMEEXPERIMENTE_ID,
  AT_MONSTERAUSDEMNEBEL_ID,
  AT_MUTTERLIEBE_ID,
  AT_PIRATENLEBEN_ID,
  AT_RASENDERBULLE_ID,
  AT_RETTETDASWEIHNACHTSFEST_2013__ID,
  AT_RÄUBER_GEGENSCHLAG_ID,
  AT_RÄUBERBANDE_ID,
  AT_RÄUBERNEST_ID,
  AT_RÜCKKEHRINSRÄUBERNEST_ID,
  AT_SATTELFEST_ID,
  AT_SCHIESSPULVER_ID,
  AT_SCHLUMMERNDESRIFF_ID,
  AT_SEEUFER_SCHATZ_ID,
  AT_SUMPFHEXE_ID,
  AT_SÖHNEDERSTEPPE_ID,
  AT_TROPENSONNE_ID,
  AT_VERRÄTER_ID,
  AT_VIKTORDERVERSCHLAGENE_ID,
  AT_WINTERWUNDERLAND10_ID,
  AT_WINTERWUNDERLAND4_ID,
  AT_WINTERWUNDERLAND7_ID,
  AT_DIEGETEILTESTADTIMSOMMER_ID,
  AT_NEBLIGERKUCHEN1_ID,
  AT_NEBLIGERKUCHEN2_ID,
  AT_NEBLIGERKUCHEN3_ID,
  AT_DIEGETEILTESTADTIMHERBST_ID,
  AT_BONABERTI_ID
} from './adventures/Klassische';

import {
  AT_AMFUSSEDESBERGES_ID,
  AT_ANGRIFFDERNORDMÄNNER_ID,
  AT_BERGLABYRINTH_ID,
  AT_DASBERGVOLK_ID,
  AT_DASQUARTIERDESDRACHEN_ID,
  AT_DERCHUPACABRA_ID,
  AT_DERRATTENFÄNGERVONHAMELN_ID,
  AT_DIEVERLORENESTADT_ID,
  AT_EINRIESENKAMPF_ID,
  AT_EINENSCHRITTVORAUS_ID,
  AT_HÄNSELUNDGRETEL_ID,
  AT_ROTKÄPPCHEN_ID,
  AT_SCHNEEWITTCHEN_ID,
  AT_TIKKI_INSEL_ID,
  AT_UNBEKANNTEREGIONEN_ID,
  AT_VOMFISCHERUNDSEINERFRAU_ID,
  AT_VONLIEDERNUNDFLÜCHEN_ID,
  AT_WERTVOLLEINFORMATIONEN_ID
} from './adventures/Excelsior';

import {
  AT_ALADINUNDDIESCHÖNEPRINZESSIN_ID,
  AT_ALADINUNDDIEÖLLAMPE_ID,
  AT_ALIBABADERJUNGEHOLZFÄLLER_ID,
  AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ID,
  AT_ALIBABAUNDDERSCHATZDESWISSENS_ID,
  AT_ALIBABAUNDDERDRITTEDIEB_ID,
  AT_ALIBABAUNDDERERSTEDIEB_ID,
  AT_ALIBABAUNDDERZWEITEDIEB_ID,
  AT_DASBETROGENESCHNEIDERLEIN_ID,
  AT_DASHELDENHAFTESCHNEIDERLEIN_ID,
  AT_DASKLUGESCHNEIDERLEIN_ID,
  AT_DASTAPFERESCHNEIDERLEIN_ID,
  AT_DIESÖHNEDESSCHNEIDERLEINS_ID,
  AT_SINDBADUNDDIESEESCHLANGE_ID,
  AT_SINDBADUNDDIEBELAGERTESTADT_ID
} from './adventures/Maerchen';
import { AdventureId } from './adventures';

export interface CampData {
  id: AdventureId;
  mapSize: { width: number; height: number };
  camps: CampDataCamp[];
}

export interface CampDataCamp {
  sector: string;
  number: number;
  isBoss?: boolean;
  position: { left: number; top: number };
}

export const BonabertiBusinessLager = {
  id: AT_BONABERTI_ID,
  mapSize: { width: 889, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 230, top: 226 } },
    { sector: 'A', number: 2, position: { left: 252, top: 219 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 264, top: 226 } },
    { sector: 'B', number: 1, position: { left: 318, top: 226 } },
    { sector: 'B', number: 2, position: { left: 307, top: 208 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 333, top: 210 } },
    { sector: 'C', number: 1, position: { left: 382, top: 187 } },
    { sector: 'C', number: 2, position: { left: 371, top: 173 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 390, top: 175 } },
    { sector: 'D', number: 1, position: { left: 471, top: 120 } },
    { sector: 'D', number: 2, position: { left: 464, top: 108 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 480, top: 106 } },
    { sector: 'E', number: 1, position: { left: 567, top: 120 } },
    { sector: 'E', number: 2, position: { left: 578, top: 105 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 595, top: 116 } },
    { sector: 'F', number: 1, position: { left: 664, top: 146 } },
    { sector: 'F', number: 2, position: { left: 670, top: 130 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 692, top: 149 } }
  ]
} as CampData;

export const NebligerKuchen1Lager = {
  id: AT_NEBLIGERKUCHEN1_ID,
  mapSize: { width: 733, height: 500 },
  camps: [
    { sector: 'A', number: 1.1, position: { left: 285, top: 430 } },
    { sector: 'A', number: 1.2, position: { left: 285, top: 395 } },
    { sector: 'A', number: 1.3, position: { left: 285, top: 360 } },
    { sector: 'A', number: 2.1, position: { left: 250, top: 420 } },
    { sector: 'A', number: 2.2, position: { left: 250, top: 385 } },
    { sector: 'A', number: 2.3, position: { left: 250, top: 350 } },
    { sector: 'A', number: 3.1, position: { left: 220, top: 380 } },
    { sector: 'A', number: 3.2, position: { left: 220, top: 415 } },
    { sector: 'A', number: 3.3, position: { left: 220, top: 450 } },
    { sector: 'B', number: 1.1, position: { left: 270, top: 240 } },
    { sector: 'B', number: 1.2, position: { left: 270, top: 275 } },
    { sector: 'B', number: 1.3, position: { left: 270, top: 310 } },
    { sector: 'B', number: 2.1, position: { left: 320, top: 180 } },
    { sector: 'B', number: 2.2, position: { left: 320, top: 215 } },
    { sector: 'B', number: 2.3, position: { left: 320, top: 250 } },
    { sector: 'B', number: 3.1, position: { left: 345, top: 135 } },
    { sector: 'B', number: 3.2, position: { left: 345, top: 170 } },
    { sector: 'B', number: 3.3, position: { left: 345, top: 205 } },
    { sector: 'C', number: 1.1, position: { left: 455, top: 135 } },
    { sector: 'C', number: 1.2, position: { left: 455, top: 170 } },
    { sector: 'C', number: 1.3, position: { left: 455, top: 205 } },
    { sector: 'C', number: 2.1, position: { left: 570, top: 130 } },
    { sector: 'C', number: 2.2, position: { left: 570, top: 165 } },
    { sector: 'C', number: 2.3, position: { left: 570, top: 200 } },
    { sector: 'C', number: 3.1, position: { left: 545, top: 240 } },
    { sector: 'C', number: 3.2, position: { left: 545, top: 275 } },
    { sector: 'C', number: 3.3, position: { left: 545, top: 310 } }
  ]
} as CampData;

export const NebligerKuchen2Lager = {
  id: AT_NEBLIGERKUCHEN2_ID,
  mapSize: { width: 733, height: 500 },
  camps: [
    { sector: 'A', number: 1.1, position: { left: 285, top: 430 } },
    { sector: 'A', number: 1.2, position: { left: 285, top: 395 } },
    { sector: 'A', number: 1.3, position: { left: 285, top: 360 } },
    { sector: 'A', number: 2.1, position: { left: 250, top: 420 } },
    { sector: 'A', number: 2.2, position: { left: 250, top: 385 } },
    { sector: 'A', number: 2.3, position: { left: 250, top: 350 } },
    { sector: 'A', number: 3.1, position: { left: 220, top: 380 } },
    { sector: 'A', number: 3.2, position: { left: 220, top: 415 } },
    { sector: 'A', number: 3.3, position: { left: 220, top: 450 } },
    { sector: 'B', number: 1.1, position: { left: 270, top: 240 } },
    { sector: 'B', number: 1.2, position: { left: 270, top: 275 } },
    { sector: 'B', number: 1.3, position: { left: 270, top: 310 } },
    { sector: 'B', number: 2.1, position: { left: 320, top: 180 } },
    { sector: 'B', number: 2.2, position: { left: 320, top: 215 } },
    { sector: 'B', number: 2.3, position: { left: 320, top: 250 } },
    { sector: 'B', number: 3.1, position: { left: 345, top: 135 } },
    { sector: 'B', number: 3.2, position: { left: 345, top: 170 } },
    { sector: 'B', number: 3.3, position: { left: 345, top: 205 } },
    { sector: 'C', number: 1.1, position: { left: 455, top: 135 } },
    { sector: 'C', number: 1.2, position: { left: 455, top: 170 } },
    { sector: 'C', number: 1.3, position: { left: 455, top: 205 } },
    { sector: 'C', number: 2.1, position: { left: 570, top: 130 } },
    { sector: 'C', number: 2.2, position: { left: 570, top: 165 } },
    { sector: 'C', number: 2.3, position: { left: 570, top: 200 } },
    { sector: 'C', number: 3.1, position: { left: 545, top: 240 } },
    { sector: 'C', number: 3.2, position: { left: 545, top: 275 } },
    { sector: 'C', number: 3.3, position: { left: 545, top: 310 } }
  ]
} as CampData;

export const NebligerKuchen3Lager = {
  id: AT_NEBLIGERKUCHEN3_ID,
  mapSize: { width: 733, height: 500 },
  camps: [
    { sector: 'A', number: 1.1, position: { left: 285, top: 430 } },
    { sector: 'A', number: 1.2, position: { left: 285, top: 395 } },
    { sector: 'A', number: 1.3, position: { left: 285, top: 360 } },
    { sector: 'A', number: 2.1, position: { left: 250, top: 420 } },
    { sector: 'A', number: 2.2, position: { left: 250, top: 385 } },
    { sector: 'A', number: 2.3, position: { left: 250, top: 350 } },
    { sector: 'A', number: 3.1, position: { left: 220, top: 380 } },
    { sector: 'A', number: 3.2, position: { left: 220, top: 415 } },
    { sector: 'A', number: 3.3, position: { left: 220, top: 450 } },
    { sector: 'B', number: 1.1, position: { left: 270, top: 240 } },
    { sector: 'B', number: 1.2, position: { left: 270, top: 275 } },
    { sector: 'B', number: 1.3, position: { left: 270, top: 310 } },
    { sector: 'B', number: 2.1, position: { left: 320, top: 180 } },
    { sector: 'B', number: 2.2, position: { left: 320, top: 215 } },
    { sector: 'B', number: 2.3, position: { left: 320, top: 250 } },
    { sector: 'B', number: 3.1, position: { left: 345, top: 135 } },
    { sector: 'B', number: 3.2, position: { left: 345, top: 170 } },
    { sector: 'B', number: 3.3, position: { left: 345, top: 205 } },
    { sector: 'C', number: 1.1, position: { left: 455, top: 135 } },
    { sector: 'C', number: 1.2, position: { left: 455, top: 170 } },
    { sector: 'C', number: 1.3, position: { left: 455, top: 205 } },
    { sector: 'C', number: 2.1, position: { left: 570, top: 130 } },
    { sector: 'C', number: 2.2, position: { left: 570, top: 165 } },
    { sector: 'C', number: 2.3, position: { left: 570, top: 200 } },
    { sector: 'C', number: 3.1, position: { left: 545, top: 240 } },
    { sector: 'C', number: 3.2, position: { left: 545, top: 275 } },
    { sector: 'C', number: 3.3, position: { left: 545, top: 310 } }
  ]
} as CampData;

export const AladinunddieschönePrinzessinLager = {
  id: AT_ALADINUNDDIESCHÖNEPRINZESSIN_ID,
  mapSize: { width: 714, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 152, top: 425 } },
    { sector: 'A', number: 2, position: { left: 142, top: 397 } },
    { sector: 'A', number: 3, position: { left: 109, top: 378 } },
    { sector: 'A', number: 4, position: { left: 114, top: 334 } },
    { sector: 'A', number: 5, position: { left: 186, top: 337 } },
    { sector: 'A', number: 6, position: { left: 267, top: 287 } },
    { sector: 'A', number: 7, position: { left: 338, top: 277 } },
    { sector: 'A', number: 8, position: { left: 172, top: 280 } },
    { sector: 'A', number: 9, isBoss: true, position: { left: 182, top: 256 } },
    { sector: 'A', number: 10, position: { left: 302, top: 328 } },
    {
      sector: 'A',
      number: 11,
      isBoss: true,
      position: { left: 323, top: 343 }
    },
    { sector: 'B', number: 1, position: { left: 60, top: 247 } },
    { sector: 'B', number: 2, position: { left: 92, top: 219 } },
    { sector: 'B', number: 3, position: { left: 29, top: 190 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 54, top: 190 } },
    { sector: 'B', number: 5, position: { left: 228, top: 221 } },
    { sector: 'B', number: 6, isBoss: true, position: { left: 228, top: 197 } },
    { sector: 'B', number: 7, position: { left: 169, top: 180 } },
    { sector: 'B', number: 8, position: { left: 114, top: 153 } },
    { sector: 'B', number: 9, position: { left: 138, top: 118 } },
    {
      sector: 'B',
      number: 10,
      isBoss: true,
      position: { left: 155, top: 138 }
    },
    { sector: 'C', number: 1, position: { left: 172, top: 98 } },
    { sector: 'C', number: 2, position: { left: 187, top: 78 } },
    { sector: 'C', number: 3, position: { left: 245, top: 72 } },
    { sector: 'C', number: 4, position: { left: 271, top: 22 } },
    { sector: 'C', number: 5, position: { left: 361, top: 56 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 354, top: 18 } },
    { sector: 'C', number: 7, position: { left: 425, top: 70 } },
    { sector: 'C', number: 8, position: { left: 437, top: 36 } },
    { sector: 'C', number: 9, position: { left: 482, top: 59 } },
    { sector: 'C', number: 10, position: { left: 482, top: 18 } },
    { sector: 'C', number: 11, isBoss: true, position: { left: 523, top: 28 } },
    { sector: 'D', number: 1, position: { left: 542, top: 69 } },
    { sector: 'D', number: 2, position: { left: 568, top: 69 } },
    { sector: 'D', number: 3, position: { left: 510, top: 109 } },
    { sector: 'D', number: 4, position: { left: 536, top: 109 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 616, top: 123 } },
    { sector: 'D', number: 6, position: { left: 578, top: 131 } },
    { sector: 'D', number: 7, isBoss: true, position: { left: 572, top: 155 } },
    { sector: 'D', number: 8, position: { left: 573, top: 197 } },
    { sector: 'D', number: 9, position: { left: 560, top: 219 } },
    { sector: 'D', number: 10, position: { left: 615, top: 238 } },
    {
      sector: 'D',
      number: 11,
      isBoss: true,
      position: { left: 615, top: 213 }
    },
    { sector: 'E', number: 1, position: { left: 533, top: 258 } },
    { sector: 'E', number: 2, position: { left: 522, top: 297 } },
    { sector: 'E', number: 3, position: { left: 496, top: 274 } },
    { sector: 'E', number: 4, position: { left: 459, top: 266 } },
    { sector: 'E', number: 5, position: { left: 447, top: 286 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 435, top: 266 } },
    { sector: 'E', number: 7, position: { left: 479, top: 357 } },
    { sector: 'E', number: 8, isBoss: true, position: { left: 501, top: 350 } },
    { sector: 'E', number: 9, position: { left: 441, top: 341 } },
    { sector: 'E', number: 10, position: { left: 379, top: 399 } },
    {
      sector: 'E',
      number: 11,
      isBoss: true,
      position: { left: 400, top: 385 }
    },
    { sector: 'E', number: 12, position: { left: 500, top: 229 } },
    { sector: 'E', number: 13, position: { left: 530, top: 192 } },
    {
      sector: 'E',
      number: 14,
      isBoss: true,
      position: { left: 547, top: 174 }
    },
    {
      sector: 'E',
      number: 15,
      isBoss: true,
      position: { left: 463, top: 105 }
    },
    { sector: 'F', number: 1, position: { left: 289, top: 126 } },
    { sector: 'F', number: 2, position: { left: 257, top: 153 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 197, top: 122 } },
    { sector: 'F', number: 4, position: { left: 289, top: 220 } },
    { sector: 'F', number: 5, position: { left: 346, top: 243 } },
    { sector: 'F', number: 6, isBoss: true, position: { left: 372, top: 243 } },
    { sector: 'F', number: 7, position: { left: 403, top: 304 } },
    { sector: 'F', number: 8, position: { left: 373, top: 318 } },
    { sector: 'F', number: 9, isBoss: true, position: { left: 394, top: 333 } },
    { sector: 'G', number: 1, position: { left: 408, top: 220 } },
    { sector: 'G', number: 2, position: { left: 463, top: 200 } },
    { sector: 'G', number: 3, position: { left: 444, top: 177 } },
    { sector: 'G', number: 4, isBoss: true, position: { left: 460, top: 158 } },
    { sector: 'G', number: 5, position: { left: 376, top: 126 } },
    { sector: 'G', number: 6, isBoss: true, position: { left: 356, top: 110 } },
    { sector: 'G', number: 7, position: { left: 310, top: 166 } },
    { sector: 'H', number: 1, position: { left: 359, top: 180 } },
    { sector: 'H', number: 2, position: { left: 355, top: 157 } },
    { sector: 'H', number: 3, position: { left: 397, top: 187 } },
    { sector: 'H', number: 4, position: { left: 403, top: 163 } },
    { sector: 'H', number: 5, isBoss: true, position: { left: 394, top: 141 } }
  ]
} as CampData;

export const AladinunddieÖllampeLager = {
  id: AT_ALADINUNDDIEÖLLAMPE_ID,
  mapSize: { width: 773, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 171, top: 162 } },
    { sector: 'A', number: 2, position: { left: 215, top: 174 } },
    { sector: 'A', number: 3, position: { left: 215, top: 111 } },
    { sector: 'A', number: 4, position: { left: 275, top: 155 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 307, top: 156 } },
    { sector: 'A', number: 6, position: { left: 307, top: 103 } },
    { sector: 'A', number: 7, position: { left: 352, top: 131 } },
    { sector: 'A', number: 8, position: { left: 397, top: 119 } },
    { sector: 'A', number: 9, isBoss: true, position: { left: 237, top: 66 } },
    { sector: 'A', number: 10, position: { left: 339, top: 75 } },
    { sector: 'A', number: 11, isBoss: true, position: { left: 367, top: 75 } },
    { sector: 'B', number: 1, position: { left: 307, top: 184 } },
    { sector: 'B', number: 2, position: { left: 314, top: 218 } },
    { sector: 'B', number: 3, position: { left: 262, top: 224 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 263, top: 196 } },
    { sector: 'B', number: 5, position: { left: 223, top: 250 } },
    { sector: 'B', number: 6, position: { left: 249, top: 273 } },
    { sector: 'B', number: 7, isBoss: true, position: { left: 282, top: 269 } },
    { sector: 'B', number: 8, position: { left: 352, top: 242 } },
    { sector: 'B', number: 9, position: { left: 372, top: 181 } },
    { sector: 'B', number: 10, position: { left: 398, top: 192 } },
    {
      sector: 'B',
      number: 11,
      isBoss: true,
      position: { left: 393, top: 167 }
    },
    { sector: 'B', number: 12, position: { left: 453, top: 196 } },
    {
      sector: 'B',
      number: 13,
      isBoss: true,
      position: { left: 472, top: 174 }
    },
    { sector: 'C', number: 1, isBoss: true, position: { left: 366, top: 274 } },
    { sector: 'C', number: 2, position: { left: 366, top: 302 } },
    { sector: 'C', number: 3, position: { left: 340, top: 323 } },
    { sector: 'C', number: 4, position: { left: 280, top: 334 } },
    { sector: 'C', number: 5, position: { left: 353, top: 363 } },
    { sector: 'C', number: 6, position: { left: 209, top: 337 } },
    { sector: 'C', number: 7, isBoss: true, position: { left: 193, top: 315 } },
    { sector: 'C', number: 8, position: { left: 262, top: 372 } },
    { sector: 'C', number: 9, position: { left: 262, top: 397 } },
    { sector: 'C', number: 10, position: { left: 301, top: 427 } },
    { sector: 'C', number: 11, position: { left: 348, top: 415 } },
    {
      sector: 'C',
      number: 12,
      isBoss: true,
      position: { left: 368, top: 435 }
    },
    { sector: 'D', number: 1, position: { left: 430, top: 236 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 430, top: 261 } },
    { sector: 'D', number: 3, position: { left: 508, top: 225 } },
    { sector: 'D', number: 4, position: { left: 546, top: 209 } },
    { sector: 'D', number: 5, position: { left: 579, top: 270 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 600, top: 234 } },
    { sector: 'D', number: 7, position: { left: 500, top: 267 } },
    { sector: 'D', number: 8, position: { left: 516, top: 287 } },
    { sector: 'D', number: 9, position: { left: 436, top: 302 } },
    { sector: 'D', number: 10, position: { left: 409, top: 359 } },
    { sector: 'D', number: 11, position: { left: 409, top: 387 } },
    {
      sector: 'D',
      number: 12,
      isBoss: true,
      position: { left: 462, top: 372 }
    },
    {
      sector: 'D',
      number: 13,
      isBoss: true,
      position: { left: 535, top: 323 }
    },
    { sector: 'E', number: 1, position: { left: 540, top: 381 } },
    { sector: 'E', number: 2, position: { left: 552, top: 344 } },
    { sector: 'E', number: 3, position: { left: 617, top: 285 } },
    { sector: 'E', number: 4, position: { left: 650, top: 290 } },
    { sector: 'E', number: 5, position: { left: 679, top: 305 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 585, top: 349 } },
    { sector: 'E', number: 7, isBoss: true, position: { left: 605, top: 333 } },
    { sector: 'E', number: 8, position: { left: 603, top: 363 } },
    { sector: 'E', number: 9, position: { left: 629, top: 346 } },
    { sector: 'E', number: 10, position: { left: 623, top: 375 } },
    { sector: 'E', number: 11, position: { left: 655, top: 354 } },
    {
      sector: 'E',
      number: 12,
      isBoss: true,
      position: { left: 716, top: 350 }
    }
  ]
} as CampData;

export const AliBabaderjungeHolzfällerLager = {
  id: AT_ALIBABADERJUNGEHOLZFÄLLER_ID,
  mapSize: { width: 862, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 187, top: 247 } },
    { sector: 'A', number: 2, position: { left: 277, top: 328 } },
    { sector: 'A', number: 3, position: { left: 288, top: 413 } },
    { sector: 'A', number: 4, position: { left: 264, top: 438 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 310, top: 431 } },
    { sector: 'B', number: 1, position: { left: 494, top: 398 } },
    { sector: 'B', number: 2, position: { left: 636, top: 413 } },
    { sector: 'B', number: 3, position: { left: 616, top: 345 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 700, top: 302 } },
    { sector: 'B', number: 5, position: { left: 725, top: 294 } },
    { sector: 'C', number: 1, position: { left: 667, top: 261 } },
    { sector: 'C', number: 2, position: { left: 667, top: 129 } },
    { sector: 'C', number: 3, position: { left: 519, top: 234 } },
    { sector: 'C', number: 4, position: { left: 589, top: 121 } },
    { sector: 'C', number: 5, position: { left: 685, top: 55 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 719, top: 56 } },
    { sector: 'D', number: 1, position: { left: 500, top: 301 } },
    { sector: 'D', number: 2, position: { left: 436, top: 152 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 463, top: 150 } },
    { sector: 'D', number: 4, position: { left: 398, top: 97 } },
    { sector: 'D', number: 5, position: { left: 417, top: 70 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 375, top: 80 } },
    { sector: 'E', number: 1, position: { left: 277, top: 165 } },
    { sector: 'E', number: 2, position: { left: 245, top: 175 } },
    { sector: 'E', number: 3, position: { left: 290, top: 102 } },
    { sector: 'E', number: 4, position: { left: 288, top: 83 } },
    { sector: 'E', number: 5, position: { left: 233, top: 119 } },
    { sector: 'E', number: 6, position: { left: 161, top: 85 } },
    { sector: 'E', number: 7, isBoss: true, position: { left: 142, top: 62 } }
  ]
} as CampData;

export const AliBabaundderSchatzderWeisheitLager = {
  id: AT_ALIBABAUNDDERSCHATZDERWEISHEIT_ID,
  mapSize: { width: 822, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 104, top: 394 } },
    { sector: 'A', number: 2, position: { left: 88, top: 416 } },
    { sector: 'A', number: 3, position: { left: 150, top: 317 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 270, top: 345 } },
    { sector: 'A', number: 5, position: { left: 234, top: 413 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 270, top: 403 } },
    { sector: 'A', number: 7, position: { left: 354, top: 339 } },
    { sector: 'A', number: 8, position: { left: 383, top: 335 } },
    { sector: 'A', number: 9, position: { left: 383, top: 438 } },
    { sector: 'A', number: 10, position: { left: 468, top: 357 } },
    { sector: 'A', number: 11, position: { left: 500, top: 407 } },
    {
      sector: 'A',
      number: 12,
      isBoss: true,
      position: { left: 524, top: 421 }
    },
    { sector: 'B', number: 1, position: { left: 684, top: 445 } },
    { sector: 'B', number: 2, position: { left: 676, top: 392 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 702, top: 381 } },
    { sector: 'B', number: 4, position: { left: 680, top: 276 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 708, top: 267 } },
    { sector: 'B', number: 6, position: { left: 695, top: 225 } },
    { sector: 'B', number: 7, position: { left: 527, top: 254 } },
    { sector: 'B', number: 8, isBoss: true, position: { left: 510, top: 323 } },
    { sector: 'B', number: 9, position: { left: 402, top: 273 } },
    { sector: 'C', number: 1, position: { left: 377, top: 265 } },
    { sector: 'C', number: 2, position: { left: 300, top: 284 } },
    { sector: 'C', number: 3, position: { left: 180, top: 253 } },
    { sector: 'C', number: 4, position: { left: 198, top: 169 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 150, top: 138 } },
    { sector: 'C', number: 6, position: { left: 48, top: 114 } },
    { sector: 'C', number: 7, position: { left: 138, top: 30 } },
    { sector: 'C', number: 8, isBoss: true, position: { left: 180, top: 30 } },
    { sector: 'D', number: 1, position: { left: 168, top: 91 } },
    { sector: 'D', number: 2, position: { left: 246, top: 118 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 258, top: 58 } },
    { sector: 'D', number: 4, position: { left: 300, top: 48 } },
    { sector: 'D', number: 5, position: { left: 447, top: 40 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 434, top: 62 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 390, top: 167 } },
    { sector: 'E', number: 2, position: { left: 486, top: 176 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 510, top: 227 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 557, top: 131 } },
    { sector: 'F', number: 1, position: { left: 456, top: 136 } },
    { sector: 'F', number: 2, position: { left: 580, top: 87 } },
    { sector: 'F', number: 3, position: { left: 596, top: 114 } },
    { sector: 'F', number: 4, position: { left: 652, top: 151 } },
    { sector: 'F', number: 5, isBoss: true, position: { left: 624, top: 121 } },
    { sector: 'F', number: 6, isBoss: true, position: { left: 650, top: 131 } }
  ]
} as CampData;

export const AliBabaundderSchatzdesWissensLager = {
  id: AT_ALIBABAUNDDERSCHATZDESWISSENS_ID,
  mapSize: { width: 775, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 71, top: 130 } },
    { sector: 'A', number: 2, position: { left: 111, top: 113 } },
    { sector: 'A', number: 3, position: { left: 125, top: 159 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 144, top: 175 } },
    { sector: 'A', number: 5, position: { left: 117, top: 186 } },
    { sector: 'A', number: 6, position: { left: 66, top: 218 } },
    { sector: 'A', number: 7, position: { left: 66, top: 260 } },
    { sector: 'A', number: 8, position: { left: 134, top: 273 } },
    { sector: 'A', number: 9, position: { left: 196, top: 334 } },
    { sector: 'A', number: 10, position: { left: 295, top: 344 } },
    { sector: 'A', number: 11, position: { left: 319, top: 370 } },
    {
      sector: 'A',
      number: 12,
      isBoss: true,
      position: { left: 322, top: 343 }
    },
    { sector: 'A', number: 13, position: { left: 445, top: 361 } },
    { sector: 'A', number: 14, position: { left: 592, top: 346 } },
    { sector: 'A', number: 15, position: { left: 711, top: 301 } },
    { sector: 'A', number: 16, position: { left: 711, top: 196 } },
    { sector: 'A', number: 17, position: { left: 643, top: 193 } },
    { sector: 'A', number: 18, position: { left: 643, top: 165 } },
    {
      sector: 'A',
      number: 19,
      isBoss: true,
      position: { left: 669, top: 182 }
    },
    { sector: 'A', number: 20, position: { left: 604, top: 95 } },
    { sector: 'A', number: 21, position: { left: 536, top: 26 } },
    { sector: 'A', number: 22, position: { left: 352, top: 68 } },
    { sector: 'A', number: 23, isBoss: true, position: { left: 335, top: 86 } },
    { sector: 'A', number: 24, position: { left: 293, top: 71 } },
    { sector: 'A', number: 25, position: { left: 235, top: 15 } },
    { sector: 'B', number: 1, position: { left: 275, top: 96 } },
    { sector: 'B', number: 2, position: { left: 440, top: 50 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 531, top: 77 } },
    { sector: 'B', number: 4, position: { left: 619, top: 268 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 640, top: 253 } },
    { sector: 'B', number: 6, position: { left: 598, top: 308 } },
    { sector: 'B', number: 7, position: { left: 632, top: 343 } },
    { sector: 'B', number: 8, position: { left: 535, top: 305 } },
    { sector: 'B', number: 9, position: { left: 394, top: 329 } },
    { sector: 'B', number: 10, position: { left: 145, top: 93 } },
    { sector: 'B', number: 11, position: { left: 179, top: 82 } },
    { sector: 'B', number: 12, isBoss: true, position: { left: 179, top: 58 } },
    { sector: 'C', number: 1, position: { left: 436, top: 90 } },
    { sector: 'C', number: 2, position: { left: 457, top: 112 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 464, top: 85 } },
    { sector: 'C', number: 4, position: { left: 553, top: 168 } },
    { sector: 'C', number: 5, position: { left: 497, top: 245 } },
    { sector: 'C', number: 6, position: { left: 486, top: 293 } },
    { sector: 'C', number: 7, isBoss: true, position: { left: 374, top: 293 } },
    { sector: 'C', number: 8, position: { left: 346, top: 293 } },
    { sector: 'C', number: 9, isBoss: true, position: { left: 287, top: 289 } },
    { sector: 'C', number: 10, position: { left: 208, top: 247 } },
    { sector: 'C', number: 11, position: { left: 197, top: 178 } },
    { sector: 'C', number: 12, isBoss: true, position: { left: 224, top: 91 } },
    { sector: 'C', number: 13, position: { left: 248, top: 105 } },
    { sector: 'D', number: 1, position: { left: 279, top: 152 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 305, top: 161 } },
    { sector: 'D', number: 3, position: { left: 225, top: 126 } },
    { sector: 'D', number: 4, position: { left: 260, top: 251 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 293, top: 258 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 479, top: 205 } },
    { sector: 'E', number: 1, position: { left: 287, top: 234 } },
    { sector: 'E', number: 2, position: { left: 327, top: 216 } },
    { sector: 'E', number: 3, position: { left: 366, top: 214 } },
    { sector: 'E', number: 4, position: { left: 394, top: 226 } },
    { sector: 'E', number: 5, position: { left: 406, top: 201 } },
    { sector: 'E', number: 6, position: { left: 389, top: 171 } },
    { sector: 'E', number: 7, isBoss: true, position: { left: 420, top: 177 } }
  ]
} as CampData;

export const AliBabaundderdritteDiebLager = {
  id: AT_ALIBABAUNDDERDRITTEDIEB_ID,
  mapSize: { width: 748, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 608, top: 104 } },
    { sector: 'A', number: 2, position: { left: 596, top: 141 } },
    { sector: 'A', number: 3, position: { left: 500, top: 150 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 490, top: 125 } },
    { sector: 'A', number: 5, position: { left: 566, top: 325 } },
    { sector: 'A', number: 6, position: { left: 511, top: 358 } },
    { sector: 'A', number: 7, isBoss: true, position: { left: 547, top: 364 } },
    { sector: 'B', number: 1, position: { left: 413, top: 111 } },
    { sector: 'B', number: 2, position: { left: 353, top: 96 } },
    { sector: 'B', number: 3, position: { left: 280, top: 22 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 255, top: 12 } },
    { sector: 'B', number: 5, position: { left: 147, top: 74 } },
    { sector: 'B', number: 6, position: { left: 193, top: 121 } },
    { sector: 'B', number: 7, isBoss: true, position: { left: 211, top: 105 } },
    { sector: 'C', number: 1, position: { left: 269, top: 125 } },
    { sector: 'C', number: 2, position: { left: 329, top: 125 } },
    { sector: 'C', number: 3, position: { left: 378, top: 178 } },
    { sector: 'C', number: 4, position: { left: 457, top: 174 } },
    { sector: 'C', number: 5, position: { left: 512, top: 267 } },
    { sector: 'C', number: 6, position: { left: 448, top: 272 } },
    { sector: 'C', number: 7, isBoss: true, position: { left: 425, top: 263 } },
    { sector: 'D', number: 1, position: { left: 403, top: 305 } },
    { sector: 'D', number: 2, position: { left: 379, top: 230 } },
    { sector: 'D', number: 3, position: { left: 330, top: 238 } },
    { sector: 'D', number: 4, position: { left: 269, top: 200 } },
    { sector: 'D', number: 5, position: { left: 235, top: 168 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 254, top: 155 } },
    { sector: 'D', number: 7, isBoss: true, position: { left: 213, top: 182 } },
    { sector: 'E', number: 1, position: { left: 195, top: 208 } },
    { sector: 'E', number: 2, position: { left: 134, top: 216 } },
    { sector: 'E', number: 3, position: { left: 129, top: 271 } },
    { sector: 'E', number: 4, position: { left: 189, top: 270 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 117, top: 315 } },
    { sector: 'E', number: 6, position: { left: 234, top: 348 } },
    { sector: 'E', number: 7, position: { left: 254, top: 334 } },
    { sector: 'E', number: 8, position: { left: 238, top: 384 } },
    { sector: 'E', number: 9, isBoss: true, position: { left: 277, top: 366 } }
  ]
} as CampData;

export const AliBabaundderersteDiebLager = {
  id: AT_ALIBABAUNDDERERSTEDIEB_ID,
  mapSize: { width: 1022, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 161, top: 285 } },
    { sector: 'A', number: 2, position: { left: 280, top: 258 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 280, top: 222 } },
    { sector: 'A', number: 4, position: { left: 316, top: 123 } },
    { sector: 'B', number: 1, position: { left: 416, top: 285 } },
    { sector: 'B', number: 2, position: { left: 425, top: 325 } },
    { sector: 'B', number: 3, position: { left: 325, top: 365 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 290, top: 395 } },
    { sector: 'B', number: 5, position: { left: 443, top: 156 } },
    { sector: 'C', number: 1, position: { left: 553, top: 345 } },
    { sector: 'C', number: 2, position: { left: 661, top: 315 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 661, top: 290 } },
    { sector: 'C', number: 4, position: { left: 517, top: 177 } },
    { sector: 'D', number: 1, position: { left: 590, top: 110 } },
    { sector: 'D', number: 2, position: { left: 627, top: 121 } },
    { sector: 'D', number: 3, position: { left: 707, top: 139 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 700, top: 166 } },
    { sector: 'D', number: 5, position: { left: 744, top: 196 } },
    { sector: 'D', number: 6, position: { left: 818, top: 149 } },
    { sector: 'D', number: 7, position: { left: 818, top: 183 } },
    { sector: 'E', number: 1, position: { left: 835, top: 95 } },
    { sector: 'E', number: 2, position: { left: 753, top: 56 } },
    { sector: 'E', number: 3, position: { left: 690, top: 61 } },
    { sector: 'E', number: 4, position: { left: 581, top: 37 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 407, top: 99 } }
  ]
} as CampData;

export const AliBabaundderzweiteDiebLager = {
  id: AT_ALIBABAUNDDERZWEITEDIEB_ID,
  mapSize: { width: 865, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 161, top: 213 } },
    { sector: 'A', number: 2, position: { left: 242, top: 238 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 276, top: 232 } },
    { sector: 'A', number: 4, position: { left: 195, top: 292 } },
    { sector: 'A', number: 5, position: { left: 276, top: 343 } },
    { sector: 'A', number: 6, position: { left: 297, top: 414 } },
    { sector: 'A', number: 7, isBoss: true, position: { left: 426, top: 391 } },
    { sector: 'B', number: 1, position: { left: 494, top: 324 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 542, top: 370 } },
    { sector: 'B', number: 3, position: { left: 528, top: 236 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 565, top: 225 } },
    { sector: 'B', number: 5, position: { left: 658, top: 266 } },
    { sector: 'B', number: 6, position: { left: 671, top: 291 } },
    { sector: 'B', number: 7, isBoss: true, position: { left: 698, top: 274 } },
    { sector: 'C', number: 1, position: { left: 392, top: 202 } },
    { sector: 'C', number: 2, position: { left: 540, top: 182 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 548, top: 201 } },
    { sector: 'C', number: 4, position: { left: 455, top: 117 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 450, top: 94 } },
    { sector: 'C', number: 6, position: { left: 378, top: 77 } },
    { sector: 'D', number: 1, position: { left: 656, top: 138 } },
    { sector: 'D', number: 2, position: { left: 657, top: 166 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 726, top: 140 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 691, top: 178 } }
  ]
} as CampData;

export const AlteBekannteLager = {
  id: AT_ALTEBEKANNTE_ID,
  mapSize: { width: 622, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 108, top: 135 } },
    { sector: 'A', number: 2, position: { left: 165, top: 124 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 246, top: 153 } },
    { sector: 'B', number: 1, position: { left: 265, top: 264 } },
    { sector: 'B', number: 2, position: { left: 358, top: 209 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 342, top: 253 } },
    { sector: 'C', number: 1, position: { left: 413, top: 310 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 434, top: 355 } }
  ]
} as CampData;

export const AlteRuinenLager = {
  id: AT_ALTERUINEN_ID,
  mapSize: { width: 791, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 485, top: 365 } },
    { sector: 'A', number: 2, position: { left: 470, top: 297 } },
    { sector: 'A', number: 3, position: { left: 510, top: 225 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 585, top: 185 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 115, top: 134 } },
    { sector: 'C', number: 1, position: { left: 226, top: 240 } },
    { sector: 'C', number: 2, position: { left: 150, top: 315 } },
    { sector: 'C', number: 3, position: { left: 125, top: 297 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 108, top: 322 } }
  ]
} as CampData;

export const AmFußedesBergesLager = {
  id: AT_AMFUSSEDESBERGES_ID,
  mapSize: { width: 838, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 317, top: 399 } },
    { sector: 'A', number: 2, position: { left: 358, top: 422 } },
    { sector: 'A', number: 3, position: { left: 398, top: 435 } },
    { sector: 'A', number: 4, position: { left: 441, top: 384 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 378, top: 395 } },
    { sector: 'A', number: 11, position: { left: 118, top: 365 } },
    { sector: 'A', number: 12, position: { left: 167, top: 321 } },
    { sector: 'A', number: 13, position: { left: 35, top: 276 } },
    { sector: 'A', number: 14, position: { left: 93, top: 276 } },
    { sector: 'A', number: 15, position: { left: 106, top: 190 } },
    { sector: 'A', number: 16, position: { left: 146, top: 243 } },
    {
      sector: 'A',
      number: 17,
      isBoss: true,
      position: { left: 160, top: 274 }
    },
    { sector: 'A', number: 21, position: { left: 149, top: 75 } },
    { sector: 'A', number: 22, position: { left: 241, top: 57 } },
    { sector: 'A', number: 23, position: { left: 210, top: 153 } },
    {
      sector: 'A',
      number: 24,
      isBoss: true,
      position: { left: 286, top: 134 }
    },
    { sector: 'B', number: 1, position: { left: 588, top: 191 } },
    { sector: 'B', number: 2, position: { left: 578, top: 161 } },
    { sector: 'B', number: 3, position: { left: 630, top: 205 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 630, top: 166 } },
    { sector: 'C', number: 1, position: { left: 484, top: 77 } },
    { sector: 'C', number: 2, position: { left: 473, top: 31 } },
    { sector: 'C', number: 3, position: { left: 431, top: 82 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 399, top: 35 } },
    { sector: 'D', number: 1, position: { left: 703, top: 264 } },
    { sector: 'D', number: 2, position: { left: 651, top: 271 } },
    { sector: 'D', number: 3, position: { left: 661, top: 301 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 773, top: 290 } },
    { sector: 'E', number: 1, position: { left: 682, top: 121 } },
    { sector: 'E', number: 2, position: { left: 712, top: 140 } },
    { sector: 'E', number: 3, position: { left: 692, top: 91 } },
    { sector: 'E', number: 4, position: { left: 735, top: 103 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 724, top: 43 } }
  ]
} as CampData;

export const AngriffderNordmännerLager = {
  id: AT_ANGRIFFDERNORDMÄNNER_ID,
  mapSize: { width: 719, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 255, top: 357 } },
    { sector: 'A', number: 2, position: { left: 233, top: 378 } },
    { sector: 'A', number: 3, position: { left: 116, top: 357 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 82, top: 385 } },
    { sector: 'A', number: 5, position: { left: 137, top: 303 } },
    { sector: 'A', number: 6, position: { left: 102, top: 289 } },
    { sector: 'B', number: 1, position: { left: 151, top: 242 } },
    { sector: 'B', number: 2, position: { left: 164, top: 149 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 102, top: 149 } },
    { sector: 'B', number: 4, position: { left: 200, top: 109 } },
    { sector: 'C', number: 1, position: { left: 456, top: 345 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 540, top: 405 } },
    { sector: 'C', number: 3, position: { left: 574, top: 350 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 639, top: 370 } },
    { sector: 'C', number: 5, position: { left: 574, top: 281 } },
    { sector: 'D', number: 1, position: { left: 538, top: 233 } },
    { sector: 'D', number: 2, position: { left: 538, top: 175 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 603, top: 194 } },
    { sector: 'D', number: 4, position: { left: 582, top: 132 } },
    { sector: 'E', number: 1, position: { left: 490, top: 75 } },
    { sector: 'E', number: 2, position: { left: 352, top: 42 } },
    { sector: 'E', number: 3, position: { left: 275, top: 122 } },
    { sector: 'E', number: 4, position: { left: 283, top: 75 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 276, top: 19 } }
  ]
} as CampData;

export const ArchipelLager = {
  id: AT_ARCHIPEL_ID,
  mapSize: { width: 872, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 165, top: 133 } },
    { sector: 'A', number: 2, position: { left: 205, top: 108 } },
    { sector: 'A', number: 3, position: { left: 202, top: 167 } },
    { sector: 'A', number: 4, position: { left: 225, top: 142 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 252, top: 149 } },
    { sector: 'B', number: 1, position: { left: 374, top: 110 } },
    { sector: 'B', number: 2, position: { left: 319, top: 125 } },
    { sector: 'B', number: 3, position: { left: 298, top: 100 } },
    { sector: 'B', number: 4, position: { left: 339, top: 103 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 336, top: 77 } },
    { sector: 'B', number: 6, position: { left: 366, top: 136 } },
    { sector: 'B', number: 7, position: { left: 381, top: 199 } },
    { sector: 'B', number: 8, isBoss: true, position: { left: 422, top: 196 } },
    { sector: 'C', number: 1, position: { left: 18, top: 400 } },
    { sector: 'C', number: 2, position: { left: 64, top: 373 } },
    { sector: 'C', number: 3, position: { left: 88, top: 388 } },
    { sector: 'C', number: 4, position: { left: 97, top: 354 } },
    { sector: 'C', number: 5, position: { left: 142, top: 373 } },
    { sector: 'C', number: 6, position: { left: 205, top: 421 } },
    { sector: 'C', number: 7, position: { left: 235, top: 375 } },
    { sector: 'C', number: 8, position: { left: 260, top: 391 } },
    { sector: 'C', number: 9, position: { left: 261, top: 364 } },
    {
      sector: 'C',
      number: 10,
      isBoss: true,
      position: { left: 275, top: 338 }
    },
    {
      sector: 'C',
      number: 11,
      isBoss: true,
      position: { left: 283, top: 412 }
    },
    { sector: 'D', number: 1, position: { left: 322, top: 417 } },
    { sector: 'D', number: 2, position: { left: 376, top: 423 } },
    { sector: 'D', number: 3, position: { left: 400, top: 369 } },
    { sector: 'D', number: 4, position: { left: 430, top: 426 } },
    { sector: 'D', number: 5, position: { left: 435, top: 391 } },
    { sector: 'D', number: 6, position: { left: 462, top: 382 } },
    { sector: 'D', number: 7, isBoss: true, position: { left: 461, top: 407 } },
    { sector: 'D', number: 8, position: { left: 477, top: 429 } },
    { sector: 'E', number: 1, position: { left: 555, top: 436 } },
    { sector: 'E', number: 2, position: { left: 604, top: 440 } },
    { sector: 'E', number: 3, position: { left: 632, top: 438 } },
    { sector: 'E', number: 4, position: { left: 617, top: 413 } },
    { sector: 'E', number: 5, position: { left: 660, top: 399 } },
    { sector: 'E', number: 6, position: { left: 709, top: 340 } },
    { sector: 'E', number: 7, isBoss: true, position: { left: 678, top: 312 } },
    { sector: 'E', number: 8, position: { left: 741, top: 311 } },
    { sector: 'E', number: 9, position: { left: 702, top: 259 } },
    { sector: 'E', number: 10, position: { left: 732, top: 251 } },
    { sector: 'E', number: 11, position: { left: 638, top: 231 } },
    { sector: 'E', number: 12, position: { left: 667, top: 209 } },
    { sector: 'E', number: 13, position: { left: 641, top: 199 } },
    {
      sector: 'E',
      number: 14,
      isBoss: true,
      position: { left: 664, top: 183 }
    },
    { sector: 'F', number: 1, position: { left: 700, top: 145 } },
    { sector: 'F', number: 2, position: { left: 728, top: 139 } },
    { sector: 'F', number: 3, position: { left: 686, top: 107 } },
    { sector: 'F', number: 4, position: { left: 638, top: 97 } },
    { sector: 'F', number: 5, position: { left: 662, top: 74 } },
    { sector: 'F', number: 6, position: { left: 623, top: 59 } },
    { sector: 'F', number: 7, position: { left: 704, top: 41 } },
    { sector: 'F', number: 8, position: { left: 737, top: 32 } },
    { sector: 'F', number: 9, position: { left: 723, top: 9 } },
    { sector: 'F', number: 10, position: { left: 778, top: 39 } },
    { sector: 'F', number: 11, position: { left: 796, top: 60 } },
    { sector: 'F', number: 12, position: { left: 801, top: 24 } },
    { sector: 'F', number: 13, isBoss: true, position: { left: 821, top: 44 } }
  ]
} as CampData;

export const ArktischeExplosionLager = {
  id: AT_ARKTISCHEEXPLOSION_ID,
  mapSize: { width: 563, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 106, top: 130 } },
    { sector: 'A', number: 2, position: { left: 292, top: 147 } },
    { sector: 'A', number: 3, position: { left: 365, top: 167 } },
    { sector: 'A', number: 4, position: { left: 472, top: 315 } },
    { sector: 'A', number: 5, position: { left: 339, top: 445 } },
    { sector: 'A', number: 6, position: { left: 272, top: 297 } },
    { sector: 'A', number: 7, isBoss: true, position: { left: 340, top: 248 } }
  ]
} as CampData;

export const BerglabyrinthLager = {
  id: AT_BERGLABYRINTH_ID,
  mapSize: { width: 743, height: 500 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 43, top: 339 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 104, top: 328 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 225, top: 358 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 111, top: 260 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 195, top: 283 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 315, top: 228 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 42, top: 237 } },
    { sector: 'G', number: 1, position: { left: 255, top: 191 } },
    { sector: 'G', number: 2, position: { left: 172, top: 178 } },
    { sector: 'G', number: 3, position: { left: 210, top: 149 } },
    { sector: 'G', number: 4, position: { left: 198, top: 123 } },
    { sector: 'G', number: 5, position: { left: 185, top: 97 } },
    { sector: 'G', number: 6, position: { left: 224, top: 97 } },
    { sector: 'G', number: 7, isBoss: true, position: { left: 253, top: 80 } },
    { sector: 'H', number: 1, position: { left: 285, top: 106 } },
    { sector: 'H', number: 2, position: { left: 322, top: 91 } },
    { sector: 'H', number: 3, position: { left: 323, top: 130 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 384, top: 101 } },
    { sector: 'H', number: 5, isBoss: true, position: { left: 353, top: 45 } },
    { sector: 'I', number: 1, position: { left: 451, top: 15 } },
    { sector: 'I', number: 2, isBoss: true, position: { left: 519, top: 18 } },
    { sector: 'J', number: 1, position: { left: 459, top: 75 } },
    { sector: 'J', number: 2, isBoss: true, position: { left: 490, top: 102 } },
    { sector: 'K', number: 1, position: { left: 595, top: 66 } },
    { sector: 'K', number: 2, position: { left: 647, top: 98 } },
    { sector: 'K', number: 3, isBoss: true, position: { left: 678, top: 172 } },
    { sector: 'L', number: 1, position: { left: 534, top: 122 } },
    { sector: 'L', number: 2, position: { left: 572, top: 155 } },
    { sector: 'L', number: 3, isBoss: true, position: { left: 595, top: 205 } },
    { sector: 'M', number: 1, isBoss: true, position: { left: 652, top: 18 } },
    { sector: 'N', number: 1, isBoss: true, position: { left: 465, top: 190 } },
    { sector: 'O', number: 1, isBoss: true, position: { left: 640, top: 269 } }
  ]
} as CampData;

export const BeutelschneiderLager = {
  id: AT_BEUTELSCHNEIDER_ID,
  mapSize: { width: 832, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 175, top: 365 } },
    { sector: 'A', number: 2, position: { left: 140, top: 310 } },
    { sector: 'A', number: 3, position: { left: 170, top: 270 } },
    { sector: 'A', number: 4, position: { left: 175, top: 225 } },
    { sector: 'A', number: 5, position: { left: 100, top: 225 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 130, top: 180 } },
    { sector: 'B', number: 1, position: { left: 665, top: 375 } },
    { sector: 'B', number: 2, position: { left: 589, top: 305 } },
    { sector: 'B', number: 3, position: { left: 625, top: 305 } },
    { sector: 'B', number: 4, position: { left: 689, top: 295 } },
    { sector: 'B', number: 5, position: { left: 635, top: 245 } },
    { sector: 'B', number: 6, isBoss: true, position: { left: 620, top: 215 } },
    { sector: 'B', number: 7, position: { left: 705, top: 215 } },
    { sector: 'C', number: 1, position: { left: 620, top: 145 } },
    { sector: 'C', number: 2, position: { left: 641, top: 120 } },
    { sector: 'C', number: 3, position: { left: 663, top: 98 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 636, top: 93 } },
    { sector: 'D', number: 1, position: { left: 214, top: 135 } },
    { sector: 'D', number: 2, position: { left: 193, top: 103 } },
    { sector: 'D', number: 3, position: { left: 232, top: 95 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 209, top: 79 } },
    { sector: 'E', number: 1, position: { left: 245, top: 170 } },
    { sector: 'E', number: 2, position: { left: 302, top: 204 } },
    { sector: 'E', number: 3, position: { left: 305, top: 235 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 415, top: 259 } },
    { sector: 'E', number: 5, position: { left: 505, top: 230 } },
    { sector: 'E', number: 6, position: { left: 500, top: 190 } },
    { sector: 'E', number: 7, position: { left: 575, top: 173 } },
    { sector: 'F', number: 1, position: { left: 375, top: 140 } },
    { sector: 'F', number: 2, position: { left: 465, top: 140 } },
    { sector: 'F', number: 3, position: { left: 380, top: 88 } },
    { sector: 'F', number: 4, position: { left: 402, top: 103 } },
    { sector: 'F', number: 5, position: { left: 437, top: 104 } },
    { sector: 'F', number: 6, position: { left: 460, top: 90 } },
    { sector: 'F', number: 7, isBoss: true, position: { left: 405, top: 76 } },
    { sector: 'F', number: 8, isBoss: true, position: { left: 435, top: 76 } },
    { sector: 'F', number: 9, position: { left: 505, top: 115 } },
    { sector: 'G', number: 1, position: { left: 395, top: 315 } },
    { sector: 'G', number: 2, position: { left: 366, top: 366 } },
    { sector: 'G', number: 3, position: { left: 385, top: 345 } },
    { sector: 'G', number: 4, position: { left: 415, top: 343 } },
    { sector: 'G', number: 5, position: { left: 430, top: 365 } },
    { sector: 'G', number: 6, isBoss: true, position: { left: 403, top: 372 } }
  ]
} as CampData;

export const DasBergvolkLager = {
  id: AT_DASBERGVOLK_ID,
  mapSize: { width: 641, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 224, top: 432 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 278, top: 424 } },
    { sector: 'B', number: 1, position: { left: 415, top: 403 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 505, top: 415 } },
    { sector: 'C', number: 1, position: { left: 179, top: 352 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 152, top: 357 } },
    { sector: 'D', number: 1, position: { left: 399, top: 333 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 512, top: 342 } },
    { sector: 'E', number: 1, position: { left: 452, top: 293 } },
    { sector: 'E', number: 2, isBoss: true, position: { left: 543, top: 244 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 71, top: 252 } },
    { sector: 'G', number: 1, isBoss: true, position: { left: 216, top: 140 } },
    { sector: 'H', number: 1, isBoss: true, position: { left: 86, top: 69 } },
    { sector: 'I', number: 1, isBoss: true, position: { left: 304, top: 118 } },
    { sector: 'J', number: 1, isBoss: true, position: { left: 416, top: 124 } },
    { sector: 'K', number: 1, isBoss: true, position: { left: 511, top: 86 } },
    { sector: 'L', number: 1, position: { left: 276, top: 29 } },
    { sector: 'L', number: 2, position: { left: 298, top: 12 } },
    { sector: 'L', number: 3, isBoss: true, position: { left: 264, top: 2 } },
    { sector: 'X', number: 1, position: { left: 122, top: 234 } },
    { sector: 'X', number: 2, position: { left: 205, top: 90 } },
    { sector: 'X', number: 3, position: { left: 448, top: 85 } },
    { sector: 'X', number: 4, position: { left: 430, top: 185 } },
    { sector: 'Y', number: 1, position: { left: 225, top: 360 } },
    { sector: 'Y', number: 2, position: { left: 127, top: 162 } },
    { sector: 'Y', number: 3, position: { left: 421, top: 76 } },
    { sector: 'Y', number: 4, position: { left: 471, top: 272 } },
    { sector: 'Z', number: 1, position: { left: 64, top: 142 } },
    { sector: 'Z', number: 2, position: { left: 182, top: 74 } },
    { sector: 'Z', number: 3, position: { left: 309, top: 147 } },
    { sector: 'Z', number: 4, position: { left: 435, top: 344 } }
  ]
} as CampData;

export const DasEndederWeltLager = {
  id: AT_DASENDEDERWELT_ID,
  mapSize: { width: 809, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 9, top: 292 } },
    { sector: 'A', number: 2, position: { left: 163, top: 292 } },
    { sector: 'A', number: 3, position: { left: 115, top: 237 } },
    { sector: 'A', number: 4, position: { left: 16, top: 213 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 124, top: 182 } },
    { sector: 'A', number: 6, position: { left: 108, top: 146 } },
    { sector: 'B', number: 1, position: { left: 114, top: 73 } },
    { sector: 'B', number: 2, position: { left: 198, top: 49 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 207, top: 112 } },
    { sector: 'B', number: 4, position: { left: 302, top: 42 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 312, top: 5 } },
    { sector: 'C', number: 1, position: { left: 337, top: 382 } },
    { sector: 'C', number: 2, position: { left: 344, top: 419 } },
    { sector: 'C', number: 3, position: { left: 449, top: 373 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 332, top: 465 } },
    { sector: 'C', number: 5, position: { left: 413, top: 416 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 418, top: 451 } },
    { sector: 'D', number: 1, position: { left: 359, top: 302 } },
    { sector: 'D', number: 2, position: { left: 373, top: 240 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 306, top: 204 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 329, top: 185 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 296, top: 171 } },
    { sector: 'E', number: 1, position: { left: 567, top: 395 } },
    { sector: 'E', number: 2, position: { left: 638, top: 437 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 687, top: 468 } },
    { sector: 'E', number: 4, position: { left: 680, top: 378 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 764, top: 387 } },
    { sector: 'F', number: 1, position: { left: 588, top: 315 } },
    { sector: 'F', number: 2, position: { left: 519, top: 263 } },
    { sector: 'F', number: 3, position: { left: 720, top: 279 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 739, top: 303 } },
    { sector: 'F', number: 5, isBoss: true, position: { left: 598, top: 250 } },
    { sector: 'F', number: 6, position: { left: 575, top: 211 } },
    { sector: 'G', number: 1, position: { left: 344, top: 85 } },
    { sector: 'G', number: 2, position: { left: 366, top: 133 } },
    { sector: 'G', number: 3, position: { left: 463, top: 89 } },
    { sector: 'G', number: 4, isBoss: true, position: { left: 416, top: 52 } },
    { sector: 'H', number: 1, position: { left: 560, top: 124 } },
    { sector: 'H', number: 2, position: { left: 614, top: 75 } },
    { sector: 'H', number: 3, position: { left: 699, top: 101 } },
    { sector: 'H', number: 4, position: { left: 714, top: 40 } },
    { sector: 'H', number: 5, isBoss: true, position: { left: 764, top: 36 } }
  ]
} as CampData;

export const DasQuartierdesDrachenLager = {
  id: AT_DASQUARTIERDESDRACHEN_ID,
  mapSize: { width: 680, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 364, top: 368 } },
    { sector: 'A', number: 2, position: { left: 467, top: 385 } },
    { sector: 'A', number: 3, position: { left: 544, top: 369 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 605, top: 340 } },
    { sector: 'B', number: 1, position: { left: 552, top: 308 } },
    { sector: 'B', number: 2, position: { left: 506, top: 249 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 398, top: 228 } },
    { sector: 'D', number: 1, position: { left: 164, top: 212 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 235, top: 135 } },
    { sector: 'F', number: 1, position: { left: 356, top: 125 } },
    { sector: 'F', number: 2, isBoss: true, position: { left: 445, top: 135 } },
    { sector: 'G', number: 1, isBoss: true, position: { left: 473, top: 55 } }
  ]
} as CampData;

export const DasbetrogeneSchneiderleinLager = {
  id: AT_DASBETROGENESCHNEIDERLEIN_ID,
  mapSize: { width: 852, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 96, top: 332 } },
    { sector: 'A', number: 2, position: { left: 167, top: 337 } },
    { sector: 'A', number: 3, position: { left: 69, top: 269 } },
    { sector: 'A', number: 4, position: { left: 173, top: 285 } },
    { sector: 'A', number: 5, position: { left: 239, top: 285 } },
    { sector: 'A', number: 6, position: { left: 153, top: 249 } },
    { sector: 'A', number: 7, position: { left: 109, top: 214 } },
    { sector: 'A', number: 8, position: { left: 155, top: 188 } },
    { sector: 'A', number: 9, isBoss: true, position: { left: 141, top: 165 } },
    { sector: 'B', number: 1, position: { left: 174, top: 140 } },
    { sector: 'B', number: 2, position: { left: 147, top: 99 } },
    { sector: 'B', number: 3, position: { left: 113, top: 70 } },
    { sector: 'B', number: 4, position: { left: 206, top: 91 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 190, top: 68 } },
    { sector: 'C', number: 1, position: { left: 266, top: 53 } },
    { sector: 'C', number: 2, position: { left: 369, top: 52 } },
    { sector: 'C', number: 3, position: { left: 324, top: 119 } },
    { sector: 'C', number: 4, position: { left: 439, top: 129 } },
    { sector: 'C', number: 5, position: { left: 368, top: 181 } },
    { sector: 'C', number: 6, position: { left: 439, top: 234 } },
    { sector: 'C', number: 7, position: { left: 376, top: 272 } },
    { sector: 'C', number: 8, position: { left: 427, top: 293 } },
    { sector: 'C', number: 9, isBoss: true, position: { left: 444, top: 313 } },
    { sector: 'D', number: 1, position: { left: 415, top: 345 } },
    { sector: 'D', number: 2, position: { left: 479, top: 352 } },
    { sector: 'D', number: 3, position: { left: 479, top: 402 } },
    { sector: 'D', number: 4, position: { left: 538, top: 381 } },
    { sector: 'D', number: 5, position: { left: 550, top: 429 } },
    { sector: 'D', number: 6, position: { left: 640, top: 429 } },
    { sector: 'D', number: 7, position: { left: 614, top: 388 } },
    { sector: 'D', number: 8, isBoss: true, position: { left: 647, top: 389 } },
    { sector: 'E', number: 1, position: { left: 648, top: 327 } },
    { sector: 'E', number: 2, position: { left: 702, top: 283 } },
    { sector: 'E', number: 3, position: { left: 641, top: 275 } },
    { sector: 'E', number: 4, position: { left: 585, top: 257 } },
    { sector: 'E', number: 5, position: { left: 657, top: 236 } },
    { sector: 'E', number: 6, position: { left: 622, top: 208 } },
    { sector: 'E', number: 7, position: { left: 533, top: 209 } },
    { sector: 'E', number: 8, position: { left: 590, top: 181 } },
    { sector: 'E', number: 9, position: { left: 536, top: 117 } },
    {
      sector: 'E',
      number: 10,
      isBoss: true,
      position: { left: 559, top: 102 }
    },
    { sector: 'F', number: 1, position: { left: 596, top: 88 } },
    { sector: 'F', number: 2, position: { left: 629, top: 60 } },
    { sector: 'F', number: 3, position: { left: 623, top: 24 } },
    { sector: 'F', number: 4, position: { left: 720, top: 60 } },
    { sector: 'F', number: 5, position: { left: 687, top: 96 } },
    { sector: 'F', number: 6, isBoss: true, position: { left: 720, top: 108 } },
    { sector: 'F', number: 7, position: { left: 739, top: 137 } },
    { sector: 'F', number: 8, position: { left: 791, top: 120 } }
  ]
} as CampData;

export const DasdunkleTorLager = {
  id: AT_DASDUNKLETOR_ID,
  mapSize: { width: 768, height: 490 },
  camps: [
    { sector: 'A', number: 1, position: { left: 443, top: 388 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 402, top: 362 } },
    { sector: 'A', number: 3, position: { left: 562, top: 285 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 611, top: 231 } },
    { sector: 'B', number: 1, position: { left: 48, top: 195 } },
    { sector: 'B', number: 2, position: { left: 114, top: 75 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 150, top: 43 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 284, top: 164 } }
  ]
} as CampData;

export const DasheldenhafteSchneiderleinLager = {
  id: AT_DASHELDENHAFTESCHNEIDERLEIN_ID,
  mapSize: { width: 862, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 421, top: 371 } },
    { sector: 'A', number: 2, position: { left: 407, top: 270 } },
    { sector: 'A', number: 3, position: { left: 337, top: 259 } },
    { sector: 'A', number: 4, position: { left: 269, top: 255 } },
    { sector: 'A', number: 5, position: { left: 287, top: 293 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 258, top: 297 } },
    { sector: 'B', number: 1, position: { left: 231, top: 317 } },
    { sector: 'B', number: 2, position: { left: 263, top: 384 } },
    { sector: 'B', number: 3, position: { left: 223, top: 351 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 206, top: 373 } },
    { sector: 'C', number: 1, position: { left: 111, top: 353 } },
    { sector: 'C', number: 2, position: { left: 48, top: 322 } },
    { sector: 'C', number: 3, position: { left: 143, top: 287 } },
    { sector: 'C', number: 4, position: { left: 36, top: 205 } },
    { sector: 'C', number: 5, position: { left: 124, top: 183 } },
    { sector: 'C', number: 6, position: { left: 51, top: 137 } },
    { sector: 'C', number: 7, isBoss: true, position: { left: 64, top: 113 } },
    { sector: 'D', number: 1, position: { left: 98, top: 72 } },
    { sector: 'D', number: 2, position: { left: 168, top: 139 } },
    { sector: 'D', number: 3, position: { left: 148, top: 41 } },
    { sector: 'D', number: 4, position: { left: 216, top: 50 } },
    { sector: 'D', number: 5, position: { left: 236, top: 73 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 245, top: 46 } },
    { sector: 'E', number: 1, position: { left: 308, top: 38 } },
    { sector: 'E', number: 2, position: { left: 345, top: 77 } },
    { sector: 'E', number: 3, position: { left: 396, top: 47 } },
    { sector: 'E', number: 4, position: { left: 351, top: 127 } },
    { sector: 'E', number: 5, position: { left: 427, top: 96 } },
    { sector: 'E', number: 6, position: { left: 431, top: 135 } },
    { sector: 'E', number: 7, isBoss: true, position: { left: 431, top: 162 } },
    { sector: 'F', number: 1, position: { left: 534, top: 138 } },
    { sector: 'F', number: 2, position: { left: 484, top: 69 } },
    { sector: 'F', number: 3, position: { left: 583, top: 74 } },
    { sector: 'F', number: 4, position: { left: 654, top: 119 } },
    { sector: 'F', number: 5, position: { left: 649, top: 23 } },
    { sector: 'F', number: 6, position: { left: 731, top: 119 } },
    { sector: 'F', number: 7, isBoss: true, position: { left: 692, top: 197 } },
    { sector: 'G', number: 1, position: { left: 679, top: 245 } },
    { sector: 'G', number: 2, position: { left: 629, top: 236 } },
    { sector: 'G', number: 3, position: { left: 577, top: 273 } },
    { sector: 'G', number: 4, position: { left: 736, top: 318 } },
    { sector: 'G', number: 5, position: { left: 604, top: 337 } },
    { sector: 'G', number: 6, position: { left: 622, top: 387 } },
    { sector: 'G', number: 7, position: { left: 650, top: 375 } },
    { sector: 'G', number: 8, isBoss: true, position: { left: 654, top: 402 } }
  ]
} as CampData;

export const DasklugeSchneiderleinLager = {
  id: AT_DASKLUGESCHNEIDERLEIN_ID,
  mapSize: { width: 862, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 543, top: 361 } },
    { sector: 'A', number: 2, position: { left: 628, top: 343 } },
    { sector: 'A', number: 3, position: { left: 708, top: 387 } },
    { sector: 'A', number: 4, position: { left: 740, top: 343 } },
    { sector: 'A', number: 5, position: { left: 701, top: 252 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 716, top: 229 } },
    { sector: 'B', number: 1, position: { left: 746, top: 207 } },
    { sector: 'B', number: 2, position: { left: 689, top: 157 } },
    { sector: 'B', number: 3, position: { left: 739, top: 157 } },
    { sector: 'B', number: 4, position: { left: 707, top: 104 } },
    { sector: 'B', number: 5, position: { left: 690, top: 71 } },
    { sector: 'B', number: 6, position: { left: 632, top: 43 } },
    { sector: 'B', number: 7, position: { left: 594, top: 113 } },
    { sector: 'B', number: 8, isBoss: true, position: { left: 563, top: 109 } },
    { sector: 'B', number: 9, position: { left: 530, top: 75 } },
    { sector: 'C', number: 1, position: { left: 526, top: 171 } },
    { sector: 'C', number: 2, position: { left: 639, top: 207 } },
    { sector: 'C', number: 3, position: { left: 651, top: 288 } },
    { sector: 'C', number: 4, position: { left: 563, top: 303 } },
    { sector: 'C', number: 5, position: { left: 538, top: 241 } },
    { sector: 'C', number: 6, position: { left: 482, top: 198 } },
    { sector: 'C', number: 7, position: { left: 436, top: 149 } },
    { sector: 'C', number: 8, isBoss: true, position: { left: 409, top: 125 } },
    { sector: 'C', number: 9, position: { left: 330, top: 121 } },
    { sector: 'D', number: 1, position: { left: 441, top: 262 } },
    { sector: 'D', number: 2, position: { left: 385, top: 282 } },
    { sector: 'D', number: 3, position: { left: 419, top: 301 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 365, top: 302 } },
    { sector: 'E', number: 1, position: { left: 305, top: 270 } },
    { sector: 'E', number: 2, position: { left: 273, top: 234 } },
    { sector: 'E', number: 3, position: { left: 287, top: 169 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 287, top: 142 } },
    { sector: 'E', number: 5, position: { left: 292, top: 308 } },
    { sector: 'F', number: 1, position: { left: 273, top: 95 } },
    { sector: 'F', number: 2, position: { left: 172, top: 71 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 197, top: 102 } },
    { sector: 'G', number: 1, position: { left: 203, top: 144 } },
    { sector: 'G', number: 2, position: { left: 216, top: 253 } },
    { sector: 'G', number: 3, position: { left: 152, top: 247 } },
    { sector: 'G', number: 4, isBoss: true, position: { left: 123, top: 246 } },
    { sector: 'G', number: 5, position: { left: 198, top: 312 } },
    { sector: 'H', number: 1, position: { left: 96, top: 288 } },
    { sector: 'H', number: 2, position: { left: 45, top: 328 } },
    { sector: 'H', number: 3, position: { left: 121, top: 374 } },
    { sector: 'H', number: 4, position: { left: 57, top: 389 } },
    { sector: 'H', number: 5, isBoss: true, position: { left: 29, top: 396 } }
  ]
} as CampData;

export const DastapfereSchneiderleinLager = {
  id: AT_DASTAPFERESCHNEIDERLEIN_ID,
  mapSize: { width: 828, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 652, top: 305 } },
    { sector: 'A', number: 2, position: { left: 571, top: 325 } },
    { sector: 'A', number: 3, position: { left: 552, top: 251 } },
    { sector: 'A', number: 4, position: { left: 459, top: 294 } },
    { sector: 'A', number: 5, position: { left: 473, top: 248 } },
    { sector: 'A', number: 6, position: { left: 445, top: 240 } },
    { sector: 'A', number: 7, position: { left: 405, top: 260 } },
    { sector: 'A', number: 8, position: { left: 380, top: 329 } },
    { sector: 'A', number: 9, position: { left: 304, top: 290 } },
    { sector: 'A', number: 10, position: { left: 255, top: 367 } },
    { sector: 'A', number: 11, position: { left: 264, top: 410 } },
    {
      sector: 'A',
      number: 12,
      isBoss: true,
      position: { left: 235, top: 410 }
    },
    { sector: 'B', number: 1, position: { left: 174, top: 425 } },
    { sector: 'B', number: 2, position: { left: 211, top: 310 } },
    { sector: 'B', number: 3, position: { left: 94, top: 397 } },
    { sector: 'B', number: 4, position: { left: 56, top: 376 } },
    { sector: 'B', number: 5, position: { left: 112, top: 310 } },
    { sector: 'B', number: 6, position: { left: 74, top: 255 } },
    { sector: 'B', number: 7, position: { left: 93, top: 185 } },
    { sector: 'B', number: 8, isBoss: true, position: { left: 102, top: 160 } },
    { sector: 'C', number: 1, position: { left: 132, top: 143 } },
    { sector: 'C', number: 2, position: { left: 62, top: 86 } },
    { sector: 'C', number: 3, position: { left: 120, top: 63 } },
    { sector: 'C', number: 4, position: { left: 206, top: 53 } },
    { sector: 'C', number: 5, position: { left: 235, top: 95 } },
    { sector: 'C', number: 6, position: { left: 187, top: 148 } },
    { sector: 'C', number: 7, position: { left: 242, top: 183 } },
    { sector: 'C', number: 8, position: { left: 316, top: 160 } },
    { sector: 'C', number: 9, position: { left: 319, top: 119 } },
    { sector: 'C', number: 10, isBoss: true, position: { left: 303, top: 95 } },
    { sector: 'D', number: 1, position: { left: 374, top: 178 } },
    { sector: 'D', number: 2, position: { left: 388, top: 90 } },
    { sector: 'D', number: 3, position: { left: 438, top: 175 } },
    { sector: 'D', number: 4, position: { left: 488, top: 135 } },
    { sector: 'D', number: 5, position: { left: 515, top: 155 } },
    { sector: 'D', number: 6, position: { left: 589, top: 77 } },
    { sector: 'D', number: 7, position: { left: 633, top: 108 } },
    { sector: 'D', number: 8, position: { left: 734, top: 134 } },
    { sector: 'D', number: 9, position: { left: 614, top: 174 } },
    {
      sector: 'D',
      number: 10,
      isBoss: true,
      position: { left: 572, top: 185 }
    }
  ]
} as CampData;

export const DerChupacabraLager = {
  id: AT_DERCHUPACABRA_ID,
  mapSize: { width: 766, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 394, top: 45 } },
    { sector: 'A', number: 2, position: { left: 342, top: 85 } },
    { sector: 'A', number: 3, position: { left: 300, top: 79 } },
    { sector: 'A', number: 4, position: { left: 259, top: 50 } },
    { sector: 'A', number: 5, position: { left: 211, top: 59 } },
    { sector: 'A', number: 6, position: { left: 208, top: 29 } },
    { sector: 'B', number: 1, position: { left: 27, top: 332 } },
    { sector: 'B', number: 2, position: { left: 100, top: 365 } },
    { sector: 'B', number: 3, position: { left: 129, top: 369 } },
    { sector: 'B', number: 4, position: { left: 155, top: 391 } },
    { sector: 'B', number: 5, position: { left: 247, top: 410 } },
    { sector: 'B', number: 6, position: { left: 205, top: 381 } },
    { sector: 'C', number: 1, position: { left: 538, top: 305 } },
    { sector: 'C', number: 2, position: { left: 503, top: 281 } },
    { sector: 'C', number: 3, position: { left: 574, top: 247 } },
    { sector: 'C', number: 4, position: { left: 581, top: 218 } },
    { sector: 'C', number: 5, position: { left: 595, top: 155 } },
    { sector: 'C', number: 6, position: { left: 520, top: 179 } },
    { sector: 'C', number: 7, position: { left: 537, top: 156 } },
    { sector: 'D', number: 1, position: { left: 182, top: 54 } },
    { sector: 'D', number: 2, position: { left: 195, top: 95 } },
    { sector: 'D', number: 3, position: { left: 165, top: 156 } },
    { sector: 'D', number: 4, position: { left: 185, top: 181 } },
    { sector: 'D', number: 5, position: { left: 255, top: 154 } },
    { sector: 'D', number: 6, position: { left: 255, top: 192 } },
    { sector: 'E', number: 1, position: { left: 313, top: 358 } },
    { sector: 'E', number: 2, position: { left: 268, top: 345 } },
    { sector: 'E', number: 3, position: { left: 350, top: 311 } },
    { sector: 'E', number: 4, position: { left: 385, top: 265 } },
    { sector: 'E', number: 5, position: { left: 260, top: 279 } },
    { sector: 'E', number: 6, position: { left: 320, top: 243 } },
    { sector: 'F', number: 1, position: { left: 545, top: 200 } },
    { sector: 'F', number: 2, position: { left: 510, top: 235 } },
    { sector: 'F', number: 3, position: { left: 436, top: 228 } },
    { sector: 'F', number: 4, position: { left: 463, top: 190 } },
    { sector: 'F', number: 5, position: { left: 440, top: 166 } },
    { sector: 'F', number: 6, position: { left: 398, top: 177 } },
    { sector: 'G', number: 1, isBoss: true, position: { left: 335, top: 201 } }
  ]
} as CampData;

export const DerRattenfängervonHamelnLager = {
  id: AT_DERRATTENFÄNGERVONHAMELN_ID,
  mapSize: { width: 729, height: 499 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 119, top: 242 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 71, top: 160 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 246, top: 67 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 464, top: 63 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 457, top: 95 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 525, top: 75 } },
    { sector: 'F', number: 1, position: { left: 617, top: 202 } },
    { sector: 'F', number: 2, position: { left: 521, top: 368 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 495, top: 354 } },
    { sector: 'G', number: 1, position: { left: 365, top: 428 } },
    { sector: 'G', number: 2, isBoss: true, position: { left: 407, top: 463 } },
    { sector: 'H', number: 1, isBoss: true, position: { left: 295, top: 194 } },
    { sector: 'H', number: 2, isBoss: true, position: { left: 410, top: 200 } }
  ]
} as CampData;

export const DerSchamaneLager = {
  id: AT_DERSCHAMANE_ID,
  mapSize: { width: 625, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 140, top: 281 } },
    { sector: 'A', number: 2, position: { left: 173, top: 225 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 179, top: 251 } },
    { sector: 'B', number: 1, position: { left: 257, top: 272 } },
    { sector: 'B', number: 2, position: { left: 322, top: 335 } },
    { sector: 'B', number: 3, position: { left: 375, top: 344 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 354, top: 363 } },
    { sector: 'C', number: 1, position: { left: 511, top: 331 } },
    { sector: 'C', number: 2, position: { left: 537, top: 284 } },
    { sector: 'C', number: 3, position: { left: 497, top: 238 } },
    { sector: 'C', number: 4, position: { left: 438, top: 178 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 432, top: 142 } },
    { sector: 'D', number: 1, position: { left: 347, top: 104 } },
    { sector: 'D', number: 2, position: { left: 290, top: 142 } },
    { sector: 'D', number: 3, position: { left: 204, top: 89 } },
    { sector: 'D', number: 4, position: { left: 179, top: 115 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 173, top: 89 } }
  ]
} as CampData;

export const DerWirbelwindLager = {
  id: AT_DERWIRBELWIND_ID,
  mapSize: { width: 749, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 280, top: 400 } },
    { sector: 'A', number: 2, position: { left: 305, top: 422 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 345, top: 411 } },
    { sector: 'B', number: 1, position: { left: 462, top: 389 } },
    { sector: 'B', number: 2, position: { left: 514, top: 386 } },
    { sector: 'B', number: 3, position: { left: 543, top: 362 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 580, top: 331 } },
    { sector: 'C', number: 1, position: { left: 625, top: 269 } },
    { sector: 'C', number: 2, position: { left: 655, top: 247 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 637, top: 223 } },
    { sector: 'D', number: 1, position: { left: 611, top: 168 } },
    { sector: 'D', number: 2, position: { left: 615, top: 136 } },
    { sector: 'D', number: 3, position: { left: 561, top: 105 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 507, top: 101 } },
    { sector: 'E', number: 1, position: { left: 426, top: 35 } },
    { sector: 'E', number: 2, position: { left: 402, top: 58 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 368, top: 41 } },
    { sector: 'F', number: 1, position: { left: 210, top: 55 } },
    { sector: 'F', number: 2, position: { left: 203, top: 87 } },
    { sector: 'F', number: 3, position: { left: 139, top: 98 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 135, top: 160 } },
    { sector: 'G', number: 1, position: { left: 89, top: 203 } },
    { sector: 'G', number: 2, position: { left: 64, top: 227 } },
    { sector: 'G', number: 3, isBoss: true, position: { left: 81, top: 257 } },
    { sector: 'H', number: 1, position: { left: 81, top: 314 } },
    { sector: 'H', number: 2, position: { left: 140, top: 329 } },
    { sector: 'H', number: 3, position: { left: 145, top: 360 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 189, top: 362 } },
    { sector: 'I', number: 1, position: { left: 532, top: 290 } },
    { sector: 'I', number: 2, position: { left: 540, top: 252 } },
    { sector: 'I', number: 3, isBoss: true, position: { left: 520, top: 230 } },
    { sector: 'J', number: 1, position: { left: 387, top: 160 } },
    { sector: 'J', number: 2, position: { left: 386, top: 188 } },
    { sector: 'J', number: 3, isBoss: true, position: { left: 345, top: 174 } },
    { sector: 'K', number: 1, position: { left: 162, top: 190 } },
    { sector: 'K', number: 2, position: { left: 194, top: 183 } },
    { sector: 'K', number: 3, isBoss: true, position: { left: 193, top: 211 } },
    { sector: 'L', number: 1, position: { left: 335, top: 299 } },
    { sector: 'L', number: 2, position: { left: 326, top: 271 } },
    { sector: 'L', number: 3, isBoss: true, position: { left: 364, top: 272 } },
    { sector: 'M', number: 1, position: { left: 345, top: 196 } },
    { sector: 'M', number: 2, position: { left: 321, top: 215 } },
    { sector: 'M', number: 3, position: { left: 352, top: 243 } },
    { sector: 'M', number: 4, position: { left: 380, top: 219 } },
    { sector: 'M', number: 5, isBoss: true, position: { left: 351, top: 216 } }
  ]
} as CampData;

export const DergestohleneSchlittenLager = {
  id: AT_DERGESTOHLENESCHLITTEN_ID,
  mapSize: { width: 720, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 386, top: 441 } },
    { sector: 'A', number: 2, position: { left: 421, top: 347 } },
    { sector: 'A', number: 3, position: { left: 464, top: 415 } },
    { sector: 'A', number: 4, position: { left: 593, top: 368 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 629, top: 356 } },
    { sector: 'A', number: 6, position: { left: 465, top: 275 } },
    { sector: 'B', number: 1, position: { left: 162, top: 368 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 88, top: 368 } },
    { sector: 'B', number: 3, position: { left: 257, top: 351 } },
    { sector: 'B', number: 4, position: { left: 361, top: 310 } },
    { sector: 'B', number: 5, position: { left: 437, top: 257 } },
    { sector: 'B', number: 6, position: { left: 205, top: 277 } },
    { sector: 'B', number: 7, position: { left: 200, top: 215 } },
    { sector: 'B', number: 8, isBoss: true, position: { left: 173, top: 200 } },
    { sector: 'C', number: 1, position: { left: 463, top: 181 } },
    { sector: 'C', number: 2, position: { left: 369, top: 165 } },
    { sector: 'C', number: 3, position: { left: 284, top: 125 } },
    { sector: 'C', number: 4, position: { left: 352, top: 92 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 370, top: 46 } }
  ]
} as CampData;

export const DerschlafendeVulkanLager = {
  id: AT_DERSCHLAFENDEVULKAN_ID,
  mapSize: { width: 656, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 462, top: 336 } },
    { sector: 'A', number: 2, position: { left: 477, top: 163 } },
    { sector: 'A', number: 3, position: { left: 110, top: 214 } },
    { sector: 'A', number: 4, position: { left: 155, top: 335 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 233, top: 236 } }
  ]
} as CampData;

export const DerverscholleneSchädelLager = {
  id: AT_DERVERSCHOLLENESCHÄDEL_ID,
  mapSize: { width: 644, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 288, top: 364 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 499, top: 262 } },
    { sector: 'B', number: 1, position: { left: 183, top: 184 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 325, top: 52 } }
  ]
} as CampData;

export const DerÜberraschungsangriffLager = {
  id: AT_DERÜBERRASCHUNGSANGRIFF_ID,
  mapSize: { width: 814, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 125, top: 138 } },
    { sector: 'A', number: 2, position: { left: 92, top: 179 } },
    { sector: 'A', number: 3, position: { left: 185, top: 145 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 205, top: 120 } },
    { sector: 'B', number: 1, position: { left: 247, top: 230 } },
    { sector: 'B', number: 2, position: { left: 220, top: 279 } },
    { sector: 'B', number: 3, position: { left: 312, top: 284 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 354, top: 245 } },
    { sector: 'C', number: 1, position: { left: 405, top: 295 } },
    { sector: 'C', number: 2, position: { left: 388, top: 329 } },
    { sector: 'C', number: 3, position: { left: 322, top: 383 } },
    { sector: 'C', number: 4, position: { left: 413, top: 385 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 295, top: 426 } },
    { sector: 'D', number: 1, position: { left: 537, top: 257 } },
    { sector: 'D', number: 2, position: { left: 572, top: 255 } },
    { sector: 'D', number: 3, position: { left: 599, top: 272 } },
    { sector: 'D', number: 4, position: { left: 607, top: 301 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 605, top: 242 } },
    { sector: 'E', number: 1, position: { left: 605, top: 361 } },
    { sector: 'E', number: 2, position: { left: 572, top: 381 } },
    { sector: 'E', number: 3, position: { left: 605, top: 399 } },
    { sector: 'E', number: 4, position: { left: 585, top: 421 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 615, top: 435 } }
  ]
} as CampData;

export const DieBelagerungLager = {
  id: AT_DIEBELAGERUNG_ID,
  mapSize: { width: 666, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 566, top: 107 } },
    { sector: 'A', number: 2, position: { left: 527, top: 84 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 492, top: 69 } },
    { sector: 'B', number: 1, position: { left: 458, top: 98 } },
    { sector: 'B', number: 2, position: { left: 435, top: 119 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 479, top: 145 } },
    { sector: 'C', number: 1, position: { left: 376, top: 116 } },
    { sector: 'C', number: 2, position: { left: 323, top: 106 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 283, top: 116 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 289, top: 196 } },
    { sector: 'E', number: 1, position: { left: 217, top: 124 } },
    { sector: 'E', number: 2, position: { left: 229, top: 152 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 187, top: 150 } },
    { sector: 'F', number: 1, position: { left: 595, top: 284 } },
    { sector: 'F', number: 2, position: { left: 559, top: 306 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 514, top: 266 } },
    { sector: 'G', number: 1, position: { left: 460, top: 200 } },
    { sector: 'G', number: 2, position: { left: 417, top: 211 } },
    { sector: 'G', number: 3, isBoss: true, position: { left: 393, top: 222 } },
    { sector: 'H', number: 1, position: { left: 350, top: 284 } },
    { sector: 'H', number: 2, position: { left: 308, top: 293 } },
    { sector: 'H', number: 3, isBoss: true, position: { left: 255, top: 293 } },
    { sector: 'I', number: 1, isBoss: true, position: { left: 77, top: 386 } },
    { sector: 'J', number: 1, position: { left: 217, top: 264 } },
    { sector: 'J', number: 2, position: { left: 193, top: 284 } },
    { sector: 'J', number: 3, isBoss: true, position: { left: 171, top: 262 } }
  ]
} as CampData;

export const DieDunkleBruderschaftLager = {
  id: AT_DIEDUNKLEBRUDERSCHAFT_ID,
  mapSize: { width: 846, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 111, top: 465 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 197, top: 406 } },
    { sector: 'B', number: 1, position: { left: 132, top: 332 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 157, top: 284 } },
    { sector: 'C', number: 1, position: { left: 237, top: 276 } },
    { sector: 'C', number: 2, position: { left: 275, top: 325 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 242, top: 360 } },
    { sector: 'D', number: 1, position: { left: 297, top: 407 } },
    { sector: 'D', number: 2, position: { left: 366, top: 437 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 391, top: 413 } },
    { sector: 'E', number: 1, position: { left: 346, top: 367 } },
    { sector: 'E', number: 2, isBoss: true, position: { left: 339, top: 324 } },
    { sector: 'E', number: 3, position: { left: 314, top: 293 } },
    { sector: 'F', number: 1, position: { left: 65, top: 155 } },
    { sector: 'F', number: 2, isBoss: true, position: { left: 119, top: 197 } },
    { sector: 'G', number: 1, position: { left: 184, top: 140 } },
    { sector: 'G', number: 2, isBoss: true, position: { left: 236, top: 173 } },
    { sector: 'H', number: 1, position: { left: 302, top: 182 } },
    { sector: 'H', number: 2, isBoss: true, position: { left: 287, top: 238 } },
    { sector: 'I', number: 1, position: { left: 748, top: 122 } },
    { sector: 'I', number: 2, isBoss: true, position: { left: 676, top: 95 } },
    { sector: 'J', number: 1, position: { left: 663, top: 21 } },
    { sector: 'J', number: 2, isBoss: true, position: { left: 598, top: 47 } },
    { sector: 'K', number: 1, position: { left: 469, top: 47 } },
    { sector: 'K', number: 2, isBoss: true, position: { left: 527, top: 98 } },
    { sector: 'L', number: 1, position: { left: 593, top: 137 } },
    { sector: 'L', number: 2, position: { left: 650, top: 132 } },
    { sector: 'L', number: 3, isBoss: true, position: { left: 676, top: 190 } },
    { sector: 'M', number: 1, isBoss: true, position: { left: 553, top: 243 } },
    { sector: 'N', number: 1, position: { left: 553, top: 192 } },
    { sector: 'N', number: 2, isBoss: true, position: { left: 424, top: 130 } },
    { sector: 'N', number: 3, position: { left: 367, top: 157 } },
    { sector: 'O', number: 1, isBoss: true, position: { left: 411, top: 210 } },
    { sector: 'P', number: 1, position: { left: 472, top: 249 } },
    { sector: 'P', number: 2, position: { left: 501, top: 265 } },
    { sector: 'P', number: 3, position: { left: 448, top: 262 } },
    { sector: 'P', number: 4, position: { left: 426, top: 278 } },
    { sector: 'P', number: 5, isBoss: true, position: { left: 471, top: 278 } },
    { sector: 'P', number: 6, position: { left: 449, top: 298 } },
    { sector: 'P', number: 7, position: { left: 482, top: 300 } },
    { sector: 'Q', number: 1, isBoss: true, position: { left: 547, top: 349 } },
    { sector: 'R', number: 1, position: { left: 775, top: 417 } },
    { sector: 'R', number: 2, isBoss: true, position: { left: 787, top: 337 } },
    { sector: 'S', number: 1, isBoss: true, position: { left: 644, top: 289 } },
    { sector: 'T', number: 1, isBoss: true, position: { left: 673, top: 347 } }
  ]
} as CampData;

export const DieFreibeuter_RazziaLager = {
  id: AT_DIEFREIBEUTER_RAZZIA_ID,
  mapSize: { width: 862, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 114, top: 242 } },
    { sector: 'A', number: 2, position: { left: 181, top: 273 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 222, top: 320 } },
    { sector: 'B', number: 1, position: { left: 152, top: 76 } },
    { sector: 'B', number: 2, position: { left: 171, top: 123 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 232, top: 182 } },
    { sector: 'C', number: 1, position: { left: 556, top: 105 } },
    { sector: 'C', number: 2, position: { left: 584, top: 172 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 545, top: 230 } },
    { sector: 'D', number: 1, position: { left: 377, top: 343 } },
    { sector: 'D', number: 2, position: { left: 356, top: 364 } },
    { sector: 'D', number: 3, position: { left: 346, top: 338 } },
    { sector: 'D', number: 4, position: { left: 398, top: 395 } },
    { sector: 'D', number: 5, position: { left: 371, top: 407 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 481, top: 438 } }
  ]
} as CampData;

export const DieInselderFreibeuterLager = {
  id: AT_DIEINSELDERFREIBEUTER_ID,
  mapSize: { width: 494, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 91, top: 318 } },
    { sector: 'A', number: 2, position: { left: 119, top: 278 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 63, top: 267 } },
    { sector: 'B', number: 1, position: { left: 100, top: 173 } },
    { sector: 'B', number: 2, position: { left: 138, top: 126 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 176, top: 80 } },
    { sector: 'C', number: 1, position: { left: 326, top: 91 } },
    { sector: 'C', number: 2, position: { left: 348, top: 137 } },
    { sector: 'C', number: 3, position: { left: 375, top: 132 } },
    { sector: 'C', number: 4, position: { left: 384, top: 185 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 346, top: 220 } }
  ]
} as CampData;

export const DieInvasionderNordmännerLager = {
  id: AT_DIEINVASIONDERNORDMÄNNER_ID,
  mapSize: { width: 767, height: 499 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 245, top: 343 } },
    { sector: 'A', number: 2, position: { left: 197, top: 330 } },
    { sector: 'B', number: 1, position: { left: 57, top: 286 } },
    { sector: 'B', number: 2, position: { left: 43, top: 255 } },
    { sector: 'B', number: 3, position: { left: 115, top: 275 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 45, top: 225 } },
    { sector: 'B', number: 5, position: { left: 165, top: 265 } },
    { sector: 'B', number: 6, position: { left: 190, top: 228 } },
    { sector: 'B', number: 7, position: { left: 69, top: 187 } },
    { sector: 'C', number: 1, position: { left: 117, top: 167 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 194, top: 152 } },
    { sector: 'C', number: 3, position: { left: 190, top: 195 } },
    { sector: 'D', number: 1, position: { left: 330, top: 130 } },
    { sector: 'D', number: 2, position: { left: 365, top: 131 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 393, top: 147 } },
    { sector: 'D', number: 4, position: { left: 345, top: 166 } },
    { sector: 'E', number: 1, position: { left: 329, top: 251 } },
    { sector: 'E', number: 2, isBoss: true, position: { left: 357, top: 275 } },
    { sector: 'E', number: 3, position: { left: 385, top: 265 } },
    { sector: 'F', number: 1, position: { left: 371, top: 318 } },
    { sector: 'F', number: 2, position: { left: 452, top: 365 } },
    { sector: 'F', number: 3, position: { left: 485, top: 399 } },
    { sector: 'F', number: 4, position: { left: 491, top: 370 } },
    { sector: 'F', number: 5, position: { left: 521, top: 396 } },
    { sector: 'F', number: 6, isBoss: true, position: { left: 518, top: 369 } },
    { sector: 'G', number: 1, position: { left: 612, top: 271 } },
    { sector: 'G', number: 2, position: { left: 585, top: 235 } },
    { sector: 'G', number: 3, isBoss: true, position: { left: 628, top: 245 } },
    { sector: 'G', number: 4, position: { left: 618, top: 218 } },
    { sector: 'H', number: 1, position: { left: 679, top: 92 } },
    { sector: 'H', number: 2, position: { left: 662, top: 68 } },
    { sector: 'H', number: 3, position: { left: 708, top: 87 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 690, top: 64 } }
  ]
} as CampData;

export const DieNordmännerLager = {
  id: AT_DIENORDMÄNNER_ID,
  mapSize: { width: 843, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 90, top: 310 } },
    { sector: 'A', number: 2, position: { left: 65, top: 269 } },
    { sector: 'A', number: 3, position: { left: 146, top: 270 } },
    { sector: 'A', number: 4, position: { left: 110, top: 231 } },
    { sector: 'A', number: 5, position: { left: 142, top: 162 } },
    { sector: 'A', number: 6, position: { left: 216, top: 321 } },
    { sector: 'A', number: 7, position: { left: 248, top: 276 } },
    { sector: 'A', number: 8, position: { left: 203, top: 239 } },
    { sector: 'A', number: 9, position: { left: 215, top: 190 } },
    {
      sector: 'A',
      number: 10,
      isBoss: true,
      position: { left: 215, top: 149 }
    },
    { sector: 'B', number: 1, position: { left: 354, top: 121 } },
    { sector: 'B', number: 2, position: { left: 367, top: 161 } },
    { sector: 'B', number: 3, position: { left: 441, top: 156 } },
    { sector: 'B', number: 4, position: { left: 395, top: 202 } },
    { sector: 'B', number: 5, position: { left: 373, top: 246 } },
    { sector: 'B', number: 6, isBoss: true, position: { left: 402, top: 262 } },
    { sector: 'C', number: 1, position: { left: 394, top: 309 } },
    { sector: 'C', number: 2, position: { left: 446, top: 332 } },
    { sector: 'C', number: 3, position: { left: 474, top: 358 } },
    { sector: 'C', number: 4, position: { left: 515, top: 357 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 543, top: 359 } },
    { sector: 'C', number: 6, position: { left: 545, top: 387 } },
    { sector: 'D', number: 1, position: { left: 645, top: 332 } },
    { sector: 'D', number: 2, position: { left: 632, top: 260 } },
    { sector: 'D', number: 3, position: { left: 641, top: 220 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 625, top: 195 } },
    { sector: 'E', number: 1, position: { left: 698, top: 85 } },
    { sector: 'E', number: 2, position: { left: 682, top: 62 } },
    { sector: 'E', number: 3, position: { left: 728, top: 83 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 713, top: 58 } }
  ]
} as CampData;

export const DieSchwarzenPriesterLager = {
  id: AT_DIESCHWARZENPRIESTER_ID,
  mapSize: { width: 710, height: 499 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 348, top: 140 } },
    { sector: 'A', number: 2, position: { left: 349, top: 172 } },
    { sector: 'A', number: 3, position: { left: 305, top: 159 } },
    { sector: 'A', number: 4, position: { left: 270, top: 115 } },
    { sector: 'A', number: 5, position: { left: 398, top: 232 } },
    { sector: 'A', number: 6, position: { left: 435, top: 240 } },
    { sector: 'A', number: 7, position: { left: 398, top: 259 } },
    { sector: 'A', number: 8, position: { left: 232, top: 228 } },
    { sector: 'A', number: 9, position: { left: 171, top: 165 } },
    { sector: 'A', number: 10, position: { left: 265, top: 265 } },
    { sector: 'B', number: 1, position: { left: 225, top: 58 } },
    { sector: 'B', number: 2, position: { left: 177, top: 65 } },
    { sector: 'B', number: 3, position: { left: 151, top: 87 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 95, top: 97 } },
    { sector: 'C', number: 1, position: { left: 571, top: 118 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 577, top: 75 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 445, top: 55 } },
    { sector: 'D', number: 2, position: { left: 429, top: 91 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 519, top: 39 } }
  ]
} as CampData;

export const DieSchwarzenRitterLager = {
  id: AT_DIESCHWARZENRITTER_ID,
  mapSize: { width: 777, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 176, top: 322 } },
    { sector: 'A', number: 2, position: { left: 222, top: 319 } },
    { sector: 'A', number: 3, position: { left: 228, top: 291 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 260, top: 287 } },
    { sector: 'B', number: 1, position: { left: 203, top: 116 } },
    { sector: 'B', number: 2, position: { left: 240, top: 121 } },
    { sector: 'B', number: 3, position: { left: 235, top: 154 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 265, top: 169 } },
    { sector: 'C', number: 1, position: { left: 541, top: 330 } },
    { sector: 'C', number: 2, position: { left: 515, top: 308 } },
    { sector: 'C', number: 3, position: { left: 478, top: 305 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 449, top: 284 } },
    { sector: 'D', number: 1, position: { left: 325, top: 200 } },
    { sector: 'D', number: 2, position: { left: 326, top: 250 } },
    { sector: 'D', number: 3, position: { left: 391, top: 249 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 360, top: 215 } },
    { sector: 'E', number: 1, position: { left: 404, top: 175 } },
    { sector: 'E', number: 2, position: { left: 436, top: 185 } },
    { sector: 'E', number: 3, position: { left: 465, top: 144 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 430, top: 121 } },
    { sector: 'F', number: 1, position: { left: 526, top: 119 } },
    { sector: 'F', number: 2, position: { left: 540, top: 146 } },
    { sector: 'F', number: 3, position: { left: 573, top: 87 } },
    { sector: 'F', number: 4, position: { left: 605, top: 115 } },
    { sector: 'F', number: 5, position: { left: 659, top: 130 } },
    { sector: 'F', number: 6, position: { left: 611, top: 71 } },
    { sector: 'F', number: 7, position: { left: 638, top: 87 } },
    { sector: 'F', number: 8, isBoss: true, position: { left: 655, top: 50 } }
  ]
} as CampData;

export const DieStörenfriedeLager = {
  id: AT_DIESTÖRENFRIEDE_ID,
  mapSize: { width: 500, height: 450 },
  camps: [
    { sector: 'A', number: 1, position: { left: 186, top: 292 } },
    { sector: 'A', number: 2, position: { left: 237, top: 292 } },
    { sector: 'A', number: 3, position: { left: 286, top: 292 } },
    { sector: 'A', number: 4, position: { left: 338, top: 292 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 385, top: 292 } },
    { sector: 'A', number: 6, position: { left: 186, top: 328 } },
    { sector: 'A', number: 7, position: { left: 237, top: 328 } },
    { sector: 'A', number: 8, position: { left: 286, top: 328 } },
    { sector: 'A', number: 9, position: { left: 338, top: 328 } },
    {
      sector: 'A',
      number: 10,
      isBoss: true,
      position: { left: 385, top: 328 }
    },
    { sector: 'A', number: 11, position: { left: 184, top: 364 } },
    { sector: 'A', number: 12, position: { left: 234, top: 364 } },
    { sector: 'A', number: 13, position: { left: 285, top: 364 } },
    { sector: 'A', number: 14, position: { left: 336, top: 364 } },
    {
      sector: 'A',
      number: 15,
      isBoss: true,
      position: { left: 385, top: 364 }
    },
    {
      sector: 'A',
      number: 16,
      isBoss: true,
      position: { left: 186, top: 400 }
    }
  ]
} as CampData;

export const DieSöhnedesSchneiderleinsLager = {
  id: AT_DIESÖHNEDESSCHNEIDERLEINS_ID,
  mapSize: { width: 828, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 310, top: 379 } },
    { sector: 'A', number: 2, position: { left: 234, top: 346 } },
    { sector: 'A', number: 3, position: { left: 379, top: 375 } },
    { sector: 'A', number: 4, position: { left: 253, top: 313 } },
    { sector: 'A', number: 5, position: { left: 320, top: 310 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 349, top: 311 } },
    { sector: 'A', number: 7, position: { left: 391, top: 305 } },
    { sector: 'A', number: 8, position: { left: 339, top: 285 } },
    { sector: 'C', number: 1, position: { left: 179, top: 313 } },
    { sector: 'C', number: 2, position: { left: 115, top: 320 } },
    { sector: 'C', number: 3, position: { left: 202, top: 281 } },
    { sector: 'C', number: 4, position: { left: 115, top: 273 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 115, top: 245 } },
    { sector: 'D', number: 1, position: { left: 64, top: 210 } },
    { sector: 'D', number: 2, position: { left: 203, top: 173 } },
    { sector: 'D', number: 3, position: { left: 155, top: 119 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 127, top: 112 } },
    { sector: 'E', number: 1, position: { left: 135, top: 53 } },
    { sector: 'E', number: 2, position: { left: 204, top: 72 } },
    { sector: 'E', number: 3, position: { left: 243, top: 94 } },
    { sector: 'E', number: 4, position: { left: 238, top: 44 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 263, top: 31 } },
    { sector: 'M', number: 1, position: { left: 298, top: 240 } },
    { sector: 'M', number: 2, position: { left: 417, top: 243 } },
    { sector: 'M', number: 3, position: { left: 429, top: 202 } },
    { sector: 'M', number: 4, position: { left: 298, top: 207 } },
    { sector: 'M', number: 5, position: { left: 317, top: 173 } },
    { sector: 'M', number: 6, position: { left: 355, top: 180 } },
    { sector: 'M', number: 7, position: { left: 400, top: 179 } },
    { sector: 'M', number: 8, position: { left: 380, top: 155 } },
    { sector: 'M', number: 9, isBoss: true, position: { left: 424, top: 144 } },
    { sector: 'N', number: 1, position: { left: 444, top: 111 } },
    { sector: 'N', number: 2, position: { left: 481, top: 95 } },
    { sector: 'N', number: 3, position: { left: 456, top: 74 } },
    { sector: 'N', number: 4, position: { left: 478, top: 52 } },
    { sector: 'N', number: 5, isBoss: true, position: { left: 446, top: 46 } },
    { sector: 'X', number: 1, position: { left: 430, top: 399 } },
    { sector: 'X', number: 2, position: { left: 481, top: 367 } },
    { sector: 'X', number: 3, position: { left: 503, top: 425 } },
    { sector: 'X', number: 4, position: { left: 552, top: 450 } },
    { sector: 'X', number: 5, isBoss: true, position: { left: 607, top: 397 } },
    { sector: 'X', number: 6, position: { left: 576, top: 372 } },
    { sector: 'Y', number: 1, position: { left: 657, top: 382 } },
    { sector: 'Y', number: 2, position: { left: 690, top: 330 } },
    { sector: 'Y', number: 3, position: { left: 580, top: 280 } },
    { sector: 'Y', number: 4, position: { left: 722, top: 243 } },
    { sector: 'Y', number: 5, position: { left: 668, top: 215 } },
    { sector: 'Y', number: 6, isBoss: true, position: { left: 662, top: 186 } },
    { sector: 'Z', number: 1, position: { left: 645, top: 155 } },
    { sector: 'Z', number: 2, position: { left: 700, top: 115 } },
    { sector: 'Z', number: 3, position: { left: 670, top: 86 } },
    { sector: 'Z', number: 4, isBoss: true, position: { left: 714, top: 75 } }
  ]
} as CampData;
export const DieGeteilteStadtImHerbstLager = {
  id: AT_DIEGETEILTESTADTIMHERBST_ID,
  mapSize: { width: 750, height: 473 },
  camps: [
    { sector: 'A', number: 1, position: { left: 180, top: 340 } },
    { sector: 'A', number: 2, position: { left: 200, top: 350 } },
    { sector: 'A', number: 3, position: { left: 180, top: 315 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 210, top: 327 } },
    { sector: 'B', number: 1, position: { left: 472, top: 340 } },
    { sector: 'B', number: 2, position: { left: 460, top: 352 } },
    { sector: 'B', number: 3, position: { left: 460, top: 316 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 445, top: 335 } },
    { sector: 'C', number: 1, position: { left: 285, top: 330 } },
    { sector: 'C', number: 2, position: { left: 315, top: 320 } },
    { sector: 'C', number: 3, position: { left: 338, top: 357 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 332, top: 330 } },
    { sector: 'D', number: 1, position: { left: 385, top: 250 } },
    { sector: 'D', number: 2, position: { left: 360, top: 255 } },
    { sector: 'D', number: 3, position: { left: 385, top: 235 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 355, top: 230 } },
    { sector: 'E', number: 1, position: { left: 296, top: 270 } },
    { sector: 'E', number: 2, position: { left: 250, top: 257 } },
    { sector: 'E', number: 3, position: { left: 273, top: 242 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 237, top: 227 } },
    { sector: 'F', number: 1, position: { left: 524, top: 224 } },
    { sector: 'F', number: 2, position: { left: 595, top: 246 } },
    { sector: 'F', number: 3, position: { left: 571, top: 224 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 606, top: 224 } },
    { sector: 'G', number: 1, position: { left: 184, top: 261 } },
    { sector: 'G', number: 2, position: { left: 144, top: 250 } },
    { sector: 'G', number: 3, position: { left: 97, top: 235 } },
    { sector: 'G', number: 4, position: { left: 132, top: 221 } },
    { sector: 'G', number: 5, isBoss: true, position: { left: 91, top: 210 } },
    { sector: 'H', number: 1, position: { left: 654, top: 181 } },
    { sector: 'H', number: 2, position: { left: 625, top: 163 } },
    { sector: 'H', number: 3, position: { left: 589, top: 163 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 594, top: 138 } },
    { sector: 'I', number: 1, position: { left: 150, top: 130 } },
    { sector: 'I', number: 2, position: { left: 209, top: 145 } },
    { sector: 'I', number: 3, position: { left: 190, top: 120 } },
    { sector: 'I', number: 4, isBoss: true, position: { left: 204, top: 105 } },
    { sector: 'J', number: 1, position: { left: 536, top: 139 } },
    { sector: 'J', number: 2, position: { left: 501, top: 138 } },
    { sector: 'J', number: 3, position: { left: 483, top: 113 } },
    { sector: 'J', number: 4, isBoss: true, position: { left: 460, top: 99 } },
    { sector: 'K', number: 1, position: { left: 243, top: 95 } },
    { sector: 'K', number: 2, position: { left: 273, top: 92 } },
    { sector: 'K', number: 3, position: { left: 273, top: 76 } },
    { sector: 'K', number: 4, isBoss: true, position: { left: 290, top: 73 } },
    { sector: 'K', number: 5, position: { left: 401, top: 91 } },
    { sector: 'K', number: 6, position: { left: 373, top: 80 } },
    { sector: 'K', number: 7, position: { left: 348, top: 80 } },
    { sector: 'K', number: 8, isBoss: true, position: { left: 360, top: 66 } }
  ]
} as CampData;

export const DieGeteilteStadtImSommerLager = {
  id: AT_DIEGETEILTESTADTIMSOMMER_ID,
  mapSize: { width: 761, height: 486 },
  camps: [
    { sector: 'A', number: 1, position: { left: 178, top: 357 } },
    { sector: 'A', number: 2, position: { left: 230, top: 354 } },
    { sector: 'A', number: 3, position: { left: 183, top: 325 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 236, top: 320 } },
    { sector: 'B', number: 1, position: { left: 518, top: 357 } },
    { sector: 'B', number: 2, position: { left: 475, top: 354 } },
    { sector: 'B', number: 3, position: { left: 488, top: 332 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 459, top: 329 } },
    { sector: 'C', number: 1, position: { left: 295, top: 343 } },
    { sector: 'C', number: 2, position: { left: 360, top: 361 } },
    { sector: 'C', number: 3, position: { left: 342, top: 329 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 377, top: 335 } },
    { sector: 'D', number: 1, position: { left: 430, top: 282 } },
    { sector: 'D', number: 2, position: { left: 389, top: 278 } },
    { sector: 'D', number: 3, position: { left: 413, top: 256 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 394, top: 238 } },
    { sector: 'E', number: 1, position: { left: 296, top: 270 } },
    { sector: 'E', number: 2, position: { left: 250, top: 257 } },
    { sector: 'E', number: 3, position: { left: 273, top: 242 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 237, top: 227 } },
    { sector: 'F', number: 1, position: { left: 524, top: 224 } },
    { sector: 'F', number: 2, position: { left: 595, top: 246 } },
    { sector: 'F', number: 3, position: { left: 571, top: 224 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 606, top: 224 } },
    { sector: 'G', number: 1, position: { left: 184, top: 261 } },
    { sector: 'G', number: 2, position: { left: 144, top: 250 } },
    { sector: 'G', number: 3, position: { left: 97, top: 235 } },
    { sector: 'G', number: 4, position: { left: 132, top: 221 } },
    { sector: 'G', number: 5, isBoss: true, position: { left: 91, top: 210 } },
    { sector: 'H', number: 1, position: { left: 654, top: 181 } },
    { sector: 'H', number: 2, position: { left: 625, top: 163 } },
    { sector: 'H', number: 3, position: { left: 589, top: 163 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 594, top: 138 } },
    { sector: 'I', number: 1, position: { left: 150, top: 130 } },
    { sector: 'I', number: 2, position: { left: 209, top: 145 } },
    { sector: 'I', number: 3, position: { left: 190, top: 120 } },
    { sector: 'I', number: 4, isBoss: true, position: { left: 204, top: 105 } },
    { sector: 'J', number: 1, position: { left: 536, top: 139 } },
    { sector: 'J', number: 2, position: { left: 501, top: 138 } },
    { sector: 'J', number: 3, position: { left: 483, top: 113 } },
    { sector: 'J', number: 4, isBoss: true, position: { left: 460, top: 99 } },
    { sector: 'K', number: 1, position: { left: 243, top: 95 } },
    { sector: 'K', number: 2, position: { left: 273, top: 92 } },
    { sector: 'K', number: 3, position: { left: 273, top: 76 } },
    { sector: 'K', number: 4, isBoss: true, position: { left: 290, top: 73 } },
    { sector: 'K', number: 5, position: { left: 401, top: 91 } },
    { sector: 'K', number: 6, position: { left: 373, top: 80 } },
    { sector: 'K', number: 7, position: { left: 348, top: 80 } },
    { sector: 'K', number: 8, isBoss: true, position: { left: 360, top: 66 } }
  ]
} as CampData;

export const DieWildeWaltrautLager = {
  id: AT_DIEWILDEWALTRAUT_ID,
  mapSize: { width: 883, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 176, top: 261 } },
    { sector: 'A', number: 2, position: { left: 203, top: 224 } },
    { sector: 'A', number: 3, position: { left: 335, top: 187 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 380, top: 181 } },
    { sector: 'B', number: 1, position: { left: 503, top: 189 } },
    { sector: 'B', number: 2, position: { left: 583, top: 205 } },
    { sector: 'B', number: 3, position: { left: 642, top: 194 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 672, top: 186 } },
    { sector: 'C', number: 1, position: { left: 680, top: 81 } },
    { sector: 'C', number: 2, position: { left: 785, top: 80 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 800, top: 108 } },
    { sector: 'D', number: 1, position: { left: 830, top: 186 } },
    { sector: 'D', number: 2, position: { left: 780, top: 250 } },
    { sector: 'D', number: 3, position: { left: 743, top: 295 } },
    { sector: 'D', number: 4, position: { left: 665, top: 275 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 653, top: 317 } },
    { sector: 'E', number: 1, position: { left: 582, top: 339 } },
    { sector: 'E', number: 2, position: { left: 530, top: 339 } },
    { sector: 'E', number: 3, position: { left: 425, top: 306 } },
    { sector: 'E', number: 4, position: { left: 382, top: 342 } },
    { sector: 'E', number: 5, position: { left: 345, top: 377 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 300, top: 370 } }
  ]
} as CampData;

export const DieverloreneStadtLager = {
  id: AT_DIEVERLORENESTADT_ID,
  mapSize: { width: 693, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 273, top: 392 } },
    { sector: 'A', number: 2, position: { left: 149, top: 371 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 141, top: 396 } },
    { sector: 'A', number: 4, position: { left: 129, top: 335 } },
    { sector: 'B', number: 1, position: { left: 518, top: 380 } },
    { sector: 'B', number: 2, position: { left: 552, top: 370 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 582, top: 392 } },
    { sector: 'C', number: 1, position: { left: 153, top: 238 } },
    { sector: 'C', number: 2, position: { left: 139, top: 208 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 122, top: 230 } },
    { sector: 'D', number: 1, position: { left: 533, top: 256 } },
    { sector: 'D', number: 2, position: { left: 589, top: 216 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 602, top: 189 } },
    { sector: 'D', number: 4, position: { left: 555, top: 170 } },
    { sector: 'E', number: 1, position: { left: 254, top: 83 } },
    { sector: 'E', number: 2, position: { left: 168, top: 74 } },
    { sector: 'E', number: 3, position: { left: 142, top: 95 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 136, top: 66 } },
    { sector: 'F', number: 1, position: { left: 562, top: 138 } },
    { sector: 'F', number: 2, position: { left: 458, top: 90 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 548, top: 85 } }
  ]
} as CampData;

export const DrohendeGefahrLager = {
  id: AT_DROHENDEGEFAHR_ID,
  mapSize: { width: 771, height: 491 },
  camps: [
    { sector: 'A', number: 1, position: { left: 518, top: 126 } },
    { sector: 'A', number: 2, position: { left: 455, top: 71 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 389, top: 61 } },
    { sector: 'B', number: 1, position: { left: 297, top: 55 } },
    { sector: 'B', number: 2, position: { left: 151, top: 159 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 204, top: 174 } }
  ]
} as CampData;

export const EinRiesenkampfLager = {
  id: AT_EINRIESENKAMPF_ID,
  mapSize: { width: 723, height: 500 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 218, top: 434 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 428, top: 453 } },
    { sector: 'C', number: 1, position: { left: 120, top: 422 } },
    { sector: 'C', number: 2, position: { left: 82, top: 417 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 15, top: 411 } },
    { sector: 'D', number: 1, position: { left: 488, top: 454 } },
    { sector: 'D', number: 2, position: { left: 615, top: 403 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 669, top: 396 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 61, top: 466 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 481, top: 412 } },
    { sector: 'H', number: 1, isBoss: true, position: { left: 661, top: 363 } },
    { sector: 'I', number: 1, position: { left: 67, top: 362 } },
    { sector: 'I', number: 2, position: { left: 126, top: 362 } },
    { sector: 'I', number: 3, isBoss: true, position: { left: 142, top: 323 } },
    { sector: 'I', number: 4, isBoss: true, position: { left: 195, top: 365 } },
    { sector: 'J', number: 1, isBoss: true, position: { left: 256, top: 370 } },
    { sector: 'K', number: 1, position: { left: 345, top: 366 } },
    { sector: 'K', number: 2, position: { left: 443, top: 353 } },
    { sector: 'K', number: 3, isBoss: true, position: { left: 383, top: 333 } },
    { sector: 'L', number: 1, isBoss: true, position: { left: 569, top: 352 } },
    { sector: 'M', number: 1, isBoss: true, position: { left: 667, top: 273 } },
    { sector: 'N', number: 1, position: { left: 237, top: 262 } },
    { sector: 'N', number: 2, position: { left: 214, top: 245 } },
    { sector: 'N', number: 3, isBoss: true, position: { left: 180, top: 245 } },
    { sector: 'N', number: 4, isBoss: true, position: { left: 119, top: 245 } },
    { sector: 'O', number: 1, position: { left: 345, top: 291 } },
    { sector: 'O', number: 2, isBoss: true, position: { left: 277, top: 258 } },
    { sector: 'O', number: 3, position: { left: 448, top: 293 } },
    { sector: 'O', number: 4, isBoss: true, position: { left: 395, top: 293 } },
    { sector: 'P', number: 1, isBoss: true, position: { left: 173, top: 176 } },
    { sector: 'Q', number: 1, isBoss: true, position: { left: 258, top: 182 } },
    { sector: 'Q', number: 2, position: { left: 282, top: 199 } },
    { sector: 'Q', number: 3, isBoss: true, position: { left: 315, top: 178 } },
    { sector: 'R', number: 1, isBoss: true, position: { left: 395, top: 202 } },
    { sector: 'R', number: 2, position: { left: 423, top: 208 } },
    { sector: 'R', number: 3, isBoss: true, position: { left: 450, top: 212 } },
    { sector: 'S', number: 1, isBoss: true, position: { left: 517, top: 166 } },
    { sector: 'T', number: 1, isBoss: true, position: { left: 503, top: 130 } },
    { sector: 'T', number: 2, isBoss: true, position: { left: 406, top: 125 } },
    { sector: 'U', number: 1, isBoss: true, position: { left: 413, top: 156 } },
    { sector: 'V', number: 1, isBoss: true, position: { left: 373, top: 129 } },
    { sector: 'V', number: 2, isBoss: true, position: { left: 256, top: 133 } },
    { sector: 'W', number: 1, isBoss: true, position: { left: 211, top: 125 } },
    { sector: 'W', number: 2, isBoss: true, position: { left: 143, top: 81 } },
    { sector: 'X', number: 1, position: { left: 262, top: 56 } },
    { sector: 'X', number: 2, isBoss: true, position: { left: 301, top: 16 } },
    { sector: 'X', number: 3, isBoss: true, position: { left: 345, top: 58 } },
    { sector: 'Y', number: 1, isBoss: true, position: { left: 380, top: 60 } },
    { sector: 'Y', number: 2, position: { left: 458, top: 66 } },
    { sector: 'Y', number: 3, isBoss: true, position: { left: 473, top: 36 } },
    { sector: 'Z', number: 1, position: { left: 525, top: 71 } },
    { sector: 'Z', number: 2, isBoss: true, position: { left: 547, top: 17 } },
    { sector: 'a', number: 1, isBoss: true, position: { left: 255, top: 307 } },
    { sector: 'b', number: 1, isBoss: true, position: { left: 563, top: 258 } },
    { sector: 'c', number: 1, isBoss: true, position: { left: 97, top: 156 } },
    { sector: 'd', number: 1, isBoss: true, position: { left: 293, top: 92 } },
    { sector: 'e', number: 1, isBoss: true, position: { left: 45, top: 35 } },
    { sector: 'e', number: 2, isBoss: true, position: { left: 61, top: 11 } },
    { sector: 'e', number: 3, isBoss: true, position: { left: 15, top: 35 } },
    { sector: 'e', number: 4, isBoss: true, position: { left: -1, top: 11 } },
    { sector: 'e', number: 5, isBoss: true, position: { left: 30, top: 5 } },
    { sector: 'f', number: 4, isBoss: true, position: { left: 105, top: 5 } },
    { sector: 'g', number: 3, isBoss: true, position: { left: 145, top: 5 } },
    { sector: 'h', number: 2, isBoss: true, position: { left: 185, top: 5 } },
    { sector: 'i', number: 1, isBoss: true, position: { left: 385, top: -2 } }
  ]
} as CampData;

export const EinenSchrittvorausLager = {
  id: AT_EINENSCHRITTVORAUS_ID,
  mapSize: { width: 788, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 65, top: 304 } },
    { sector: 'A', number: 2, position: { left: 174, top: 352 } },
    { sector: 'A', number: 3, position: { left: 195, top: 393 } },
    { sector: 'A', number: 4, position: { left: 336, top: 435 } },
    { sector: 'A', number: 5, position: { left: 380, top: 315 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 315, top: 311 } },
    { sector: 'B', number: 1, position: { left: 505, top: 385 } },
    { sector: 'B', number: 2, position: { left: 587, top: 370 } },
    { sector: 'B', number: 3, position: { left: 633, top: 323 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 505, top: 312 } },
    { sector: 'C', number: 1, position: { left: 688, top: 250 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 595, top: 200 } },
    { sector: 'C', number: 3, position: { left: 695, top: 201 } },
    { sector: 'C', number: 4, position: { left: 632, top: 160 } },
    { sector: 'C', number: 5, position: { left: 675, top: 126 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 661, top: 97 } },
    { sector: 'D', number: 1, position: { left: 166, top: 265 } },
    { sector: 'D', number: 2, position: { left: 159, top: 232 } },
    { sector: 'D', number: 3, position: { left: 129, top: 243 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 212, top: 183 } },
    { sector: 'E', number: 1, position: { left: 387, top: 248 } },
    { sector: 'E', number: 2, position: { left: 360, top: 192 } },
    { sector: 'E', number: 3, position: { left: 300, top: 221 } },
    { sector: 'E', number: 4, position: { left: 242, top: 137 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 315, top: 166 } },
    { sector: 'F', number: 1, position: { left: 455, top: 226 } },
    { sector: 'F', number: 2, position: { left: 472, top: 192 } },
    { sector: 'F', number: 3, position: { left: 456, top: 167 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 491, top: 165 } },
    { sector: 'G', number: 1, position: { left: 567, top: 147 } },
    { sector: 'G', number: 2, position: { left: 454, top: 62 } },
    { sector: 'G', number: 3, position: { left: 477, top: 20 } },
    { sector: 'G', number: 4, isBoss: true, position: { left: 521, top: 39 } },
    { sector: 'H', number: 1, position: { left: 391, top: 125 } },
    { sector: 'H', number: 2, position: { left: 419, top: 105 } },
    { sector: 'H', number: 3, position: { left: 390, top: 32 } },
    { sector: 'H', number: 4, position: { left: 360, top: 52 } }
  ]
} as CampData;

export const EinsameExperimenteLager = {
  id: AT_EINSAMEEXPERIMENTE_ID,
  mapSize: { width: 811, height: 490 },
  camps: [
    { sector: 'A', number: 1, position: { left: 221, top: 105 } },
    { sector: 'A', number: 2, position: { left: 287, top: 105 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 295, top: 131 } },
    { sector: 'B', number: 1, position: { left: 136, top: 157 } },
    { sector: 'B', number: 2, position: { left: 160, top: 190 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 145, top: 212 } },
    { sector: 'C', number: 1, position: { left: 262, top: 219 } },
    { sector: 'C', number: 2, position: { left: 312, top: 184 } },
    { sector: 'C', number: 3, position: { left: 256, top: 259 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 330, top: 223 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 233, top: 311 } },
    { sector: 'D', number: 2, position: { left: 231, top: 352 } },
    { sector: 'D', number: 3, position: { left: 199, top: 328 } },
    { sector: 'D', number: 4, position: { left: 178, top: 295 } },
    { sector: 'D', number: 5, position: { left: 256, top: 387 } },
    { sector: 'D', number: 6, position: { left: 188, top: 377 } },
    { sector: 'D', number: 7, isBoss: true, position: { left: 132, top: 295 } },
    { sector: 'D', number: 8, position: { left: 104, top: 302 } },
    { sector: 'D', number: 9, position: { left: 117, top: 348 } },
    { sector: 'D', number: 10, position: { left: 39, top: 335 } },
    { sector: 'D', number: 11, position: { left: 112, top: 381 } },
    { sector: 'D', number: 12, position: { left: 214, top: 423 } },
    {
      sector: 'D',
      number: 13,
      isBoss: true,
      position: { left: 147, top: 417 }
    },
    { sector: 'E', number: 1, position: { left: 345, top: 394 } },
    { sector: 'E', number: 2, isBoss: true, position: { left: 364, top: 418 } },
    { sector: 'E', number: 3, position: { left: 399, top: 391 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 347, top: 300 } },
    { sector: 'E', number: 5, position: { left: 431, top: 332 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 457, top: 323 } },
    { sector: 'E', number: 7, position: { left: 524, top: 292 } },
    { sector: 'F', number: 1, position: { left: 396, top: 443 } },
    { sector: 'F', number: 2, position: { left: 499, top: 380 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 529, top: 420 } },
    { sector: 'F', number: 4, position: { left: 571, top: 395 } },
    { sector: 'G', number: 1, position: { left: 629, top: 376 } },
    { sector: 'G', number: 2, position: { left: 658, top: 368 } },
    { sector: 'G', number: 3, isBoss: true, position: { left: 684, top: 366 } },
    { sector: 'G', number: 4, position: { left: 651, top: 302 } },
    { sector: 'G', number: 5, position: { left: 679, top: 326 } },
    { sector: 'G', number: 6, isBoss: true, position: { left: 700, top: 342 } },
    { sector: 'G', number: 7, position: { left: 741, top: 328 } },
    { sector: 'H', number: 1, position: { left: 431, top: 190 } },
    { sector: 'H', number: 2, position: { left: 444, top: 235 } },
    { sector: 'H', number: 3, position: { left: 511, top: 193 } },
    { sector: 'H', number: 4, position: { left: 493, top: 160 } },
    { sector: 'H', number: 5, isBoss: true, position: { left: 474, top: 200 } },
    { sector: 'I', number: 1, position: { left: 500, top: 91 } },
    { sector: 'I', number: 2, position: { left: 530, top: 123 } },
    { sector: 'I', number: 3, position: { left: 558, top: 90 } },
    { sector: 'I', number: 4, isBoss: true, position: { left: 565, top: 48 } },
    { sector: 'J', number: 1, position: { left: 558, top: 208 } },
    { sector: 'J', number: 2, position: { left: 620, top: 231 } },
    { sector: 'J', number: 3, isBoss: true, position: { left: 645, top: 179 } },
    { sector: 'J', number: 4, position: { left: 621, top: 143 } },
    { sector: 'J', number: 5, position: { left: 692, top: 127 } },
    { sector: 'J', number: 6, position: { left: 698, top: 183 } },
    { sector: 'J', number: 7, isBoss: true, position: { left: 741, top: 209 } },
    { sector: 'J', number: 8, position: { left: 711, top: 243 } },
    { sector: 'K', number: 1, position: { left: 717, top: 75 } },
    { sector: 'K', number: 2, isBoss: true, position: { left: 717, top: 45 } },
    { sector: 'L', number: 1, position: { left: 371, top: 133 } },
    { sector: 'L', number: 2, position: { left: 444, top: 108 } },
    { sector: 'L', number: 3, isBoss: true, position: { left: 389, top: 90 } }
  ]
} as CampData;

export const GarrunderFallenstellerLager = {
  id: AT_GARRUNDERFALLENSTELLER_ID,
  mapSize: { width: 544, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 147, top: 405 } },
    { sector: 'A', number: 2, position: { left: 106, top: 380 } },
    { sector: 'A', number: 3, position: { left: 118, top: 326 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 211, top: 279 } },
    { sector: 'B', number: 1, position: { left: 283, top: 225 } },
    { sector: 'B', number: 2, position: { left: 325, top: 165 } },
    { sector: 'B', number: 3, position: { left: 303, top: 128 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 317, top: 98 } },
    { sector: 'C', number: 1, position: { left: 284, top: 84 } },
    { sector: 'C', number: 2, position: { left: 247, top: 64 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 249, top: 34 } }
  ]
} as CampData;

export const GefängnisinselLager = {
  id: AT_GEFÄNGNISINSEL_ID,
  mapSize: { width: 766, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 295, top: 268 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 194, top: 374 } },
    { sector: 'B', number: 1, position: { left: 253, top: 206 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 25, top: 315 } },
    { sector: 'C', number: 1, position: { left: 295, top: 141 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 159, top: 43 } },
    { sector: 'D', number: 1, position: { left: 367, top: 159 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 555, top: 71 } }
  ]
} as CampData;

export const GrabräuberLager = {
  id: AT_GRABRÄUBER_ID,
  mapSize: { width: 682, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 149, top: 54 } },
    { sector: 'A', number: 2, position: { left: 197, top: 105 } },
    { sector: 'A', number: 3, position: { left: 261, top: 144 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 300, top: 182 } },
    { sector: 'B', number: 1, position: { left: 588, top: 103 } },
    { sector: 'B', number: 2, position: { left: 539, top: 134 } },
    { sector: 'B', number: 3, position: { left: 476, top: 176 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 411, top: 213 } },
    { sector: 'C', number: 1, position: { left: 588, top: 427 } },
    { sector: 'C', number: 2, position: { left: 555, top: 364 } },
    { sector: 'C', number: 3, position: { left: 460, top: 344 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 381, top: 313 } },
    { sector: 'D', number: 1, position: { left: 34, top: 385 } },
    { sector: 'D', number: 2, position: { left: 98, top: 325 } },
    { sector: 'D', number: 3, position: { left: 180, top: 295 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 247, top: 272 } }
  ]
} as CampData;

export const HeimatinselLager = {
  id: AT_HEIMATINSEL_ID,
  mapSize: { width: 820, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 362, top: 371 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 377, top: 393 } },
    { sector: 'B', number: 1, position: { left: 238, top: 299 } },
    { sector: 'B', number: 2, position: { left: 301, top: 345 } },
    { sector: 'B', number: 3, position: { left: 323, top: 311 } },
    // { sector: "B", number: 4, position: { left: 382, top: 351 } },
    { sector: 'B', number: 4, position: { left: 386, top: 287 } },
    { sector: 'B', number: 5, position: { left: 360, top: 255 } },
    { sector: 'B', number: 6, position: { left: 412, top: 255 } },
    { sector: 'B', number: 7, position: { left: 431, top: 227 } },
    { sector: 'B', number: 8, position: { left: 308, top: 219 } },
    { sector: 'B', number: 9, position: { left: 338, top: 199 } },
    {
      sector: 'B',
      number: 10,
      isBoss: true,
      position: { left: 309, top: 188 }
    },
    { sector: 'C', number: 1, position: { left: 110, top: 124 } },
    { sector: 'C', number: 2, position: { left: 225, top: 124 } },
    { sector: 'C', number: 3, position: { left: 181, top: 72 } },
    { sector: 'C', number: 4, position: { left: 250, top: 67 } },
    { sector: 'C', number: 5, position: { left: 295, top: 48 } },
    { sector: 'C', number: 6, position: { left: 323, top: 66 } },
    { sector: 'C', number: 7, isBoss: true, position: { left: 335, top: 25 } },
    { sector: 'D', number: 1, position: { left: 565, top: 411 } },
    { sector: 'D', number: 2, position: { left: 575, top: 383 } },
    { sector: 'D', number: 3, position: { left: 645, top: 385 } },
    { sector: 'D', number: 4, position: { left: 719, top: 370 } },
    { sector: 'D', number: 5, position: { left: 738, top: 345 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 747, top: 390 } },
    { sector: 'E', number: 1, position: { left: 625, top: 346 } },
    { sector: 'E', number: 2, position: { left: 574, top: 339 } },
    { sector: 'E', number: 3, position: { left: 676, top: 291 } },
    { sector: 'E', number: 4, position: { left: 585, top: 269 } },
    { sector: 'E', number: 5, position: { left: 580, top: 238 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 625, top: 235 } },
    { sector: 'F', number: 1, position: { left: 355, top: 101 } },
    { sector: 'F', number: 2, position: { left: 409, top: 76 } },
    { sector: 'F', number: 3, position: { left: 426, top: 100 } },
    { sector: 'F', number: 4, position: { left: 458, top: 100 } },
    { sector: 'F', number: 5, isBoss: true, position: { left: 436, top: 68 } },
    { sector: 'G', number: 1, position: { left: 554, top: 152 } },
    { sector: 'G', number: 2, position: { left: 573, top: 116 } },
    { sector: 'G', number: 3, position: { left: 612, top: 139 } },
    { sector: 'G', number: 4, position: { left: 657, top: 169 } },
    { sector: 'G', number: 5, position: { left: 605, top: 89 } },
    { sector: 'G', number: 6, position: { left: 631, top: 111 } },
    { sector: 'G', number: 7, position: { left: 678, top: 147 } },
    { sector: 'G', number: 8, position: { left: 662, top: 85 } },
    { sector: 'G', number: 9, position: { left: 695, top: 108 } },
    { sector: 'G', number: 10, position: { left: 686, top: 75 } },
    { sector: 'G', number: 11, position: { left: 712, top: 89 } },
    { sector: 'G', number: 12, position: { left: 694, top: 55 } },
    { sector: 'G', number: 13, position: { left: 735, top: 77 } },
    { sector: 'G', number: 14, isBoss: true, position: { left: 718, top: 59 } }
  ]
} as CampData;

export const HänselundGretelLager = {
  id: AT_HÄNSELUNDGRETEL_ID,
  mapSize: { width: 834, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 139, top: 210 } },
    { sector: 'A', number: 2, position: { left: 89, top: 300 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 147, top: 284 } },
    { sector: 'A', number: 4, position: { left: 137, top: 339 } },
    { sector: 'B', number: 1, position: { left: 161, top: 384 } },
    { sector: 'B', number: 2, position: { left: 145, top: 412 } },
    { sector: 'B', number: 3, position: { left: 210, top: 372 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 236, top: 409 } },
    { sector: 'C', number: 1, position: { left: 297, top: 367 } },
    { sector: 'C', number: 2, position: { left: 426, top: 338 } },
    { sector: 'C', number: 3, position: { left: 395, top: 309 } },
    { sector: 'C', number: 4, position: { left: 437, top: 285 } },
    { sector: 'C', number: 5, position: { left: 387, top: 255 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 419, top: 194 } },
    { sector: 'D', number: 1, position: { left: 475, top: 235 } },
    { sector: 'D', number: 2, position: { left: 570, top: 275 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 580, top: 333 } },
    { sector: 'E', number: 1, position: { left: 490, top: 395 } },
    { sector: 'E', number: 2, position: { left: 510, top: 418 } },
    { sector: 'E', number: 3, position: { left: 401, top: 413 } },
    { sector: 'F', number: 1, position: { left: 651, top: 357 } },
    { sector: 'F', number: 2, isBoss: true, position: { left: 742, top: 401 } },
    { sector: 'G', number: 1, position: { left: 700, top: 307 } },
    { sector: 'G', number: 2, position: { left: 764, top: 296 } },
    { sector: 'G', number: 3, isBoss: true, position: { left: 790, top: 320 } },
    { sector: 'H', number: 1, position: { left: 675, top: 194 } },
    { sector: 'H', number: 2, isBoss: true, position: { left: 621, top: 187 } },
    { sector: 'I', number: 1, isBoss: true, position: { left: 770, top: 35 } }
  ]
} as CampData;

export const JagdaufdieJägerLager = {
  id: AT_JAGDAUFDIEJÄGER_ID,
  mapSize: { width: 774, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 73, top: 154 } },
    { sector: 'A', number: 2, position: { left: 123, top: 150 } },
    { sector: 'A', number: 3, position: { left: 185, top: 130 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 252, top: 95 } },
    { sector: 'B', number: 1, position: { left: 130, top: 329 } },
    { sector: 'B', number: 2, position: { left: 200, top: 320 } },
    { sector: 'B', number: 3, position: { left: 237, top: 263 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 330, top: 237 } },
    { sector: 'C', number: 1, position: { left: 377, top: 421 } },
    { sector: 'C', number: 2, position: { left: 428, top: 415 } },
    { sector: 'C', number: 3, position: { left: 427, top: 364 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 451, top: 294 } },
    { sector: 'D', number: 1, position: { left: 300, top: 65 } },
    { sector: 'D', number: 2, position: { left: 365, top: 105 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 471, top: 92 } },
    { sector: 'E', number: 1, position: { left: 384, top: 205 } },
    { sector: 'E', number: 2, position: { left: 442, top: 170 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 513, top: 110 } },
    { sector: 'F', number: 1, position: { left: 470, top: 247 } },
    { sector: 'F', number: 2, position: { left: 527, top: 206 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 556, top: 127 } }
  ]
} as CampData;

export const KopfgeldjägerLager = {
  id: AT_KOPFGELDJÄGER_ID,
  mapSize: { width: 607, height: 499 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 319, top: 245 } },
    { sector: 'B', number: 1, position: { left: 289, top: 203 } },
    { sector: 'B', number: 2, position: { left: 182, top: 150 } },
    { sector: 'B', number: 3, position: { left: 220, top: 101 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 270, top: 85 } }
  ]
} as CampData;

export const MehreinsameExperimenteLager = {
  id: AT_MEHREINSAMEEXPERIMENTE_ID,
  mapSize: { width: 777, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 61, top: 134 } },
    { sector: 'A', number: 2, position: { left: 16, top: 171 } },
    { sector: 'A', number: 3, position: { left: 84, top: 184 } },
    { sector: 'A', number: 4, position: { left: 165, top: 156 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 170, top: 183 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 204, top: 138 } },
    { sector: 'B', number: 2, position: { left: 247, top: 83 } },
    { sector: 'B', number: 3, position: { left: 167, top: 88 } },
    { sector: 'C', number: 1, position: { left: 264, top: 165 } },
    { sector: 'C', number: 2, position: { left: 212, top: 224 } },
    { sector: 'C', number: 3, position: { left: 294, top: 229 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 346, top: 224 } },
    { sector: 'D', number: 1, position: { left: 489, top: 350 } },
    { sector: 'D', number: 2, position: { left: 413, top: 359 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 467, top: 382 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 353, top: 423 } },
    { sector: 'E', number: 2, position: { left: 377, top: 438 } },
    { sector: 'E', number: 3, position: { left: 291, top: 442 } },
    { sector: 'F', number: 1, position: { left: 256, top: 456 } },
    { sector: 'F', number: 2, position: { left: 210, top: 418 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 174, top: 459 } },
    { sector: 'G', number: 1, position: { left: 525, top: 445 } },
    { sector: 'G', number: 2, isBoss: true, position: { left: 734, top: 464 } },
    { sector: 'G', number: 3, position: { left: 630, top: 363 } },
    { sector: 'H', number: 1, position: { left: 644, top: 180 } },
    { sector: 'H', number: 2, position: { left: 734, top: 180 } },
    { sector: 'H', number: 3, position: { left: 675, top: 133 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 644, top: 95 } },
    { sector: 'I', number: 1, position: { left: 570, top: 95 } },
    { sector: 'I', number: 2, position: { left: 541, top: 40 } },
    { sector: 'I', number: 3, position: { left: 593, top: 18 } },
    { sector: 'I', number: 4, isBoss: true, position: { left: 555, top: 14 } }
  ]
} as CampData;

export const MonsterausdemNebelLager = {
  id: AT_MONSTERAUSDEMNEBEL_ID,
  mapSize: { width: 782, height: 494 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 249, top: 311 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 313, top: 317 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 392, top: 286 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 431, top: 133 } }
  ]
} as CampData;

export const MutterliebeLager = {
  id: AT_MUTTERLIEBE_ID,
  mapSize: { width: 730, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 587, top: 209 } },
    { sector: 'A', number: 2, position: { left: 535, top: 241 } },
    { sector: 'A', number: 3, position: { left: 515, top: 208 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 485, top: 115 } },
    { sector: 'A', number: 5, position: { left: 431, top: 109 } },
    { sector: 'A', number: 6, position: { left: 329, top: 101 } }
  ]
} as CampData;

export const PiratenlebenLager = {
  id: AT_PIRATENLEBEN_ID,
  mapSize: { width: 823, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 523, top: 321 } },
    { sector: 'A', number: 2, position: { left: 78, top: 282 } },
    { sector: 'A', number: 3, position: { left: 205, top: 125 } },
    { sector: 'A', number: 4, position: { left: 595, top: 110 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 684, top: 138 } }
  ]
} as CampData;

export const RasenderBulleLager = {
  id: AT_RASENDERBULLE_ID,
  mapSize: { width: 849, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 135, top: 364 } },
    { sector: 'A', number: 2, position: { left: 168, top: 321 } },
    { sector: 'A', number: 3, position: { left: 141, top: 273 } },
    { sector: 'A', number: 4, position: { left: 163, top: 236 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 183, top: 255 } },
    { sector: 'B', number: 1, position: { left: 258, top: 248 } },
    { sector: 'B', number: 2, position: { left: 284, top: 295 } },
    { sector: 'B', number: 3, position: { left: 328, top: 346 } },
    { sector: 'B', number: 4, position: { left: 330, top: 393 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 346, top: 420 } },
    { sector: 'C', number: 1, position: { left: 135, top: 82 } },
    { sector: 'C', number: 2, position: { left: 205, top: 79 } },
    { sector: 'C', number: 3, position: { left: 266, top: 72 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 248, top: 98 } },
    { sector: 'C', number: 5, position: { left: 265, top: 124 } },
    { sector: 'D', number: 1, position: { left: 344, top: 163 } },
    { sector: 'D', number: 2, position: { left: 382, top: 107 } },
    { sector: 'D', number: 3, position: { left: 441, top: 125 } },
    { sector: 'D', number: 4, position: { left: 415, top: 88 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 355, top: 75 } },
    { sector: 'E', number: 1, position: { left: 702, top: 118 } },
    { sector: 'E', number: 2, position: { left: 636, top: 117 } },
    { sector: 'E', number: 3, position: { left: 656, top: 169 } },
    { sector: 'E', number: 4, position: { left: 602, top: 160 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 612, top: 188 } },
    { sector: 'E', number: 6, position: { left: 518, top: 118 } },
    { sector: 'F', number: 1, position: { left: 475, top: 218 } },
    { sector: 'F', number: 2, position: { left: 425, top: 209 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 446, top: 230 } },
    { sector: 'F', number: 4, position: { left: 408, top: 242 } },
    { sector: 'G', number: 1, position: { left: 550, top: 288 } },
    { sector: 'G', number: 2, isBoss: true, position: { left: 577, top: 295 } },
    { sector: 'G', number: 3, position: { left: 545, top: 327 } },
    { sector: 'G', number: 4, position: { left: 605, top: 290 } },
    { sector: 'G', number: 5, position: { left: 611, top: 327 } },
    { sector: 'H', number: 1, position: { left: 688, top: 383 } },
    { sector: 'H', number: 2, position: { left: 715, top: 366 } },
    { sector: 'H', number: 3, isBoss: true, position: { left: 727, top: 391 } }
  ]
} as CampData;

export const RettetdasWeihnachtsfest_2013_Lager = {
  id: AT_RETTETDASWEIHNACHTSFEST_2013__ID,
  mapSize: { width: 546, height: 499 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 260, top: 418 } },
    { sector: 'A', number: 2, position: { left: 275, top: 309 } },
    { sector: 'B', number: 1, position: { left: 150, top: 361 } },
    { sector: 'B', number: 2, position: { left: 183, top: 300 } },
    { sector: 'B', number: 3, position: { left: 175, top: 239 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 125, top: 241 } },
    { sector: 'C', number: 1, position: { left: 346, top: 247 } },
    { sector: 'C', number: 2, position: { left: 248, top: 213 } },
    { sector: 'C', number: 3, position: { left: 235, top: 181 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 280, top: 120 } }
  ]
} as CampData;

export const RotkäppchenLager = {
  id: AT_ROTKÄPPCHEN_ID,
  mapSize: { width: 815, height: 490 },
  camps: [
    { sector: 'A', number: 1, position: { left: 123, top: 345 } },
    { sector: 'A', number: 2, position: { left: 152, top: 290 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 188, top: 314 } },
    { sector: 'B', number: 1, position: { left: 93, top: 264 } },
    { sector: 'B', number: 2, position: { left: 14, top: 259 } },
    { sector: 'B', number: 3, position: { left: 65, top: 210 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 13, top: 215 } },
    { sector: 'C', number: 1, position: { left: 285, top: 255 } },
    { sector: 'C', number: 2, position: { left: 241, top: 246 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 263, top: 225 } },
    { sector: 'C', number: 4, position: { left: 102, top: 187 } },
    { sector: 'C', number: 5, position: { left: 123, top: 158 } },
    { sector: 'D', number: 1, position: { left: 79, top: 85 } },
    { sector: 'D', number: 2, position: { left: 161, top: 72 } },
    { sector: 'D', number: 3, position: { left: 211, top: 40 } },
    { sector: 'D', number: 4, position: { left: 269, top: 31 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 234, top: 16 } },
    { sector: 'E', number: 1, position: { left: 314, top: 57 } },
    { sector: 'E', number: 2, position: { left: 298, top: 83 } },
    { sector: 'E', number: 3, position: { left: 357, top: 67 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 351, top: 117 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 241, top: 119 } },
    { sector: 'G', number: 1, position: { left: 523, top: 223 } },
    { sector: 'G', number: 2, position: { left: 530, top: 159 } },
    { sector: 'G', number: 3, isBoss: true, position: { left: 538, top: 134 } },
    { sector: 'H', number: 1, position: { left: 750, top: 183 } },
    { sector: 'H', number: 2, position: { left: 662, top: 165 } },
    { sector: 'H', number: 3, position: { left: 602, top: 95 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 605, top: 67 } },
    { sector: 'I', number: 1, position: { left: 530, top: 307 } },
    { sector: 'I', number: 2, position: { left: 632, top: 305 } },
    { sector: 'I', number: 3, isBoss: true, position: { left: 640, top: 332 } },
    { sector: 'I', number: 4, position: { left: 668, top: 267 } },
    { sector: 'J', number: 1, isBoss: true, position: { left: 685, top: 32 } }
  ]
} as CampData;

export const Räuber_GegenschlagLager = {
  id: AT_RÄUBER_GEGENSCHLAG_ID,
  mapSize: { width: 728, height: 470 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 275, top: 405 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 350, top: 405 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 425, top: 405 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 500, top: 405 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 575, top: 405 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 650, top: 405 } }
  ]
} as CampData;

export const RäuberbandeLager = {
  id: AT_RÄUBERBANDE_ID,
  mapSize: { width: 862, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 273, top: 236 } },
    { sector: 'A', number: 2, position: { left: 285, top: 207 } },
    { sector: 'A', number: 3, position: { left: 215, top: 166 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 189, top: 128 } },
    { sector: 'B', number: 1, position: { left: 135, top: 84 } },
    { sector: 'B', number: 2, position: { left: 153, top: 58 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 123, top: 58 } },
    { sector: 'C', number: 1, position: { left: 402, top: 183 } },
    { sector: 'C', number: 2, position: { left: 431, top: 174 } },
    { sector: 'C', number: 3, position: { left: 410, top: 155 } },
    { sector: 'C', number: 4, position: { left: 462, top: 85 } },
    { sector: 'C', number: 5, position: { left: 438, top: 69 } },
    { sector: 'C', number: 6, position: { left: 485, top: 67 } },
    { sector: 'C', number: 7, isBoss: true, position: { left: 462, top: 58 } },
    { sector: 'D', number: 1, position: { left: 500, top: 261 } },
    { sector: 'D', number: 2, position: { left: 518, top: 290 } },
    { sector: 'D', number: 3, position: { left: 584, top: 344 } },
    { sector: 'D', number: 4, position: { left: 655, top: 381 } },
    { sector: 'D', number: 5, position: { left: 590, top: 389 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 648, top: 244 } },
    { sector: 'E', number: 1, position: { left: 660, top: 162 } },
    { sector: 'E', number: 2, position: { left: 694, top: 157 } },
    { sector: 'E', number: 3, position: { left: 636, top: 67 } },
    { sector: 'E', number: 4, position: { left: 674, top: 67 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 655, top: 37 } }
  ]
} as CampData;

export const RäubernestLager = {
  id: AT_RÄUBERNEST_ID,
  mapSize: { width: 606, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 185, top: 305 } },
    { sector: 'A', number: 2, position: { left: 72, top: 257 } },
    { sector: 'A', number: 3, position: { left: 265, top: 280 } },
    { sector: 'A', number: 4, position: { left: 195, top: 178 } },
    { sector: 'A', number: 5, position: { left: 120, top: 167 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 149, top: 185 } },
    { sector: 'B', number: 1, position: { left: 408, top: 168 } },
    { sector: 'B', number: 2, position: { left: 428, top: 145 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 475, top: 162 } },
    { sector: 'C', number: 1, position: { left: 205, top: 127 } },
    { sector: 'C', number: 2, position: { left: 303, top: 113 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 255, top: 98 } }
  ]
} as CampData;

export const RückkehrinsRäubernestLager = {
  id: AT_RÜCKKEHRINSRÄUBERNEST_ID,
  mapSize: { width: 649, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 285, top: 436 } },
    { sector: 'A', number: 2, position: { left: 285, top: 406 } },
    { sector: 'A', number: 3, position: { left: 60, top: 261 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 42, top: 222 } },
    { sector: 'A', number: 5, position: { left: 160, top: 175 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 397, top: 379 } },
    { sector: 'B', number: 2, position: { left: 203, top: 185 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 194, top: 154 } },
    { sector: 'C', number: 2, position: { left: 212, top: 128 } },
    { sector: 'C', number: 3, position: { left: 405, top: 147 } },
    { sector: 'D', number: 1, position: { left: 455, top: 155 } },
    { sector: 'D', number: 2, position: { left: 497, top: 166 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 345, top: 35 } },
    { sector: 'E', number: 1, position: { left: 355, top: 242 } },
    { sector: 'E', number: 2, position: { left: 405, top: 222 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 565, top: 125 } }
  ]
} as CampData;

export const SattelfestLager = {
  id: AT_SATTELFEST_ID,
  mapSize: { width: 762, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 195, top: 291 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 224, top: 318 } },
    { sector: 'A', number: 3, position: { left: 257, top: 297 } },
    { sector: 'A', number: 4, position: { left: 248, top: 346 } },
    { sector: 'B', number: 1, isBoss: true, position: { left: 259, top: 374 } },
    { sector: 'B', number: 2, position: { left: 355, top: 412 } },
    { sector: 'C', number: 1, position: { left: 454, top: 373 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 455, top: 406 } },
    { sector: 'C', number: 3, position: { left: 543, top: 319 } },
    { sector: 'D', number: 1, position: { left: 275, top: 197 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 206, top: 142 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 295, top: 164 } },
    { sector: 'E', number: 2, position: { left: 382, top: 141 } },
    { sector: 'E', number: 3, position: { left: 462, top: 170 } },
    { sector: 'F', number: 1, position: { left: 347, top: 264 } },
    { sector: 'F', number: 2, position: { left: 355, top: 215 } },
    { sector: 'F', number: 3, position: { left: 445, top: 215 } },
    { sector: 'F', number: 4, position: { left: 500, top: 259 } },
    { sector: 'F', number: 5, position: { left: 456, top: 304 } },
    { sector: 'F', number: 6, isBoss: true, position: { left: 419, top: 264 } }
  ]
} as CampData;

export const SchießpulverLager = {
  id: AT_SCHIESSPULVER_ID,
  mapSize: { width: 819, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 100, top: 341 } },
    { sector: 'A', number: 2, position: { left: 123, top: 320 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 129, top: 292 } },
    { sector: 'B', number: 1, position: { left: 308, top: 374 } },
    { sector: 'B', number: 2, position: { left: 300, top: 335 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 261, top: 310 } },
    { sector: 'C', number: 1, position: { left: 113, top: 129 } },
    { sector: 'C', number: 2, position: { left: 121, top: 165 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 142, top: 187 } },
    { sector: 'C', number: 4, position: { left: 152, top: 218 } },
    { sector: 'D', number: 1, position: { left: 170, top: 266 } },
    { sector: 'D', number: 2, position: { left: 190, top: 225 } },
    { sector: 'D', number: 3, position: { left: 214, top: 259 } },
    { sector: 'D', number: 4, position: { left: 241, top: 246 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 235, top: 220 } },
    { sector: 'E', number: 1, position: { left: 340, top: 220 } },
    { sector: 'E', number: 2, position: { left: 368, top: 237 } },
    { sector: 'E', number: 3, position: { left: 401, top: 232 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 430, top: 218 } },
    { sector: 'F', number: 1, position: { left: 519, top: 202 } },
    { sector: 'F', number: 2, position: { left: 523, top: 165 } },
    { sector: 'F', number: 3, position: { left: 569, top: 211 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 550, top: 181 } },
    { sector: 'G', number: 1, position: { left: 575, top: 265 } },
    { sector: 'G', number: 2, position: { left: 627, top: 243 } },
    { sector: 'G', number: 3, position: { left: 635, top: 285 } },
    { sector: 'G', number: 4, isBoss: true, position: { left: 650, top: 257 } },
    { sector: 'H', number: 1, position: { left: 702, top: 211 } },
    { sector: 'H', number: 2, position: { left: 749, top: 167 } },
    { sector: 'H', number: 3, position: { left: 763, top: 91 } },
    { sector: 'H', number: 4, isBoss: true, position: { left: 730, top: 56 } },
    { sector: 'I', number: 1, position: { left: 690, top: 20 } },
    { sector: 'I', number: 2, position: { left: 599, top: 34 } },
    { sector: 'I', number: 3, position: { left: 520, top: 50 } },
    { sector: 'I', number: 4, isBoss: true, position: { left: 508, top: 78 } },
    { sector: 'J', number: 1, position: { left: 583, top: 95 } },
    { sector: 'J', number: 2, position: { left: 610, top: 77 } },
    { sector: 'J', number: 3, position: { left: 590, top: 125 } },
    { sector: 'J', number: 4, position: { left: 643, top: 90 } },
    { sector: 'J', number: 5, isBoss: true, position: { left: 645, top: 125 } }
  ]
} as CampData;

export const SchlummerndesRiffLager = {
  id: AT_SCHLUMMERNDESRIFF_ID,
  mapSize: { width: 614, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 288, top: 319 } },
    { sector: 'A', number: 2, position: { left: 338, top: 215 } },
    { sector: 'A', number: 3, position: { left: 280, top: 170 } },
    { sector: 'A', number: 4, position: { left: 394, top: 90 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 495, top: 110 } }
  ]
} as CampData;

export const SchneewittchenLager = {
  id: AT_SCHNEEWITTCHEN_ID,
  mapSize: { width: 757, height: 490 },
  camps: [
    { sector: 'A', number: 1, position: { left: 116, top: 327 } },
    { sector: 'A', number: 2, position: { left: 44, top: 285 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 66, top: 267 } },
    { sector: 'B', number: 1, position: { left: 211, top: 280 } },
    { sector: 'B', number: 2, position: { left: 100, top: 230 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 125, top: 170 } },
    { sector: 'C', number: 1, position: { left: 35, top: 197 } },
    { sector: 'C', number: 2, position: { left: 114, top: 121 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 145, top: 50 } },
    { sector: 'D', number: 1, position: { left: 273, top: 59 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 216, top: 122 } },
    { sector: 'E', number: 1, position: { left: 505, top: 95 } },
    { sector: 'E', number: 2, position: { left: 592, top: 76 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 615, top: 55 } },
    { sector: 'E', number: 4, position: { left: 527, top: 188 } },
    { sector: 'E', number: 5, position: { left: 635, top: 129 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 666, top: 122 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 688, top: 44 } }
  ]
} as CampData;

export const Seeufer_SchatzLager = {
  id: AT_SEEUFER_SCHATZ_ID,
  mapSize: { width: 712, height: 490 },
  camps: [
    { sector: 'A', number: 1, position: { left: 62, top: 297 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 92, top: 245 } },
    { sector: 'B', number: 1, position: { left: 222, top: 112 } },
    { sector: 'B', number: 2, position: { left: 203, top: 64 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 270, top: 58 } },
    { sector: 'C', number: 1, position: { left: 370, top: 41 } },
    { sector: 'C', number: 2, position: { left: 386, top: 76 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 416, top: 67 } },
    { sector: 'D', number: 1, position: { left: 370, top: 359 } },
    { sector: 'D', number: 2, isBoss: true, position: { left: 408, top: 325 } },
    { sector: 'E', number: 1, position: { left: 488, top: 308 } },
    { sector: 'E', number: 2, position: { left: 534, top: 293 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 574, top: 220 } },
    { sector: 'F', number: 1, position: { left: 516, top: 190 } },
    { sector: 'F', number: 2, position: { left: 543, top: 138 } },
    { sector: 'F', number: 3, isBoss: true, position: { left: 513, top: 107 } }
  ]
} as CampData;

export const SindbadunddieSeeschlangeLager = {
  id: AT_SINDBADUNDDIESEESCHLANGE_ID,
  mapSize: { width: 808, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 648, top: 395 } },
    { sector: 'A', number: 2, position: { left: 641, top: 423 } },
    { sector: 'A', number: 3, position: { left: 607, top: 432 } },
    { sector: 'A', number: 4, position: { left: 591, top: 393 } },
    { sector: 'A', number: 5, position: { left: 552, top: 445 } },
    { sector: 'A', number: 6, position: { left: 509, top: 431 } },
    { sector: 'A', number: 7, isBoss: true, position: { left: 482, top: 424 } },
    { sector: 'A', number: 8, position: { left: 501, top: 387 } },
    { sector: 'A', number: 9, position: { left: 444, top: 359 } },
    { sector: 'A', number: 10, position: { left: 502, top: 352 } },
    { sector: 'A', number: 11, position: { left: 525, top: 325 } },
    { sector: 'A', number: 12, position: { left: 480, top: 324 } },
    {
      sector: 'A',
      number: 13,
      isBoss: true,
      position: { left: 502, top: 307 }
    },
    { sector: 'B', number: 1, position: { left: 467, top: 283 } },
    { sector: 'B', number: 2, position: { left: 495, top: 271 } },
    { sector: 'B', number: 3, position: { left: 608, top: 314 } },
    { sector: 'B', number: 4, position: { left: 589, top: 346 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 616, top: 352 } },
    { sector: 'B', number: 6, position: { left: 653, top: 288 } },
    { sector: 'B', number: 7, position: { left: 639, top: 263 } },
    { sector: 'B', number: 8, position: { left: 667, top: 263 } },
    { sector: 'B', number: 9, position: { left: 704, top: 199 } },
    { sector: 'B', number: 10, position: { left: 692, top: 137 } },
    { sector: 'B', number: 11, position: { left: 648, top: 82 } },
    { sector: 'B', number: 12, position: { left: 670, top: 65 } },
    { sector: 'B', number: 13, isBoss: true, position: { left: 704, top: 82 } },
    { sector: 'C', number: 1, position: { left: 590, top: 45 } },
    { sector: 'C', number: 2, position: { left: 567, top: 68 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 566, top: 30 } },
    { sector: 'C', number: 4, position: { left: 529, top: 58 } },
    { sector: 'C', number: 5, position: { left: 453, top: 41 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 437, top: 18 } },
    { sector: 'D', number: 1, position: { left: 78, top: 195 } },
    { sector: 'D', number: 2, position: { left: 50, top: 227 } },
    { sector: 'D', number: 3, position: { left: 73, top: 241 } },
    { sector: 'D', number: 4, position: { left: 117, top: 185 } },
    { sector: 'D', number: 5, position: { left: 140, top: 168 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 115, top: 156 } },
    { sector: 'D', number: 7, position: { left: 163, top: 144 } },
    { sector: 'D', number: 8, position: { left: 179, top: 92 } },
    { sector: 'D', number: 9, isBoss: true, position: { left: 206, top: 98 } },
    { sector: 'D', number: 10, position: { left: 225, top: 50 } },
    { sector: 'D', number: 11, isBoss: true, position: { left: 248, top: 29 } },
    { sector: 'E', number: 1, position: { left: 287, top: 54 } },
    { sector: 'E', number: 2, position: { left: 326, top: 15 } },
    { sector: 'E', number: 3, position: { left: 383, top: 27 } },
    { sector: 'E', number: 4, isBoss: true, position: { left: 383, top: 3 } },
    { sector: 'E', number: 5, position: { left: 254, top: 81 } },
    { sector: 'E', number: 6, isBoss: true, position: { left: 242, top: 119 } },
    { sector: 'E', number: 7, position: { left: 225, top: 142 } },
    { sector: 'E', number: 8, isBoss: true, position: { left: 214, top: 224 } },
    { sector: 'F', number: 1, position: { left: 249, top: 224 } },
    { sector: 'F', number: 2, position: { left: 299, top: 283 } },
    { sector: 'F', number: 3, position: { left: 239, top: 304 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 221, top: 282 } },
    { sector: 'F', number: 5, isBoss: true, position: { left: 406, top: 273 } },
    { sector: 'F', number: 6, position: { left: 416, top: 238 } },
    { sector: 'F', number: 7, position: { left: 448, top: 165 } },
    { sector: 'F', number: 8, position: { left: 372, top: 140 } },
    { sector: 'F', number: 9, position: { left: 428, top: 130 } },
    {
      sector: 'F',
      number: 10,
      isBoss: true,
      position: { left: 421, top: 157 }
    }
  ]
} as CampData;

export const SindbadunddiebelagerteStadtLager = {
  id: AT_SINDBADUNDDIEBELAGERTESTADT_ID,
  mapSize: { width: 885, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 67, top: 198 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 145, top: 125 } },
    { sector: 'A', number: 3, position: { left: 248, top: 54 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 288, top: 38 } },
    { sector: 'A', number: 5, position: { left: 280, top: 122 } },
    { sector: 'A', number: 6, position: { left: 254, top: 243 } },
    { sector: 'A', number: 7, position: { left: 244, top: 196 } },
    { sector: 'A', number: 8, isBoss: true, position: { left: 266, top: 207 } },
    { sector: 'B', number: 1, position: { left: 107, top: 244 } },
    { sector: 'B', number: 2, position: { left: 90, top: 265 } },
    { sector: 'B', number: 3, position: { left: 137, top: 297 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 165, top: 297 } },
    { sector: 'B', number: 5, position: { left: 118, top: 342 } },
    { sector: 'B', number: 6, position: { left: 169, top: 383 } },
    { sector: 'B', number: 7, position: { left: 228, top: 363 } },
    { sector: 'B', number: 8, position: { left: 312, top: 325 } },
    { sector: 'B', number: 9, position: { left: 327, top: 345 } },
    {
      sector: 'B',
      number: 10,
      isBoss: true,
      position: { left: 340, top: 325 }
    },
    {
      sector: 'B',
      number: 11,
      isBoss: true,
      position: { left: 425, top: 330 }
    },
    { sector: 'C', number: 1, position: { left: 594, top: 372 } },
    { sector: 'C', number: 2, position: { left: 626, top: 367 } },
    { sector: 'C', number: 3, position: { left: 612, top: 343 } },
    { sector: 'C', number: 4, position: { left: 639, top: 344 } },
    { sector: 'C', number: 5, position: { left: 613, top: 316 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 535, top: 326 } },
    { sector: 'C', number: 7, position: { left: 718, top: 342 } },
    { sector: 'C', number: 8, position: { left: 718, top: 311 } },
    { sector: 'C', number: 9, isBoss: true, position: { left: 745, top: 351 } },
    { sector: 'C', number: 10, position: { left: 769, top: 278 } },
    { sector: 'C', number: 11, position: { left: 796, top: 247 } },
    {
      sector: 'C',
      number: 12,
      isBoss: true,
      position: { left: 817, top: 232 }
    },
    { sector: 'C', number: 13, position: { left: 821, top: 199 } },
    { sector: 'D', number: 1, position: { left: 373, top: 316 } },
    { sector: 'D', number: 2, position: { left: 353, top: 295 } },
    { sector: 'D', number: 3, position: { left: 215, top: 298 } },
    { sector: 'D', number: 4, position: { left: 160, top: 258 } },
    { sector: 'D', number: 5, isBoss: true, position: { left: 187, top: 254 } },
    { sector: 'D', number: 6, position: { left: 406, top: 264 } },
    { sector: 'D', number: 7, isBoss: true, position: { left: 406, top: 242 } },
    { sector: 'D', number: 8, position: { left: 476, top: 259 } },
    { sector: 'D', number: 9, isBoss: true, position: { left: 549, top: 301 } },
    { sector: 'D', number: 10, position: { left: 581, top: 227 } },
    {
      sector: 'D',
      number: 11,
      isBoss: true,
      position: { left: 679, top: 215 }
    },
    { sector: 'E', number: 1, isBoss: true, position: { left: 810, top: 77 } },
    { sector: 'E', number: 2, position: { left: 710, top: 90 } },
    { sector: 'E', number: 3, position: { left: 684, top: 66 } },
    { sector: 'E', number: 4, position: { left: 645, top: 59 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 667, top: 43 } },
    { sector: 'E', number: 6, position: { left: 495, top: 14 } },
    { sector: 'E', number: 7, position: { left: 736, top: 187 } },
    { sector: 'E', number: 8, isBoss: true, position: { left: 614, top: 182 } },
    { sector: 'F', number: 1, position: { left: 404, top: 198 } },
    { sector: 'F', number: 2, position: { left: 469, top: 190 } },
    { sector: 'F', number: 3, position: { left: 533, top: 207 } },
    { sector: 'F', number: 4, isBoss: true, position: { left: 557, top: 200 } },
    { sector: 'F', number: 5, position: { left: 339, top: 118 } },
    { sector: 'F', number: 6, position: { left: 378, top: 79 } },
    { sector: 'F', number: 7, position: { left: 561, top: 127 } },
    { sector: 'F', number: 8, position: { left: 509, top: 94 } },
    { sector: 'F', number: 9, position: { left: 461, top: 50 } },
    { sector: 'F', number: 10, isBoss: true, position: { left: 486, top: 54 } },
    { sector: 'F', number: 11, position: { left: 503, top: 144 } },
    { sector: 'F', number: 12, position: { left: 470, top: 124 } },
    { sector: 'F', number: 13, position: { left: 437, top: 135 } },
    { sector: 'F', number: 14, position: { left: 399, top: 127 } },
    { sector: 'F', number: 15, isBoss: true, position: { left: 426, top: 86 } }
  ]
} as CampData;

export const SumpfhexeLager = {
  id: AT_SUMPFHEXE_ID,
  mapSize: { width: 704, height: 490 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 453, top: 321 } },
    { sector: 'B', number: 1, position: { left: 348, top: 393 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 349, top: 430 } },
    { sector: 'C', number: 1, position: { left: 76, top: 399 } },
    { sector: 'C', number: 2, position: { left: 132, top: 285 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 134, top: 239 } },
    { sector: 'D', number: 1, position: { left: 160, top: 198 } },
    { sector: 'D', number: 2, position: { left: 245, top: 147 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 237, top: 210 } },
    { sector: 'E', number: 1, isBoss: true, position: { left: 300, top: 292 } }
  ]
} as CampData;

export const SöhnederSteppeLager = {
  id: AT_SÖHNEDERSTEPPE_ID,
  mapSize: { width: 828, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 190, top: 357 } },
    { sector: 'A', number: 2, isBoss: true, position: { left: 252, top: 305 } },
    { sector: 'A', number: 3, position: { left: 242, top: 333 } },
    { sector: 'A', number: 4, position: { left: 255, top: 389 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 255, top: 414 } },
    { sector: 'A', number: 6, position: { left: 314, top: 305 } },
    { sector: 'A', number: 7, position: { left: 320, top: 356 } },
    { sector: 'B', number: 1, position: { left: 190, top: 92 } },
    { sector: 'B', number: 2, position: { left: 215, top: 156 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 235, top: 81 } },
    { sector: 'B', number: 4, position: { left: 268, top: 124 } },
    { sector: 'B', number: 5, position: { left: 288, top: 80 } },
    { sector: 'B', number: 6, isBoss: true, position: { left: 307, top: 142 } },
    { sector: 'B', number: 7, position: { left: 267, top: 204 } },
    { sector: 'B', number: 8, isBoss: true, position: { left: 197, top: 192 } },
    { sector: 'C', number: 1, position: { left: 366, top: 288 } },
    { sector: 'C', number: 2, position: { left: 385, top: 333 } },
    { sector: 'C', number: 3, position: { left: 365, top: 410 } },
    { sector: 'C', number: 4, position: { left: 431, top: 361 } },
    { sector: 'C', number: 5, isBoss: true, position: { left: 431, top: 410 } },
    { sector: 'C', number: 6, position: { left: 497, top: 377 } },
    { sector: 'C', number: 7, position: { left: 483, top: 322 } },
    { sector: 'C', number: 8, isBoss: true, position: { left: 562, top: 362 } },
    { sector: 'C', number: 9, isBoss: true, position: { left: 511, top: 281 } },
    { sector: 'D', number: 1, position: { left: 353, top: 79 } },
    { sector: 'D', number: 2, position: { left: 352, top: 135 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 412, top: 84 } },
    { sector: 'D', number: 4, position: { left: 424, top: 124 } },
    { sector: 'D', number: 5, position: { left: 437, top: 188 } },
    { sector: 'D', number: 6, isBoss: true, position: { left: 457, top: 226 } },
    { sector: 'D', number: 7, position: { left: 470, top: 152 } },
    { sector: 'D', number: 8, position: { left: 516, top: 133 } },
    { sector: 'D', number: 9, isBoss: true, position: { left: 575, top: 169 } },
    { sector: 'E', number: 1, position: { left: 209, top: 273 } },
    { sector: 'E', number: 2, position: { left: 190, top: 245 } },
    { sector: 'E', number: 3, position: { left: 131, top: 277 } },
    { sector: 'E', number: 4, position: { left: 131, top: 252 } },
    { sector: 'E', number: 5, isBoss: true, position: { left: 106, top: 258 } },
    { sector: 'F', number: 1, position: { left: 683, top: 277 } },
    { sector: 'F', number: 2, position: { left: 693, top: 249 } },
    { sector: 'F', number: 3, position: { left: 696, top: 308 } },
    { sector: 'F', number: 4, position: { left: 719, top: 293 } },
    { sector: 'F', number: 5, position: { left: 715, top: 265 } },
    { sector: 'F', number: 6, isBoss: true, position: { left: 741, top: 277 } }
  ]
} as CampData;

export const Tikki_InselLager = {
  id: AT_TIKKI_INSEL_ID,
  mapSize: { width: 685, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 376, top: 314 } },
    { sector: 'A', number: 2, position: { left: 436, top: 274 } },
    { sector: 'A', number: 3, position: { left: 333, top: 212 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 315, top: 185 } },
    { sector: 'B', number: 1, position: { left: 447, top: 113 } },
    { sector: 'B', number: 2, isBoss: true, position: { left: 483, top: 106 } }
  ]
} as CampData;

export const TropensonneLager = {
  id: AT_TROPENSONNE_ID,
  mapSize: { width: 897, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 636, top: 390 } },
    { sector: 'A', number: 2, position: { left: 381, top: 454 } },
    { sector: 'A', number: 3, position: { left: 305, top: 390 } },
    { sector: 'A', number: 4, position: { left: 475, top: 280 } },
    { sector: 'A', number: 5, position: { left: 467, top: 145 } },
    { sector: 'B', number: 1, position: { left: 381, top: 115 } },
    { sector: 'B', number: 2, position: { left: 285, top: 175 } },
    { sector: 'B', number: 3, position: { left: 287, top: 58 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 155, top: 127 } }
  ]
} as CampData;

export const UnbekannteRegionenLager = {
  id: AT_UNBEKANNTEREGIONEN_ID,
  mapSize: { width: 730, height: 500 },
  camps: [
    { sector: 'A', number: 1, isBoss: true, position: { left: 298, top: 212 } },
    { sector: 'B', number: 1, position: { left: 253, top: 183 } },
    { sector: 'B', number: 2, position: { left: 194, top: 210 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 234, top: 205 } },
    { sector: 'C', number: 1, isBoss: true, position: { left: 237, top: 126 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 420, top: 208 } },
    { sector: 'E', number: 1, position: { left: 504, top: 201 } },
    { sector: 'E', number: 2, isBoss: true, position: { left: 545, top: 184 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 610, top: 205 } },
    { sector: 'G', number: 1, isBoss: true, position: { left: 513, top: 85 } },
    { sector: 'H', number: 1, isBoss: true, position: { left: 420, top: 306 } },
    { sector: 'I', number: 1, position: { left: 479, top: 296 } },
    { sector: 'I', number: 2, position: { left: 545, top: 258 } },
    { sector: 'I', number: 3, isBoss: true, position: { left: 521, top: 280 } },
    { sector: 'J', number: 1, position: { left: 520, top: 351 } },
    { sector: 'J', number: 2, position: { left: 569, top: 310 } },
    { sector: 'J', number: 3, isBoss: true, position: { left: 562, top: 345 } },
    { sector: 'K', number: 1, isBoss: true, position: { left: 561, top: 415 } },
    { sector: 'L', number: 1, isBoss: true, position: { left: 258, top: 308 } },
    { sector: 'M', number: 1, position: { left: 212, top: 342 } },
    { sector: 'M', number: 2, position: { left: 191, top: 290 } },
    { sector: 'M', number: 3, isBoss: true, position: { left: 208, top: 315 } },
    { sector: 'N', number: 1, position: { left: 131, top: 291 } },
    { sector: 'N', number: 2, position: { left: 186, top: 256 } },
    { sector: 'N', number: 3, position: { left: 132, top: 228 } },
    { sector: 'N', number: 4, isBoss: true, position: { left: 131, top: 260 } },
    { sector: 'O', number: 1, isBoss: true, position: { left: 34, top: 271 } },
    { sector: 'Z', number: 1, position: { left: 348, top: 156 } },
    { sector: 'Z', number: 2, position: { left: 373, top: 131 } },
    { sector: 'Z', number: 3, position: { left: 388, top: 80 } },
    { sector: 'Z', number: 4, position: { left: 356, top: 60 } },
    { sector: 'Z', number: 5, isBoss: true, position: { left: 438, top: 19 } }
  ]
} as CampData;

export const VerräterLager = {
  id: AT_VERRÄTER_ID,
  mapSize: { width: 614, height: 490 },
  camps: [
    { sector: 'A', number: 1, position: { left: 63, top: 303 } },
    { sector: 'A', number: 2, position: { left: 70, top: 227 } },
    { sector: 'A', number: 3, position: { left: 106, top: 149 } },
    { sector: 'A', number: 4, isBoss: true, position: { left: 160, top: 97 } },
    { sector: 'B', number: 1, position: { left: 235, top: 273 } },
    { sector: 'B', number: 2, position: { left: 262, top: 337 } },
    { sector: 'B', number: 3, position: { left: 316, top: 377 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 398, top: 356 } },
    { sector: 'C', number: 1, position: { left: 427, top: 273 } },
    { sector: 'C', number: 2, position: { left: 488, top: 292 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 465, top: 267 } }
  ]
} as CampData;

export const ViktorderVerschlageneLager = {
  id: AT_VIKTORDERVERSCHLAGENE_ID,
  mapSize: { width: 842, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 89, top: 365 } },
    { sector: 'A', number: 2, position: { left: 146, top: 315 } },
    { sector: 'A', number: 3, position: { left: 202, top: 257 } },
    { sector: 'A', number: 4, position: { left: 246, top: 315 } },
    { sector: 'A', number: 5, position: { left: 320, top: 365 } },
    { sector: 'A', number: 6, isBoss: true, position: { left: 383, top: 338 } },
    { sector: 'B', number: 1, position: { left: 396, top: 424 } },
    { sector: 'B', number: 2, position: { left: 435, top: 401 } },
    { sector: 'B', number: 3, position: { left: 491, top: 397 } },
    { sector: 'B', number: 4, position: { left: 510, top: 370 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 484, top: 337 } },
    { sector: 'C', number: 1, position: { left: 95, top: 136 } },
    { sector: 'C', number: 2, position: { left: 114, top: 78 } },
    { sector: 'C', number: 3, position: { left: 241, top: 70 } },
    { sector: 'C', number: 4, position: { left: 358, top: 121 } },
    { sector: 'C', number: 5, position: { left: 372, top: 180 } },
    { sector: 'C', number: 6, isBoss: true, position: { left: 402, top: 201 } },
    { sector: 'D', number: 1, position: { left: 446, top: 291 } },
    { sector: 'D', number: 2, position: { left: 470, top: 264 } },
    { sector: 'D', number: 3, isBoss: true, position: { left: 480, top: 291 } },
    { sector: 'E', number: 1, position: { left: 686, top: 331 } },
    { sector: 'E', number: 2, position: { left: 710, top: 310 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 726, top: 336 } },
    { sector: 'F', number: 1, position: { left: 645, top: 250 } },
    { sector: 'F', number: 2, position: { left: 665, top: 217 } },
    { sector: 'F', number: 3, position: { left: 586, top: 190 } },
    { sector: 'F', number: 4, position: { left: 542, top: 187 } },
    { sector: 'F', number: 5, isBoss: true, position: { left: 599, top: 158 } },
    { sector: 'F', number: 6, position: { left: 698, top: 153 } },
    { sector: 'G', number: 1, position: { left: 718, top: 98 } },
    { sector: 'G', number: 2, position: { left: 687, top: 59 } },
    { sector: 'G', number: 3, position: { left: 727, top: 68 } },
    { sector: 'G', number: 4, isBoss: true, position: { left: 757, top: 70 } }
  ]
} as CampData;

export const VomFischerundseinerFrauLager = {
  id: AT_VOMFISCHERUNDSEINERFRAU_ID,
  mapSize: { width: 753, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 59, top: 324 } },
    { sector: 'A', number: 2, position: { left: 124, top: 291 } },
    { sector: 'A', number: 3, position: { left: 178, top: 283 } },
    { sector: 'A', number: 4, position: { left: 224, top: 329 } },
    { sector: 'A', number: 5, position: { left: 80, top: 254 } },
    { sector: 'A', number: 6, position: { left: 48, top: 235 } },
    { sector: 'A', number: 7, position: { left: 91, top: 223 } },
    { sector: 'A', number: 8, isBoss: true, position: { left: 67, top: 215 } },
    { sector: 'B', number: 1, position: { left: 65, top: 155 } },
    { sector: 'B', number: 2, position: { left: 131, top: 157 } },
    { sector: 'B', number: 3, position: { left: 118, top: 109 } },
    { sector: 'B', number: 4, position: { left: 191, top: 88 } },
    { sector: 'B', number: 5, isBoss: true, position: { left: 251, top: 50 } },
    { sector: 'B', number: 6, position: { left: 218, top: 145 } },
    { sector: 'B', number: 7, isBoss: true, position: { left: 244, top: 185 } },
    { sector: 'C', number: 1, position: { left: 329, top: 173 } },
    { sector: 'C', number: 2, position: { left: 382, top: 149 } },
    { sector: 'C', number: 3, position: { left: 342, top: 100 } },
    { sector: 'C', number: 4, isBoss: true, position: { left: 364, top: 72 } },
    { sector: 'D', number: 1, position: { left: 560, top: 225 } },
    { sector: 'D', number: 2, position: { left: 611, top: 214 } },
    { sector: 'D', number: 3, position: { left: 655, top: 223 } },
    { sector: 'D', number: 4, isBoss: true, position: { left: 629, top: 238 } },
    { sector: 'E', number: 1, position: { left: 659, top: 116 } },
    { sector: 'E', number: 2, position: { left: 597, top: 107 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 615, top: 85 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 665, top: 25 } }
  ]
} as CampData;

export const VonLiedernundFlüchenLager = {
  id: AT_VONLIEDERNUNDFLÜCHEN_ID,
  mapSize: { width: 633, height: 500 },
  camps: [
    { sector: 'A', number: 1, position: { left: 157, top: 236 } },
    { sector: 'A', number: 2, position: { left: 177, top: 277 } },
    { sector: 'A', number: 3, isBoss: true, position: { left: 243, top: 293 } },
    { sector: 'B', number: 1, position: { left: 201, top: 328 } },
    { sector: 'B', number: 2, position: { left: 188, top: 377 } },
    { sector: 'B', number: 3, isBoss: true, position: { left: 142, top: 395 } },
    { sector: 'C', number: 1, position: { left: 425, top: 401 } },
    { sector: 'C', number: 2, position: { left: 464, top: 407 } },
    { sector: 'C', number: 3, isBoss: true, position: { left: 514, top: 426 } },
    { sector: 'D', number: 1, isBoss: true, position: { left: 481, top: 144 } },
    { sector: 'E', number: 1, position: { left: 356, top: 74 } },
    { sector: 'E', number: 2, position: { left: 417, top: 69 } },
    { sector: 'E', number: 3, isBoss: true, position: { left: 452, top: 89 } },
    { sector: 'F', number: 1, isBoss: true, position: { left: 458, top: 25 } }
  ]
} as CampData;

export const WertvolleInformationenLager = {
  id: AT_WERTVOLLEINFORMATIONEN_ID,
  mapSize: { width: 685, height: 499 },
  camps: [
    { sector: 'A', number: 1, position: { left: 550, top: 155 } },
    { sector: 'A', number: 2, position: { left: 495, top: 140 } },
    { sector: 'A', number: 3, position: { left: 464, top: 228 } },
    { sector: 'A', number: 4, position: { left: 375, top: 321 } },
    { sector: 'A', number: 5, isBoss: true, position: { left: 240, top: 297 } },
    { sector: 'B', number: 1, position: { left: 240, top: 247 } },
    { sector: 'B', number: 2, position: { left: 232, top: 214 } },
    { sector: 'B', number: 3, position: { left: 320, top: 238 } },
    { sector: 'B', number: 4, isBoss: true, position: { left: 369, top: 218 } },
    { sector: 'C', number: 1, position: { left: 400, top: 168 } },
    { sector: 'C', number: 2, isBoss: true, position: { left: 300, top: 156 } },
    { sector: 'D', number: 1, position: { left: 320, top: 41 } },
    { sector: 'D', number: 2, position: { left: 350, top: 23 } }
  ]
} as CampData;

export const WinterWunderland10Lager = {
  id: AT_WINTERWUNDERLAND10_ID,
  mapSize: { width: 683, height: 443 },
  camps: [
    { sector: 'A', number: 1, position: { left: 260, top: 269 } },
    { sector: 'A', number: 2, position: { left: 485, top: 185 } },
    { sector: 'A', number: 3, position: { left: 355, top: 135 } }
  ]
} as CampData;

export const WinterWunderland4Lager = {
  id: AT_WINTERWUNDERLAND4_ID,
  mapSize: { width: 683, height: 443 },
  camps: [
    { sector: 'A', number: 1, position: { left: 260, top: 269 } },
    { sector: 'A', number: 2, position: { left: 485, top: 185 } },
    { sector: 'A', number: 3, position: { left: 355, top: 135 } }
  ]
} as CampData;

export const WinterWunderland7Lager = {
  id: AT_WINTERWUNDERLAND7_ID,
  mapSize: { width: 683, height: 443 },
  camps: [
    { sector: 'A', number: 1, position: { left: 260, top: 269 } },
    { sector: 'A', number: 2, position: { left: 485, top: 185 } },
    { sector: 'A', number: 3, position: { left: 355, top: 135 } }
  ]
} as CampData;

export function getAllCampDetails() {
  return [
    AladinunddieschönePrinzessinLager,
    AladinunddieÖllampeLager,
    AliBabaderjungeHolzfällerLager,
    AliBabaundderSchatzderWeisheitLager,
    AliBabaundderSchatzdesWissensLager,
    AliBabaundderdritteDiebLager,
    AliBabaundderersteDiebLager,
    AliBabaundderzweiteDiebLager,
    AlteBekannteLager,
    AlteRuinenLager,
    AmFußedesBergesLager,
    AngriffderNordmännerLager,
    ArchipelLager,
    ArktischeExplosionLager,
    BerglabyrinthLager,
    BeutelschneiderLager,
    BonabertiBusinessLager,
    DasBergvolkLager,
    DasEndederWeltLager,
    DasQuartierdesDrachenLager,
    DasbetrogeneSchneiderleinLager,
    DasdunkleTorLager,
    DasheldenhafteSchneiderleinLager,
    DasklugeSchneiderleinLager,
    DastapfereSchneiderleinLager,
    DerChupacabraLager,
    DerRattenfängervonHamelnLager,
    DerSchamaneLager,
    DerWirbelwindLager,
    DergestohleneSchlittenLager,
    DerschlafendeVulkanLager,
    DerverscholleneSchädelLager,
    DerÜberraschungsangriffLager,
    DieBelagerungLager,
    DieDunkleBruderschaftLager,
    DieFreibeuter_RazziaLager,
    DieGeteilteStadtImHerbstLager,
    DieGeteilteStadtImSommerLager,
    DieInselderFreibeuterLager,
    DieInvasionderNordmännerLager,
    DieNordmännerLager,
    DieSchwarzenPriesterLager,
    DieSchwarzenRitterLager,
    DieStörenfriedeLager,
    DieSöhnedesSchneiderleinsLager,
    DieWildeWaltrautLager,
    DieverloreneStadtLager,
    DrohendeGefahrLager,
    EinRiesenkampfLager,
    EinenSchrittvorausLager,
    EinsameExperimenteLager,
    GarrunderFallenstellerLager,
    GefängnisinselLager,
    GrabräuberLager,
    HeimatinselLager,
    HänselundGretelLager,
    JagdaufdieJägerLager,
    KopfgeldjägerLager,
    MehreinsameExperimenteLager,
    MonsterausdemNebelLager,
    NebligerKuchen1Lager,
    NebligerKuchen2Lager,
    NebligerKuchen3Lager,
    MutterliebeLager,
    PiratenlebenLager,
    RasenderBulleLager,
    RettetdasWeihnachtsfest_2013_Lager,
    RotkäppchenLager,
    Räuber_GegenschlagLager,
    RäuberbandeLager,
    RäubernestLager,
    RückkehrinsRäubernestLager,
    SattelfestLager,
    SchießpulverLager,
    SchlummerndesRiffLager,
    SchneewittchenLager,
    Seeufer_SchatzLager,
    SindbadunddieSeeschlangeLager,
    SindbadunddiebelagerteStadtLager,
    SumpfhexeLager,
    SöhnederSteppeLager,
    Tikki_InselLager,
    TropensonneLager,
    UnbekannteRegionenLager,
    VerräterLager,
    ViktorderVerschlageneLager,
    VomFischerundseinerFrauLager,
    VonLiedernundFlüchenLager,
    WertvolleInformationenLager,
    WinterWunderland10Lager,
    WinterWunderland4Lager,
    WinterWunderland7Lager
  ];
}

export function getCampDetailsById(id: AdventureId) {
  return getAllCampDetails().find(at => at.id === id);
}
