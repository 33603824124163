// Weater station
export const TOGGLE_NEBEL = 'toogle.nebel';
export const TOGGLE_WIRBELSTURM = 'toogle.wirbelsturm';
export const TOGGLE_SONNENSCHEIN = 'toogle.sonnenschein';
export const TOGGLE_FROST = 'toogle.frost';

// Mountain clan buffs
export const TOGGLE_BK_COLD = 'toogle.bk.cold';
export const TOGGLE_BK_ICE = 'toogle.bk.ice';
export const TOGGLE_BK_STORM = 'toogle.bk.storm';
export const TOGGLE_BK_SNOW = 'toogle.bk.snow';
export const TOGGLE_BK_ARMORY = 'toogle.bk.armory';
export const TOGGLE_BK_HEADQUARTER = 'toogle.bk.headqurter';
export const TOGGLE_BK_RECOVERY = 'toogle.bk.recovery';
export const TOGGLE_BK_EP = 'toogle.bk.ep';

// Lost city buffs
export const TOGGLE_FIRE = 'toogle.city.fire';
export const TOGGLE_WIND = 'toogle.city.wind';
export const TOGGLE_STONE = 'toogle.city.stone';
export const TOGGLE_WATER = 'toogle.city.water';
export const TOGGLE_METAL = 'toogle.city.metal';
export const TOGGLE_NATURE = 'toogle.city.nature';

// Lazarett
export const TOGGLE_LAZARETT = 'toogle.lazarett.buff';

// Lazarett
export const TOGGLE_KANTINE = 'toogle.kantine.buff';

// Adventure
export const CHANGE_ADVENTURE = 'change.adventure';
export const CHANGE_CAMP = 'change.camp';
export const ABORT_CHANGE_CAMP = 'change.camp.abort';

// Units
export const CHANGE_GENERAL = 'change.general';
export const SHOW_CUSTOM_GENERALS = 'show.custom.generals';
export const TOGGLE_SHOW_ELITE_UNITS = 'toggle.elite-units';
export const UPDATE_SELECTED_UNITS = 'update.selected.units';
export const UPDATE_SELECTED_ENEMIES = 'update.selected.enemies';
export const CHANGE_WAVE = 'change.wave';
export const CHANGE_ENEMY_WAVE = 'change.enemy.wave';
export const DELETE_WAVE = 'delete.wave';
export const DELETE_ENEMY_WAVE = 'delete.enemy.wave';

export const CHANGE_SKILLS = 'change.skills';
