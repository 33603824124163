import { AttackSearchResult, DetailedResult } from "../../../app/store";
import { AttackSearchSingleReport } from "../../../Finder/attack-utils";

export function getBattleTimeFromResult(
  result: DetailedResult | AttackSearchSingleReport | AttackSearchResult
) {
  if ("details" in result) {
    return { rounds: [0], battleRounds: [0], abriss: [0] };
  }
  const losses = "losses" in result ? result.losses : result.lossesMap;

  const rounds = Object.keys(losses.rounds.details)
    .map((key) => parseInt(key))
    .sort((a, b) => a - b);

  const battleRounds = Object.keys(losses.battleRounds.details)
    .map((key) => parseInt(key))
    .sort((a, b) => a - b);

  const abriss = Object.keys(losses.abriss.details)
    .map((key) => parseInt(key))
    .sort((a, b) => a - b);

  return { rounds, battleRounds, abriss };
}
