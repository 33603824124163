import {
  UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID,
  UNIT_WILDEROCHSE_ROX_ID,
  UNIT_RIESENLUCHS_GL_ID,
  UNIT_CHUCK_CK_ID,
  UNIT_METALLGEBISS_MG_ID,
  UNIT_PLÜNDERER_PL_ID,
  UNIT_SCHLÄGER_SL_ID,
  UNIT_WACHHUND_WH_ID,
  UNIT_STEINWERFER_SW_ID,
  UNIT_WALDLÄUFER_WL_ID,
  UNIT_KRÄHENFÜSSE_KF_ID,
  UNIT_DESERTIERTERREKRUT_DR_ID,
  UNIT_DESERTIERTEMILIZ_DM_ID,
  UNIT_DESERTIERTEREITEREI_DC_ID,
  UNIT_DESERTIERTERSOLDAT_DS_ID,
  UNIT_DESERTIERTERELITESOLDAT_DE_ID,
  UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID,
  UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID,
  UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID,
  UNIT_DESERTIERTERKANONIER_DK_ID,
  UNIT_KULTIST_KT_ID,
  UNIT_SCHATTENSCHLEICHER_SS_ID,
  UNIT_FANATIKER_FA_ID,
  UNIT_DUNKLERPRIESTER_DP_ID,
  UNIT_FEUERTÄNZER_FT_ID,
  UNIT_NOMADE_NO_ID,
  UNIT_LANZENREITER_LR_ID,
  UNIT_BERITTENERBOGENSCHÜTZE_BB_ID,
  UNIT_BERITTENEAMAZONE_BA_ID,
  UNIT_KATAPHRAKT_KP_ID,
  UNIT_BRÜLLENDERSTIER_BS_ID,
  UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID,
  UNIT_JOMSWIKINGER_JW_ID,
  UNIT_HUSKARL_HK_ID,
  UNIT_KARL_KAR_ID,
  UNIT_THRALL_TH_ID,
  UNIT_WALKÜRE_WK_ID,
  UNIT_BERSERKER_BR_ID,
  UNIT_GRAUERWOLF_W_ID,
  UNIT_STAMMESANGEHÖRIGER_SA_ID,
  UNIT_SCHAMANE_SCH_ID,
  UNIT_JAGUAR_KRIEGER_JK_ID,
  UNIT_WILDSCHWEIN_WS_ID,
  UNIT_BÄR_BÄR_ID,
  UNIT_WOLF_WO_ID,
  UNIT_ALPHAWOLF_AW_ID,
  UNIT_FUCHS_FU_ID,
  UNIT_RIESE_RI_ID,
  UNIT_KÖNIGLICHERREKRUT_KR_ID,
  UNIT_KÖNIGLICHEMILIZ_KM_ID,
  UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID,
  UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID,
  UNIT_KÖNIGLICHEREITEREI_KC_ID,
  UNIT_KÖNIGLICHERKANONIER_KK_ID,
  UNIT_KLINGENRASSLER_KRA_ID,
  UNIT_SÄBELSCHWINGER_SÄS_ID,
  UNIT_SCHWERTMEISTER_SM_ID,
  UNIT_REITER_HM_ID,
  UNIT_DÜNENSCHÜTZE_DÜS_ID,
  UNIT_WÜSTENSCHÜTZE_WÜS_ID,
  UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID,
  UNIT_STEINKANONE_STK_ID,
  UNIT_MATROSE_MTR_ID,
  UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID,
  UNIT_BERITTENERSEEFAHRER_BSF_ID,
  UNIT_BOOTSMANN_BM_ID,
  UNIT_OBERMAAT_OM_ID,
  UNIT_KANONIERMEISTER_KME_ID,
  UNIT_STOCKSCHWINGENDERAFFE_SSA_ID,
  UNIT_STEINWERFENDERAFFE_SWA_ID,
  UNIT_ALPHA_AFFE_AA_ID,
  UNIT_NERVÖSERAFFE_NA_ID,
  UNIT_HIRSCH_HI_ID,
  UNIT_FROSTSTEINBOCK_FSB_ID,
  UNIT_FROSTBÄR_FBR_ID,
  UNIT_FROSTFUCHS_FFU_ID,
  UNIT_FROSTADLER_FAD_ID,
  UNIT_FROSTWOLF_FWO_ID,
  UNIT_FROSTLEOPARD_FLE_ID,
  UNIT_FROSTRIESE_FRI_ID,
  UNIT_EINÄUGIGERBERT_EB_ID,
  UNIT_DICKEBERTHA_DB_ID,
  UNIT_FLAMMENPREDIGER_POF_ID,
  UNIT_RIESEBOGOR_RBO_ID,
  UNIT_RIESEGOGOR_RGO_ID,
  UNIT_EINHORN_EH_ID,
  UNIT_RASENDESWILDSCHWEIN_RWS_ID,
  UNIT_EISENFAUST_EF_ID,
  UNIT_RIESIGERBÄR_RBÄ_ID,
  UNIT_DUNKLERZAUBERER_DZ_ID,
  UNIT_VERLOGENEZIEGE_VZ_ID,
  UNIT_KNÜPPEL_KLAUS_KKL_ID,
  UNIT_ASSASSINE_AS_ID,
  UNIT_GIERIGERGASTWIRT_GG_ID,
  UNIT_RATTENFÄNGER_PIP_ID,
  UNIT_DERBÜRGERMEISTER_MAY_ID,
  UNIT_RATTENKÖNIG_KOR_ID,
  UNIT_ILSEBILLE_ILES_ID,
  UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ID,
  UNIT_BÖSESTIEFMUTTER_BSM_ID,
  UNIT_KÖNIGLICHERKAPITÄN_RC_ID,
  UNIT_KÖNIGLICHERMOLOCH_RJ_ID,
  UNIT_KLUGERRÄUBER_KLUR_ID,
  UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID,
  UNIT_HINTERLISTIGERRÄUBER_HR_ID,
  UNIT_GIERIGERRÄUBER_GIER_ID,
  UNIT_EINFÄLTIGERRÄUBER_EINR_ID,
  UNIT_STEUERMANN_STM_ID,
  UNIT_MEUTERER_MEU_ID,
  UNIT_KAPITÄN_CAPT_ID,
  UNIT_ELCHUPA_CHUP_ID,
  UNIT_ANCIENTDRAGON_AD_ID,
  UNIT_STAHLBART_STB_ID,
  UNIT_GIGANTISCHERADLER_GAD_ID,
  UNIT_MAMMUT_MAM_ID,
  UNIT_WINTERSCHRECK_WT_ID,
  UNIT_SÄBELZAHNTIGER_SZT_ID,
  UNIT_RIESIGERSTEINBOCK_ESB_ID,
  UNIT_GRAUSIGERWOLF_GWO_ID,
  UNIT_GEWALTIGERELCH_ELK_ID,
  UNIT_RISI4_RISE_ID,
  UNIT_RISI3_RISF_ID,
  UNIT_RISI2_RISG_ID,
  UNIT_RISI1_RISH_ID,
  UNIT_RISI0_RISI_ID,
  UNIT_RÄUBERLORDBJÖRN_BLB_ID,
  UNIT_SCHATZ_SCHAT_ID,
} from "../units/Units";
import {
  CAMP_TYPE_N,
  CAMP_TYPE_V,
  CAMP_TYPE_W,
  CAMP_TYPE_S,
  CAMP_TYPE_K,
  CAMP_TYPE_ELCHWALD,
  CAMP_TYPE_WILD_ANFUEHRER,
  CAMP_TYPE_SAEBELZAHN_HOEHLE,
  CAMP_TYPE_MAMMUT_HOEHLE,
} from "../CampTypes";
import {
  BaseAdventure as Adventure,
  KLASSISCH_THEME,
  MINI_MISSION_TYPE,
  MYTHOS_THEME,
  MÄRCHEN_THEME,
  UNTERNEHMUNG_TYPE,
} from "./BaseAdventure";

export const AT_AMFUSSEDESBERGES_ID = 510;
export const AT_ANGRIFFDERNORDMÄNNER_ID = 511;
export const AT_BERGLABYRINTH_ID = 514;
export const AT_DASBERGVOLK_ID = 516;
export const AT_DASQUARTIERDESDRACHEN_ID = 518;
export const AT_DERCHUPACABRA_ID = 524;
export const AT_DERRATTENFÄNGERVONHAMELN_ID = 525;
export const AT_DIEVERLORENESTADT_ID = 545;
export const AT_EINRIESENKAMPF_ID = 547;
export const AT_EINENSCHRITTVORAUS_ID = 548;
export const AT_HÄNSELUNDGRETEL_ID = 554;
export const AT_ROTKÄPPCHEN_ID = 563;
export const AT_SCHNEEWITTCHEN_ID = 571;
export const AT_TIKKI_INSEL_ID = 577;
export const AT_UNBEKANNTEREGIONEN_ID = 579;
export const AT_VOMFISCHERUNDSEINERFRAU_ID = 582;
export const AT_VONLIEDERNUNDFLÜCHEN_ID = 583;
export const AT_WERTVOLLEINFORMATIONEN_ID = 584;

export type UnternehmungenIds =
  | typeof AT_AMFUSSEDESBERGES_ID
  | typeof AT_ANGRIFFDERNORDMÄNNER_ID
  | typeof AT_BERGLABYRINTH_ID
  | typeof AT_DASBERGVOLK_ID
  | typeof AT_DASQUARTIERDESDRACHEN_ID
  | typeof AT_DERCHUPACABRA_ID
  | typeof AT_DERRATTENFÄNGERVONHAMELN_ID
  | typeof AT_DIEVERLORENESTADT_ID
  | typeof AT_EINRIESENKAMPF_ID
  | typeof AT_EINENSCHRITTVORAUS_ID
  | typeof AT_HÄNSELUNDGRETEL_ID
  | typeof AT_ROTKÄPPCHEN_ID
  | typeof AT_SCHNEEWITTCHEN_ID
  | typeof AT_TIKKI_INSEL_ID
  | typeof AT_UNBEKANNTEREGIONEN_ID
  | typeof AT_VOMFISCHERUNDSEINERFRAU_ID
  | typeof AT_VONLIEDERNUNDFLÜCHEN_ID
  | typeof AT_WERTVOLLEINFORMATIONEN_ID;

const AT_AMFUSSEDESBERGES_ICON = "/icons/adventures/berg1.png";
const AT_ANGRIFFDERNORDMÄNNER_ICON = "/icons/adventures/raid_of_the_nords.png";
const AT_BERGLABYRINTH_ICON = "/icons/adventures/berg3.png";
const AT_DASBERGVOLK_ICON = "/icons/adventures/berg4.png";
const AT_DASQUARTIERDESDRACHEN_ICON = "/icons/adventures/dragons_roost.png";
const AT_DERCHUPACABRA_ICON = "/icons/adventures/el_chupacabre_avatar.png";
const AT_DERRATTENFÄNGERVONHAMELN_ICON =
  "/icons/adventures/evil_queen_pied_piper.png";
const AT_DIEVERLORENESTADT_ICON = "/icons/adventures/lost_city.png";
const AT_EINRIESENKAMPF_ICON = "/icons/adventures/berg5.png";
const AT_EINENSCHRITTVORAUS_ICON = "/icons/adventures/pathfinders.png";
const AT_HÄNSELUNDGRETEL_ICON =
  "/icons/adventures/evil_queen_hansel_gretel.png";
const AT_ROTKÄPPCHEN_ICON = "/icons/adventures/evil_queen_red_riding_hood.png";
const AT_SCHNEEWITTCHEN_ICON = "/icons/adventures/evil_queen_snow_white.png";
const AT_TIKKI_INSEL_ICON = "/icons/adventures/icon_tikki_island.png";
const AT_UNBEKANNTEREGIONEN_ICON = "/icons/adventures/berg2.png";
const AT_VOMFISCHERUNDSEINERFRAU_ICON =
  "/icons/adventures/evil_queen_fisherman.png";
const AT_VONLIEDERNUNDFLÜCHEN_ICON = "/icons/adventures/ofSongsAndCurses.png";
const AT_WERTVOLLEINFORMATIONEN_ICON = "/icons/adventures/treasure_fleet.png";

// Maps
const AT_AMFUSSEDESBERGES_MAP = "/maps/bergclan_fuss.jpg";
const AT_ANGRIFFDERNORDMÄNNER_MAP = "/maps/angriffNordi.jpg";
const AT_BERGLABYRINTH_MAP = "/maps/bergclan_laby2.jpg";
const AT_DASBERGVOLK_MAP = "/maps/bergclan_volk.jpg";
const AT_DASQUARTIERDESDRACHEN_MAP = "/maps/quartierDesDrachen.jpg";
const AT_DERCHUPACABRA_MAP = "/maps/chupacabra.jpg";
const AT_DERRATTENFÄNGERVONHAMELN_MAP = "/maps/rattenfaengerHameln.jpg";
const AT_DIEVERLORENESTADT_MAP = "/maps/lostCity.jpg";
const AT_EINRIESENKAMPF_MAP = "/maps/bergclan_riesen.jpg";
const AT_EINENSCHRITTVORAUS_MAP = "/maps/oneStep.jpg";
const AT_HÄNSELUNDGRETEL_MAP = "/maps/haenselGretel.jpg";
const AT_ROTKÄPPCHEN_MAP = "/maps/roteKappe.jpg";
const AT_SCHNEEWITTCHEN_MAP = "/maps/schneewittchen.jpg";
const AT_TIKKI_INSEL_MAP = "/maps/tikki.jpg";
const AT_UNBEKANNTEREGIONEN_MAP = "/maps/bergclan_region2.jpg";
const AT_VOMFISCHERUNDSEINERFRAU_MAP = "/maps/fischer.jpg";
const AT_VONLIEDERNUNDFLÜCHEN_MAP = "/maps/OfSongsAndCurses.jpg";
const AT_WERTVOLLEINFORMATIONEN_MAP = "/maps/wertvolleInfos.jpg";

const Ja = true;
const Nein = false;

export interface Unternehmung extends Adventure {
  id: UnternehmungenIds;
}

const AmFuSSedesBerges = {
  name: "Am Fuße des Berges",
  image: AT_AMFUSSEDESBERGES_MAP,
  icon: AT_AMFUSSEDESBERGES_ICON,
  id: AT_AMFUSSEDESBERGES_ID,
  campaign: "Der Bergklan",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 12,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KARL_KAR_ID },
        { amount: 125, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 150, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 75, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_KARL_KAR_ID },
        { amount: 250, typeId: UNIT_HUSKARL_HK_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 150, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 150, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 115, typeId: UNIT_THRALL_TH_ID },
        { amount: 200, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 200, typeId: UNIT_JOMSWIKINGER_JW_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KARL_KAR_ID },
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 250, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 50, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 11,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 175, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 200, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "A",
      number: 12,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 200, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 100, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 13,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 150, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 150, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 14,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KARL_KAR_ID },
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 150, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 150, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 15,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 300, typeId: UNIT_THRALL_TH_ID },
        { amount: 150, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 150, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 16,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 150, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 200, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 17,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KARL_KAR_ID },
        { amount: 200, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 100, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 21,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 150, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 200, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 22,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 300, typeId: UNIT_THRALL_TH_ID },
        { amount: 200, typeId: UNIT_KARL_KAR_ID },
        { amount: 200, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 23,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 200, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "A",
      number: 24,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 275, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 225, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 90, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 80, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 60, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 35, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 120, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 125, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 120, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 60, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 140, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 145, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 40, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 160, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 170, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 200, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 100, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 200, typeId: UNIT_BERSERKER_BR_ID },
        { amount: 1, typeId: UNIT_STAHLBART_STB_ID },
      ],
    },
  ],
} as Unternehmung;
const AngriffderNordmÄnner = {
  name: "Angriff der Nordmänner",
  image: AT_ANGRIFFDERNORDMÄNNER_MAP,
  icon: AT_ANGRIFFDERNORDMÄNNER_ICON,
  id: AT_ANGRIFFDERNORDMÄNNER_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 75, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_THRALL_TH_ID },
        { amount: 160, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 100, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 90, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_THRALL_TH_ID },
        { amount: 75, typeId: UNIT_KARL_KAR_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_HUSKARL_HK_ID }],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 350, typeId: UNIT_THRALL_TH_ID }],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_THRALL_TH_ID },
        { amount: 100, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_THRALL_TH_ID },
        { amount: 30, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 150, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 300, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 75, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 50, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 65, typeId: UNIT_THRALL_TH_ID },
        { amount: 70, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 150, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 45, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 170, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 40, typeId: UNIT_JOMSWIKINGER_JW_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 100, typeId: UNIT_GRAUERWOLF_W_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 250, typeId: UNIT_GRAUERWOLF_W_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_KARL_KAR_ID },
        { amount: 130, typeId: UNIT_HUSKARL_HK_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 170, typeId: UNIT_THRALL_TH_ID },
        { amount: 75, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 30, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_THRALL_TH_ID },
        { amount: 100, typeId: UNIT_GRAUERWOLF_W_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 50, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 50, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_KARL_KAR_ID },
        { amount: 80, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 60, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KARL_KAR_ID },
        { amount: 300, typeId: UNIT_GRAUERWOLF_W_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 210, typeId: UNIT_THRALL_TH_ID },
        { amount: 100, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
  ],
} as Unternehmung;

const Berglabyrinth = {
  name: "Berglabyrinth",
  image: AT_BERGLABYRINTH_MAP,
  icon: AT_BERGLABYRINTH_ICON,
  id: AT_BERGLABYRINTH_ID,
  campaign: "Der Bergklan",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 13,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 80, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 5, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 105, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "G",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "G",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 75, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "G",
      number: 6,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 100, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "G",
      number: 7,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 35, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "H",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 175, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "H",
      number: 4,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "H",
      number: 5,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "I",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 42, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 42, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 42, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 42, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "I",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "J",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 37, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 37, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 37, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 37, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 37, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "J",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 20, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 40, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "K",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "K",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 92, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "K",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
      ],
    },
    {
      sector: "L",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "L",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 98, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "L",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
      ],
    },
    {
      sector: "M",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 75, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 2, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
      ],
    },
    {
      sector: "N",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 2, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
      ],
    },
    {
      sector: "O",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 1, typeId: UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID },
      ],
    },
  ],
} as Unternehmung;
const DasBergvolk = {
  name: "Das Bergvolk",
  image: AT_DASBERGVOLK_MAP,
  icon: AT_DASBERGVOLK_ICON,
  id: AT_DASBERGVOLK_ID,
  campaign: "Der Bergklan",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 14,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_V,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 10, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [{ amount: 200, typeId: UNIT_FROSTADLER_FAD_ID }],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 140, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 80, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 105, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 10, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 80, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "I",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 150, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 10, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "J",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 125, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 80, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "K",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 90, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "L",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "L",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "L",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_WINTERSCHRECK_WT_ID },
      ],
    },
    {
      sector: "X",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 150, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "X",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 80, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "X",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 30, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "X",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 95, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 45, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "Y",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 150, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 150, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
      ],
    },
    {
      sector: "Y",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 2, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "Y",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 2, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 150, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "Y",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 2, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
      ],
    },
    {
      sector: "Z",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 200, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 125, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
      ],
    },
    {
      sector: "Z",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "Z",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 2, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "Z",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 100, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 150, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
  ],
} as Unternehmung;
const DasQuartierdesDrachen = {
  name: "Das Quartier des Drachen",
  image: AT_DASQUARTIERDESDRACHEN_MAP,
  icon: AT_DASQUARTIERDESDRACHEN_ICON,
  id: AT_DASQUARTIERDESDRACHEN_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: MYTHOS_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 10, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 15, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 5, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_GRAUERWOLF_W_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 5, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 5, typeId: UNIT_FUCHS_FU_ID },
        { amount: 5, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 5, typeId: UNIT_FUCHS_FU_ID },
        { amount: 13, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [{ amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID }],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
        { amount: 10, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 10, typeId: UNIT_KULTIST_KT_ID },
        { amount: 5, typeId: UNIT_FEUERTÄNZER_FT_ID },
        { amount: 10, typeId: UNIT_SCHATTENSCHLEICHER_SS_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 50, typeId: UNIT_KULTIST_KT_ID }],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_KULTIST_KT_ID },
        { amount: 1, typeId: UNIT_FLAMMENPREDIGER_POF_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_KULTIST_KT_ID },
        { amount: 1, typeId: UNIT_ANCIENTDRAGON_AD_ID },
      ],
    },
  ],
} as Unternehmung;
const DerChupacabra = {
  name: "Der Chupacabra",
  image: AT_DERCHUPACABRA_MAP,
  icon: AT_DERCHUPACABRA_ICON,
  id: AT_DERCHUPACABRA_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: MYTHOS_THEME,
  difficulty: 8,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 15, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 20, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 50, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 10, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 10, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 10, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 10, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 10, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 10, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 20, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 10, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID }],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 15, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 25, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID }],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 25, typeId: UNIT_NERVÖSERAFFE_NA_ID }],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 16, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 11, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 10, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 20, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID }],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 5, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 10, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 10, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 10, typeId: UNIT_NERVÖSERAFFE_NA_ID }],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 15, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 15, typeId: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID },
        { amount: 20, typeId: UNIT_ALPHA_AFFE_AA_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 10, typeId: UNIT_ALPHA_AFFE_AA_ID },
        { amount: 20, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "C",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 15, typeId: UNIT_STEINWERFENDERAFFE_SWA_ID },
        { amount: 3, typeId: UNIT_NERVÖSERAFFE_NA_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 275, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 125, typeId: UNIT_SCHAMANE_SCH_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 1200, typeId: UNIT_SCHAMANE_SCH_ID }],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 125, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 15, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 75, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 75, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 200, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "D",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 250, typeId: UNIT_SCHAMANE_SCH_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 225, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID }],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_JAGUAR_KRIEGER_JK_ID }],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 125, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 185, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 185, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 50, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 50, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID }],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 275, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 25, typeId: UNIT_SCHAMANE_SCH_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 175, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 100, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 100, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "F",
      number: 5,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 175, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "F",
      number: 6,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_STAMMESANGEHÖRIGER_SA_ID },
        { amount: 75, typeId: UNIT_SCHAMANE_SCH_ID },
        { amount: 75, typeId: UNIT_JAGUAR_KRIEGER_JK_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_WOLF_WO_ID },
        { amount: 1, typeId: UNIT_ELCHUPA_CHUP_ID },
      ],
    },
  ],
} as Unternehmung;
const DerRattenfÄngervonHameln = {
  name: "Der Rattenfänger von Hameln",
  image: AT_DERRATTENFÄNGERVONHAMELN_MAP,
  icon: AT_DERRATTENFÄNGERVONHAMELN_ICON,
  id: AT_DERRATTENFÄNGERVONHAMELN_ID,
  campaign: "Die böse Königin",
  type: UNTERNEHMUNG_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 11,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 1, typeId: UNIT_RIESEBOGOR_RBO_ID },
        { amount: 1, typeId: UNIT_RIESEGOGOR_RGO_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_VERLOGENEZIEGE_VZ_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_WOLF_WO_ID },
        { amount: 75, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_FUCHS_FU_ID },
        { amount: 75, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 1, typeId: UNIT_RATTENKÖNIG_KOR_ID },
        { amount: 1, typeId: UNIT_RATTENFÄNGER_PIP_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 1, typeId: UNIT_DUNKLERZAUBERER_DZ_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
        { amount: 1, typeId: UNIT_GIERIGERGASTWIRT_GG_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 1, typeId: UNIT_DERBÜRGERMEISTER_MAY_ID },
      ],
    },
  ],
} as Unternehmung;
const DieverloreneStadt = {
  name: "Die verlorene Stadt",
  image: AT_DIEVERLORENESTADT_MAP,
  icon: AT_DIEVERLORENESTADT_ICON,
  id: AT_DIEVERLORENESTADT_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: MYTHOS_THEME,
  difficulty: 11,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 350, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID }],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 80, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 300, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_GIERIGERRÄUBER_GIER_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 150, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 200, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_EINFÄLTIGERRÄUBER_EINR_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 70, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 70, typeId: UNIT_STEINKANONE_STK_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 80, typeId: UNIT_REITER_HM_ID },
        { amount: 30, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 1, typeId: UNIT_KLUGERRÄUBER_KLUR_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 50, typeId: UNIT_REITER_HM_ID },
        { amount: 50, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 100, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_DÜNENSCHÜTZE_DÜS_ID },
        { amount: 300, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KLINGENRASSLER_KRA_ID }],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID }],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 70, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_STEINKANONE_STK_ID }],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_SCHWERTMEISTER_SM_ID },
        { amount: 75, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID },
        { amount: 1, typeId: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 140, typeId: UNIT_KLINGENRASSLER_KRA_ID },
        { amount: 140, typeId: UNIT_REITER_HM_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_REITER_HM_ID }],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_SÄBELSCHWINGER_SÄS_ID },
        { amount: 60, typeId: UNIT_WÜSTENSCHÜTZE_WÜS_ID },
        { amount: 1, typeId: UNIT_HINTERLISTIGERRÄUBER_HR_ID },
      ],
    },
  ],
} as Unternehmung;
const EinRiesenkampf = {
  name: "Ein Riesenkampf",
  image: AT_EINRIESENKAMPF_MAP,
  icon: AT_EINRIESENKAMPF_ICON,
  id: AT_EINRIESENKAMPF_ID,
  campaign: "Der Bergklan",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 14,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 85, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 60, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 65, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 65, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 20, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 80, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "I",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 80, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "I",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 35, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "I",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "I",
      number: 4,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "J",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 25, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "K",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 25, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "K",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 70, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "K",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "L",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 25, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "M",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 75, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "N",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "N",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 30, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "N",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "N",
      number: 4,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 2, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "O",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 25, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 25, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "O",
      number: 2,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 95, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "O",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 10, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "O",
      number: 4,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "P",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "Q",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "Q",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "Q",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "R",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 35, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 75, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "R",
      number: 2,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 10, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "R",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 60, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "S",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 125, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "T",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 25, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 35, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
      ],
    },
    {
      sector: "T",
      number: 2,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 75, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "U",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 30, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "V",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 100, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "V",
      number: 2,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 45, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "W",
      number: 1,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 70, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "W",
      number: 2,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 20, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 1, typeId: UNIT_WILDEROCHSE_ROX_ID },
        { amount: 70, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "X",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 65, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 65, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "X",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "X",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 55, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 55, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "Y",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
        { amount: 1, typeId: UNIT_GRAUSIGERWOLF_GWO_ID },
        { amount: 25, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "Y",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 35, typeId: UNIT_FROSTLEOPARD_FLE_ID },
      ],
    },
    {
      sector: "Y",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 50, typeId: UNIT_FROSTBÄR_FBR_ID },
        { amount: 35, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 75, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "Z",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTRIESE_FRI_ID },
      ],
    },
    {
      sector: "Z",
      number: 2,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 150, typeId: UNIT_FROSTADLER_FAD_ID },
      ],
    },
    {
      sector: "a",
      number: 1,
      type: CAMP_TYPE_MAMMUT_HOEHLE,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_MAMMUT_MAM_ID },
      ],
    },
    {
      sector: "b",
      number: 1,
      type: CAMP_TYPE_ELCHWALD,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_GEWALTIGERELCH_ELK_ID },
      ],
    },
    {
      sector: "c",
      number: 1,
      type: CAMP_TYPE_SAEBELZAHN_HOEHLE,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_SÄBELZAHNTIGER_SZT_ID },
      ],
    },
    {
      sector: "d",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_GIGANTISCHERADLER_GAD_ID },
      ],
    },
    {
      sector: "e",
      number: 1,
      type: CAMP_TYPE_SAEBELZAHN_HOEHLE,
      art: "",
      boss: Ja,
      units: [
        { amount: 25, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_SÄBELZAHNTIGER_SZT_ID },
      ],
    },
    {
      sector: "e",
      number: 2,
      type: CAMP_TYPE_MAMMUT_HOEHLE,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_MAMMUT_MAM_ID },
      ],
    },
    {
      sector: "e",
      number: 3,
      type: CAMP_TYPE_WILD_ANFUEHRER,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTLEOPARD_FLE_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_GEWALTIGERELCH_ELK_ID },
      ],
    },
    {
      sector: "e",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 50, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_GIGANTISCHERADLER_GAD_ID },
      ],
    },
    {
      sector: "e",
      number: 5,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_RISI4_RISE_ID },
      ],
    },
    {
      sector: "f",
      number: 4,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_RISI3_RISF_ID },
      ],
    },
    {
      sector: "g",
      number: 3,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_RISI2_RISG_ID },
      ],
    },
    {
      sector: "h",
      number: 2,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_RISI1_RISH_ID },
      ],
    },
    {
      sector: "i",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 75, typeId: UNIT_FROSTADLER_FAD_ID },
        { amount: 50, typeId: UNIT_FROSTRIESE_FRI_ID },
        { amount: 1, typeId: UNIT_RISI0_RISI_ID },
      ],
    },
  ],
} as Unternehmung;
const EinenSchrittvoraus = {
  name: "Einen Schritt voraus",
  image: AT_EINENSCHRITTVORAUS_MAP,
  icon: AT_EINENSCHRITTVORAUS_ICON,
  id: AT_EINENSCHRITTVORAUS_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 12,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_BOOTSMANN_BM_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID }],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 175, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 350, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 175, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_KANONIERMEISTER_KME_ID }],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 400, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID }],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 275, typeId: UNIT_OBERMAAT_OM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 175, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 150, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 150, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 400, typeId: UNIT_OBERMAAT_OM_ID }],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_MEUTERER_MEU_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 175, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 100, typeId: UNIT_BOOTSMANN_BM_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 100, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_MATROSE_MTR_ID },
        { amount: 200, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
      ],
    },
    {
      sector: "F",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 150, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "F",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 200, typeId: UNIT_OBERMAAT_OM_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_BERITTENERSEEFAHRER_BSF_ID },
        { amount: 100, typeId: UNIT_KANONIERMEISTER_KME_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_MATROSE_MTR_ID }],
    },
    {
      sector: "G",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_BOOTSMANN_BM_ID },
        { amount: 1, typeId: UNIT_STEUERMANN_STM_ID },
        { amount: 1, typeId: UNIT_KAPITÄN_CAPT_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 50, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 30, typeId: UNIT_SCHATZ_SCHAT_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 125, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 40, typeId: UNIT_SCHATZ_SCHAT_ID },
      ],
    },
    {
      sector: "H",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 125, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 40, typeId: UNIT_SCHATZ_SCHAT_ID },
      ],
    },
    {
      sector: "H",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID },
        { amount: 125, typeId: UNIT_KANONIERMEISTER_KME_ID },
        { amount: 40, typeId: UNIT_SCHATZ_SCHAT_ID },
      ],
    },
  ],
} as Unternehmung;
const HÄnselundGretel = {
  name: "Hänsel und Gretel",
  image: AT_HÄNSELUNDGRETEL_MAP,
  icon: AT_HÄNSELUNDGRETEL_ICON,
  id: AT_HÄNSELUNDGRETEL_ID,
  campaign: "Die böse Königin",
  type: UNTERNEHMUNG_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 11,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 160, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 180, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_WOLF_WO_ID },
        { amount: 125, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 75, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 50, typeId: UNIT_HIRSCH_HI_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 150, typeId: UNIT_RIESE_RI_ID },
        { amount: 50, typeId: UNIT_HIRSCH_HI_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_WOLF_WO_ID },
        { amount: 50, typeId: UNIT_HIRSCH_HI_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "F",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 75, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 125, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 75, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 250, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "I",
      number: 1,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [
        { amount: 175, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 70, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
        { amount: 1, typeId: UNIT_ILSEBILLE_ILES_ID },
      ],
    },
  ],
} as Unternehmung;
const RotkÄppchen = {
  name: "Rotkäppchen",
  image: AT_ROTKÄPPCHEN_MAP,
  icon: AT_ROTKÄPPCHEN_ICON,
  id: AT_ROTKÄPPCHEN_ID,
  campaign: "Die böse Königin",
  type: UNTERNEHMUNG_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 12,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESEBOGOR_RBO_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 250, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 125, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_WOLF_WO_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_EINHORN_EH_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 400, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 300, typeId: UNIT_FUCHS_FU_ID },
        { amount: 250, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "C",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_WOLF_WO_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "D",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 200, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 300, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 150, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESEBOGOR_RBO_ID },
        { amount: 1, typeId: UNIT_RIESEGOGOR_RGO_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 200, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "G",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "G",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 1, typeId: UNIT_KÖNIGLICHERKAPITÄN_RC_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "H",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
      ],
    },
    {
      sector: "H",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 250, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "H",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 1, typeId: UNIT_EISENFAUST_EF_ID },
        { amount: 1, typeId: UNIT_ASSASSINE_AS_ID },
      ],
    },
    {
      sector: "I",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "I",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 175, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "I",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 175, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
        { amount: 1, typeId: UNIT_KÖNIGLICHERMOLOCH_RJ_ID },
      ],
    },
    {
      sector: "I",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
      ],
    },
    {
      sector: "J",
      number: 1,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 1, typeId: UNIT_ILSEBILLE_ILES_ID },
      ],
    },
  ],
} as Unternehmung;
const Schneewittchen = {
  name: "Schneewittchen",
  image: AT_SCHNEEWITTCHEN_MAP,
  icon: AT_SCHNEEWITTCHEN_ICON,
  id: AT_SCHNEEWITTCHEN_ID,
  campaign: "Die böse Königin",
  type: UNTERNEHMUNG_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 10,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 150, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 150, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 1, typeId: UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 125, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 1, typeId: UNIT_ASSASSINE_AS_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 1, typeId: UNIT_KNÜPPEL_KLAUS_KKL_ID },
      ],
    },
    {
      sector: "E",
      number: 4,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
      ],
    },
    {
      sector: "E",
      number: 5,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "E",
      number: 6,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 1, typeId: UNIT_DUNKLERZAUBERER_DZ_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 250, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 1, typeId: UNIT_BÖSESTIEFMUTTER_BSM_ID },
      ],
    },
  ],
} as Unternehmung;
const Tikki_Insel = {
  name: "Tikki-Insel",
  image: AT_TIKKI_INSEL_MAP,
  icon: AT_TIKKI_INSEL_ICON,
  id: AT_TIKKI_INSEL_ID,
  campaign: " ",
  type: MINI_MISSION_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 2,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 50, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_NOMADE_NO_ID },
        { amount: 50, typeId: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID },
        { amount: 50, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_NOMADE_NO_ID },
        { amount: 70, typeId: UNIT_KATAPHRAKT_KP_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 60, typeId: UNIT_LANZENREITER_LR_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 70, typeId: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID },
        { amount: 60, typeId: UNIT_KATAPHRAKT_KP_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 60, typeId: UNIT_NOMADE_NO_ID },
        { amount: 70, typeId: UNIT_BERITTENEAMAZONE_BA_ID },
        { amount: 1, typeId: UNIT_BRÜLLENDERSTIER_BS_ID },
      ],
    },
  ],
} as Unternehmung;
const UnbekannteRegionen = {
  name: "Unbekannte Regionen",
  image: AT_UNBEKANNTEREGIONEN_MAP,
  icon: AT_UNBEKANNTEREGIONEN_ICON,
  id: AT_UNBEKANNTEREGIONEN_ID,
  campaign: "Der Bergklan",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 13,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [{ amount: 165, typeId: UNIT_FROSTSTEINBOCK_FSB_ID }],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 75, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 165, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 40, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 250, typeId: UNIT_FROSTSTEINBOCK_FSB_ID }],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 70, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 130, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "G",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 30, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 40, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 40, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "H",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [{ amount: 100, typeId: UNIT_FROSTFUCHS_FFU_ID }],
    },
    {
      sector: "I",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_FROSTSTEINBOCK_FSB_ID }],
    },
    {
      sector: "I",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_FROSTFUCHS_FFU_ID }],
    },
    {
      sector: "I",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 2, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "J",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "J",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 125, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "J",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 2, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 100, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "K",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "L",
      number: 1,
      type: CAMP_TYPE_V,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "M",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "M",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "M",
      number: 3,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 130, typeId: UNIT_FROSTWOLF_FWO_ID },
        { amount: 1, typeId: UNIT_RIESENLUCHS_GL_ID },
      ],
    },
    {
      sector: "N",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 50, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "N",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 50, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "N",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 75, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
      ],
    },
    {
      sector: "N",
      number: 4,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 110, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 75, typeId: UNIT_FROSTFUCHS_FFU_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
      ],
    },
    {
      sector: "O",
      number: 1,
      type: CAMP_TYPE_W,
      art: "",
      boss: Ja,
      units: [
        { amount: 120, typeId: UNIT_FROSTSTEINBOCK_FSB_ID },
        { amount: 1, typeId: UNIT_RIESIGERSTEINBOCK_ESB_ID },
        { amount: 60, typeId: UNIT_FROSTWOLF_FWO_ID },
      ],
    },
    {
      sector: "Z",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 650, typeId: UNIT_KARL_KAR_ID }],
    },
    {
      sector: "Z",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 250, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 500, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "Z",
      number: 3,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 300, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 200, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 100, typeId: UNIT_BERSERKER_BR_ID },
      ],
    },
    {
      sector: "Z",
      number: 4,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_HUSKARL_HK_ID },
        { amount: 800, typeId: UNIT_WALKÜRE_WK_ID },
      ],
    },
    {
      sector: "Z",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 300, typeId: UNIT_JOMSWIKINGER_JW_ID },
        { amount: 200, typeId: UNIT_WALKÜRE_WK_ID },
        { amount: 1, typeId: UNIT_RÄUBERLORDBJÖRN_BLB_ID },
      ],
    },
  ],
} as Unternehmung;
const VomFischerundseinerFrau = {
  name: "Vom Fischer und seiner Frau",
  image: AT_VOMFISCHERUNDSEINERFRAU_MAP,
  icon: AT_VOMFISCHERUNDSEINERFRAU_ICON,
  id: AT_VOMFISCHERUNDSEINERFRAU_ID,
  campaign: "Die böse Königin",
  type: UNTERNEHMUNG_TYPE,
  theme: MÄRCHEN_THEME,
  difficulty: 11,
  allowsElite: Ja,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 100, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 150, typeId: UNIT_WOLF_WO_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 50, typeId: UNIT_WOLF_WO_ID },
        { amount: 150, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID }],
    },
    {
      sector: "A",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "A",
      number: 7,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
      ],
    },
    {
      sector: "A",
      number: 8,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 150, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 100, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WILDSCHWEIN_WS_ID },
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 200, typeId: UNIT_BÄR_BÄR_ID }],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 150, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_WOLF_WO_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
      ],
    },
    {
      sector: "B",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_FUCHS_FU_ID },
        { amount: 200, typeId: UNIT_RIESE_RI_ID },
        { amount: 1, typeId: UNIT_RIESIGERBÄR_RBÄ_ID },
      ],
    },
    {
      sector: "B",
      number: 6,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 200, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "B",
      number: 7,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_FUCHS_FU_ID },
        { amount: 100, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 50, typeId: UNIT_RIESE_RI_ID },
        { amount: 2, typeId: UNIT_RASENDESWILDSCHWEIN_RWS_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 200, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "C",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 250, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 1, typeId: UNIT_DUNKLERZAUBERER_DZ_ID },
        { amount: 1, typeId: UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 150, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
      ],
    },
    {
      sector: "D",
      number: 3,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "D",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
        { amount: 1, typeId: UNIT_ASSASSINE_AS_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 200, typeId: UNIT_KÖNIGLICHERREKRUT_KR_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
      ],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHEREITEREI_KC_ID },
        { amount: 50, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
        { amount: 100, typeId: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_S,
      art: "",
      boss: Ja,
      units: [
        { amount: 100, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 100, typeId: UNIT_DESERTIERTERELITESOLDAT_DE_ID },
        { amount: 150, typeId: UNIT_KÖNIGLICHERKANONIER_KK_ID },
        { amount: 1, typeId: UNIT_ILSEBILLE_ILES_ID },
      ],
    },
  ],
} as Unternehmung;
const VonLiedernundFlÜchen = {
  name: "Von Liedern und Flüchen",
  image: AT_VONLIEDERNUNDFLÜCHEN_MAP,
  icon: AT_VONLIEDERNUNDFLÜCHEN_ICON,
  id: AT_VONLIEDERNUNDFLÜCHEN_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: MYTHOS_THEME,
  difficulty: 4,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 14, typeId: UNIT_BÄR_BÄR_ID },
        { amount: 10, typeId: UNIT_ALPHAWOLF_AW_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [{ amount: 14, typeId: UNIT_FUCHS_FU_ID }],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 12, typeId: UNIT_KÖNIGLICHEMILIZ_KM_ID },
        { amount: 14, typeId: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 20, typeId: UNIT_WACHHUND_WH_ID }],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 10, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 15, typeId: UNIT_WALDLÄUFER_WL_ID },
        { amount: 50, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 1, typeId: UNIT_EINÄUGIGERBERT_EB_ID },
        { amount: 20, typeId: UNIT_KRÄHENFÜSSE_KF_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 40, typeId: UNIT_SCHLÄGER_SL_ID }],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 30, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 10, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 10, typeId: UNIT_WACHHUND_WH_ID },
      ],
    },
    {
      sector: "C",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_STEINWERFER_SW_ID },
        { amount: 1, typeId: UNIT_CHUCK_CK_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 15, typeId: UNIT_ALPHAWOLF_AW_ID },
        { amount: 175, typeId: UNIT_GRAUERWOLF_W_ID },
      ],
    },
    {
      sector: "E",
      number: 1,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [{ amount: 60, typeId: UNIT_SCHLÄGER_SL_ID }],
    },
    {
      sector: "E",
      number: 2,
      type: CAMP_TYPE_N,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 40, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 20, typeId: UNIT_FANATIKER_FA_ID },
      ],
    },
    {
      sector: "E",
      number: 3,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 45, typeId: UNIT_SCHLÄGER_SL_ID },
        { amount: 8, typeId: UNIT_FEUERTÄNZER_FT_ID },
      ],
    },
    {
      sector: "F",
      number: 1,
      type: CAMP_TYPE_K,
      art: "",
      boss: Ja,
      units: [
        { amount: 50, typeId: UNIT_PLÜNDERER_PL_ID },
        { amount: 1, typeId: UNIT_METALLGEBISS_MG_ID },
        { amount: 5, typeId: UNIT_DUNKLERPRIESTER_DP_ID },
      ],
    },
  ],
} as Unternehmung;
const WertvolleInformationen = {
  name: "Wertvolle Informationen",
  image: AT_WERTVOLLEINFORMATIONEN_MAP,
  icon: AT_WERTVOLLEINFORMATIONEN_ICON,
  id: AT_WERTVOLLEINFORMATIONEN_ID,
  campaign: " ",
  type: UNTERNEHMUNG_TYPE,
  theme: KLASSISCH_THEME,
  difficulty: 2,
  allowsElite: Nein,
  camps: [
    {
      sector: "A",
      number: 1,
      type: CAMP_TYPE_S,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 55, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 5, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 2, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
      ],
    },
    {
      sector: "A",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 20, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
      ],
    },
    {
      sector: "A",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 30, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
      ],
    },
    {
      sector: "A",
      number: 4,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [{ amount: 75, typeId: UNIT_DESERTIERTERREKRUT_DR_ID }],
    },
    {
      sector: "A",
      number: 5,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 10, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 35, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
      ],
    },
    {
      sector: "B",
      number: 1,
      type: CAMP_TYPE_S,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 55, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 5, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 2, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
      ],
    },
    {
      sector: "B",
      number: 2,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 25, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
        { amount: 5, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
      ],
    },
    {
      sector: "B",
      number: 3,
      type: CAMP_TYPE_N,
      art: "leicht",
      boss: Nein,
      units: [
        { amount: 12, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 12, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 12, typeId: UNIT_DESERTIERTERSOLDAT_DS_ID },
      ],
    },
    {
      sector: "B",
      number: 4,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 40, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 10, typeId: UNIT_DESERTIERTEREITEREI_DC_ID },
        { amount: 30, typeId: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID },
      ],
    },
    {
      sector: "C",
      number: 1,
      type: CAMP_TYPE_S,
      art: "schwer",
      boss: Nein,
      units: [
        { amount: 55, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 5, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 2, typeId: UNIT_DESERTIERTERKANONIER_DK_ID },
      ],
    },
    {
      sector: "C",
      number: 2,
      type: CAMP_TYPE_N,
      art: "",
      boss: Ja,
      units: [
        { amount: 20, typeId: UNIT_DESERTIERTEMILIZ_DM_ID },
        { amount: 40, typeId: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID },
        { amount: 1, typeId: UNIT_DICKEBERTHA_DB_ID },
      ],
    },
    {
      sector: "D",
      number: 1,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 1, typeId: UNIT_SCHATZ_SCHAT_ID },
      ],
    },
    {
      sector: "D",
      number: 2,
      type: CAMP_TYPE_N,
      art: "mittel",
      boss: Nein,
      units: [
        { amount: 50, typeId: UNIT_DESERTIERTERREKRUT_DR_ID },
        { amount: 1, typeId: UNIT_SCHATZ_SCHAT_ID },
      ],
    },
  ],
} as Unternehmung;

export function getExcelsiorAdventureById(id: UnternehmungenIds): Unternehmung {
  const myId = id;
  return getExcelsiorAdventureByExpression(
    (at): at is Unternehmung => at.id === myId
  )[0];
}

export function getAllExcelsiorAdventures(): Unternehmung[] {
  return getExcelsiorAdventureByExpression();
}

export function getExcelsiorAdventureByExpression(
  expr: (at: Unternehmung) => at is Unternehmung = (at): at is Unternehmung =>
    true
): Unternehmung[] {
  const ats = [
    AmFuSSedesBerges,
    UnbekannteRegionen,
    Berglabyrinth,
    DasBergvolk,
    EinRiesenkampf,
    VomFischerundseinerFrau,
    HÄnselundGretel,
    DerRattenfÄngervonHameln,
    RotkÄppchen,
    Schneewittchen,
    AngriffderNordmÄnner,
    DerChupacabra,
    DieverloreneStadt,
    EinenSchrittvoraus,
    Tikki_Insel,
    DasQuartierdesDrachen,
    VonLiedernundFlÜchen,
    WertvolleInformationen,
  ];
  return ats.filter(expr).map((at) => ({
    ...at,
    camps: at.camps.map((camp) => ({
      ...camp,
      units: camp.units.map((unit) => ({ ...unit })),
    })),
  }));
}
