export function convertToNumber(value?: string | number) {
  if (value) {
    if (typeof value === "number") {
      return value;
    }
    try {
      if (value.length) {
        return parseInt(value);
      }
    } catch (e) {
      // ignore and return 0
    }
  }
  return 0;
}
