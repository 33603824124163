import isEqual from 'lodash.isequal';
import md5 from 'md5';
import { CampEntry, FinderState, GeneralResult, SearchResult } from '../../app/store';
import { initialState } from '../../app/storeUtil';
import { General } from '../../data-objects/units/Army';
import { addMultipleResults, addSearchResult, SearchResultActions, startNewSearch } from './searchArmyActions';
import { SearchArmyActionTypes } from './searchArmyActionTypes';
import { mergeSearchResults } from './util';

function addSearchResults(state: FinderState, general: General, results: SearchResult[], isAttack?: true): FinderState {
  if (!results.length) {
    return state;
  }

  const found = isAttack ? 'attack' : 'found';
  const { uuid, id } = general;
  // We can use generalId here, since we cannot apply skills to "standard" generals
  // All custom generals have an uuid anyway
  const generalId = uuid ? uuid : id;

  const currentSearch = state.currentSearch;
  if (!currentSearch) {
    return state;
  }
  const { campKey } = currentSearch;
  const oldCampEntry = state[found][campKey];

  const backupResult: GeneralResult = {
    general,
    results
  };
  const existingGenEntry = oldCampEntry.simulations[generalId];

  if (existingGenEntry) {
    backupResult.results = mergeSearchResults(existingGenEntry.results, backupResult.results);
    backupResult.general = existingGenEntry.general;
  }

  return {
    ...state,
    [found]: {
      ...state[found],
      [campKey]: {
        ...oldCampEntry,
        simulations: {
          ...oldCampEntry.simulations,
          [generalId]: backupResult
        }
      }
    }
  };
}

function handleAddMultipleResults(state: FinderState, action: ReturnType<typeof addMultipleResults>) {
  const { general, results, isAttack } = action;
  return addSearchResults(state, general, results, isAttack);
}

function handleAddSearchResult(state: FinderState, action: ReturnType<typeof addSearchResult>) {
  const { general, result, isAttack } = action;
  return addSearchResults(state, general, [result], isAttack);
}

function handleFinishSearch(state: FinderState) {
  return {
    ...state,
    currentSearch: undefined
  };
}

function handleStartNewSearch(state: FinderState, action: ReturnType<typeof startNewSearch>) {
  const { clearPrevious, adventureId, camps, isAttack } = action;

  let campKey: string;
  const found = isAttack ? 'attack' : 'found';
  let newFound: Record<string, CampEntry> = clearPrevious ? {} : state[found];

  const existing = Object.entries(newFound).find(([key, results]) => isEqual(results.bandits, camps));
  if (existing) {
    campKey = existing[0];
  } else {
    campKey = md5(JSON.stringify(camps));
    newFound = {
      ...newFound,
      [campKey]: {
        adventureId,
        bandits: camps,
        simulations: {}
      }
    };
  }

  return {
    ...state,
    [found]: newFound,
    currentSearch: { campKey, isAttack: isAttack ?? false }
  };
}

export default function searchArmyReducer(
  state: FinderState | undefined = initialState.searchArmy,
  action: SearchResultActions
): FinderState {
  switch (action.type) {
    case SearchArmyActionTypes.ADD_SEARCH_RESULT:
      return handleAddSearchResult(state, action);
    case SearchArmyActionTypes.ADD_MULTIPLE_RESULTS:
      return handleAddMultipleResults(state, action);
    case SearchArmyActionTypes.START_NEW_SEARCH:
      return handleStartNewSearch(state, action);
    case SearchArmyActionTypes.ABORT_SEARCH:
    case SearchArmyActionTypes.FINISHED_SEARCH:
      return handleFinishSearch(state);
    default:
      return state;
  }
}
