export enum UIActionTypes {
  REGISTER_SEARCH_SPACE = 'search.max.amount',
  UPDATE_SEARCH_PROGRESS = 'search.progress.update',
  SET_SEARCH_STATUS = 'search.update.status',
  TOGGLE_SELECTED_GENERALS = 'search.update.generals',
  TOGGLE_SELECTED_UNITS = 'search.update.units',
  TOGGLE_SELECTED_EFFECT = 'search.update.effect',
  SET_LAZARETT_EFFECT = 'search.set.lazarett.effect',
  SET_KANTINE_EFFECT = 'search.set.kantine.effect',
  UPDATE_VALUE = 'search.update.value',
  TOGGLE_KEEP_PREVIOUS = 'search.update.keep.previous',
  CHANGE_DISPLAYED_RESULT = 'change.displayed.result'
}
