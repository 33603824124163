import { useMemo } from 'react';
import styled from 'styled-components';
import { AttackSearchResult, DetailedResult } from '../../../app/store';
import { useUnitValues } from '../../../redux/selectors';
import { BaseProps } from '../../../types/basics';
import { computeLossData } from '../utils';
import { weatherKeys } from './Constants';
import { TheTable } from './TheTable';

interface DetailedResultProps extends BaseProps {
  results: Array<DetailedResult | AttackSearchResult>;
  disableSort?: boolean;
  disableWave?: boolean;
}

function DetailedResultTableUnstyled({ results, disableSort, disableWave, ...otherProps }: DetailedResultProps) {
  const unitValues = useUnitValues();
  const { realData, weatherActive, showCompactTable } = useMemo(() => {
    const realData = results.map((result, index) => computeLossData({ ...{ result, unitValues, index } }));
    const weather = results.find(result => weatherKeys.filter(effect => result.weather[effect]).length);
    const weatherActive = weather !== undefined;

    const attackDetails = results.find(result => 'details' in result && result.details.length > 1);
    const showCompactTable = attackDetails !== undefined;

    return { realData, weatherActive, showCompactTable };
  }, [results, unitValues]);

  return (
    <TheTable
      {...otherProps}
      data={realData}
      weatherActive={weatherActive}
      showCompactTable={showCompactTable}
      disableSort={disableSort}
      disableWave={disableWave}
    />
  );
}

export const DetailedResultTable = styled(DetailedResultTableUnstyled)`
  display: grid;
  text-align: center;
  grid-row-gap: 0;
  grid-column-gap: 0;
  background-color: var(--background-color-light);

  & tcgzhable {
    font-size: 0.8rem;
    border-spacing: 0;
    border-top: 1px solid black;

    .table-header-full,
    .table-header-compact {
      font-size: 1rem;
      font-weight: 600;
      background-color: var(--primary-color-light);
      color: var(--text-on-primary);

      & .th-full,
      .th-compact {
        padding: 1rem 4px;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
          border-right: 0;
        }
      }
    }
    tbody {
      & > tr:nth-child(even) {
        background-color: var(--background-color);
      }
      & > tr:nth-child(odd) {
        background-color: var(--background-color-light);
      }
      & > tr:hover {
        background-color: var(--background-color-dark);
      }

      & td {
        text-align: center;
        padding: 1rem 4px;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
          border-right: 0;
        }
      }
    }
  }
`;
