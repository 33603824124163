import { v4 } from 'uuid';
import { ConfigurationState } from '../../app/store';
import { initialState } from '../../app/storeUtil';
import { CustomGeneralEntry, GeneralsList } from '../../components/Configurations/GeneralConfiguration';
import {
  addCustomGeneral,
  changeAmountOfMaxBlocks,
  changeDefaultBlockLength,
  changePreferedHorseShoe,
  changeSimulationsPerBlock,
  changeUnitValue,
  ConfigurationActions,
  createNewGeneralList,
  deleteConfigurationList,
  importConfiguration,
  removeCustomGeneral,
  selectConfigurationList,
  toggleBlockEliteUnitSelection,
  toggleBlockGeneralSelection,
  toggleBlockUnitSelection,
  updateCustomGeneral,
  updateGeneralOrder
} from './configurationActions';
import { ConfigurationActionTypes } from './configurationActionTypes';
import update from 'immutability-helper';

function handleChangeUnitValue(
  state: ConfigurationState,
  action: ReturnType<typeof changeUnitValue>
): ConfigurationState {
  const { unitId, newValue } = action;

  return {
    ...state,
    unitValues: { ...state.unitValues, [unitId]: newValue }
  };
}

function handleAddCustomGeneral(
  state: ConfigurationState,
  action: ReturnType<typeof addCustomGeneral>
): ConfigurationState {
  const { generalId, name, icon, skills, preferedAttack, preferedBlock } = action;

  const newGeneral: CustomGeneralEntry = {
    id: v4(),
    baseId: generalId,
    name,
    icon,
    skills,
    prefersAttack: preferedAttack,
    prefersBlock: preferedBlock
  };
  return {
    ...state,
    generalsList: state.generalsList.map((entry, index) =>
      index === state.selectedGeneralsList ? { ...entry, generals: [...entry.generals, newGeneral] } : entry
    )
  };
}

function handleUpdateCustomGeneral(
  state: ConfigurationState,
  action: ReturnType<typeof updateCustomGeneral>
): ConfigurationState {
  const { generalId, name, icon, skills, preferedAttack, preferedBlock } = action;

  return {
    ...state,
    generalsList: state.generalsList.map((entry, index) =>
      index === state.selectedGeneralsList
        ? {
            ...entry,
            generals: entry.generals.map(general => {
              if (general.id !== generalId) {
                return general;
              }
              return {
                ...general,
                name,
                icon,
                skills,
                prefersAttack: preferedAttack,
                prefersBlock: preferedBlock
              };
            })
          }
        : entry
    )
  };
}

function handleDeleteCustomGeneral(
  state: ConfigurationState,
  action: ReturnType<typeof removeCustomGeneral>
): ConfigurationState {
  const { uuid } = action;
  state.generalsList[state.selectedGeneralsList].generals.filter(general => general.id !== uuid);
  return {
    ...state,
    generalsList: state.generalsList.map((entry, index) => {
      if (index !== state.selectedGeneralsList) {
        return entry;
      }
      return {
        ...entry,
        generals: entry.generals.filter(general => general.id !== uuid)
      };
    })
  };
}

function handleCreateGeneralList(
  state: ConfigurationState,
  action: ReturnType<typeof createNewGeneralList>
): ConfigurationState {
  const { name } = action;

  return {
    ...state,
    generalsList: [...state.generalsList, { name, id: v4(), generals: [] }]
  };
}
function handleDeleteGeneralList(
  state: ConfigurationState,
  action: ReturnType<typeof deleteConfigurationList>
): ConfigurationState {
  const { uuid } = action;
  return {
    ...state,
    generalsList: state.generalsList.filter(general => general.id !== uuid)
  };
}

function handleSelectConfigurationList(
  state: ConfigurationState,
  action: ReturnType<typeof selectConfigurationList>
): ConfigurationState {
  const { id } = action;

  let index = 0;
  state.generalsList.some((list, idx) => {
    index = idx;
    return list.id === id;
  });

  return {
    ...state,
    selectedGeneralsList: index
  };
}

function handleImportConfiguration(
  state: ConfigurationState,
  action: ReturnType<typeof importConfiguration>
): ConfigurationState {
  const { configuration } = action;

  // TODO: replace ids and names
  // configuration.generalsList.map(config => config);

  return {
    ...state,
    unitValues: configuration.unitValues || state.unitValues,
    generalsList: configuration.generalsList || state.generalsList
  };
}

function handleToggleDarkMode(state: ConfigurationState): ConfigurationState {
  return {
    ...state,
    darkMode: !state.darkMode
  };
}

function toggleFromList(list: number[], entry: number) {
  if (list.includes(entry)) {
    return list.filter(num => num !== entry);
  }
  return [...list, entry];
}

function handleToggleBlockGenerals(
  state: ConfigurationState,
  action: ReturnType<typeof toggleBlockGeneralSelection>
): ConfigurationState {
  const { id } = action;
  return {
    ...state,
    blockPreferences: {
      ...state.blockPreferences,
      defaultBlockGenerals: toggleFromList(state.blockPreferences.defaultBlockGenerals, id)
    }
  };
}
function handleToggleBlockUnit(
  state: ConfigurationState,
  action: ReturnType<typeof toggleBlockUnitSelection>
): ConfigurationState {
  const { id } = action;
  return {
    ...state,
    blockPreferences: {
      ...state.blockPreferences,
      defaultBlockUnits: {
        ...state.blockPreferences.defaultBlockUnits,
        normal: toggleFromList(state.blockPreferences.defaultBlockUnits.normal, id)
      }
    }
  };
}
function handleToggleBlockEliteUnit(
  state: ConfigurationState,
  action: ReturnType<typeof toggleBlockEliteUnitSelection>
): ConfigurationState {
  const { id } = action;
  return {
    ...state,
    blockPreferences: {
      ...state.blockPreferences,
      defaultBlockUnits: {
        ...state.blockPreferences.defaultBlockUnits,
        elite: toggleFromList(state.blockPreferences.defaultBlockUnits.elite, id)
      }
    }
  };
}
function handleChangeMaxBlocks(
  state: ConfigurationState,
  action: ReturnType<typeof changeAmountOfMaxBlocks>
): ConfigurationState {
  const { value } = action;
  return {
    ...state,
    blockPreferences: { ...state.blockPreferences, maxResults: value }
  };
}
function handleChangeBlocksimulations(
  state: ConfigurationState,
  action: ReturnType<typeof changeSimulationsPerBlock>
): ConfigurationState {
  const { value } = action;
  return {
    ...state,
    blockPreferences: {
      ...state.blockPreferences,
      detailedBlockSimulations: value
    }
  };
}
function handleChangeDefaultBlockLength(
  state: ConfigurationState,
  action: ReturnType<typeof changeDefaultBlockLength>
): ConfigurationState {
  const { value } = action;
  return {
    ...state,
    blockPreferences: { ...state.blockPreferences, searchValue: value }
  };
}

function handleChangePreferedHorseshoe(
  state: ConfigurationState,
  action: ReturnType<typeof changePreferedHorseShoe>
): ConfigurationState {
  const { value } = action;

  return {
    ...state,
    blockPreferences: { ...state.blockPreferences, hufeisen: value }
  };
}

function handleUpdateGeneralListOrder(
  state: ConfigurationState,
  action: ReturnType<typeof updateGeneralOrder>
): ConfigurationState {
  const { hoverGeneralId, dropGeneralId } = action;
  const generalsListToUpdate = state.generalsList[state.selectedGeneralsList];
  const availableGens = generalsListToUpdate.generals;

  const fromIndex = availableGens.findIndex(availableGen => availableGen.id === hoverGeneralId);
  const toIndex = availableGens.findIndex(availableGen => availableGen.id === dropGeneralId);

  const generalEntry = availableGens[fromIndex];

  const newGeneralList: GeneralsList = {
    ...generalsListToUpdate,
    generals: update([...generalsListToUpdate.generals], {
      $splice: [
        [fromIndex, 1],
        [toIndex, 0, generalEntry]
      ]
    })
  };
  return {
    ...state,
    generalsList: state.generalsList.map((list, index) =>
      index !== state.selectedGeneralsList ? list : newGeneralList
    )
  };
}

export default function configurationReducer(
  state: ConfigurationState | undefined = initialState.configuration,
  action: ConfigurationActions
): ConfigurationState {
  switch (action.type) {
    case ConfigurationActionTypes.CHANGE_UNIT_VALUE:
      return handleChangeUnitValue(state, action);
    case ConfigurationActionTypes.ADD_CUSTOM_GENERAL:
      return handleAddCustomGeneral(state, action);
    case ConfigurationActionTypes.UPDATE_CUSTOM_GENERAL:
      return handleUpdateCustomGeneral(state, action);
    case ConfigurationActionTypes.DELETE_CUSTOM_GENERAL:
      return handleDeleteCustomGeneral(state, action);
    case ConfigurationActionTypes.CREATE_GENERAL_LIST:
      return handleCreateGeneralList(state, action);
    case ConfigurationActionTypes.SELECT_GENERAL_LIST:
      return handleSelectConfigurationList(state, action);
    case ConfigurationActionTypes.DELETE_GENERAL_LIST:
      return handleDeleteGeneralList(state, action);
    case ConfigurationActionTypes.IMPORT_CONFIGURATION:
      return handleImportConfiguration(state, action);
    case ConfigurationActionTypes.TOGGLE_DARK_MODE:
      return handleToggleDarkMode(state);
    case ConfigurationActionTypes.CHANGE_BLOCK_GENERAL:
      return handleToggleBlockGenerals(state, action);
    case ConfigurationActionTypes.TOGGLE_BLOCK_NORMAL_UNIT:
      return handleToggleBlockUnit(state, action);
    case ConfigurationActionTypes.TOGGLE_BLOCK_ELITE_UNIT:
      return handleToggleBlockEliteUnit(state, action);
    case ConfigurationActionTypes.CHANGE_MAX_FOUND_BLOCKS:
      return handleChangeMaxBlocks(state, action);
    case ConfigurationActionTypes.CHANGE_SIMULATIONS_PER_BLOCK:
      return handleChangeBlocksimulations(state, action);
    case ConfigurationActionTypes.CHANGE_DEFAULT_BLOCK_LENGTH:
      return handleChangeDefaultBlockLength(state, action);
    case ConfigurationActionTypes.CHANGE_PREFERED_HORSESHOE:
      return handleChangePreferedHorseshoe(state, action);
    case ConfigurationActionTypes.UPDATE_GENERAL_LIST:
      return handleUpdateGeneralListOrder(state, action);
    default:
      return state;
  }
}
