import { FullResult } from "../../worker/simulate";

export function getTimeFromResult(
  result: { rounds: FullResult["rounds"] },
  timePerRound: number
): number {
  const roundTimes = Object.keys(result.rounds).map(
    (round) => parseInt(round) * timePerRound
  );
  return (
    roundTimes.reduce((sumTime, time) => sumTime + time, 0) / roundTimes.length
  );
}

interface RoundsFromResult {
  rounds: FullResult["rounds"];
  simulations: FullResult["simulations"];
}
export function getAverageRoundsFromResult(result: RoundsFromResult) {
  const rounds = Object.entries(result.rounds).reduce(
    (sum, [round, times]) => sum + parseInt(round) * times,
    0
  );
  return rounds / result.simulations;
}
