import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import SelectableElement from '../components/Basics/SelectableElement';
import ToggleButton from '../components/Basics/ToggleButton';
import ValidatingInput from '../components/Basics/ValidatingInput';
import GeneralConfiguration from '../components/Configurations/GeneralConfiguration';
import LabeledImage from '../components/LabeledImage';
import hufeisen from '../data-objects/hufeisen';
import { getUnitById, UnitId } from '../data-objects/units/Army';
import handleSaveToPC from '../downloader/startDownload';
import * as actions from '../redux/configuration/configurationActions';
import {
  useConfiguration,
  useDarkMode,
  useGeneralsList,
  useSelectedGeneralsList,
  useUnitValues
} from '../redux/selectors';
import { BaseProps } from '../types/basics';

interface ConfigurationProps extends BaseProps {}

function ConfigurationPage({ ...otherProps }: ConfigurationProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const unitValues = useUnitValues();
  const dispatch = useDispatch();

  const generalsList = useGeneralsList();
  const darkMode = useDarkMode();
  const selectedGeneralsList = useSelectedGeneralsList();
  const configuration = useConfiguration();

  function handleValueChange(e: React.ChangeEvent<HTMLInputElement>, unitId: UnitId) {
    try {
      dispatch(actions.changeUnitValue(unitId, parseInt(e.target.value)));
    } catch {
      // Ignore
    }
  }
  return (
    <div {...otherProps}>
      <div className='card-entry configuration-card'>
        <div className='headline'>{t('Simulator Einstellungen')}</div>
        <div className='sub-header'>
          {t(
            'Hier kannst du verschiedene Einstellungen zum Simulator einstellen. Diese Einstellungen koennen auch importiert und exportiert werden.'
          )}
        </div>
      </div>
      <div className='card-entry configuration-card'>
        <div className='sub-header'>{t('Generalsverwaltung')}</div>
        <div className='sub-sub-header'>
          {t(
            'Verwalte deine eigenen Generale hier. Du kannst auch zusätzliche Listen anlegen, um z.B. Vergleichsskillungen zu verwalten oder ähnliches.'
          )}
        </div>
        <GeneralConfiguration
          generalsList={generalsList}
          currentSelectedEntry={selectedGeneralsList}
          blockPreferences={configuration.blockPreferences}
          updateGeneralOrder={(hoverGeneralId, dropGeneralId) =>
            dispatch(actions.updateGeneralOrder(hoverGeneralId, dropGeneralId))
          }
          addGeneral={(generalId, skills, name, iconId, preferedBlock, preferedAttack) =>
            dispatch(actions.addCustomGeneral(generalId, skills, name, iconId, preferedBlock, preferedAttack))
          }
          deleteGeneral={id => dispatch(actions.removeCustomGeneral(id))}
          updateGeneral={(generalId, skills, name, iconId, preferedBlock, preferedAttack) =>
            dispatch(actions.updateCustomGeneral(generalId, skills, name, iconId, preferedBlock, preferedAttack))
          }
          addNewList={name => dispatch(actions.createNewGeneralList(name))}
          selectList={id => dispatch(actions.selectConfigurationList(id))}
          deleteList={id => dispatch(actions.deleteConfigurationList(id))}
        />
      </div>
      <div className='card-entry configuration-card'>
        <div className='sub-header'>{t('Einheitenwerte')}</div>
        <div className='value-table'>
          {Object.keys(unitValues).map(unitId => {
            const myUnitId = parseInt(unitId) as UnitId;
            return (
              <div key={unitId} className='value-entry'>
                <div className='the-unit'>{t(getUnitById(myUnitId).name)}</div>
                <input
                  type='number'
                  className='the-value'
                  value={unitValues[myUnitId]}
                  onChange={e => handleValueChange(e, myUnitId)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className='card-entry configuration-card'>
        <div className='sub-header'>{t('Locksuche')}</div>
        <div className='two-column-configurations'>
          <div className='config-entry'>
            <label>Maximale Anzahl Suchergebnisse pro General </label>
            <ValidatingInput
              className='the-value'
              value={configuration.blockPreferences.maxResults}
              minValue={1}
              onValidChange={useCallback(num => dispatch(actions.changeAmountOfMaxBlocks(num)), [dispatch])}
            />
          </div>
          <div className='config-entry'>
            <label>Anzahl Simulationen für gefundene Löcke </label>
            <ValidatingInput
              className='the-value'
              value={configuration.blockPreferences.detailedBlockSimulations}
              minValue={10}
              maxValue={10000000}
              onValidChange={useCallback(num => dispatch(actions.changeSimulationsPerBlock(num)), [dispatch])}
            />
          </div>
          <div className='config-entry'>
            <label>Standard Suchzeit (in Sekunden) für Locksuche </label>
            <ValidatingInput
              className='the-value'
              value={configuration.blockPreferences.searchValue}
              minValue={20}
              toSmallValueMsg={'Mindestens 20 Sekunden'}
              onValidChange={useCallback(num => dispatch(actions.changeDefaultBlockLength(num)), [dispatch])}
            />
          </div>
          {/*<div className="two-column-entry">
            <label>Standard Lockgeneräle </label>
            <div className="unit-list">
              {availableGenerals.map((unit) => (
                <SelectableElement
                  onClick={() =>
                    dispatch(actions.toggleBlockGeneralSelection(unit.id))
                  }
                  selected={configuration.blockPreferences.defaultBlockGenerals.includes(
                    unit.id
                  )}
                  key={unit.id}
                >
                  <LabeledImage unit={unit} />
                </SelectableElement>
              ))}
            </div>
          </div>
          <div className="two-column-entry">
            <label>Standard Lockeinheiten </label>
            <div className="unit-list">
              {ourDefaultArmy.map((unit) => (
                <SelectableElement
                  key={unit.id}
                  onClick={() =>
                    dispatch(actions.toggleBlockUnitSelection(unit.id))
                  }
                  selected={configuration.blockPreferences.defaultBlockUnits.normal.includes(
                    unit.id
                  )}
                >
                  <LabeledImage unit={unit} />
                </SelectableElement>
              ))}
            </div>
          </div>
          <div className="two-column-entry">
            <label>Standard Elite-Lockeinheiten </label>
            <div className="unit-list">
              {ourEliteArmy.map((unit) => (
                <SelectableElement
                  key={unit.id}
                  onClick={() =>
                    dispatch(actions.toggleBlockEliteUnitSelection(unit.id))
                  }
                  selected={configuration.blockPreferences.defaultBlockUnits.elite.includes(
                    unit.id
                  )}
                >
                  <LabeledImage unit={unit} />
                </SelectableElement>
              ))}
            </div>
                  </div>*/}
          <div className='two-column-entry'>
            <label>Bevorzugtes Hufeisen </label>
            <div className='unit-list'>
              {Object.entries(hufeisen).map(([name, values]) => (
                <SelectableElement
                  key={name}
                  onClick={() => dispatch(actions.changePreferedHorseShoe(name as keyof typeof hufeisen))}
                  selected={configuration.blockPreferences.hufeisen === name}>
                  <LabeledImage unit={values} text={t(name)} />
                </SelectableElement>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='card-entry configuration-card'>
        <div className='sub-header'>{t('Verschiedenes')}</div>
        <div className='two-column-configurations'>
          <div className='config-entry'>
            <label>Dark Mode: </label>
            <ToggleButton selected={darkMode} toggleSelected={() => dispatch(actions.toggleDarkMode())} />
          </div>
        </div>
      </div>
      <div className='card-entry configuration-card'>
        <div className='sub-header'>{t('Export/ Import')}</div>
        <div className='import-export'>
          <div className='primary-button' onClick={() => handleSaveToPC(configuration)}>
            <div>{t('Exportiere Einstellungen')}</div>
          </div>
          <div>
            <div className='primary-button' onClick={() => inputRef.current?.click()}>
              <div>{t('Importiere Einstellungen')}</div>
            </div>
            <input
              ref={inputRef}
              className='hidden-input'
              type='file'
              accept='.json'
              onChange={e => {
                if (e && e.target && e.target.files) {
                  const fileReader = new FileReader();
                  fileReader.onloadend = () => {
                    const content = fileReader.result as string;
                    let importedConfig;
                    try {
                      importedConfig = JSON.parse(content);
                    } catch (e) {
                      console.log('invalid json provided.');
                    }

                    if (!importedConfig) {
                      return;
                    }

                    dispatch(actions.importConfiguration(importedConfig));
                  };

                  fileReader.readAsText(e.target.files[0]);
                }
              }}></input>
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledConfiguration = styled(ConfigurationPage)`
  padding: 16px;
  background-color: var(--background-color-dark);
  color: var(--color);

  & > .card-entry.configuration-card {
    margin: 0 1em 3em;
    padding: 8px;
    background-color: var(--background-color);
    color: var(--color);
  }

  .headline {
    font-weight: 800;
    font-size: 1.4em;
    padding-bottom: 16px;
  }

  .sub-header {
    font-weight: 600;
    font-size: 1.2em;
    padding-bottom: 8px;
  }
  .sub-sub-header {
    font-weight: 400;
    font-size: 1em;
    padding-bottom: 8px;
  }

  .import-export {
    display: flex;
    justify-content: space-around;
    margin: 28px;
  }

  .value-table {
    padding: 16px 28px;
    display: grid;
    grid-template-columns: max-content 80px;
    grid-column-gap: 26px;

    .value-entry {
      display: contents;
    }
  }

  .hidden-input {
    display: none;
  }

  ${GeneralConfiguration} {
    padding: 16px 28px;
  }

  .unit-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
    justify-content: flex-end;

    & > .selectable-element {
      margin-left: 8px;

      & ${LabeledImage} {
        height: 100%;

        & > img {
          max-height: 65px;
        }
      }
    }
  }
  .two-column-configurations {
    padding-top: 8px;
    padding-left: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    & .config-entry,
    .two-column-entry {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > label {
        align-self: center;
      }
    }

    & .two-column-entry {
      grid-column: 1 / span 2;
    }
  }
`;

export default StyledConfiguration;
