import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SearchInput, SearchWeather } from '../app/store';
import DisplayCamp from '../components/Basics/DisplayCamp';
import FoldableElement from '../components/Basics/FoldableElement';
import ToggleButton from '../components/Basics/ToggleButton';
import ValidatingInput from '../components/Basics/ValidatingInput';
import { MappedCustomGeneral } from '../components/Configurations/GeneralConfiguration';
import LabeledImage from '../components/LabeledImage';
import { UserInputUnits } from '../components/units/UnitSelection';
import { getAdventureById } from '../data-objects/adventures';
import { CampBuilding } from '../data-objects/CampTypes';
import { GlobalEffects, LazarettBuff } from '../data-objects/GlobalEffects';
import hufeisen from '../data-objects/hufeisen';
import { General, UnitId } from '../data-objects/units/Army';
import { useBlockPreferences, useCurrentSelectedAdventure, useGeneralsList } from '../redux/selectors';
import { availableGenerals, createMappedGenerals, ourDefaultArmy, ourEliteArmy } from '../redux/unitUtil';
import { BaseProps } from '../types/basics';
import SearchBuffSelection from './SearchBuffSelection';
import createGlobalEffectVariants from './utils/createGlobalEffectVariants';

interface SearchParameterInputProps extends BaseProps, SearchInput {
  finishConfiguration: (
    value: number,
    selectedUnits: number[],
    selectedGenerals: (number | string)[],
    clearPrevious: boolean,
    selectedEffects: GlobalEffects[]
  ) => void;
  enemies?: Array<UserInputUnits>;
  camps?: Array<CampBuilding>;
  hideValue?: boolean;
  headerLabel: string;
  setValue: (value: number) => void;
  toggleEffect: (effect: keyof SearchWeather) => void;
  toggleLazarett: (stufe: LazarettBuff['stufe'], value: LazarettBuff['value']) => void;
  toggleKantine: (value: GlobalEffects['kantine']) => void;
  toggleUnits: (unitId: Array<number>) => void;
  toggleGenerals: (generalId: Array<number | string>) => void;
  toggleKeepResults: () => void;
}

const ourDefaultArmyIds = ourDefaultArmy.map(unit => unit.id);
const ourEliteIds = ourEliteArmy.map(unit => unit.id);

interface InternalSearchGeneralLists {
  generals: (General | MappedCustomGeneral)[];
  id: number | string;
  name: string;
  initiallyOpen?: boolean;
}
function SearchParameterInput({
  finishConfiguration,
  selectedEffects,
  selectedGenerals,
  selectedUnits,
  keepPreviousResults,
  value,
  enemies,
  camps,
  hideValue,
  headerLabel,
  setValue,
  toggleEffect,
  toggleLazarett,
  toggleKantine,
  toggleGenerals,
  toggleUnits,
  toggleKeepResults,
  ...otherProps
}: SearchParameterInputProps) {
  const blockPreferences = useBlockPreferences();

  const generalsList = useGeneralsList();
  const adventureId = useCurrentSelectedAdventure();
  const { t } = useTranslation();

  const thisAdventure = adventureId !== null ? getAdventureById(adventureId) : null;

  const mappedGenerals = generalsList.map((list, index) => ({
    ...list,
    initiallyOpen: index === 0,
    generals: createMappedGenerals(list.generals)
  }));
  const hasSomeCustomGens = generalsList.some(list => list.generals.length > 0);
  const finderGenerals: InternalSearchGeneralLists[] = [
    {
      generals: availableGenerals,
      id: -42,
      name: 'Standard',
      initiallyOpen: !hasSomeCustomGens
    },
    ...mappedGenerals
  ];

  function selectGenerals(generals: { id: number | string; uuid?: string }[]) {
    const newGenIds = generals.map(gen => gen.uuid ?? gen.id);
    toggleGenerals(newGenIds);
  }

  function selectGeneral(unitId: number | string) {
    toggleGenerals([unitId]);
  }

  function selectUnit(unitId: number | string) {
    if (typeof unitId === 'number') {
      toggleUnits([unitId]);
    }
  }

  const selectAllUnitsString = t('Alle Einheiten');
  const singleGeneralString = useMemo(() => t('Einzelne Generale'), [t]);
  const singleUnitSelectionString = useMemo(() => t('Einzelne Einheiten'), [t]);

  const selectedEliteUnits = selectedUnits.reduce(
    (sum, unitId) => sum + (ourEliteIds.includes(unitId as UnitId) ? 1 : 0),
    0
  );
  const selectedNormalUnits = selectedUnits.length - selectedEliteUnits;

  const normalUnitsString = `${singleUnitSelectionString} (${selectedNormalUnits}/${ourDefaultArmyIds.length})`;
  const eliteUnitsString = `${singleUnitSelectionString} (${selectedEliteUnits}/${ourEliteIds.length})`;

  const eisen = hufeisen[blockPreferences.hufeisen];
  return (
    <div {...otherProps}>
      <div className='search-header'>
        <div className='search-header-text'>{headerLabel}</div>
        <div className='search-camps'>
          {enemies &&
            !true &&
            camps &&
            enemies.map((enemy, index) => (
              <DisplayCamp key={JSON.stringify(enemy)} units={enemy} camp={camps[index]} />
            ))}
        </div>
      </div>
      <div className='finder-generals'>
        <div className='finder-header'>{t('Welche Generale sollen verwendet werden?')}</div>
        {finderGenerals.map(listOfGenerals => {
          if (listOfGenerals.generals.length <= 0) {
            return null;
          }
          const countSelected = listOfGenerals.generals.reduce(
            (sum, general) => sum + (selectedGenerals.includes(general.uuid ?? general.id) ? 1 : 0),
            0
          );
          const generalString = `${singleGeneralString} (${countSelected}/${listOfGenerals.generals.length})`;
          return (
            <div key={listOfGenerals.id}>
              <div style={{ fontWeight: 600 }}>{`Generalsliste: ${listOfGenerals.name}`}</div>
              <FoldableElement
                className='finder-generals-selection'
                initiallyOpen={listOfGenerals.initiallyOpen}
                renderCustomHeader={({ toggleOpen }) =>
                  renderCustomHeader(toggleOpen, generalString, t('Gesamte Liste'), () =>
                    selectGenerals(listOfGenerals.generals)
                  )
                }>
                <UnitList units={listOfGenerals.generals} selectedUnits={selectedGenerals} selectUnit={selectGeneral} />
              </FoldableElement>
            </div>
          );
        })}
      </div>
      <div className='finder-army'>
        <div className='finder-header'>{t('Welche Einheiten sollen verwendet werden?')}</div>
        <div>
          <div style={{ fontWeight: 600 }}>{`Normale Einheiten:`}</div>
          <FoldableElement
            initiallyOpen={!thisAdventure?.allowsElite}
            renderCustomHeader={({ toggleOpen }) =>
              renderCustomHeader(toggleOpen, normalUnitsString, selectAllUnitsString, () =>
                toggleUnits(ourDefaultArmyIds)
              )
            }>
            <UnitList units={ourDefaultArmy} selectedUnits={selectedUnits} selectUnit={selectUnit} />
          </FoldableElement>
        </div>
        {thisAdventure?.allowsElite && (
          <div>
            <div style={{ fontWeight: 600 }}>{`Elite Einheiten:`}</div>
            <FoldableElement
              initiallyOpen
              renderCustomHeader={({ toggleOpen }) =>
                renderCustomHeader(toggleOpen, eliteUnitsString, selectAllUnitsString, () => toggleUnits(ourEliteIds))
              }>
              <UnitList units={ourEliteArmy} selectedUnits={selectedUnits} selectUnit={selectUnit} />
            </FoldableElement>
          </div>
        )}
      </div>
      <div className='finder-configuration'>
        <div>
          <div className='finder-header'>{t('Suche Einstellungen')}</div>
        </div>
        <div className='finder-old-results'>
          <div>{t('Behalte vorherige Suchergebnisse')}</div>
          <ToggleButton
            selected={keepPreviousResults}
            toggleSelected={toggleKeepResults}
            onLabel='Ja'
            offLabel='Nein'
          />
        </div>
        {!hideValue && (
          <div className='finder-configuration-input'>
            <label htmlFor='finder-input-id'>{t('Gesuchte Lockzeit')}</label>
            <ValidatingInput
              className='finder-input-field'
              value={value}
              minValue={20}
              toSmallValueMsg={'Mindestens 20 Sekunden'}
              onValidChange={value => setValue(value)}
            />
            <label htmlFor='finder-input-id'>{t('sek')}</label>
          </div>
        )}
        {!hideValue && (
          <LabeledImage
            unit={{ icon: eisen.icon }}
            text={`${(value / eisen.value(selectedEffects.bk_snow)).toFixed(1)} FA`}
            style={{ height: '35px' }}
          />
        )}
        <SearchBuffSelection
          selectedAdventure={adventureId!}
          effects={selectedEffects}
          toggleEffect={toggleEffect}
          toggleLazarett={toggleLazarett}
          toggleKantine={toggleKantine}
        />
      </div>
      <div className='finish-button'>
        <div className='input-validation-msg'>
          {selectedGenerals.length > 2 ? 'Beachte bitte: Bei mehreren Generalen kann die Suche länger dauern.' : null}
        </div>
        <div
          className='primary-button'
          onClick={() => {
            const effectList = createGlobalEffectVariants(selectedEffects);
            finishConfiguration(value, selectedUnits, selectedGenerals, !keepPreviousResults, effectList);
          }}>
          <span>
            <span>{t('Start')}</span>
            <span
              style={{
                paddingLeft: '2ch',
                textAlign: 'center',
                fontSize: '0.8em'
              }}>{`(${selectedGenerals.length} Generale & ${selectedUnits.length} Einheiten)`}</span>
          </span>
        </div>
      </div>
    </div>
  );
}

function renderCustomHeader(toggleOpen: () => void, name: string, selectAllString: string, onSelectAll: () => void) {
  return (
    <div className='finder-selection-header'>
      <div className='finder-selection-all button' onClick={onSelectAll}>
        <div>{selectAllString}</div>
      </div>
      <div className='finder-selection-name primary-button' onClick={toggleOpen}>
        <div>{name}</div>
      </div>
    </div>
  );
}

interface UnitListProps {
  units: {
    id: number | string;
    uuid?: string;
    icon: string;
    name: string;
    abr: string;
  }[];
  selectedUnits: (number | string)[];
  selectUnit: (id: number | string) => void;
}
function UnitList({ units, selectedUnits, selectUnit }: UnitListProps) {
  return (
    <div className='finder-army-units'>
      {units.map(unit => {
        const myId = unit.uuid ?? unit.id;
        return (
          <div
            key={myId}
            className={`finder-army-entry selectable-element ${
              selectedUnits.includes(myId) ? 'selectable-element-selected' : ''
            }`}
            onClick={() => selectUnit(myId)}>
            <img className='icon' src={unit.icon} alt={unit.name} draggable={false} />
            <div>{unit.abr}</div>
          </div>
        );
      })}
    </div>
  );
}

export default styled(SearchParameterInput)`
  display: grid;
  grid-template-columns: minmax(400px, 1fr) minmax(400px, 1fr) 400px;
  background-color: var(--background-color-light);
  color: var(--color);
  padding: 24px;
  border-radius: 4px;
  grid-gap: 16px;

  .search-header {
    grid-column: 1 / span 3;

    .search-header-text {
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
    }

    .search-camps {
      display: flex;
      justify-content: space-around;
    }
  }
  .wetter-infos {
    display: none;
  }

  .finder-header {
    font-weight: 800;
    padding-bottom: 16px;
  }

  .finish-button {
    grid-column: 1 / span 3;
  }

  .finder-configuration {
    .finder-old-results {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;

      & > * {
        margin: auto 0;
      }
    }

    .finder-input-field {
      display: inline-block;
      margin: 0 8px;
      min-width: 10ch;
    }

    .finder-configuration-input {
      padding-bottom: 8px;
    }
    & ${LabeledImage} {
      font-size: 0.9em;
    }
  }

  .finder-selection-header {
    display: flex;
    width: 100%;

    .finder-selection-all {
      flex-basis: 100px;
      flex-grow: 1;
    }

    .finder-selection-name {
      margin-left: 16px;
      flex-basis: 150px;
      flex-grow: 1;
    }
  }

  .finder-generals,
  .finder-army {
    width: 100%;

    ${FoldableElement} {
      margin-bottom: 8px;
    }
  }

  .finder-army-units {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    cursor: default;

    .finder-army-entry {
      display: flex;
      flex-direction: column;
      & > * {
        margin: auto;
      }
      .icon {
        width: 30px;
      }
    }
  }
`;
