import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { AttackSearchResult, GeneralResult } from '../../app/store';
import { AdventureId } from '../../data-objects/adventures';
import FinderResultEntry from '../../Finder/FinderResultEntry';
import { SearchCamp } from '../../Finder/utils';
import { ShownResult } from '../../redux/result/util';
import {
  useCurrentAttackSearchArmyResults,
  useCurrentLockSearchArmyResults,
  useDisplayedResults,
  useShowAttackResultSelectable,
  useShowLockResultSelectable,
  useShowSimulationResultSelectable,
  useSimulationResult
} from '../../redux/selectors';
import * as uiActions from '../../redux/user-interface/uiActions';
import { BaseProps } from '../../types/basics';
import FoldableElement from '../Basics/FoldableElement';
import Tab from '../Basics/Tab';
import { DetailedResultTable } from './DetailedResult';

interface Props extends BaseProps {}

function SimulationAndSearchResults({ ...otherProps }: Props) {
  const dispatch = useDispatch();
  const show = useDisplayedResults();
  const showSimulationResultButton = useShowSimulationResultSelectable();
  const showLockResultButton = useShowLockResultSelectable();
  const showAttackResultButton = useShowAttackResultSelectable();

  const searchResults = useCurrentLockSearchArmyResults();
  const attackSearchResults = useCurrentAttackSearchArmyResults();
  const searchResultArray = Object.entries(searchResults);

  const multipleResultsAvailable =
    showSimulationResultButton === showLockResultButton ? showLockResultButton : showAttackResultButton;

  const attackResults = Object.entries(attackSearchResults).flatMap(([_id, adventureResults]) =>
    Object.entries(adventureResults.simulations).flatMap(([_genId, genResult]) => genResult.results)
  );

  const results = useSimulationResult();
  return (
    <div {...otherProps}>
      {multipleResultsAvailable && (
        <div className='sim-result-header'>
          {showSimulationResultButton && (
            <Tab
              label='Simulation'
              selected={show === ShownResult.SIMULATION}
              onSelect={() => dispatch(uiActions.changeDisplayedResult(ShownResult.SIMULATION))}
            />
          )}
          {showLockResultButton && (
            <Tab
              label='Lock Suche'
              selected={show === ShownResult.SEARCH}
              onSelect={() => dispatch(uiActions.changeDisplayedResult(ShownResult.SEARCH))}
            />
          )}
          {showAttackResultButton && (
            <Tab
              label='Angriff Suche'
              selected={show === ShownResult.ATTACK}
              onSelect={() => dispatch(uiActions.changeDisplayedResult(ShownResult.ATTACK))}
            />
          )}
        </div>
      )}
      {show === ShownResult.SIMULATION && <DetailedResultTable results={results} disableSort />}
      {show === ShownResult.SEARCH &&
        searchResultArray.map(([id, adventureResults]) => (
          <SearchResultCard
            key={id}
            adventureId={adventureResults.adventureId}
            banditCamps={adventureResults.bandits}
            results={Object.entries(adventureResults.simulations)}
          />
        ))}
      {show === ShownResult.ATTACK && (
        <DetailedResultTable results={attackResults as Array<AttackSearchResult>} disableWave />
      )}
    </div>
  );
}

interface SearchResultProps extends BaseProps {
  results: [string, GeneralResult][];
  adventureId: AdventureId;
  banditCamps: SearchCamp[];
}
function SearchResultCardUnstyled({ results, adventureId, banditCamps, ...otherProps }: SearchResultProps) {
  return (
    <div {...otherProps}>
      {results.map(([id, generalResults]) => (
        <FinderResultEntry
          key={id}
          general={generalResults.general}
          results={generalResults.results}
          adventureId={adventureId}
          bandits={banditCamps}
        />
      ))}
    </div>
  );
}

const SearchResultCard = styled(SearchResultCardUnstyled)`
  display: grid;

  ${props =>
    props.results.length > 1
      ? css`
          grid-template-columns: 1fr 1fr;
        `
      : css`
          grid-template-columns: 1fr;
        `}

  grid-gap: 10px;

  ${FoldableElement} {
    margin-bottom: 8px;
  }
`;

export default styled(SimulationAndSearchResults)`
  .sim-result-header {
    display: flex;
  }

  .result-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    outline: none;
    cursor: pointer;
    background-color: var(--background-color-light);

    border-radius: 2px 2px 0 0;
    padding: 8px 16px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .result-tab:not(:first-child) {
    margin-left: 2px;
  }

  ${DetailedResultTable} {
    width: 100%;
  }
`;
