import { AttackSearchResult, DetailedResult } from "../../../app/store";
import { AttackSearchSingleReport } from "../../../Finder/attack-utils";

export function getGeneralFromResult(
  result: DetailedResult | AttackSearchSingleReport | AttackSearchResult
) {
  if ("general" in result) {
    return result.general;
  }
  const losses = "losses" in result ? result.losses : result.lossesMap;

  return losses.usedUnits.general.unit;
}
