export type CampBuilding = { id: number; hp: number };

export const CAMP_TYPE_N = {
  id: 10000,
  hp: 250,
} as CampBuilding;
export const CAMP_TYPE_V = {
  id: 10001,
  hp: 3700,
} as CampBuilding;
export const CAMP_TYPE_W = {
  id: 10002,
  hp: 1000,
} as CampBuilding;
export const CAMP_TYPE_H = {
  id: 10003,
  hp: 2000,
} as CampBuilding;
export const CAMP_TYPE_S = {
  id: 10004,
  hp: 2000,
} as CampBuilding;
export const CAMP_TYPE_P = {
  id: 10005,
  hp: 500,
} as CampBuilding;
export const CAMP_TYPE_K = {
  id: 10006,
  hp: 2000,
} as CampBuilding;
export const CAMP_TYPE_L = {
  id: 10007,
  hp: 250,
} as CampBuilding;
export const CAMP_TYPE_WILD_ANFUEHRER = {
  id: 10008,
  hp: 900,
} as CampBuilding;
export const CAMP_TYPE_ELCHWALD = {
  id: 10009,
  hp: 1400,
} as CampBuilding;
export const CAMP_TYPE_SAEBELZAHN_HOEHLE = {
  id: 10010,
  hp: 1100,
} as CampBuilding;
export const CAMP_TYPE_MAMMUT_HOEHLE = {
  id: 10011,
  hp: 2100,
} as CampBuilding;
export const CAMP_TYPE_WALD = {
  id: 10012,
  hp: 10,
} as CampBuilding;
export const CAMP_TYPE_WACHTURM = {
  id: 10013,
  hp: 1000,
} as CampBuilding;
export const CAMP_TYPE_VERSTAERKTER_WACHTURM = {
  id: 10014,
  hp: 1500,
} as CampBuilding;
