import { Table } from 'antd';
import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { GlobalEffects } from '../../../data-objects/GlobalEffects';
import { getImageForEffect } from '../../../Finder/FinderBuffs';
import { Amount } from '../../../redux/simulationUtil';
import { BaseProps } from '../../../types/basics';
import DisplayAmount from '../../Basics/DisplayAmount';
import { DisplayUnits, DisplayUnitsProps } from '../../Basics/DisplayUnits';
import { weatherKeys, WEATHER_KEY } from './Constants';

const { Column, ColumnGroup } = Table;

export interface ResultTableRow {
  key: string;
  attacker: DisplayUnitsProps;
  win: string;
  simulations: string;
  wave: string;
  rounds: Amount;
  time: Amount;
  battle: Amount;
  building: Amount;
  ep: Amount;
  value: Amount;
  [WEATHER_KEY]: GlobalEffects;

  enemy: DisplayUnitsProps;
  own: DisplayUnitsProps;
  resurrected: DisplayUnitsProps;
  real: DisplayUnitsProps;
  details: Array<Omit<ResultTableRow, 'details'>>;
}

interface TheTableProps extends BaseProps {
  data: Array<ResultTableRow>;
  weatherActive: boolean;
  showCompactTable: boolean;
  hideHeader?: boolean;
  disablePagination?: boolean;
  disableSort?: boolean;
  disableWave?: boolean;
}

function TheTableUnstyled({
  data,
  weatherActive,
  showCompactTable,
  hideHeader,
  disablePagination = false,
  disableSort = false,
  disableWave = false,
  ...otherProps
}: TheTableProps) {
  const sortAmount = useCallback((rowA, rowB) => {
    const firstRowAmount: Amount = rowA.value;
    const secondRowAmount: Amount = rowB.value;

    const avgDiff = (firstRowAmount.avg ?? 0) - (secondRowAmount.avg ?? 0);
    if (avgDiff !== 0) {
      return avgDiff;
    }
    (['min', 'max'] as const).forEach(attr => {
      const attrDiff = firstRowAmount[attr] - secondRowAmount[attr];
      if (attrDiff !== 0) {
        return attrDiff;
      }
    });
    return 0;
  }, []);

  const sortOptions = {
    sorter: sortAmount,
    defaultSortOrder: 'ascend'
  } as const;

  const usedSortOptions = disableSort ? {} : sortOptions;
  const doDisablePagination = disablePagination || data.length < 10;
  return (
    <div {...otherProps}>
      <Table<ResultTableRow>
        dataSource={data}
        pagination={doDisablePagination ? false : {}}
        expandable={
          showCompactTable
            ? {
                expandedRowRender: record => (
                  <TheTable
                    data={record.details.map(detailedResult => ({
                      ...detailedResult,
                      details: []
                    }))}
                    style={{ display: 'flex', flexDirection: 'column', padding: '0 12px' }}
                    showCompactTable={false}
                    hideHeader
                    disablePagination
                    disableSort
                    weatherActive={weatherActive}
                  />
                )
              }
            : undefined
        }>
        {showCompactTable || disableWave ? null : <Column title='Welle' dataIndex='wave' key='wave' />}
        {weatherActive ? (
          <Column
            title='Wetter'
            dataIndex={WEATHER_KEY}
            render={globalEffects =>
              weatherKeys
                .filter(key => globalEffects[key])
                .map(key => <img key={key} src={getImageForEffect(key)} alt={key} />)
            }
          />
        ) : null}
        <Column title='Angreifer' dataIndex='attacker' render={units => <DisplayUnits {...units} />} />
        <Column title='Sieg' dataIndex='win' />
        <Column title='Simulation' dataIndex='simulations' />
        {showCompactTable ? null : (
          <ColumnGroup title='Kampfzeiten'>
            <Column title='Runden' dataIndex='rounds' render={value => <DisplayAmount amount={value} />} />
            <Column title='Gesamt' dataIndex='time' render={value => <DisplayAmount amount={value} />} />
            <Column title='Kampf' dataIndex='battle' render={value => <DisplayAmount amount={value} />} />
            <Column title='Abriss' dataIndex='building' render={value => <DisplayAmount amount={value} />} />
          </ColumnGroup>
        )}
        {showCompactTable ? (
          <Column title='Eigene' dataIndex='own' render={units => <DisplayUnits center {...units} />} />
        ) : (
          <ColumnGroup title='Verluste'>
            <Column title='Gegner' dataIndex='enemy' render={units => <DisplayUnits center {...units} />} />
            <Column title='Eigene' dataIndex='own' render={units => <DisplayUnits center {...units} />} />
            <Column title='Wiederbelebt' dataIndex='resurrected' render={units => <DisplayUnits center {...units} />} />
            <Column title='nach Wiederbeleben' dataIndex='real' render={units => <DisplayUnits center {...units} />} />
          </ColumnGroup>
        )}
        <Column title='EP' dataIndex='ep' render={value => <DisplayAmount amount={value} />} />
        <Column
          title='Verlustwert'
          dataIndex='value'
          render={value => <DisplayAmount amount={value} />}
          {...usedSortOptions}
        />
        {/*<Column title='Aktionen' key='action' render={(text, record) => <a>Simulieren</a>} />*/}
      </Table>
    </div>
  );
}

const TheTableMemo = memo(TheTableUnstyled);
export const TheTable = styled(TheTableMemo)`
  .pagination-section {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    justify-content: end;
    list-style: none;
    margin: 16px 0;
  }

  .pagination-item {
    display: block;
    min-width: 32px;
    line-height: 30px;
    margin-right: 8px;
    text-align: center;
    border: 1px solid gray;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    user-select: none;
  }

  .pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: transparent;
    color: var(--color);
    border: 1px solid gray;
    cursor: pointer;
    user-select: none;
  }
`;
