import { useRef, useEffect } from "react";

export function useOnClickOutsideHandler({ onClickOutside }) {
  const content = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (content.current && !content.current.contains(event.target)) {
        onClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [content, onClickOutside]);

  return content;
}
