import { roundNicely } from "./roundNicely";

export function toRange(valueOne: number, valueTwo: number): string {
  if (valueOne === valueTwo) {
    return `${roundNicely(valueOne)}`;
  }
  const minValue = Math.min(valueOne, valueTwo);
  const maxValue = Math.max(valueOne, valueTwo);
  return `${roundNicely(minValue)} - ${roundNicely(maxValue)}`;
}
