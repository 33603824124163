import { GlobalEffects } from "../../data-objects/GlobalEffects";
import { SimulationLosses } from "../simulationUtil";
import { ResultActionTypes } from "./resultActionTypes";

export function addWaveResult(
  campNo: string,
  battleResult: number,
  losses: SimulationLosses,
  weather: GlobalEffects,
  simulations: number,
  attackerWave: number,
  enemyWave: number
) {
  return {
    type: ResultActionTypes.ADD_WAVE_RESULT,
    campNo,
    battleResult,
    losses,
    simulations,
    weather,
    attackerWave,
    enemyWave,
  } as const;
}

export function startNewSimulation() {
  return {
    type: ResultActionTypes.START_WAVE_SIMULATION,
  } as const;
}

export function clearSimulationResults() {
  return {
    type: ResultActionTypes.CLEAR_SIMULATION_RESULTS,
  } as const;
}

export type ResultActions =
  | ReturnType<typeof addWaveResult>
  | ReturnType<typeof startNewSimulation>
  | ReturnType<typeof clearSimulationResults>;
