import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';
import { Amount } from '../../redux/simulationUtil';
import { BaseProps } from '../../types/basics';
import DisplayAmount from './DisplayAmount';

interface LabeledBaseProps extends BaseProps {
  icon: string;
  labelAfter?: boolean;
}
interface ComponentProps extends LabeledBaseProps {
  amount: Amount;
}

const CHILD_CLASSNAME = 'labled';

function LabeledImage({ icon, labelAfter, ...otherProps }: ComponentProps) {
  var renderComponent;
  const uid = useMemo(() => v4(), []);
  var forwardedProps;
  /*
  if ("text" in otherProps) {
    const { text, ...rest } = otherProps;
    forwardedProps = rest;
    renderComponent = (
      <label className={CHILD_CLASSNAME} htmlFor={`img-of-${uid}`}>
        {text}
      </label>
    );
  } else {
    */
  const { amount, ...rest } = otherProps;
  renderComponent = <DisplayAmount className={CHILD_CLASSNAME} amount={amount} />;
  forwardedProps = rest;
  // }

  return (
    <span {...forwardedProps}>
      {!labelAfter && renderComponent}
      <img id={`img-of-${uid}`} src={icon} alt='' draggable={false} />
      {labelAfter && renderComponent}
    </span>
  );
}

export default styled(LabeledImage)`
  display: flex;

  & .${CHILD_CLASSNAME} {
    ${props =>
      props.labelAfter
        ? css`
            padding-left: 4px;
          `
        : null}
    margin: auto 0;
  }

  & img {
    ${props =>
      props.labelAfter
        ? null
        : css`
            padding-left: 4px;
          `}
    height: 100%;
  }
`;
