import { AnimateSharedLayout, motion } from 'framer-motion';
import { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { General, GeneralId } from '../../data-objects/units/Army';
import { MappedCustomGeneral } from '../Configurations/GeneralConfiguration';

const spring = { type: 'spring', stiffness: 500, damping: 30 };

type Props<Type extends General | MappedCustomGeneral> = SkipNoGeneralSelection<Type> | NoGeneralSelection<Type>;

interface SkipNoGeneralSelection<Type extends General | MappedCustomGeneral> extends BaseProps<Type> {
  enableSelectNoGeneral?: false;
}

interface BaseProps<Type extends General | MappedCustomGeneral> {
  selectedGeneralId?: Type extends General ? GeneralId : string;
  selectedGeneralIndex?: number | string;
  availableGens: Type[];
  setGeneral: (general: Type, index: number) => void;
  enableDragDrop?: boolean;
  updateGeneralOrder?: (hoverGeneralId: string | number, dropGeneralId: string | number) => void;
}

interface NoGeneralSelection<Type extends General | MappedCustomGeneral> extends BaseProps<Type> {
  enableSelectNoGeneral: true;
  setGeneral: (general?: Type, index?: number) => void;
}

function GeneralSelection<GeneralType extends General | MappedCustomGeneral>({
  selectedGeneralId,
  enableSelectNoGeneral,
  selectedGeneralIndex,
  availableGens,
  setGeneral,
  enableDragDrop,
  updateGeneralOrder,
  ...otherProps
}: Props<GeneralType>) {
  const move = useCallback(
    (hoverGeneralId: string | number, dropGeneralId: string | number) => {
      updateGeneralOrder && updateGeneralOrder(hoverGeneralId, dropGeneralId);
    },
    [updateGeneralOrder]
  );
  return (
    <div {...otherProps}>
      <AnimateSharedLayout>
        {enableSelectNoGeneral && (
          <div
            className={`general-selection selectable-element `}
            onClick={() => setGeneral(undefined as any, undefined as any)}>
            <div>Keiner</div>
            {selectedGeneralId === undefined && selectedGeneralIndex === undefined && (
              <motion.div
                layoutId='selected-general'
                className='selection-outline'
                initial={false}
                transition={spring}
              />
            )}
          </div>
        )}
        {availableGens.map((general, index) => (
          <GeneralEntry
            key={general.uuid ?? general.id}
            setGeneral={() => setGeneral(general, index)}
            move={move}
            general={general}
            enableDragDrop={enableDragDrop}
            isSelected={
              selectedGeneralId ? selectedGeneralId === (general.uuid ?? general.id) : selectedGeneralIndex === index
            }
          />
        ))}
      </AnimateSharedLayout>
    </div>
  );
}

interface IndexItem {
  genId: string | number;
}

interface GeneralEntryProps<Type extends General | MappedCustomGeneral> {
  general: Type;
  isSelected: boolean;
  enableDragDrop?: boolean;
  setGeneral: () => void;
  move: (dragGeneral: number | string, overGeneral: number | string) => void;
}

function GeneralEntry<Type extends General | MappedCustomGeneral>({
  general,
  isSelected,
  enableDragDrop,
  move,
  setGeneral
}: GeneralEntryProps<Type>) {
  const myDropId = general.uuid ?? general.id;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'General',
    item: { genId: myDropId },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => !!enableDragDrop
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_spec, dropRef] = useDrop<IndexItem, unknown, {}>({
    accept: 'General',
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragGenId = item.genId;

      // Don't replace items with themselves
      if (dragGenId === myDropId) {
        return;
      }

      if (monitor.isOver()) {
        move(dragGenId, myDropId);
      }
    }
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef<HTMLDivElement>(null!);
  const dragDropRef: any = drag(dropRef(ref));

  return (
    <div
      ref={enableDragDrop ? dragDropRef : undefined}
      draggable={!!enableDragDrop}
      className={'general-selection selectable-element '}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={setGeneral}>
      <img draggable={false} src={general.icon} alt={general.name} />
      <div>{general.abr}</div>
      {!isDragging && isSelected && (
        <motion.div layoutId='selected-general' className='selection-outline' initial={false} transition={spring} />
      )}
    </div>
  );
}

export default styled(GeneralSelection)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(54px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  min-width: 200px;
  cursor: default;
  user-select: none;

  .general-selection {
    display: flex;
    position: relative;
    flex-direction: column;

    .general-detail {
      font-size: 10px;
      display: flex;
    }

    & > div {
      margin: auto;
    }
    & > img {
      width: 30px;
      margin: auto;
      flex-shrink: 1;
    }
  }
`;
