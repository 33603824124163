import React from 'react';
import styled from 'styled-components';
import Popup, { Props as PopupProps } from './Popup';

interface Props {
  onAccept: React.MouseEventHandler<HTMLDivElement>;
  onDeny?: PopupProps['onClose'];
  explain: string;
  text: string;
  visible: boolean;
}

function ConfirmDialog({ onAccept = () => {}, onDeny, explain, text, visible, ...otherProps }: Props) {
  return (
    <Popup visible={visible} {...otherProps} onClose={onDeny}>
      <div className='dialog'>
        <div className='header'>
          <div className='centered'>{text}</div>
        </div>
        <div className='content'>
          <div className='centered'>{explain}</div>
        </div>
        <div className='buttons'>
          <div className='primary-button accept-button' onClick={onAccept}>
            Ok
          </div>
          {onDeny && (
            <div className='button deny-button' onClick={onDeny}>
              Cancel
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
}

export default styled(ConfirmDialog)`
  display: flex;

  .dialog {
    background-color: var(--background-color-light);
    color: var(--color);
    padding: 28px;
    border-radius: 5px;

    .centered {
      margin: auto;
    }

    .header {
      display: flex;
      font-weight: 800;
      padding-bottom: 16px;
    }

    .content {
      display: flex;
      padding-bottom: 16px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
`;
