import styled from 'styled-components';
import { CampBuilding } from '../../data-objects/CampTypes';
import { EnemyId, getEnemyUnitById } from '../../data-objects/units/Units';
import { BaseProps } from '../../types/basics';
import { UserInputUnits } from '../units/UnitSelection';
import LabeledImage from './LabeledImage';

interface Props extends BaseProps {
  units: UserInputUnits;
  camp: CampBuilding;
}

function DisplayCamp({ units, camp, ...otherProps }: Props) {
  return (
    <div {...otherProps}>
      <div className='the-camp'>
        <img src='/icons/buffs/Festung.png' alt='camp' />
        <div className='camp-hp'>
          <span className='camp-hp-value'>{camp.hp}</span>
          <div className='camp-hp-bar'></div>
        </div>
      </div>
      <div className='the-camp-units'>
        {Object.entries(units).map(([unitId, amount]) => (
          <LabeledImage
            key={unitId}
            amount={{ min: parseInt(amount), max: parseInt(amount) }}
            icon={getEnemyUnitById(parseInt(unitId) as EnemyId).icon}
          />
        ))}
      </div>
    </div>
  );
}

export default styled(DisplayCamp)`
  display: flex;
  max-height: 100px;
  gap: 16px;

  .the-camp {
    img {
      max-height: calc(100% - 28px);
    }
    .camp-hp {
      border: var(--neutral-border);
      position: relative;
      padding: 3px;
      min-width: 65px;
    }

    .camp-hp-value {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }

    .camp-hp-bar {
      height: 20px;
      background-color: green;
      width: 100%;
    }
  }

  .the-camp-units {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    ${LabeledImage} {
      img {
        height: 32px;
      }
      border: 2px solid var(--primary-color);
      margin: 2px;
      padding: 4px 6px;
    }
  }
`;
