export default function handleSaveToPC(
  jsonData: unknown,
  fileName: string = "sim-config.json"
) {
  function replaceErrors(key: unknown, value: unknown) {
    if (value instanceof Error) {
      var error = {} as any;

      Object.getOwnPropertyNames(value).forEach(function (propertyName) {
        error[propertyName] = value[propertyName as keyof Error];
      });

      return error;
    }

    return value;
  }
  const fileData = JSON.stringify(jsonData, replaceErrors);
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = fileName;
  link.href = url;
  link.click();
}
