import { AttackSearchResult, DetailedResult } from "../../../app/store";
import { AttackSearchSingleReport } from "../../../Finder/attack-utils";
import { getOurArmyLosses } from "./getOurArmyLosses";

export function getOurUnitLossesFromResult(
  result: DetailedResult | AttackSearchSingleReport | AttackSearchResult
) {
  if ("details" in result) {
    return result.unitLosses;
  }

  const losses = "losses" in result ? result.losses : result.lossesMap;

  return getOurArmyLosses(losses, result.simulations);
}
