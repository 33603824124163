import { resultIsBetter } from "./resultIsBetter";
import { DefinedAmount } from "./calculateLossValue";

export function resultIsOkayish(
  oldLossValue: DefinedAmount,
  resultValue: DefinedAmount
) {
  if (resultIsBetter(oldLossValue, resultValue)) {
    return true;
  }

  // TODO: implement margin in lossValue to report this result.
  return false;
}
