import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useGlobalEffects,
  useSelectedEnemies,
  useSelectedGenerals,
  useSelectedSkills,
  useSelectedUnits
} from '../../redux/selectors';

const WaveExample = function WaveExample({
  selectedGenerals,
  selectedSkills,
  selectedUnits,
  selectedEnemies,
  selectedCamps,
  weatherEffects,
  setSimResult,
  attackerWave = 0,
  enemyWave = 0
}) {
  return <div>Wave</div>;
};

const ExampleSimulation = function ExampleSimulation({ selectedCamps }) {
  const [exampleResult, setExampleResult] = useState([]);
  const weatherEffects = useGlobalEffects();
  const selectedGenerals = useSelectedGenerals();
  const selectedUnits = useSelectedUnits();
  const selectedEnemies = useSelectedEnemies();
  // const selectedCamps = useSelectedCamps();
  const selectedSkills = useSelectedSkills();

  useEffect(() => {
    setExampleResult([]);
    /*startAttack({
      generals: selectedGenerals,
      skills: selectedSkills,
      attacker: selectedUnits,
      defender: selectedEnemies,
      camps: selectedCamps,
      weather: weatherEffects,
      setSimResult: (...params) =>
        setExampleResult((oldResult) => [...oldResult, { params }]),
      simulationRuns: 25,
    });
    */
  }, [selectedGenerals, selectedUnits, selectedCamps, selectedEnemies, selectedSkills, weatherEffects]);

  return (
    <div>
      <div>I guess, we will win!</div>
      {exampleResult.map(result => (
        <WaveExample />
      ))}
    </div>
  );
};

export default styled(React.memo(ExampleSimulation))``;
