import { AttackSearchResult, DetailedResult } from '../../../app/store';
import { getUnitById, UnitId } from '../../../data-objects/units/Army';
import { AttackSearchSingleReport } from '../../../Finder/attack-utils';

export function getOurUnitsFromResult(result: DetailedResult | AttackSearchSingleReport | AttackSearchResult) {
  if ('general' in result) {
    return Object.entries(result.units).map(([stringId, amount]) => {
      const theUnit = getUnitById(parseInt(stringId) as UnitId);
      return {
        amount: { min: amount, max: amount },
        icon: theUnit.icon,
        rang: theUnit.rang
      };
    });
  }
  const usedUnits = 'losses' in result ? result.losses.usedUnits : result.lossesMap.usedUnits;

  return Object.values(usedUnits.units).map(theUnit => {
    const testValue = theUnit.amount;
    const amount = { min: -1, max: -1, avg: undefined };
    if (typeof testValue === 'number') {
      amount.min = testValue;
      amount.max = testValue;
    } else if ('min' in testValue) {
      amount.min = testValue.min;
      amount.max = testValue.max;
      amount.avg = testValue.avg;
    }
    return {
      amount,
      icon: theUnit.unit.icon,
      rang: theUnit.unit.rang
    };
  });
}
