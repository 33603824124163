import styled from "styled-components";
import { BaseProps } from "../../types/basics";

interface Props extends BaseProps {
  selected: boolean;
  toggleSelected: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onLabel?: string;
  offLabel?: string;
}
function ToggleButton({
  selected,
  toggleSelected,
  onLabel = "An",
  offLabel = "Aus",
  ...otherProps
}: Props) {
  return (
    <div {...otherProps} onClick={toggleSelected}>
      <div className={`dialog-button ${selected ? "" : "disabled"}`}>
        {selected ? onLabel : offLabel}
      </div>
    </div>
  );
}

export default styled(ToggleButton)`
  width: 70px;
  background-color: var(--background-color-dark);
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 32px;
  position: relative;

  & .dialog-button {
    top: 0;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: var(--text-on-secondary);
    padding: 8px 12px;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    min-width: unset;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 34px;
    transition: all 0.3s ease;
  }

  & .disabled {
    top: 0;
    background-color: var(--primary-color);
    color: var(--text-on-primary);
    left: 2px;
  }
`;
