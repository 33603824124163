import { toast } from 'react-toastify';
import { UIState } from '../../app/store';
import { initialState } from '../../app/storeUtil';
import { ResultActionTypes } from '../result/resultActionTypes';
import { ShownResult } from '../result/util';
import { abortLockSearch, finishLockSearch } from '../search-army/searchArmyActions';
import { SearchArmyActionTypes } from '../search-army/searchArmyActionTypes';
import { SearchStatus } from '../search-army/SearchStatus';
import { changeAdventure } from '../simulationActions';
import { CHANGE_ADVENTURE } from '../simulationActionTypes';
import {
  changeDisplayedResult,
  registerSearchAmount,
  setKantineEffect,
  setLazarettEffect,
  toggleKeepPreviousResults,
  toggleSelectedEffect,
  toggleSelectedGenerals,
  toggleSelectedUnits,
  UIActions,
  updateProgress,
  updateSearchState,
  updateValue
} from './uiActions';
import { UIActionTypes } from './uiActionTypes';

function handleStartNewSearch(state: UIState): UIState {
  return {
    ...state,
    search: {
      ...state.search,
      maxSearchSpace: undefined,
      locksFound: 0,
      progress: 0,
      status: SearchStatus.SEARCHING
    }
  };
}

function selectEntry<ListEntryType extends number | string>(
  ids: ListEntryType[],
  list: ListEntryType[]
): Array<ListEntryType> {
  if (ids.some(id => !list.includes(id))) {
    return [...list, ...ids].filter((item, pos, self) => self.indexOf(item) === pos);
  } else {
    return [...list.filter(oldId => !ids.includes(oldId))];
  }
}
function handleToggleKeepResults(state: UIState, action: ReturnType<typeof toggleKeepPreviousResults>): UIState {
  const { statePart } = action;
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      keepPreviousResults: !state[statePart].keepPreviousResults
    }
  };
}

function handleSetLazarettEffect(
  state: UIState,
  { lazarett: { stufe, value }, statePart }: ReturnType<typeof setLazarettEffect>
) {
  const unsetCurrent =
    state[statePart].selectedEffects.lazarett?.stufe === stufe &&
    state[statePart].selectedEffects.lazarett?.value === value;

  if (unsetCurrent) {
    return {
      ...state,
      [statePart]: {
        ...state[statePart],
        selectedEffects: {
          ...state[statePart].selectedEffects,
          lazarett: undefined
        }
      }
    };
  }
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      selectedEffects: {
        ...state[statePart].selectedEffects,
        lazarett: { stufe, value }
      }
    }
  };
}

function handleSetKantineEffect(state: UIState, { kantine, statePart }: ReturnType<typeof setKantineEffect>) {
  const unsetCurrent = state[statePart].selectedEffects.kantine === kantine;

  if (unsetCurrent) {
    return {
      ...state,
      [statePart]: {
        ...state[statePart],
        selectedEffects: {
          ...state[statePart].selectedEffects,
          kantine: undefined
        }
      }
    };
  }
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      selectedEffects: {
        ...state[statePart].selectedEffects,
        kantine
      }
    }
  };
}

function handleToggleEffect(state: UIState, action: ReturnType<typeof toggleSelectedEffect>): UIState {
  const { statePart, effect } = action;
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      selectedEffects: {
        ...state[statePart].selectedEffects,
        [effect]: !state[statePart].selectedEffects[effect]
      }
    }
  };
}

function handleToggleGenerals(state: UIState, action: ReturnType<typeof toggleSelectedGenerals>): UIState {
  const { statePart } = action;
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      selectedGenerals: selectEntry(action.generalIds, state[statePart].selectedGenerals)
    }
  };
}

function handleToggleUnits(state: UIState, action: ReturnType<typeof toggleSelectedUnits>): UIState {
  const { statePart } = action;
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      selectedUnits: selectEntry(action.unitIds, state[statePart].selectedUnits)
    }
  };
}

function handleUpdateValue(state: UIState, action: ReturnType<typeof updateValue>): UIState {
  const { statePart } = action;
  return {
    ...state,
    [statePart]: {
      ...state[statePart],
      value: action.value
    }
  };
}

function resetBlockSearchState(state: UIState, isAttack?: true): UIState {
  return {
    ...state,
    search: {
      ...state.search,
      maxSearchSpace: undefined,
      progress: 0,
      status: SearchStatus.READY
    },
    shownResult:
      state.shownResult === ShownResult.NONE ? (isAttack ? ShownResult.ATTACK : ShownResult.SEARCH) : state.shownResult
  };
}

function handleFinishSearch(state: UIState, action: ReturnType<typeof finishLockSearch>) {
  toast.success(`Suche beendet. ${state.search.locksFound} Ergebnisse gefunden.`);
  return resetBlockSearchState(state, action.isAttack);
}

function handleAbortSearch(state: UIState, action: ReturnType<typeof abortLockSearch>) {
  toast.warn(`Suche abgebrochen. Bisher ${state.search.locksFound} Ergebnisse gefunden.`, { autoClose: 2000 });
  return resetBlockSearchState(state, action.isAttack);
}

function handleRegisterSearchSpace(state: UIState, action: ReturnType<typeof registerSearchAmount>) {
  const { maxAmount } = action;

  return {
    ...state,
    search: {
      ...state.search,
      maxSearchSpace: maxAmount,
      status: SearchStatus.SEARCHING
    }
  };
}
function handleUpdateSearchProgress(state: UIState, action: ReturnType<typeof updateProgress>) {
  const { progress } = action;
  return {
    ...state,
    search: {
      ...state.search,
      progress: state.search.progress + progress
    }
  };
}

function handleAddResult(state: UIState, results: unknown[], isAttack?: true): UIState {
  return {
    ...state,
    search: {
      ...state.search,
      locksFound: state.search.locksFound + results.length
    },
    shownResult:
      state.shownResult === ShownResult.NONE ? (isAttack ? ShownResult.ATTACK : ShownResult.SEARCH) : state.shownResult
  };
}

function handleStartNewSimulation(state: UIState): UIState {
  return { ...state, simulationInProgress: true };
}

function handleFinishSimulation(state: UIState): UIState {
  if (state.simulationInProgress) {
    return {
      ...state,
      simulationInProgress: false,
      shownResult: state.shownResult === ShownResult.NONE ? ShownResult.SIMULATION : state.shownResult
    };
  }
  return state;
}

function handleSetSearchStatus(state: UIState, action: ReturnType<typeof updateSearchState>) {
  const { status } = action;
  if (status === state.search.status) {
    return state;
  }

  return { ...state, search: { ...state.search, status } };
}

function handleChangeDisplayedResult(state: UIState, action: ReturnType<typeof changeDisplayedResult>): UIState {
  const { resultType } = action;
  if (state.shownResult !== resultType) {
    return { ...state, shownResult: resultType };
  }
  return state;
}

function handleChangeAventure(state: UIState, action: ReturnType<typeof changeAdventure>): UIState {
  if (!action.adventure) {
    return {
      ...state,
      // Once adventure changes, clear selected units.
      // May need to think about resetting weather and generals as well...
      attackSearchInput: { ...state.attackSearchInput, selectedUnits: [] },
      lockSearchInput: { ...state.lockSearchInput, selectedUnits: [] }
    };
  }
  return state;
}

export default function uiReducer(state: UIState | undefined = initialState.ui, action: UIActions): UIState {
  switch (action.type) {
    case UIActionTypes.REGISTER_SEARCH_SPACE:
      return handleRegisterSearchSpace(state, action);
    case UIActionTypes.UPDATE_SEARCH_PROGRESS:
      return handleUpdateSearchProgress(state, action);
    case UIActionTypes.SET_SEARCH_STATUS:
      return handleSetSearchStatus(state, action);
    case SearchArmyActionTypes.ADD_MULTIPLE_RESULTS:
      return handleAddResult(state, action.results, action.isAttack);
    case SearchArmyActionTypes.ADD_SEARCH_RESULT:
      return handleAddResult(state, [action.result], action.isAttack);
    case SearchArmyActionTypes.START_NEW_SEARCH:
      return handleStartNewSearch(state);
    case SearchArmyActionTypes.ABORT_SEARCH:
      return handleAbortSearch(state, action);
    case SearchArmyActionTypes.FINISHED_SEARCH:
      return handleFinishSearch(state, action);
    case UIActionTypes.TOGGLE_SELECTED_EFFECT:
      return handleToggleEffect(state, action);
    case UIActionTypes.TOGGLE_SELECTED_GENERALS:
      return handleToggleGenerals(state, action);
    case UIActionTypes.TOGGLE_SELECTED_UNITS:
      return handleToggleUnits(state, action);
    case UIActionTypes.UPDATE_VALUE:
      return handleUpdateValue(state, action);
    case UIActionTypes.TOGGLE_KEEP_PREVIOUS:
      return handleToggleKeepResults(state, action);
    case UIActionTypes.CHANGE_DISPLAYED_RESULT:
      return handleChangeDisplayedResult(state, action);
    case UIActionTypes.SET_LAZARETT_EFFECT:
      return handleSetLazarettEffect(state, action);
    case UIActionTypes.SET_KANTINE_EFFECT:
      return handleSetKantineEffect(state, action);
    case ResultActionTypes.START_WAVE_SIMULATION:
      return handleStartNewSimulation(state);
    case ResultActionTypes.ADD_WAVE_RESULT:
      return handleFinishSimulation(state);
    case CHANGE_ADVENTURE:
      return handleChangeAventure(state, action);
    default:
  }
  return state;
}
