import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { useOnClickOutsideHandler } from '../../redux/hooks';

export interface Props {
  visible: boolean;
  onClose?: () => void;
}
function Popup({ children, onClose, visible, ...otherProps }: React.PropsWithChildren<Props>) {
  const popupContent = useOnClickOutsideHandler({ onClickOutside: onClose });

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          {...otherProps}>
          <motion.div
            ref={popupContent}
            className='popup-content'
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default styled(Popup)`
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  z-index: 100;
  cursor: default;

  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);

  & .popup-content {
    margin: auto;
    position: relative;
    opacity: 1;
    width: fit-content;
    height: fit-content;
  }
`;
