import React from "react";
import styled from "styled-components";
import { BaseProps } from "../../types/basics";

export interface Effect {
  name: string;
  icon: string;
}

interface Props extends BaseProps {
  globalPicture: string;
  effects: Effect[];
}

function EffectSelection({ globalPicture, effects, ...otherProps }: Props) {
  return (
    <div {...otherProps}>
      <div className="effect-picture">
        <img src={globalPicture} alt="effect-selection-global" />
      </div>
      <div className="effect-icons">
        <div className="selected-effect-header">
          {effects.length ? "Aktiv:" : "Kein Wetter aktiv"}
        </div>
        <div className="selected-effects">
          {effects.map((effect) => (
            <EffectIcon key={effect.name} effect={effect} />
          ))}
        </div>
      </div>
      <div className="effect-text"></div>
    </div>
  );
}

interface EffectIconProps extends BaseProps {
  effect: Effect;
}

function EffectIconInternal({ effect, ...otherProps }: EffectIconProps) {
  return (
    <div key={effect.name} {...otherProps}>
      <div className="effect-icon">
        <img src={effect.icon} alt={effect.name} />
      </div>
      <div className="effect-icon-text">{effect.name}</div>
    </div>
  );
}

const EffectIcon = styled(EffectIconInternal)`
  display: grid;
  justify-items: center;

  .effect-icon {
    display: flex;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: var(--effect-selection-icon-background);

    img {
      margin: auto;
      width: 45px;
      height: 45px;
    }
  }

  .effect-icon-text {
    font-size: 0.7em;
  }
`;

export default styled(EffectSelection)`
  display: grid;
  grid-template-columns: 130px auto;
  isolation: isolate;
  border: 4px solid var(--effect-selection-border-color);
  background-color: var(--effect-selection-background);
  color: var(--effect-selection-color);
  border-radius: 8px;

  .effect-picture {
    margin: auto;

    img {
      width: 100%;
    }
  }

  .effect-icons {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-left: 2px solid var(--effect-selection-border-color);

    .selected-effect-header {
      background-color: var(--selected-effects-header-background-color);
      color: var(--selected-effects-header-color);
      border-radius: 4px;
      margin-bottom: 1em;
    }

    .selected-effects {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      ${EffectIcon} {
        padding-bottom: 1em;
        margin: auto;
        width: 50%;
      }
    }
  }

  .effect-text {
  }
`;
