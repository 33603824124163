import { MappedCustomGeneral } from "../components/Configurations/GeneralConfiguration";
import { General, getUnitById } from "../data-objects/units/Army";
import {
  useCurrentAvailableEnemies,
  useCurrentSelectedGeneral,
  useCurrentSelectedSkills,
  useCurrentShowCustomGenerals,
  useCurrentShowEliteUnits,
  useCustomgenerals,
  useGlobalEffects,
} from "../redux/selectors";
import {
  applySkillsAndWeather,
  availableGenerals,
  createMappedGenerals,
  ourDefaultArmy,
  ourEliteArmy,
} from "../redux/unitUtil";

export function useCurrentSkilledUnits() {
  const currentSelectedGeneral = useCurrentSelectedGeneral();
  const currentSelectedSkills = useCurrentSelectedSkills() || {};
  const currentAvailableEnemies = useCurrentAvailableEnemies();
  const weatherEffects = useGlobalEffects();

  const general = currentSelectedGeneral
    ? getUnitById(currentSelectedGeneral)
    : undefined;

  return applySkillsAndWeather({
    general,
    units: useCurrentUnits(),
    bandits: currentAvailableEnemies,
    skills: currentSelectedSkills,
    weatherEffects,
  });
}

export function useCurrentUnits() {
  const currentShowEliteUnits = useCurrentShowEliteUnits();
  return currentShowEliteUnits ? ourEliteArmy : ourDefaultArmy;
}
export function useCurrentAvailableGenerals(): (
  | General
  | MappedCustomGeneral
)[] {
  const showCustomGens = useCurrentShowCustomGenerals();
  const generalsLists = useCustomgenerals();

  return showCustomGens < 0
    ? availableGenerals
    : createMappedGenerals(generalsLists[showCustomGens].generals);
}
