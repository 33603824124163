import { mapValues } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { General, Unit } from '../../data-objects/units/Army';
import { Enemy } from '../../data-objects/units/Units';
import { useOnClickOutsideHandler } from '../../redux/hooks';
import { sortByRang } from '../../redux/unitUtil';
import FoldableElement from '../Basics/FoldableElement';
import { convertToNumber } from '../Basics/util';
import UnitDetails from './UnitDetails';
import { getNumberOfUnits } from './utils';

export type SelectedUnits = Record<number, number>;
export type UserInputUnits = Record<number, string>;

export function toSelectedUnits(units: Array<SelectedUnits | UserInputUnits>) {
  return units.map(unit => mapValues(unit, amount => convertToNumber(amount)));
}

const BACK_SYMBOL = '🢦';
const CLEAR_SYMBOL = '␡';
const FAST_INPUT_VALUES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', CLEAR_SYMBOL, '0', BACK_SYMBOL] as const;

interface Props<T extends Unit | Enemy> {
  availableUnits: T[];
  selectedUnits: UserInputUnits;
  setUserInputUnits: (selected: UserInputUnits) => void;
  showDetails: boolean;
  general: General | null;
  isPlayer?: T extends Unit ? true : false;
  availableWaves: (number | string)[];
  currentWave: number | string;
  selectWave: (index: number | string) => void;
  deleteWave: (index: number | string) => void;
  onNewWave: () => void;
}

function UnitSelection<T extends Unit | Enemy>({
  availableUnits,
  selectedUnits,
  setUserInputUnits,
  showDetails = true,
  general,
  isPlayer,
  availableWaves = [],
  currentWave,
  selectWave,
  deleteWave,
  onNewWave,
  ...otherProps
}: Props<Unit | Enemy>) {
  const { t } = useTranslation();
  const [fastInputVisible, setFastInputVisible] = useState<string | number>('0');
  const valuesContent = useOnClickOutsideHandler({
    onClickOutside: () => setFastInputVisible('0')
  });

  const maxCapacity = general ? general.capacity : 200;

  const totalAmount = getNumberOfUnits(selectedUnits);

  function handleChange(id: T['id'], newValue: string) {
    setUserInputUnits({
      ...selectedUnits,
      [id]: newValue
    });
  }

  function fillUpUnits(id: T['id']) {
    const maxUnitsToAdd = maxCapacity - totalAmount;
    const currentSelected = convertToNumber(selectedUnits[id]);

    setUserInputUnits({
      ...selectedUnits,
      [id]: `${Math.max(0, currentSelected + maxUnitsToAdd)}`
    });
  }

  function clearUnits(id: T['id']) {
    const { [id]: clearMe, ...otherSelected } = selectedUnits;
    setUserInputUnits(otherSelected as UserInputUnits);
  }
  const detailedInfos = Object.entries(selectedUnits).reduce(
    (prev, [myUnit, thisAmount]) => {
      const theUnit = availableUnits.find(unit => unit.id === parseInt(myUnit));
      if (!theUnit) {
        return prev;
      }

      const amount = convertToNumber(thisAmount);
      const addedHp = amount * theUnit.hp;
      const addedMinDmg = amount * theUnit.min;
      const addedMaxDmg = amount * theUnit.max;
      const maxDmgAmount = Math.floor((amount * theUnit.accuracy) / 100);
      const addedAvgDmg = maxDmgAmount * theUnit.max + (amount - maxDmgAmount) * theUnit.min;

      return {
        ...prev,
        hp: prev.hp + addedHp,
        min: prev.min + addedMinDmg,
        max: prev.max + addedMaxDmg,
        avgDmg: prev.avgDmg + addedAvgDmg
      };
    },
    { hp: 0, min: 0, max: 0, avgDmg: 0 }
  );

  return (
    <div {...otherProps}>
      <div className='wave-config'>
        <label>{isPlayer ? `${t('Welle')}: ` : `${t('Gegner-Welle')}: `}</label>
        {availableWaves.map(wave => (
          <div
            key={wave}
            onClick={() => selectWave(wave)}
            className={`wave-number selectable-element ${
              // eslint-disable-next-line
              wave == currentWave ? 'selectable-element-selected' : ''
            }`}>
            <div className='value-container'>
              <div className='value-text'>{parseInt(wave + '') + 1}</div>
            </div>
            {availableWaves.length > 1 && (
              <div className='delete-list-layout'>
                <div
                  className='delete-list'
                  onClick={e => {
                    e.stopPropagation();
                    deleteWave(wave);
                  }}>
                  <div className='delete-text'>x</div>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className='selectable-element' onClick={onNewWave}>
          ＋
        </div>
      </div>
      {availableUnits.sort(sortByRang).map(unit => (
        <div className='unit-entry' key={unit.id}>
          <img className='icon' src={unit.icon} alt={unit.name} />
          <div className='unit-name'>{t(unit.name)}</div>
          <div className='input'>
            <input
              type='number'
              key={`${unit.id}-input`}
              value={selectedUnits[unit.id] ?? ''}
              placeholder='0'
              min={0}
              onChange={event => handleChange(unit.id, event.target.value)}
              onBlur={event => handleChange(unit.id, event.target.value)}
            />
            {isPlayer && (
              <label
                onClick={() => {
                  fillUpUnits(unit.id);
                }}>
                ⤊
              </label>
            )}
            <label
              onClick={() => {
                clearUnits(unit.id);
              }}>
              ⤋
            </label>
            {isPlayer && (
              <div className='special-fill'>
                <label
                  onClick={() => {
                    setUserInputUnits({
                      ...selectedUnits,
                      [unit.id]: ''
                    });
                    setFastInputVisible(unit.id);
                  }}>
                  🖰
                </label>
                {fastInputVisible === unit.id && (
                  <div ref={valuesContent} className='pre-set-values'>
                    {FAST_INPUT_VALUES.map(value => (
                      <div
                        key={value}
                        className='preset-value-container'
                        onClick={() => {
                          let newValue;
                          if (value === CLEAR_SYMBOL) {
                            newValue = '';
                          } else if (value === BACK_SYMBOL) {
                            newValue = selectedUnits[unit.id] ?? '';
                            if (newValue.length) {
                              newValue = newValue.substr(0, newValue.length - 1);
                            }
                          } else {
                            newValue = (selectedUnits[unit.id] ?? '') + value;
                          }
                          setUserInputUnits({
                            ...selectedUnits,
                            [unit.id]: newValue
                          });
                        }}>
                        <div className='the-preset-value-text'>{value} </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <UnitDetails unit={unit} hideEp={isPlayer} />
        </div>
      ))}
      {isPlayer && (
        <label className='total-amount' key='totalAmount'>
          <span style={totalAmount > maxCapacity ? { color: 'red' } : undefined}>{totalAmount}</span>/{maxCapacity}
        </label>
      )}
      <FoldableElement>
        <div className='table'>
          <div className='header'>{t('Armee-Details')}</div>
          <div className='left'>{t('HP')}</div>
          <div className='right'>{detailedInfos.hp.toLocaleString()}</div>
          <div className='left'>{t('Schaden')}</div>
          <div className='right'>{`${detailedInfos.min.toLocaleString()} - ${detailedInfos.max.toLocaleString()} (Ø ${detailedInfos.avgDmg.toLocaleString()})`}</div>
        </div>
      </FoldableElement>
    </div>
  );
}

export default styled(UnitSelection)`
  width: 100%;
  display: grid;
  grid-row-gap: 0;

  .wave-config {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: default;
    padding-bottom: 18px;

    & > label {
      margin: auto 0;
    }

    & > div {
      margin: auto 0 auto 5px;
    }

    .wave-number {
      min-width: 2em;
      display: grid;

      .value-container {
        grid-column: 1;
        grid-row: 1;
        display: flex;

        .value-text {
          margin: auto;
        }
      }

      .wave-number-label {
        margin: auto;
      }

      .delete-list-layout {
        grid-column: 1;
        grid-row: 1;
        display: none;
        opacity: 0;
        transition: opacity 200ms;
      }
    }

    & .wave-number:hover {
      .delete-list-layout {
        grid-column: 1;
        grid-row: 1;
        opacity: 1;
        display: unset;
        position: relative;
        transition: opacity 200ms;

        .delete-list {
          position: absolute;
          display: flex;
          top: -8px;
          right: -8px;
          height: 16px;
          width: 16px;
          font-size: 10px;
          border: 1px solid red;
          border-radius: 50%;
          color: red;
          background-color: lightgray;
          transition: opacity 200ms;

          .delete-text {
            margin: auto;
            cursor: default;
          }
        }
      }
    }
  }

  .total-amount {
    text-align: end;
    padding-right: 25px;
    font-size: 0.8em;
  }

  ${FoldableElement} {
    margin: 28px 16px 0 16px;

    .table {
      display: grid;
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  .unit-entry {
    display: grid;
    width: 100%;
    grid-template-columns: 35px auto 80px;
    grid-column-gap: 5px;
    grid-row-gap: 0;
    align-items: center;
    line-height: 30px;

    .icon {
      height: 30px;
    }

    .input {
      display: flex;
      align-items: center;

      input {
        min-width: 4em;
      }

      .special-fill {
        position: relative;
        cursor: pointer;

        .pre-set-values {
          font-size: 1.3em;
          position: absolute;
          display: grid;
          grid-template-columns: repeat(3, 4ch);
          left: 20px;
          top: -30px;
          flex-direction: column;
          border: 1px solid gray;
          border-radius: 5px;
          background-color: var(--tertiary-color);
          color: var(--text-on-primary);

          .preset-value-container {
            display: flex;
            border: var(--neutral-border);

            .the-preset-value-text {
              margin: auto;
            }
          }
          .preset-value-container:hover {
            background-color: var(--hover-background-color);
            color: var(--hover-foreground-color);
          }
        }
      }

      input {
        width: 100%;
        height: 16px;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }

    ${UnitDetails} {
      grid-column: 2 / span 2;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      ${props =>
        props.showDetails
          ? css`
              max-height: 180px;
              transition: max-height 200ms ease-out;
            `
          : css`
              max-height: 0;
              transition: max-height 200ms ease-out;
            `}
    }
  }
`;
