import { BaseUnit, BaseUnitBase, BossType, UnitBaseType, EnemyType, InitiativeType } from './BaseUnitTypes';

const UNIT_FROSTBÄRENMATRIARCHIN_FBM_ICON = '/icons/units/FBM.png';
const UNIT_WILDEROCHSE_ROX_ICON = '/icons/units/ROx.png';
const UNIT_RIESENLUCHS_GL_ICON = '/icons/units/GL.png';
const UNIT_WILDEWALTRAUD_WW_ICON = '/icons/units/icon_bandit_boss5.png';
const UNIT_CHUCK_CK_ICON = '/icons/units/icon_bandit_boss4.png';
const UNIT_METALLGEBISS_MG_ICON = '/icons/units/icon_bandit_boss3.png';
const UNIT_PLÜNDERER_PL_ICON = '/icons/units/icon_bandit_recruit.png';
const UNIT_SCHLÄGER_SL_ICON = '/icons/units/icon_bandit_militia.png';
const UNIT_WACHHUND_WH_ICON = '/icons/units/icon_bandit_wardog.png';
const UNIT_RAUFBOLD_RB_ICON = '/icons/units/icon_bandit_soldier.png';
const UNIT_STEINWERFER_SW_ICON = '/icons/units/icon_bandit_bowman.png';
const UNIT_WALDLÄUFER_WL_ICON = '/icons/units/icon_bandit_longbowman.png';
const UNIT_KRÄHENFÜSSE_KF_ICON = '/icons/units/icon_pirate_cavalry.png';
const UNIT_DECKSCHRUBBER_DSR_ICON = '/icons/units/icon_pirate_recruit.png';
const UNIT_SÄBELRASSLER_SÄR_ICON = '/icons/units/icon_pirate_militia.png';
const UNIT_VERRÜCKTERSMUTJE_VS_ICON = '/icons/units/icon_pirate_boss1.png';
const UNIT_MESSERWERFER_MW_ICON = '/icons/units/icon_pirate_bowman.png';
const UNIT_PISTOLENSCHÜTZE_PS_ICON = '/icons/units/icon_pirate_longbowman.png';
const UNIT_MAAT_MA_ICON = '/icons/units/icon_pirate_captain.png';
const UNIT_DESERTIERTERREKRUT_DR_ICON = '/icons/units/icon_military_recruit01.png';
const UNIT_DESERTIERTEMILIZ_DM_ICON = '/icons/units/icon_military_militia01.png';
const UNIT_DESERTIERTEREITEREI_DC_ICON = '/icons/units/icon_military_cavalry01.png';
const UNIT_DESERTIERTERSOLDAT_DS_ICON = '/icons/units/icon_military_soldier.png';
const UNIT_DESERTIERTERELITESOLDAT_DE_ICON = '/icons/units/icon_epicraidkingdomelitesoldier.png';
const UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ICON = '/icons/units/icon_military_bowman01.png';
const UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ICON = '/icons/units/icon_military_longbowman01.png';
const UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ICON = '/icons/units/icon_military_crossbowman.png';
const UNIT_DESERTIERTERKANONIER_DK_ICON = '/icons/units/icon_military_cannoneer01.png';
const UNIT_AUSGEBURTDERNACHT_ADN_ICON = '/icons/units/icon_cult_boss3.png';
const UNIT_KULTIST_KT_ICON = '/icons/units/icon_cult_recruit.png';
const UNIT_SUMPFHEXE_SH_ICON = '/icons/units/icon_cult_boss1.png';
const UNIT_SCHATTENSCHLEICHER_SS_ICON = '/icons/units/icon_cult_cavalry.png';
const UNIT_FANATIKER_FA_ICON = '/icons/units/icon_cult_bowman.png';
const UNIT_DUNKLERPRIESTER_DP_ICON = '/icons/units/icon_cult_longbowman.png';
const UNIT_FEUERTÄNZER_FT_ICON = '/icons/units/icon_cult_crossbowman.png';
const UNIT_TANZENDERDERWISCH_TD_ICON = '/icons/units/icon_cult_canoneer.png';
const UNIT_NOMADE_NO_ICON = '/icons/units/icon_raiders_recruit.png';
const UNIT_LANZENREITER_LR_ICON = '/icons/units/icon_raiders_cavalary.png';
const UNIT_BERITTENERBOGENSCHÜTZE_BB_ICON = '/icons/units/icon_raiders_cavalry_bow.png';
const UNIT_BERITTENEAMAZONE_BA_ICON = '/icons/units/icon_raiders_cavalry_longbow.png';
const UNIT_KATAPHRAKT_KP_ICON = '/icons/units/icon_raiders_cavalry_crossbow.png';
const UNIT_BRÜLLENDERSTIER_BS_ICON = '/icons/units/icon_raiders_boss1.png';
const UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ICON = '/icons/units/icon_raiders_bowman.png';
const UNIT_JOMSWIKINGER_JW_ICON = '/icons/units/icon_nords_elite_soldier.png';
const UNIT_HUSKARL_HK_ICON = '/icons/units/icon_nords_soldier.png';
const UNIT_KARL_KAR_ICON = '/icons/units/icon_nords_militia.png';
const UNIT_THRALL_TH_ICON = '/icons/units/icon_nords_recruit.png';
const UNIT_WALKÜRE_WK_ICON = '/icons/units/icon_nords_bowman.png';
const UNIT_BERSERKER_BR_ICON = '/icons/units/icon_nords_canoneer.png';
const UNIT_GRAUERWOLF_W_ICON = '/icons/units/icon_neutral_cavalry.png';
const UNIT_STÜRMER_STÜ_ICON = '/icons/units/icon_em_unit_cavalry.png';
const UNIT_ABWEHRSPIELER_ABW_ICON = '/icons/units/icon_em_unit_milita.png';
const UNIT_MITTELFELDSPIELER_MF_ICON = '/icons/units/icon_em_unit_longbowman.png';
const UNIT_STAMMESANGEHÖRIGER_SA_ICON = '/icons/units/icon_mayan_tribesman.png';
const UNIT_SCHAMANE_SCH_ICON = '/icons/units/icon_mayan_shaman.png';
const UNIT_JAGUAR_KRIEGER_JK_ICON = '/icons/units/icon_mayan_jaguar.png';
const UNIT_WILDSCHWEIN_WS_ICON = '/icons/units/icon_wildlife_recruit.png';
const UNIT_BÄR_BÄR_ICON = '/icons/units/icon_wildlife_militia.png';
const UNIT_WOLF_WO_ICON = '/icons/units/icon_wildlife_bowman.png';
const UNIT_ALPHAWOLF_AW_ICON = '/icons/units/icon_wildlife_longbowman.png';
const UNIT_FUCHS_FU_ICON = '/icons/units/icon_wildlife_cavalry.png';
const UNIT_RIESE_RI_ICON = '/icons/units/icon_wildlife_cannoneer.png';
const UNIT_KÖNIGLICHERREKRUT_KR_ICON = '/icons/units/icon_military_recruit01.png';
const UNIT_KÖNIGLICHEMILIZ_KM_ICON = '/icons/units/icon_military_militia01.png';
const UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ICON = '/icons/units/icon_military_bowman01.png';
const UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ICON = '/icons/units/icon_military_longbowman01.png';
const UNIT_KÖNIGLICHEREITEREI_KC_ICON = '/icons/units/icon_military_cavalry01.png';
const UNIT_KÖNIGLICHERKANONIER_KK_ICON = '/icons/units/icon_military_cannoneer01.png';
const UNIT_EINFACHERWILDERER_EW_ICON = '/icons/units/icon_bandit_recruit.png';
const UNIT_HASENJÄGER_HJ_ICON = '/icons/units/icon_bandit_longbowman.png';
const UNIT_JAGDHUND_JH_ICON = '/icons/units/icon_bandit_wardog.png';
const UNIT_KLINGENRASSLER_KRA_ICON = '/icons/units/klingenrassler.png';
const UNIT_SÄBELSCHWINGER_SÄS_ICON = '/icons/units/saebelschwinger.png';
const UNIT_SCHWERTMEISTER_SM_ICON = '/icons/units/schwertmeister.png';
const UNIT_REITER_HM_ICON = '/icons/units/reiter.png';
const UNIT_DÜNENSCHÜTZE_DÜS_ICON = '/icons/units/duenenschuetze.png';
const UNIT_WÜSTENSCHÜTZE_WÜS_ICON = '/icons/units/wuestenschuetze.png';
const UNIT_BERITTENERBOGENSCHÜTZE_BBS_ICON = '/icons/units/berittener_bogenschuetze_bbs.png';
const UNIT_STEINKANONE_STK_ICON = '/icons/units/steinkanone.png';
const UNIT_MATROSE_MTR_ICON = '/icons/units/oriental_sailor_bandit_01.png';
const UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ICON = '/icons/units/oriental_sailor_bandit_02.png';
const UNIT_BERITTENERSEEFAHRER_BSF_ICON = '/icons/units/oriental_sailor_bandit_03.png';
const UNIT_BOOTSMANN_BM_ICON = '/icons/units/oriental_sailor_bandit_04.png';
const UNIT_OBERMAAT_OM_ICON = '/icons/units/oriental_sailor_bandit_06.png';
const UNIT_KANONIERMEISTER_KME_ICON = '/icons/units/oriental_sailor_bandit_05.png';
const UNIT_STOCKSCHWINGENDERAFFE_SSA_ICON = '/icons/units/icon_ape_01.png';
const UNIT_STEINWERFENDERAFFE_SWA_ICON = '/icons/units/icon_ape_02.png';
const UNIT_ALPHA_AFFE_AA_ICON = '/icons/units/icon_ape_03.png';
const UNIT_NERVÖSERAFFE_NA_ICON = '/icons/units/icon_ape_04.png';
const UNIT_SCHLITZOHR_SO_ICON = '/icons/units/icon_party_crashers_rascal.png';
const UNIT_VANDALE_VND_ICON = '/icons/units/icon_party_crashers_vandal.png';
const UNIT_RABAUKE_RBK_ICON = '/icons/units/icon_party_crashers_bully.png';
const UNIT_SCHELM_SM_ICON = '/icons/units/icon_party_crashers_prankster.png';
const UNIT_RÜPEL_RÜ_ICON = '/icons/units/icon_party_crashers_ruffian.png';
const UNIT_SCHURKE_SU_ICON = '/icons/units/icon_party_crashers_scoundrel.png';
const UNIT_FLEGEL_FL_ICON = '/icons/units/icon_party_crashers_cad.png';
const UNIT_NÖRGLER_NÖ_ICON = '/icons/units/icon_party_crashers_grouch.png';
const UNIT_QUENGLER_QU_ICON = '/icons/units/icon_party_crashers_whiner.png';
const UNIT_LUMP_LU_ICON = '/icons/units/icon_party_crashers_scallywag.png';
const UNIT_HIRSCH_HI_ICON = '/icons/units/icon_deer_unit.png';
const UNIT_FROSTSTEINBOCK_FSB_ICON = '/icons/units/FSb.png';
const UNIT_FROSTBÄR_FBR_ICON = '/icons/units/FBr.png';
const UNIT_FROSTFUCHS_FFU_ICON = '/icons/units/FFu.png';
const UNIT_FROSTADLER_FAD_ICON = '/icons/units/FAd.png';
const UNIT_FROSTWOLF_FWO_ICON = '/icons/units/FWo.png';
const UNIT_FROSTLEOPARD_FLE_ICON = '/icons/units/FLe.png';
const UNIT_FROSTRIESE_FRI_ICON = '/icons/units/FRi.png';
const UNIT_REKRUTF_ER_ICON = UNIT_DESERTIERTERREKRUT_DR_ICON;
const UNIT_MILIZF_EM_ICON = UNIT_DESERTIERTEMILIZ_DM_ICON;
const UNIT_SOLDATF_ES_ICON = UNIT_DESERTIERTERSOLDAT_DS_ICON;
const UNIT_ELITESOLDATF_EE_ICON = UNIT_DESERTIERTERELITESOLDAT_DE_ICON;
const UNIT_REITEREIF_EC_ICON = UNIT_DESERTIERTEREITEREI_DC_ICON;
const UNIT_BOGENSCHÜTZEF_EB_ICON = UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ICON;
const UNIT_LANGBOGENSCHÜTZEF_ELB_ICON = UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ICON;
const UNIT_ARMBRUSTSCHÜTZEF_EA_ICON = UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ICON;
const UNIT_KANONIERF_EK_ICON = UNIT_DESERTIERTERKANONIER_DK_ICON;
const UNIT_MINIATUR_LEHMGOLEM_MCG_ICON = '/icons/units/MClayGolem.png';
const UNIT_MINIATUR_FELSGOLEM_MRG_ICON = '/icons/units/MStoneGolem.png';
const UNIT_MINIATUR_EISGOLEM_MIG_ICON = '/icons/units/MIceGolem.png';
const UNIT_MINIATUR_LAVAGOLEM_MLG_ICON = '/icons/units/MLavaGolem.png';
const UNIT_STINKTIER_ST_ICON = '/icons/units/icon_bandit_boss1.png';
const UNIT_EINÄUGIGERBERT_EB_ICON = '/icons/units/icon_bandit_boss2.png';
const UNIT_SIRROBIN_ROB_ICON = '/icons/units/icon_kingdom_boss1.png';
const UNIT_DICKEBERTHA_DB_ICON = '/icons/units/icon_kingdom_boss2.png';
const UNIT_DUNKLERHOHEPRIESTER_DHP_ICON = '/icons/units/icon_cult_boss2.png';
const UNIT_FLAMMENPREDIGER_POF_ICON = '/icons/units/unit_preacher_of_flames.png';
const UNIT_RIESEBOGOR_RBO_ICON = '/icons/units/icon_epicraid_boss1.png';
const UNIT_RIESEGOGOR_RGO_ICON = '/icons/units/icon_epicraid_boss2.png';
const UNIT_EINHORN_EH_ICON = '/icons/units/icon_epicraid_boss3.png';
const UNIT_RASENDESWILDSCHWEIN_RWS_ICON = '/icons/units/icon_epicraid_boss4.png';
const UNIT_BÖSERKÖNIG_BK_ICON = '/icons/units/icon_epicraid_boss5.png';
const UNIT_EISENFAUST_EF_ICON = '/icons/units/icon_epicraid_boss6.png';
const UNIT_RIESIGERBÄR_RBÄ_ICON = '/icons/units/icon_epicraid_boss7.png';
const UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ICON = '/icons/units/icon_epicraid_boss8.png';
const UNIT_SCHWARZERSTIER_SST_ICON = '/icons/units/icon_epicraid_boss9.png';
const UNIT_DUNKLERZAUBERER_DZ_ICON = '/icons/units/icon_epicraid_boss10.png';
const UNIT_VERLOGENEZIEGE_VZ_ICON = '/icons/units/icon_epicraid_boss11.png';
const UNIT_KNÜPPEL_KLAUS_KKL_ICON = '/icons/units/icon_epicraid_boss12.png';
const UNIT_ASSASSINE_AS_ICON = '/icons/units/icon_epicraid_boss13.png';
const UNIT_GIERIGERGASTWIRT_GG_ICON = '/icons/units/icon_epicraid_boss14.png';
const UNIT_RATTENFÄNGER_PIP_ICON = '/icons/units/rattenfaenger.png';
const UNIT_DERBÜRGERMEISTER_MAY_ICON = '/icons/units/major.png';
const UNIT_RATTENKÖNIG_KOR_ICON = '/icons/units/rattenkoenig.png';
const UNIT_ILSEBILLE_ILES_ICON = '/icons/units/ilesbille.png';
const UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ICON = '/icons/units/jaeger.png';
const UNIT_BÖSESTIEFMUTTER_BSM_ICON = '/icons/units/stiefmutter.png';
const UNIT_KÖNIGLICHERKAPITÄN_RC_ICON = '/icons/units/koeniglicherkapitaen.png';
const UNIT_KÖNIGLICHERMOLOCH_RJ_ICON = '/icons/units/koeniglicherMoloch.png';
const UNIT_GEWITZTERRÄUBER_WITZR_ICON = '/icons/units/gewitzterRaeuber.png';
const UNIT_KLUGERRÄUBER_KLUR_ICON = '/icons/units/klugerRaeuber.png';
const UNIT_MYSTERIÖSERRÄUBER_MYSTR_ICON = '/icons/units/mysterioeserRaeuber.png';
const UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ICON = '/icons/units/heimtueckischerRaeuber.png';
const UNIT_HOCHNÄSIGERRÄUBER_HNR_ICON = '/icons/units/icon_1001_nights_boss_05.png';
const UNIT_ERGRAUTERRÄUBER_GRAUR_ICON = '/icons/units/icon_1001_nights_boss_06.png';
const UNIT_HINTERLISTIGERRÄUBER_HR_ICON = '/icons/units/icon_1001_nights_boss_07.png';
const UNIT_VERNARBTERRÄUBER_NAR_ICON = '/icons/units/icon_1001_nights_boss_08.png';
const UNIT_GIERIGERRÄUBER_GIER_ICON = '/icons/units/gierigerRaeuber.png';
const UNIT_EINFÄLTIGERRÄUBER_EINR_ICON = '/icons/units/icon_1001_nights_boss_10.png';
const UNIT_STEUERMANN_STM_ICON = '/icons/units/oriental_sailor_boss_01.png';
const UNIT_MEUTERER_MEU_ICON = '/icons/units/oriental_sailor_boss_02.png';
const UNIT_KAPITÄN_CAPT_ICON = '/icons/units/oriental_sailor_boss_03.png';
const UNIT_RÄUBERLORD_RL_ICON = '/icons/units/raeuberlord.png';
const UNIT_MÜRRISCHERPAPAGEI_MP_ICON = '/icons/units/icon_grumpy_parrot.png';
const UNIT_OASEN_HÜTER_OH_ICON = '/icons/units/icon_oasis_guardian.png';
const UNIT_FELSENWURM_FW_ICON = '/icons/units/icon_bolder_worm.png';
const UNIT_RIESENFLEDERMAUS_RFM_ICON = '/icons/units/RFm.png';
const UNIT_HUNGRIGEBLÜTE_HUB_ICON = '/icons/units/blossom_01.png';
const UNIT_GRIMMIGEBLÜTE_GRB_ICON = '/icons/units/blossom_02.png';
const UNIT_KNOLLIGEBLÜTE_KNB_ICON = '/icons/units/blossom_03.png';
const UNIT_BÜCHEREI_GOLEM_BG_ICON = '/icons/units/BG.png';
const UNIT_GROSSESTEINSTATUE_GSTS_ICON = '/icons/units/icon_statue_elephant.png';
const UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ICON = '/icons/units/icon_statue_snake.png';
const UNIT_RIESENKANONE_RKA_ICON = '/icons/units/icon_unit_canon.png';
const UNIT_INFORMANT_INF_ICON = '/icons/units/icon_informant.png';
const UNIT_RIESENSEESCHLANGE_RSS_ICON = '/icons/units/icon_sea_snake.png';
const UNIT_MÜRRISCHEWACHE_MÜW_ICON = '/icons/units/icon_guard_01.png';
const UNIT_WAHNSINNIGEWACHE_WSW_ICON = '/icons/units/icon_guard_02.png';
const UNIT_ELCHUPA_CHUP_ICON = '/icons/units/chupacabra.png';
const UNIT_ANCIENTDRAGON_AD_ICON = '/icons/units/drache.png';
const UNIT_DENISDERDELINQUENT_DEN_ICON = '/icons/units/denis.png';
const UNIT_ALEXDERQUERULANT_ALX_ICON = '/icons/units/alex.png';
const UNIT_BOBDERHINTERHÄLTIGE_BOB_ICON = '/icons/units/bob.png';
const UNIT_CROAKER_CR_ICON = '/icons/units/icon_croaker.png';
const UNIT_GARRUNDERFALLENSTELLER_GDF_ICON = '/icons/units/icon_trapper.png';
const UNIT_TORWART_TW_ICON = '/icons/units/icon_em_unit_boss.png';
const UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ICON = '/icons/units/icon_birthday2013_boss01.png';
const UNIT_STAHLBART_STB_ICON = '/icons/units/icon_nords_boss.png';
const UNIT_GIGANTISCHERADLER_GAD_ICON = '/icons/units/GAd.png';
const UNIT_MAMMUT_MAM_ICON = '/icons/units/mamuth.png';
const UNIT_WINTERSCHRECK_WT_ICON = '/icons/units/winterterror.png';
const UNIT_SÄBELZAHNTIGER_SZT_ICON = '/icons/units/SZt.png';
const UNIT_RIESIGERSTEINBOCK_ESB_ICON = '/icons/units/ESb.png';
const UNIT_GRAUSIGERWOLF_GWO_ICON = '/icons/units/GWo.png';
const UNIT_GEWALTIGERELCH_ELK_ICON = '/icons/units/elk.png';
const UNIT_RISI4_RISE_ICON = '/icons/units/risi.png';
const UNIT_RISI3_RISF_ICON = '/icons/units/risi.png';
const UNIT_RISI2_RISG_ICON = '/icons/units/risi.png';
const UNIT_RISI1_RISH_ICON = '/icons/units/risi.png';
const UNIT_RISI0_RISI_ICON = '/icons/units/risi.png';
const UNIT_RÄUBERLORDBJÖRN_BLB_ICON = '/icons/units/BLB.png';
const UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ICON = '/icons/units/MadScientist.png';
const UNIT_DUNKLERLORD_DLORD_ICON = '/icons/units/dLord.png';
const UNIT_RIBBITHA4_RIBA_ICON = '/icons/units/icon_croaker_twin.png';
const UNIT_RIBBITHA7_RIBB_ICON = '/icons/units/icon_croaker_twin.png';
const UNIT_RIBBITHA10_RIBC_ICON = '/icons/units/icon_croaker_twin.png';
const UNIT_SCHATZ_SCHAT_ICON = '/icons/units/schatz.png';
const UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ICON = '/icons/units/icon_corrupt_giant_bat.png';
const UNIT_VERDORBENERFELSWURM_VFW_ICON = '/icons/units/icon_corrupted_bolder_worm.png';
const UNIT_BLUE_MAN_BMA_ICON = '/icons/units/blue_man.png';
const UNIT_GREEN_MAN_GMA_ICON = '/icons/units/green_hair_guy.png';
const UNIT_BLACK_BEARD_BBE_ICON = '/icons/units/black_beard.png';
const UNIT_PETER_SPE_ICON = '/icons/units/struwelpeter.png';
const UNIT_GREEN_HOOD_GHO_ICON = '/icons/units/green_hood.png';
const UNIT_FRENCH_MAN_ICON = '/icons/units/french_man.png';

export const UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID = 1000;
export const UNIT_WILDEROCHSE_ROX_ID = 1001;
export const UNIT_RIESENLUCHS_GL_ID = 1002;
export const UNIT_WILDEWALTRAUD_WW_ID = 1003;
export const UNIT_CHUCK_CK_ID = 1004;
export const UNIT_METALLGEBISS_MG_ID = 1005;
export const UNIT_PLÜNDERER_PL_ID = 1006;
export const UNIT_SCHLÄGER_SL_ID = 1007;
export const UNIT_WACHHUND_WH_ID = 1008;
export const UNIT_RAUFBOLD_RB_ID = 1009;
export const UNIT_STEINWERFER_SW_ID = 1010;
export const UNIT_WALDLÄUFER_WL_ID = 1011;
export const UNIT_KRÄHENFÜSSE_KF_ID = 1012;
export const UNIT_DECKSCHRUBBER_DSR_ID = 1013;
export const UNIT_SÄBELRASSLER_SÄR_ID = 1014;
export const UNIT_VERRÜCKTERSMUTJE_VS_ID = 1015;
export const UNIT_MESSERWERFER_MW_ID = 1016;
export const UNIT_PISTOLENSCHÜTZE_PS_ID = 1017;
export const UNIT_MAAT_MA_ID = 1018;
export const UNIT_DESERTIERTERREKRUT_DR_ID = 1019;
export const UNIT_DESERTIERTEMILIZ_DM_ID = 1020;
export const UNIT_DESERTIERTEREITEREI_DC_ID = 1021;
export const UNIT_DESERTIERTERSOLDAT_DS_ID = 1022;
export const UNIT_DESERTIERTERELITESOLDAT_DE_ID = 1023;
export const UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID = 1024;
export const UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID = 1025;
export const UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID = 1026;
export const UNIT_DESERTIERTERKANONIER_DK_ID = 1027;
export const UNIT_AUSGEBURTDERNACHT_ADN_ID = 1028;
export const UNIT_KULTIST_KT_ID = 1029;
export const UNIT_SUMPFHEXE_SH_ID = 1030;
export const UNIT_SCHATTENSCHLEICHER_SS_ID = 1031;
export const UNIT_FANATIKER_FA_ID = 1032;
export const UNIT_DUNKLERPRIESTER_DP_ID = 1033;
export const UNIT_FEUERTÄNZER_FT_ID = 1034;
export const UNIT_TANZENDERDERWISCH_TD_ID = 1035;
export const UNIT_NOMADE_NO_ID = 1036;
export const UNIT_LANZENREITER_LR_ID = 1037;
export const UNIT_BERITTENERBOGENSCHÜTZE_BB_ID = 1038;
export const UNIT_BERITTENEAMAZONE_BA_ID = 1039;
export const UNIT_KATAPHRAKT_KP_ID = 1040;
export const UNIT_BRÜLLENDERSTIER_BS_ID = 1041;
export const UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID = 1042;
export const UNIT_JOMSWIKINGER_JW_ID = 1043;
export const UNIT_HUSKARL_HK_ID = 1044;
export const UNIT_KARL_KAR_ID = 1045;
export const UNIT_THRALL_TH_ID = 1046;
export const UNIT_WALKÜRE_WK_ID = 1047;
export const UNIT_BERSERKER_BR_ID = 1048;
export const UNIT_GRAUERWOLF_W_ID = 1049;
export const UNIT_STÜRMER_STÜ_ID = 1050;
export const UNIT_ABWEHRSPIELER_ABW_ID = 1051;
export const UNIT_MITTELFELDSPIELER_MF_ID = 1052;
export const UNIT_STAMMESANGEHÖRIGER_SA_ID = 1053;
export const UNIT_SCHAMANE_SCH_ID = 1054;
export const UNIT_JAGUAR_KRIEGER_JK_ID = 1055;
export const UNIT_WILDSCHWEIN_WS_ID = 1056;
export const UNIT_BÄR_BÄR_ID = 1057;
export const UNIT_WOLF_WO_ID = 1058;
export const UNIT_ALPHAWOLF_AW_ID = 1059;
export const UNIT_FUCHS_FU_ID = 1060;
export const UNIT_RIESE_RI_ID = 1061;
export const UNIT_KÖNIGLICHERREKRUT_KR_ID = 1062;
export const UNIT_KÖNIGLICHEMILIZ_KM_ID = 1063;
export const UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID = 1064;
export const UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID = 1065;
export const UNIT_KÖNIGLICHEREITEREI_KC_ID = 1066;
export const UNIT_KÖNIGLICHERKANONIER_KK_ID = 1067;
export const UNIT_EINFACHERWILDERER_EW_ID = 1068;
export const UNIT_HASENJÄGER_HJ_ID = 1069;
export const UNIT_JAGDHUND_JH_ID = 1070;
export const UNIT_KLINGENRASSLER_KRA_ID = 1071;
export const UNIT_SÄBELSCHWINGER_SÄS_ID = 1072;
export const UNIT_SCHWERTMEISTER_SM_ID = 1073;
export const UNIT_REITER_HM_ID = 1074;
export const UNIT_DÜNENSCHÜTZE_DÜS_ID = 1075;
export const UNIT_WÜSTENSCHÜTZE_WÜS_ID = 1076;
export const UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID = 1077;
export const UNIT_STEINKANONE_STK_ID = 1078;
export const UNIT_MATROSE_MTR_ID = 1079;
export const UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID = 1080;
export const UNIT_BERITTENERSEEFAHRER_BSF_ID = 1081;
export const UNIT_BOOTSMANN_BM_ID = 1082;
export const UNIT_OBERMAAT_OM_ID = 1083;
export const UNIT_KANONIERMEISTER_KME_ID = 1084;
export const UNIT_STOCKSCHWINGENDERAFFE_SSA_ID = 1085;
export const UNIT_STEINWERFENDERAFFE_SWA_ID = 1086;
export const UNIT_ALPHA_AFFE_AA_ID = 1087;
export const UNIT_NERVÖSERAFFE_NA_ID = 1088;
export const UNIT_SCHLITZOHR_SO_ID = 1089;
export const UNIT_VANDALE_VND_ID = 1090;
export const UNIT_RABAUKE_RBK_ID = 1091;
export const UNIT_SCHELM_SM_ID = 1092;
export const UNIT_RÜPEL_RÜ_ID = 1093;
export const UNIT_SCHURKE_SU_ID = 1094;
export const UNIT_FLEGEL_FL_ID = 1095;
export const UNIT_NÖRGLER_NÖ_ID = 1096;
export const UNIT_QUENGLER_QU_ID = 1097;
export const UNIT_LUMP_LU_ID = 1098;
export const UNIT_HIRSCH_HI_ID = 1099;
export const UNIT_FROSTSTEINBOCK_FSB_ID = 1100;
export const UNIT_FROSTBÄR_FBR_ID = 1101;
export const UNIT_FROSTFUCHS_FFU_ID = 1102;
export const UNIT_FROSTADLER_FAD_ID = 1103;
export const UNIT_FROSTWOLF_FWO_ID = 1104;
export const UNIT_FROSTLEOPARD_FLE_ID = 1105;
export const UNIT_FROSTRIESE_FRI_ID = 1106;
export const UNIT_REKRUTF_ER_ID = 1107;
export const UNIT_MILIZF_EM_ID = 1108;
export const UNIT_SOLDATF_ES_ID = 1109;
export const UNIT_ELITESOLDATF_EE_ID = 1110;
export const UNIT_REITEREIF_EC_ID = 1111;
export const UNIT_BOGENSCHÜTZEF_EB_ID = 1112;
export const UNIT_LANGBOGENSCHÜTZEF_ELB_ID = 1113;
export const UNIT_ARMBRUSTSCHÜTZEF_EA_ID = 1114;
export const UNIT_KANONIERF_EK_ID = 1115;
export const UNIT_MINIATUR_LEHMGOLEM_MCG_ID = 1116;
export const UNIT_MINIATUR_FELSGOLEM_MRG_ID = 1117;
export const UNIT_MINIATUR_EISGOLEM_MIG_ID = 1118;
export const UNIT_MINIATUR_LAVAGOLEM_MLG_ID = 1119;
export const UNIT_STINKTIER_ST_ID = 1120;
export const UNIT_EINÄUGIGERBERT_EB_ID = 1121;
export const UNIT_SIRROBIN_ROB_ID = 1122;
export const UNIT_DICKEBERTHA_DB_ID = 1123;
export const UNIT_DUNKLERHOHEPRIESTER_DHP_ID = 1124;
export const UNIT_FLAMMENPREDIGER_POF_ID = 1125;
export const UNIT_RIESEBOGOR_RBO_ID = 1126;
export const UNIT_RIESEGOGOR_RGO_ID = 1127;
export const UNIT_EINHORN_EH_ID = 1128;
export const UNIT_RASENDESWILDSCHWEIN_RWS_ID = 1129;
export const UNIT_BÖSERKÖNIG_BK_ID = 1130;
export const UNIT_EISENFAUST_EF_ID = 1131;
export const UNIT_RIESIGERBÄR_RBÄ_ID = 1132;
export const UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ID = 1133;
export const UNIT_SCHWARZERSTIER_SST_ID = 1134;
export const UNIT_DUNKLERZAUBERER_DZ_ID = 1135;
export const UNIT_VERLOGENEZIEGE_VZ_ID = 1136;
export const UNIT_KNÜPPEL_KLAUS_KKL_ID = 1137;
export const UNIT_ASSASSINE_AS_ID = 1138;
export const UNIT_GIERIGERGASTWIRT_GG_ID = 1139;
export const UNIT_RATTENFÄNGER_PIP_ID = 1140;
export const UNIT_DERBÜRGERMEISTER_MAY_ID = 1141;
export const UNIT_RATTENKÖNIG_KOR_ID = 1142;
export const UNIT_ILSEBILLE_ILES_ID = 1143;
export const UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ID = 1144;
export const UNIT_BÖSESTIEFMUTTER_BSM_ID = 1145;
export const UNIT_KÖNIGLICHERKAPITÄN_RC_ID = 1146;
export const UNIT_KÖNIGLICHERMOLOCH_RJ_ID = 1147;
export const UNIT_GEWITZTERRÄUBER_WITZR_ID = 1148;
export const UNIT_KLUGERRÄUBER_KLUR_ID = 1149;
export const UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID = 1150;
export const UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID = 1151;
export const UNIT_HOCHNÄSIGERRÄUBER_HNR_ID = 1152;
export const UNIT_ERGRAUTERRÄUBER_GRAUR_ID = 1153;
export const UNIT_HINTERLISTIGERRÄUBER_HR_ID = 1154;
export const UNIT_VERNARBTERRÄUBER_NAR_ID = 1155;
export const UNIT_GIERIGERRÄUBER_GIER_ID = 1156;
export const UNIT_EINFÄLTIGERRÄUBER_EINR_ID = 1157;
export const UNIT_STEUERMANN_STM_ID = 1158;
export const UNIT_MEUTERER_MEU_ID = 1159;
export const UNIT_KAPITÄN_CAPT_ID = 1160;
export const UNIT_RÄUBERLORD_RL_ID = 1161;
export const UNIT_MÜRRISCHERPAPAGEI_MP_ID = 1162;
export const UNIT_OASEN_HÜTER_OH_ID = 1163;
export const UNIT_FELSENWURM_FW_ID = 1164;
export const UNIT_RIESENFLEDERMAUS_RFM_ID = 1165;
export const UNIT_HUNGRIGEBLÜTE_HUB_ID = 1166;
export const UNIT_GRIMMIGEBLÜTE_GRB_ID = 1167;
export const UNIT_KNOLLIGEBLÜTE_KNB_ID = 1168;
export const UNIT_BÜCHEREI_GOLEM_BG_ID = 1169;
export const UNIT_GROSSESTEINSTATUE_GSTS_ID = 1170;
export const UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ID = 1171;
export const UNIT_RIESENKANONE_RKA_ID = 1172;
export const UNIT_INFORMANT_INF_ID = 1173;
export const UNIT_RIESENSEESCHLANGE_RSS_ID = 1174;
export const UNIT_MÜRRISCHEWACHE_MÜW_ID = 1175;
export const UNIT_WAHNSINNIGEWACHE_WSW_ID = 1176;
export const UNIT_ELCHUPA_CHUP_ID = 1177;
export const UNIT_ANCIENTDRAGON_AD_ID = 1178;
export const UNIT_DENISDERDELINQUENT_DEN_ID = 1179;
export const UNIT_ALEXDERQUERULANT_ALX_ID = 1180;
export const UNIT_BOBDERHINTERHÄLTIGE_BOB_ID = 1181;
export const UNIT_CROAKER_CR_ID = 1182;
export const UNIT_GARRUNDERFALLENSTELLER_GDF_ID = 1183;
export const UNIT_TORWART_TW_ID = 1184;
export const UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ID = 1185;
export const UNIT_STAHLBART_STB_ID = 1186;
export const UNIT_GIGANTISCHERADLER_GAD_ID = 1187;
export const UNIT_MAMMUT_MAM_ID = 1188;
export const UNIT_WINTERSCHRECK_WT_ID = 1189;
export const UNIT_SÄBELZAHNTIGER_SZT_ID = 1190;
export const UNIT_RIESIGERSTEINBOCK_ESB_ID = 1191;
export const UNIT_GRAUSIGERWOLF_GWO_ID = 1192;
export const UNIT_GEWALTIGERELCH_ELK_ID = 1193;
export const UNIT_RISI4_RISE_ID = 1194;
export const UNIT_RISI3_RISF_ID = 1195;
export const UNIT_RISI2_RISG_ID = 1196;
export const UNIT_RISI1_RISH_ID = 1197;
export const UNIT_RISI0_RISI_ID = 1198;
export const UNIT_RÄUBERLORDBJÖRN_BLB_ID = 1199;
export const UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ID = 1200;
export const UNIT_DUNKLERLORD_DLORD_ID = 1201;
export const UNIT_RIBBITHA4_RIBA_ID = 1202;
export const UNIT_RIBBITHA7_RIBB_ID = 1203;
export const UNIT_RIBBITHA10_RIBC_ID = 1204;
export const UNIT_SCHATZ_SCHAT_ID = 1205;
export const UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ID = 1206;
export const UNIT_VERDORBENERFELSWURM_VFW_ID = 1207;
export const UNIT_BLUE_MAN_BMA_ID = 1208;
export const UNIT_GREEN_MAN_GMA_ID = 1209;
export const UNIT_BLACK_BEARD_BBE_ID = 1210;
export const UNIT_GREEN_HOOD_GHO_ID = 1211;
export const UNIT_PETER_SPE_ID = 1212;
export const UNIT_FRENCH_MAN_ID = 1213;
export type EnemyId =
  | typeof UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID
  | typeof UNIT_WILDEROCHSE_ROX_ID
  | typeof UNIT_GREEN_MAN_GMA_ID
  | typeof UNIT_BLUE_MAN_BMA_ID
  | typeof UNIT_BLACK_BEARD_BBE_ID
  | typeof UNIT_GREEN_HOOD_GHO_ID
  | typeof UNIT_PETER_SPE_ID
  | typeof UNIT_FRENCH_MAN_ID
  | typeof UNIT_RIESENLUCHS_GL_ID
  | typeof UNIT_WILDEWALTRAUD_WW_ID
  | typeof UNIT_CHUCK_CK_ID
  | typeof UNIT_METALLGEBISS_MG_ID
  | typeof UNIT_PLÜNDERER_PL_ID
  | typeof UNIT_SCHLÄGER_SL_ID
  | typeof UNIT_WACHHUND_WH_ID
  | typeof UNIT_RAUFBOLD_RB_ID
  | typeof UNIT_STEINWERFER_SW_ID
  | typeof UNIT_WALDLÄUFER_WL_ID
  | typeof UNIT_KRÄHENFÜSSE_KF_ID
  | typeof UNIT_DECKSCHRUBBER_DSR_ID
  | typeof UNIT_SÄBELRASSLER_SÄR_ID
  | typeof UNIT_VERRÜCKTERSMUTJE_VS_ID
  | typeof UNIT_MESSERWERFER_MW_ID
  | typeof UNIT_PISTOLENSCHÜTZE_PS_ID
  | typeof UNIT_MAAT_MA_ID
  | typeof UNIT_DESERTIERTERREKRUT_DR_ID
  | typeof UNIT_DESERTIERTEMILIZ_DM_ID
  | typeof UNIT_DESERTIERTEREITEREI_DC_ID
  | typeof UNIT_DESERTIERTERSOLDAT_DS_ID
  | typeof UNIT_DESERTIERTERELITESOLDAT_DE_ID
  | typeof UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID
  | typeof UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID
  | typeof UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID
  | typeof UNIT_DESERTIERTERKANONIER_DK_ID
  | typeof UNIT_AUSGEBURTDERNACHT_ADN_ID
  | typeof UNIT_KULTIST_KT_ID
  | typeof UNIT_SUMPFHEXE_SH_ID
  | typeof UNIT_SCHATTENSCHLEICHER_SS_ID
  | typeof UNIT_FANATIKER_FA_ID
  | typeof UNIT_DUNKLERPRIESTER_DP_ID
  | typeof UNIT_FEUERTÄNZER_FT_ID
  | typeof UNIT_TANZENDERDERWISCH_TD_ID
  | typeof UNIT_NOMADE_NO_ID
  | typeof UNIT_LANZENREITER_LR_ID
  | typeof UNIT_BERITTENERBOGENSCHÜTZE_BB_ID
  | typeof UNIT_BERITTENEAMAZONE_BA_ID
  | typeof UNIT_KATAPHRAKT_KP_ID
  | typeof UNIT_BRÜLLENDERSTIER_BS_ID
  | typeof UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID
  | typeof UNIT_JOMSWIKINGER_JW_ID
  | typeof UNIT_HUSKARL_HK_ID
  | typeof UNIT_KARL_KAR_ID
  | typeof UNIT_THRALL_TH_ID
  | typeof UNIT_WALKÜRE_WK_ID
  | typeof UNIT_BERSERKER_BR_ID
  | typeof UNIT_GRAUERWOLF_W_ID
  | typeof UNIT_STÜRMER_STÜ_ID
  | typeof UNIT_ABWEHRSPIELER_ABW_ID
  | typeof UNIT_MITTELFELDSPIELER_MF_ID
  | typeof UNIT_STAMMESANGEHÖRIGER_SA_ID
  | typeof UNIT_SCHAMANE_SCH_ID
  | typeof UNIT_JAGUAR_KRIEGER_JK_ID
  | typeof UNIT_WILDSCHWEIN_WS_ID
  | typeof UNIT_BÄR_BÄR_ID
  | typeof UNIT_WOLF_WO_ID
  | typeof UNIT_ALPHAWOLF_AW_ID
  | typeof UNIT_FUCHS_FU_ID
  | typeof UNIT_RIESE_RI_ID
  | typeof UNIT_KÖNIGLICHERREKRUT_KR_ID
  | typeof UNIT_KÖNIGLICHEMILIZ_KM_ID
  | typeof UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID
  | typeof UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID
  | typeof UNIT_KÖNIGLICHEREITEREI_KC_ID
  | typeof UNIT_KÖNIGLICHERKANONIER_KK_ID
  | typeof UNIT_EINFACHERWILDERER_EW_ID
  | typeof UNIT_HASENJÄGER_HJ_ID
  | typeof UNIT_JAGDHUND_JH_ID
  | typeof UNIT_KLINGENRASSLER_KRA_ID
  | typeof UNIT_SÄBELSCHWINGER_SÄS_ID
  | typeof UNIT_SCHWERTMEISTER_SM_ID
  | typeof UNIT_REITER_HM_ID
  | typeof UNIT_DÜNENSCHÜTZE_DÜS_ID
  | typeof UNIT_WÜSTENSCHÜTZE_WÜS_ID
  | typeof UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID
  | typeof UNIT_STEINKANONE_STK_ID
  | typeof UNIT_MATROSE_MTR_ID
  | typeof UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID
  | typeof UNIT_BERITTENERSEEFAHRER_BSF_ID
  | typeof UNIT_BOOTSMANN_BM_ID
  | typeof UNIT_OBERMAAT_OM_ID
  | typeof UNIT_KANONIERMEISTER_KME_ID
  | typeof UNIT_STOCKSCHWINGENDERAFFE_SSA_ID
  | typeof UNIT_STEINWERFENDERAFFE_SWA_ID
  | typeof UNIT_ALPHA_AFFE_AA_ID
  | typeof UNIT_NERVÖSERAFFE_NA_ID
  | typeof UNIT_SCHLITZOHR_SO_ID
  | typeof UNIT_VANDALE_VND_ID
  | typeof UNIT_RABAUKE_RBK_ID
  | typeof UNIT_SCHELM_SM_ID
  | typeof UNIT_RÜPEL_RÜ_ID
  | typeof UNIT_SCHURKE_SU_ID
  | typeof UNIT_FLEGEL_FL_ID
  | typeof UNIT_NÖRGLER_NÖ_ID
  | typeof UNIT_QUENGLER_QU_ID
  | typeof UNIT_LUMP_LU_ID
  | typeof UNIT_HIRSCH_HI_ID
  | typeof UNIT_FROSTSTEINBOCK_FSB_ID
  | typeof UNIT_FROSTBÄR_FBR_ID
  | typeof UNIT_FROSTFUCHS_FFU_ID
  | typeof UNIT_FROSTADLER_FAD_ID
  | typeof UNIT_FROSTWOLF_FWO_ID
  | typeof UNIT_FROSTLEOPARD_FLE_ID
  | typeof UNIT_FROSTRIESE_FRI_ID
  | typeof UNIT_REKRUTF_ER_ID
  | typeof UNIT_MILIZF_EM_ID
  | typeof UNIT_SOLDATF_ES_ID
  | typeof UNIT_ELITESOLDATF_EE_ID
  | typeof UNIT_REITEREIF_EC_ID
  | typeof UNIT_BOGENSCHÜTZEF_EB_ID
  | typeof UNIT_LANGBOGENSCHÜTZEF_ELB_ID
  | typeof UNIT_ARMBRUSTSCHÜTZEF_EA_ID
  | typeof UNIT_KANONIERF_EK_ID
  | typeof UNIT_MINIATUR_LEHMGOLEM_MCG_ID
  | typeof UNIT_MINIATUR_FELSGOLEM_MRG_ID
  | typeof UNIT_MINIATUR_EISGOLEM_MIG_ID
  | typeof UNIT_MINIATUR_LAVAGOLEM_MLG_ID
  | typeof UNIT_STINKTIER_ST_ID
  | typeof UNIT_EINÄUGIGERBERT_EB_ID
  | typeof UNIT_SIRROBIN_ROB_ID
  | typeof UNIT_DICKEBERTHA_DB_ID
  | typeof UNIT_DUNKLERHOHEPRIESTER_DHP_ID
  | typeof UNIT_FLAMMENPREDIGER_POF_ID
  | typeof UNIT_RIESEBOGOR_RBO_ID
  | typeof UNIT_RIESEGOGOR_RGO_ID
  | typeof UNIT_EINHORN_EH_ID
  | typeof UNIT_RASENDESWILDSCHWEIN_RWS_ID
  | typeof UNIT_BÖSERKÖNIG_BK_ID
  | typeof UNIT_EISENFAUST_EF_ID
  | typeof UNIT_RIESIGERBÄR_RBÄ_ID
  | typeof UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ID
  | typeof UNIT_SCHWARZERSTIER_SST_ID
  | typeof UNIT_DUNKLERZAUBERER_DZ_ID
  | typeof UNIT_VERLOGENEZIEGE_VZ_ID
  | typeof UNIT_KNÜPPEL_KLAUS_KKL_ID
  | typeof UNIT_ASSASSINE_AS_ID
  | typeof UNIT_GIERIGERGASTWIRT_GG_ID
  | typeof UNIT_RATTENFÄNGER_PIP_ID
  | typeof UNIT_DERBÜRGERMEISTER_MAY_ID
  | typeof UNIT_RATTENKÖNIG_KOR_ID
  | typeof UNIT_ILSEBILLE_ILES_ID
  | typeof UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ID
  | typeof UNIT_BÖSESTIEFMUTTER_BSM_ID
  | typeof UNIT_KÖNIGLICHERKAPITÄN_RC_ID
  | typeof UNIT_KÖNIGLICHERMOLOCH_RJ_ID
  | typeof UNIT_GEWITZTERRÄUBER_WITZR_ID
  | typeof UNIT_KLUGERRÄUBER_KLUR_ID
  | typeof UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID
  | typeof UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID
  | typeof UNIT_HOCHNÄSIGERRÄUBER_HNR_ID
  | typeof UNIT_ERGRAUTERRÄUBER_GRAUR_ID
  | typeof UNIT_HINTERLISTIGERRÄUBER_HR_ID
  | typeof UNIT_VERNARBTERRÄUBER_NAR_ID
  | typeof UNIT_GIERIGERRÄUBER_GIER_ID
  | typeof UNIT_EINFÄLTIGERRÄUBER_EINR_ID
  | typeof UNIT_STEUERMANN_STM_ID
  | typeof UNIT_MEUTERER_MEU_ID
  | typeof UNIT_KAPITÄN_CAPT_ID
  | typeof UNIT_RÄUBERLORD_RL_ID
  | typeof UNIT_MÜRRISCHERPAPAGEI_MP_ID
  | typeof UNIT_OASEN_HÜTER_OH_ID
  | typeof UNIT_FELSENWURM_FW_ID
  | typeof UNIT_RIESENFLEDERMAUS_RFM_ID
  | typeof UNIT_HUNGRIGEBLÜTE_HUB_ID
  | typeof UNIT_GRIMMIGEBLÜTE_GRB_ID
  | typeof UNIT_KNOLLIGEBLÜTE_KNB_ID
  | typeof UNIT_BÜCHEREI_GOLEM_BG_ID
  | typeof UNIT_GROSSESTEINSTATUE_GSTS_ID
  | typeof UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ID
  | typeof UNIT_RIESENKANONE_RKA_ID
  | typeof UNIT_INFORMANT_INF_ID
  | typeof UNIT_RIESENSEESCHLANGE_RSS_ID
  | typeof UNIT_MÜRRISCHEWACHE_MÜW_ID
  | typeof UNIT_WAHNSINNIGEWACHE_WSW_ID
  | typeof UNIT_ELCHUPA_CHUP_ID
  | typeof UNIT_ANCIENTDRAGON_AD_ID
  | typeof UNIT_DENISDERDELINQUENT_DEN_ID
  | typeof UNIT_ALEXDERQUERULANT_ALX_ID
  | typeof UNIT_BOBDERHINTERHÄLTIGE_BOB_ID
  | typeof UNIT_CROAKER_CR_ID
  | typeof UNIT_GARRUNDERFALLENSTELLER_GDF_ID
  | typeof UNIT_TORWART_TW_ID
  | typeof UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ID
  | typeof UNIT_STAHLBART_STB_ID
  | typeof UNIT_GIGANTISCHERADLER_GAD_ID
  | typeof UNIT_MAMMUT_MAM_ID
  | typeof UNIT_WINTERSCHRECK_WT_ID
  | typeof UNIT_SÄBELZAHNTIGER_SZT_ID
  | typeof UNIT_RIESIGERSTEINBOCK_ESB_ID
  | typeof UNIT_GRAUSIGERWOLF_GWO_ID
  | typeof UNIT_GEWALTIGERELCH_ELK_ID
  | typeof UNIT_RISI4_RISE_ID
  | typeof UNIT_RISI3_RISF_ID
  | typeof UNIT_RISI2_RISG_ID
  | typeof UNIT_RISI1_RISH_ID
  | typeof UNIT_RISI0_RISI_ID
  | typeof UNIT_RÄUBERLORDBJÖRN_BLB_ID
  | typeof UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ID
  | typeof UNIT_DUNKLERLORD_DLORD_ID
  | typeof UNIT_RIBBITHA4_RIBA_ID
  | typeof UNIT_RIBBITHA7_RIBB_ID
  | typeof UNIT_RIBBITHA10_RIBC_ID
  | typeof UNIT_SCHATZ_SCHAT_ID
  | typeof UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ID
  | typeof UNIT_VERDORBENERFELSWURM_VFW_ID;

export interface Enemy extends BaseUnit {
  id: EnemyId;
  type?: EnemyType;
  base: BaseUnitBase & { ep: number };
  rang: number;
  ep: number;
}

interface InternalEnemy {
  name: string;
  id: EnemyId;
  type?: EnemyType;
  abr: string;
  rang: number;
  ep: number;
  hp: number;
  min: number;
  max: number;
  accuracy: number;
  icon: string;
  flank?: boolean;
  splash?: number;
  initiative?: InitiativeType;
}

const Frostbärenmatriarchin: InternalEnemy = {
  name: 'Frostbärenmatriarchin',
  hp: 600000,
  ep: 60000,
  min: 300,
  max: 600,
  accuracy: 85,
  id: UNIT_FROSTBÄRENMATRIARCHIN_FBM_ID,
  icon: UNIT_FROSTBÄRENMATRIARCHIN_FBM_ICON,
  type: BossType.BOSS,
  abr: 'FBM',
  rang: 50,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const WilderOchse: InternalEnemy = {
  name: 'Wilder Ochse',
  hp: 100000,
  ep: 10000,
  min: 600,
  max: 800,
  accuracy: 50,
  id: UNIT_WILDEROCHSE_ROX_ID,
  icon: UNIT_WILDEROCHSE_ROX_ICON,
  type: BossType.BOSS,
  abr: 'ROx',
  rang: 51,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Riesenluchs: InternalEnemy = {
  name: 'Riesenluchs',
  hp: 20000,
  ep: 7200,
  min: 2500,
  max: 3500,
  accuracy: 50,
  id: UNIT_RIESENLUCHS_GL_ID,
  icon: UNIT_RIESENLUCHS_GL_ICON,
  type: BossType.BOSS,
  abr: 'GL',
  rang: 52,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const WildeWaltraud: InternalEnemy = {
  name: 'Wilde Waltraud',
  hp: 60000,
  ep: 430,
  min: 740,
  max: 800,
  accuracy: 50,
  id: UNIT_WILDEWALTRAUD_WW_ID,
  icon: UNIT_WILDEWALTRAUD_WW_ICON,
  type: BossType.BOSS,
  abr: 'WW',
  rang: 100,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Chuck: InternalEnemy = {
  name: 'Chuck',
  hp: 9000,
  ep: 250,
  min: 2000,
  max: 2500,
  accuracy: 50,
  id: UNIT_CHUCK_CK_ID,
  icon: UNIT_CHUCK_CK_ICON,
  type: BossType.BOSS,
  abr: 'CK',
  rang: 101,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Metallgebiss: InternalEnemy = {
  name: 'Metallgebiss',
  hp: 11000,
  ep: 160,
  min: 250,
  max: 500,
  accuracy: 50,
  id: UNIT_METALLGEBISS_MG_ID,
  icon: UNIT_METALLGEBISS_MG_ICON,
  type: BossType.BOSS,
  abr: 'MG',
  rang: 102,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Plünderer: InternalEnemy = {
  name: 'Plünderer',
  hp: 40,
  ep: 4,
  min: 15,
  max: 30,
  accuracy: 60,
  id: UNIT_PLÜNDERER_PL_ID,
  icon: UNIT_PLÜNDERER_PL_ICON,
  type: UnitBaseType.DEF,
  abr: 'PL',
  rang: 103
};
const Schläger: InternalEnemy = {
  name: 'Schläger',
  hp: 60,
  ep: 3,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_SCHLÄGER_SL_ID,
  icon: UNIT_SCHLÄGER_SL_ICON,
  type: UnitBaseType.DEF,
  abr: 'SL',
  rang: 104
};
const Wachhund: InternalEnemy = {
  name: 'Wachhund',
  hp: 5,
  ep: 4,
  min: 5,
  max: 10,
  accuracy: 60,
  id: UNIT_WACHHUND_WH_ID,
  icon: UNIT_WACHHUND_WH_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'WH',
  rang: 105,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Raufbold: InternalEnemy = {
  name: 'Raufbold',
  hp: 90,
  ep: 6,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_RAUFBOLD_RB_ID,
  icon: UNIT_RAUFBOLD_RB_ICON,
  type: UnitBaseType.DEF,
  abr: 'RB',
  rang: 106
};
const Steinwerfer: InternalEnemy = {
  name: 'Steinwerfer',
  hp: 10,
  ep: 3,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_STEINWERFER_SW_ID,
  icon: UNIT_STEINWERFER_SW_ICON,
  type: UnitBaseType.OFF,
  abr: 'SW',
  rang: 107
};
const Waldläufer: InternalEnemy = {
  name: 'Waldläufer',
  hp: 10,
  ep: 4,
  min: 30,
  max: 60,
  accuracy: 60,
  id: UNIT_WALDLÄUFER_WL_ID,
  icon: UNIT_WALDLÄUFER_WL_ICON,
  type: UnitBaseType.OFF,
  abr: 'WL',
  rang: 108
};
const Krähenfüße: InternalEnemy = {
  name: 'Krähenfüße',
  hp: 4,
  ep: 1,
  min: 0,
  max: 20,
  accuracy: 33,
  id: UNIT_KRÄHENFÜSSE_KF_ID,
  icon: UNIT_KRÄHENFÜSSE_KF_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'KF',
  rang: 110,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Deckschrubber: InternalEnemy = {
  name: 'Deckschrubber',
  hp: 15,
  ep: 1,
  min: 10,
  max: 15,
  accuracy: 50,
  id: UNIT_DECKSCHRUBBER_DSR_ID,
  icon: UNIT_DECKSCHRUBBER_DSR_ICON,
  type: UnitBaseType.DEF,
  abr: 'DSr',
  rang: 111
};
const Säbelrassler: InternalEnemy = {
  name: 'Säbelrassler',
  hp: 30,
  ep: 2,
  min: 10,
  max: 20,
  accuracy: 50,
  id: UNIT_SÄBELRASSLER_SÄR_ID,
  icon: UNIT_SÄBELRASSLER_SÄR_ICON,
  type: UnitBaseType.DEF,
  abr: 'SäR',
  rang: 112
};
const VerrückterSmutje: InternalEnemy = {
  name: 'Verrückter Smutje',
  hp: 5000,
  ep: 200,
  min: 200,
  max: 300,
  accuracy: 66,
  id: UNIT_VERRÜCKTERSMUTJE_VS_ID,
  icon: UNIT_VERRÜCKTERSMUTJE_VS_ICON,
  type: BossType.BOSS,
  abr: 'VS',
  rang: 113,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Messerwerfer: InternalEnemy = {
  name: 'Messerwerfer',
  hp: 5,
  ep: 1,
  min: 10,
  max: 20,
  accuracy: 50,
  id: UNIT_MESSERWERFER_MW_ID,
  icon: UNIT_MESSERWERFER_MW_ICON,
  type: UnitBaseType.OFF,
  abr: 'MW',
  rang: 114
};
const Pistolenschütze: InternalEnemy = {
  name: 'Pistolenschütze',
  hp: 5,
  ep: 2,
  min: 20,
  max: 30,
  accuracy: 50,
  id: UNIT_PISTOLENSCHÜTZE_PS_ID,
  icon: UNIT_PISTOLENSCHÜTZE_PS_ICON,
  type: UnitBaseType.OFF,
  abr: 'PS',
  rang: 115
};
const Maat: InternalEnemy = {
  name: 'Maat',
  hp: 60,
  ep: 6,
  min: 40,
  max: 60,
  accuracy: 70,
  id: UNIT_MAAT_MA_ID,
  icon: UNIT_MAAT_MA_ICON,
  type: UnitBaseType.DEF,
  abr: 'Ma',
  rang: 116,
  flank: true,
  splash: 1
};
const DesertierterRekrut: InternalEnemy = {
  name: 'Desertierter Rekrut',
  hp: 40,
  ep: 2,
  min: 15,
  max: 30,
  accuracy: 60,
  id: UNIT_DESERTIERTERREKRUT_DR_ID,
  icon: UNIT_DESERTIERTERREKRUT_DR_ICON,
  type: UnitBaseType.DEF,
  abr: 'dR',
  rang: 120
};
const DesertierteMiliz: InternalEnemy = {
  name: 'Desertierte Miliz',
  hp: 60,
  ep: 4,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_DESERTIERTEMILIZ_DM_ID,
  icon: UNIT_DESERTIERTEMILIZ_DM_ICON,
  type: UnitBaseType.DEF,
  abr: 'dM',
  rang: 121
};
const DesertierteReiterei: InternalEnemy = {
  name: 'Desertierte Reiterei',
  hp: 5,
  ep: 4,
  min: 5,
  max: 10,
  accuracy: 60,
  id: UNIT_DESERTIERTEREITEREI_DC_ID,
  icon: UNIT_DESERTIERTEREITEREI_DC_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'dC',
  rang: 122,
  initiative: InitiativeType.FIRST,
  flank: true
};
const DesertierterSoldat: InternalEnemy = {
  name: 'Desertierter Soldat',
  hp: 90,
  ep: 6,
  min: 20,
  max: 40,
  accuracy: 65,
  id: UNIT_DESERTIERTERSOLDAT_DS_ID,
  icon: UNIT_DESERTIERTERSOLDAT_DS_ICON,
  type: UnitBaseType.DEF,
  abr: 'dS',
  rang: 123
};
const DesertierterElitesoldat: InternalEnemy = {
  name: 'Desertierter Elitesoldat',
  hp: 120,
  ep: 8,
  min: 20,
  max: 40,
  accuracy: 70,
  id: UNIT_DESERTIERTERELITESOLDAT_DE_ID,
  icon: UNIT_DESERTIERTERELITESOLDAT_DE_ICON,
  type: UnitBaseType.DEF,
  abr: 'dE',
  rang: 124
};
const DesertierterBogenschütze: InternalEnemy = {
  name: 'Desertierter Bogenschütze',
  hp: 10,
  ep: 3,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ID,
  icon: UNIT_DESERTIERTERBOGENSCHÜTZE_DB_ICON,
  type: UnitBaseType.OFF,
  abr: 'dB',
  rang: 125
};
const DesertierterLangbogenschütze: InternalEnemy = {
  name: 'Desertierter Langbogenschütze',
  hp: 10,
  ep: 5,
  min: 30,
  max: 60,
  accuracy: 60,
  id: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ID,
  icon: UNIT_DESERTIERTERLANGBOGENSCHÜTZE_DLB_ICON,
  type: UnitBaseType.OFF,
  abr: 'dLB',
  rang: 126
};
const DesertierterArmbrustschütze: InternalEnemy = {
  name: 'Desertierter Armbrustschütze',
  hp: 10,
  ep: 7,
  min: 45,
  max: 90,
  accuracy: 60,
  id: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ID,
  icon: UNIT_DESERTIERTERARMBRUSTSCHÜTZE_DA_ICON,
  type: UnitBaseType.OFF,
  abr: 'dA',
  rang: 127
};
const DesertierterKanonier: InternalEnemy = {
  name: 'Desertierter Kanonier',
  hp: 60,
  ep: 15,
  min: 60,
  max: 120,
  accuracy: 70,
  id: UNIT_DESERTIERTERKANONIER_DK_ID,
  icon: UNIT_DESERTIERTERKANONIER_DK_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'dK',
  rang: 128,
  initiative: InitiativeType.LAST
};
const AusgeburtderNacht: InternalEnemy = {
  name: 'Ausgeburt der Nacht',
  hp: 35000,
  ep: 500,
  min: 700,
  max: 800,
  accuracy: 75,
  id: UNIT_AUSGEBURTDERNACHT_ADN_ID,
  icon: UNIT_AUSGEBURTDERNACHT_ADN_ICON,
  type: BossType.BOSS,
  abr: 'ADN',
  rang: 130,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Kultist: InternalEnemy = {
  name: 'Kultist',
  hp: 40,
  ep: 3,
  min: 15,
  max: 30,
  accuracy: 80,
  id: UNIT_KULTIST_KT_ID,
  icon: UNIT_KULTIST_KT_ICON,
  type: UnitBaseType.DEF,
  abr: 'KT',
  rang: 131
};
const Sumpfhexe: InternalEnemy = {
  name: 'Sumpfhexe',
  hp: 13000,
  ep: 250,
  min: 400,
  max: 600,
  accuracy: 75,
  id: UNIT_SUMPFHEXE_SH_ID,
  icon: UNIT_SUMPFHEXE_SH_ICON,
  type: BossType.BOSS,
  abr: 'SH',
  rang: 132,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Schattenschleicher: InternalEnemy = {
  name: 'Schattenschleicher',
  hp: 5,
  ep: 6,
  min: 0,
  max: 5,
  accuracy: 60,
  id: UNIT_SCHATTENSCHLEICHER_SS_ID,
  icon: UNIT_SCHATTENSCHLEICHER_SS_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'SS',
  rang: 133,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Fanatiker: InternalEnemy = {
  name: 'Fanatiker',
  hp: 20,
  ep: 6,
  min: 30,
  max: 60,
  accuracy: 90,
  id: UNIT_FANATIKER_FA_ID,
  icon: UNIT_FANATIKER_FA_ICON,
  type: UnitBaseType.OFF,
  abr: 'Fa',
  rang: 134
};
const DunklerPriester: InternalEnemy = {
  name: 'Dunkler Priester',
  hp: 20,
  ep: 8,
  min: 40,
  max: 80,
  accuracy: 100,
  id: UNIT_DUNKLERPRIESTER_DP_ID,
  icon: UNIT_DUNKLERPRIESTER_DP_ICON,
  type: UnitBaseType.OFF,
  abr: 'DP',
  rang: 135
};
const Feuertänzer: InternalEnemy = {
  name: 'Feuertänzer',
  hp: 30,
  ep: 14,
  min: 60,
  max: 120,
  accuracy: 100,
  id: UNIT_FEUERTÄNZER_FT_ID,
  icon: UNIT_FEUERTÄNZER_FT_ICON,
  type: UnitBaseType.OFF,
  abr: 'FT',
  rang: 136
};
const TanzenderDerwisch: InternalEnemy = {
  name: 'Tanzender Derwisch',
  hp: 90,
  ep: 20,
  min: 60,
  max: 120,
  accuracy: 90,
  id: UNIT_TANZENDERDERWISCH_TD_ID,
  icon: UNIT_TANZENDERDERWISCH_TD_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'TD',
  rang: 137,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Nomade: InternalEnemy = {
  name: 'Nomade',
  hp: 30,
  ep: 2,
  min: 10,
  max: 20,
  accuracy: 60,
  id: UNIT_NOMADE_NO_ID,
  icon: UNIT_NOMADE_NO_ICON,
  type: UnitBaseType.DEF,
  abr: 'NO',
  rang: 140
};
const Lanzenreiter: InternalEnemy = {
  name: 'Lanzenreiter',
  hp: 20,
  ep: 4,
  min: 5,
  max: 20,
  accuracy: 90,
  id: UNIT_LANZENREITER_LR_ID,
  icon: UNIT_LANZENREITER_LR_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'LR',
  rang: 141,
  initiative: InitiativeType.FIRST,
  flank: true
};
const BerittenerBogenschütze: InternalEnemy = {
  name: 'Berittener Bogenschütze',
  hp: 20,
  ep: 6,
  min: 30,
  max: 40,
  accuracy: 90,
  id: UNIT_BERITTENERBOGENSCHÜTZE_BB_ID,
  icon: UNIT_BERITTENERBOGENSCHÜTZE_BB_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'BB',
  rang: 142,
  initiative: InitiativeType.FIRST,
  flank: true
};
const BeritteneAmazone: InternalEnemy = {
  name: 'Berittene Amazone',
  hp: 20,
  ep: 8,
  min: 40,
  max: 60,
  accuracy: 90,
  id: UNIT_BERITTENEAMAZONE_BA_ID,
  icon: UNIT_BERITTENEAMAZONE_BA_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'BA',
  rang: 143,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Kataphrakt: InternalEnemy = {
  name: 'Kataphrakt',
  hp: 20,
  ep: 12,
  min: 90,
  max: 90,
  accuracy: 100,
  id: UNIT_KATAPHRAKT_KP_ID,
  icon: UNIT_KATAPHRAKT_KP_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'KP',
  rang: 144,
  initiative: InitiativeType.FIRST,
  flank: true
};
const BrüllenderStier: InternalEnemy = {
  name: 'Brüllender Stier',
  hp: 2000,
  ep: 50,
  min: 120,
  max: 120,
  accuracy: 100,
  id: UNIT_BRÜLLENDERSTIER_BS_ID,
  icon: UNIT_BRÜLLENDERSTIER_BS_ICON,
  type: BossType.BOSS,
  abr: 'BS',
  rang: 145,
  initiative: InitiativeType.FIRST
};
const Kompositbogenschütze: InternalEnemy = {
  name: 'Kompositbogenschütze',
  hp: 20,
  ep: 2,
  min: 20,
  max: 40,
  accuracy: 80,
  id: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ID,
  icon: UNIT_KOMPOSITBOGENSCHÜTZE_KBS_ICON,
  type: UnitBaseType.OFF,
  abr: 'KBs',
  rang: 146,
  initiative: InitiativeType.LAST
};
const Jomswikinger: InternalEnemy = {
  name: 'Jomswikinger',
  hp: 140,
  ep: 14,
  min: 60,
  max: 80,
  accuracy: 95,
  id: UNIT_JOMSWIKINGER_JW_ID,
  icon: UNIT_JOMSWIKINGER_JW_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'JW',
  rang: 150,
  initiative: InitiativeType.LAST
};
const Huskarl: InternalEnemy = {
  name: 'Huskarl',
  hp: 140,
  ep: 10,
  min: 40,
  max: 50,
  accuracy: 90,
  id: UNIT_HUSKARL_HK_ID,
  icon: UNIT_HUSKARL_HK_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'HK',
  rang: 151,
  initiative: InitiativeType.LAST
};
const Karl: InternalEnemy = {
  name: 'Karl',
  hp: 80,
  ep: 6,
  min: 40,
  max: 50,
  accuracy: 90,
  id: UNIT_KARL_KAR_ID,
  icon: UNIT_KARL_KAR_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'Kar',
  rang: 152,
  initiative: InitiativeType.LAST
};
const Thrall: InternalEnemy = {
  name: 'Thrall',
  hp: 60,
  ep: 3,
  min: 20,
  max: 25,
  accuracy: 85,
  id: UNIT_THRALL_TH_ID,
  icon: UNIT_THRALL_TH_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'TH',
  rang: 153,
  initiative: InitiativeType.LAST
};
const Walküre: InternalEnemy = {
  name: 'Walküre',
  hp: 10,
  ep: 3,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_WALKÜRE_WK_ID,
  icon: UNIT_WALKÜRE_WK_ICON,
  type: UnitBaseType.OFF,
  abr: 'WK',
  rang: 154
};
const Berserker: InternalEnemy = {
  name: 'Berserker',
  hp: 90,
  ep: 20,
  min: 20,
  max: 40,
  accuracy: 70,
  id: UNIT_BERSERKER_BR_ID,
  icon: UNIT_BERSERKER_BR_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'BR',
  rang: 155,
  initiative: InitiativeType.LAST,
  splash: 1
};
const GrauerWolf: InternalEnemy = {
  name: 'Grauer Wolf',
  hp: 10,
  ep: 1,
  min: 2,
  max: 3,
  accuracy: 80,
  id: UNIT_GRAUERWOLF_W_ID,
  icon: UNIT_GRAUERWOLF_W_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'W',
  rang: 160,
  initiative: InitiativeType.FIRST
};
const Stürmer: InternalEnemy = {
  name: 'Stürmer',
  hp: 10,
  ep: 10,
  min: 0,
  max: 10,
  accuracy: 80,
  id: UNIT_STÜRMER_STÜ_ID,
  icon: UNIT_STÜRMER_STÜ_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'STÜ',
  rang: 170,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Abwehrspieler: InternalEnemy = {
  name: 'Abwehrspieler',
  hp: 35,
  ep: 10,
  min: 25,
  max: 35,
  accuracy: 80,
  id: UNIT_ABWEHRSPIELER_ABW_ID,
  icon: UNIT_ABWEHRSPIELER_ABW_ICON,
  type: UnitBaseType.DEF,
  abr: 'ABW',
  rang: 171
};
const Mittelfeldspieler: InternalEnemy = {
  name: 'Mittelfeldspieler',
  hp: 10,
  ep: 5,
  min: 30,
  max: 60,
  accuracy: 80,
  id: UNIT_MITTELFELDSPIELER_MF_ID,
  icon: UNIT_MITTELFELDSPIELER_MF_ICON,
  type: UnitBaseType.OFF,
  abr: 'MF',
  rang: 172
};
const Stammesangehöriger: InternalEnemy = {
  name: 'Stammesangehöriger',
  hp: 40,
  ep: 1,
  min: 15,
  max: 30,
  accuracy: 80,
  id: UNIT_STAMMESANGEHÖRIGER_SA_ID,
  icon: UNIT_STAMMESANGEHÖRIGER_SA_ICON,
  type: UnitBaseType.DEF,
  abr: 'SA',
  rang: 180
};
const Schamane: InternalEnemy = {
  name: 'Schamane',
  hp: 5,
  ep: 1,
  min: 0,
  max: 5,
  accuracy: 60,
  id: UNIT_SCHAMANE_SCH_ID,
  icon: UNIT_SCHAMANE_SCH_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'SCH',
  rang: 181,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Jaguar_Krieger: InternalEnemy = {
  name: 'Jaguar_Krieger',
  hp: 90,
  ep: 2,
  min: 60,
  max: 120,
  accuracy: 90,
  id: UNIT_JAGUAR_KRIEGER_JK_ID,
  icon: UNIT_JAGUAR_KRIEGER_JK_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'JK',
  rang: 182,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Wildschwein: InternalEnemy = {
  name: 'Wildschwein',
  hp: 100,
  ep: 12,
  min: 30,
  max: 60,
  accuracy: 85,
  id: UNIT_WILDSCHWEIN_WS_ID,
  icon: UNIT_WILDSCHWEIN_WS_ICON,
  type: UnitBaseType.DEF,
  abr: 'WS',
  rang: 190
};
const Bär: InternalEnemy = {
  name: 'Bär',
  hp: 140,
  ep: 18,
  min: 70,
  max: 90,
  accuracy: 95,
  id: UNIT_BÄR_BÄR_ID,
  icon: UNIT_BÄR_BÄR_ICON,
  type: UnitBaseType.DEF,
  abr: 'BÄR',
  rang: 191,
  splash: 1
};
const Wolf: InternalEnemy = {
  name: 'Wolf',
  hp: 40,
  ep: 10,
  min: 60,
  max: 100,
  accuracy: 85,
  id: UNIT_WOLF_WO_ID,
  icon: UNIT_WOLF_WO_ICON,
  type: UnitBaseType.OFF,
  abr: 'Wo',
  rang: 192
};
const Alphawolf: InternalEnemy = {
  name: 'Alphawolf',
  hp: 60,
  ep: 15,
  min: 80,
  max: 120,
  accuracy: 95,
  id: UNIT_ALPHAWOLF_AW_ID,
  icon: UNIT_ALPHAWOLF_AW_ICON,
  type: UnitBaseType.OFF,
  abr: 'AW',
  rang: 193,
  splash: 1
};
const Fuchs: InternalEnemy = {
  name: 'Fuchs',
  hp: 30,
  ep: 12,
  min: 10,
  max: 40,
  accuracy: 95,
  id: UNIT_FUCHS_FU_ID,
  icon: UNIT_FUCHS_FU_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'Fu',
  rang: 194,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Riese: InternalEnemy = {
  name: 'Riese',
  hp: 160,
  ep: 26,
  min: 60,
  max: 90,
  accuracy: 95,
  id: UNIT_RIESE_RI_ID,
  icon: UNIT_RIESE_RI_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'RI',
  rang: 195,
  initiative: InitiativeType.LAST,
  splash: 1
};
const KöniglicherRekrut: InternalEnemy = {
  name: 'Königl. Rekrut',
  hp: 120,
  ep: 14,
  min: 30,
  max: 60,
  accuracy: 85,
  id: UNIT_KÖNIGLICHERREKRUT_KR_ID,
  icon: UNIT_KÖNIGLICHERREKRUT_KR_ICON,
  type: UnitBaseType.DEF,
  abr: 'KR',
  rang: 196
};
const KöniglicheMiliz: InternalEnemy = {
  name: 'Königl. Miliz',
  hp: 160,
  ep: 17,
  min: 70,
  max: 90,
  accuracy: 95,
  id: UNIT_KÖNIGLICHEMILIZ_KM_ID,
  icon: UNIT_KÖNIGLICHEMILIZ_KM_ICON,
  type: UnitBaseType.DEF,
  abr: 'KM',
  rang: 197,
  splash: 1
};
const KöniglicherBogenschütze: InternalEnemy = {
  name: 'Königl. Bogenschütze',
  hp: 40,
  ep: 12,
  min: 60,
  max: 120,
  accuracy: 85,
  id: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ID,
  icon: UNIT_KÖNIGLICHERBOGENSCHÜTZE_KB_ICON,
  type: UnitBaseType.OFF,
  abr: 'KB',
  rang: 199
};
const KöniglicherLangbogenschütze: InternalEnemy = {
  name: 'Königl. Langbogenschütze',
  hp: 60,
  ep: 16,
  min: 80,
  max: 140,
  accuracy: 95,
  id: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ID,
  icon: UNIT_KÖNIGLICHERLANGBOGENSCHÜTZE_KLB_ICON,
  type: UnitBaseType.OFF,
  abr: 'KLB',
  rang: 200,
  splash: 1
};
const KöniglicheReiterei: InternalEnemy = {
  name: 'Königl. Reiterei',
  hp: 40,
  ep: 15,
  min: 10,
  max: 60,
  accuracy: 95,
  id: UNIT_KÖNIGLICHEREITEREI_KC_ID,
  icon: UNIT_KÖNIGLICHEREITEREI_KC_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'KC',
  rang: 201,
  initiative: InitiativeType.FIRST,
  flank: true
};
const KöniglicherKanonier: InternalEnemy = {
  name: 'Königl. Kanonier',
  hp: 200,
  ep: 28,
  min: 60,
  max: 90,
  accuracy: 95,
  id: UNIT_KÖNIGLICHERKANONIER_KK_ID,
  icon: UNIT_KÖNIGLICHERKANONIER_KK_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'KK',
  rang: 202,
  initiative: InitiativeType.LAST,
  splash: 1
};
const EinfacherWilderer: InternalEnemy = {
  name: 'Einfacher Wilderer',
  hp: 40,
  ep: 4,
  min: 15,
  max: 30,
  accuracy: 60,
  id: UNIT_EINFACHERWILDERER_EW_ID,
  icon: UNIT_EINFACHERWILDERER_EW_ICON,
  type: UnitBaseType.DEF,
  abr: 'EW',
  rang: 210
};
const Hasenjäger: InternalEnemy = {
  name: 'Hasenjäger',
  hp: 10,
  ep: 3,
  min: 20,
  max: 40,
  accuracy: 60,
  id: UNIT_HASENJÄGER_HJ_ID,
  icon: UNIT_HASENJÄGER_HJ_ICON,
  type: UnitBaseType.OFF,
  abr: 'HJ',
  rang: 211
};
const Jagdhund: InternalEnemy = {
  name: 'Jagdhund',
  hp: 5,
  ep: 4,
  min: 5,
  max: 10,
  accuracy: 60,
  id: UNIT_JAGDHUND_JH_ID,
  icon: UNIT_JAGDHUND_JH_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'JH',
  rang: 212,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Klingenrassler: InternalEnemy = {
  name: 'Klingenrassler',
  hp: 100,
  ep: 27,
  min: 75,
  max: 150,
  accuracy: 80,
  id: UNIT_KLINGENRASSLER_KRA_ID,
  icon: UNIT_KLINGENRASSLER_KRA_ICON,
  type: UnitBaseType.DEF,
  abr: 'KRa',
  rang: 220,
  splash: 1
};
const Säbelschwinger: InternalEnemy = {
  name: 'Säbelschwinger',
  hp: 300,
  ep: 35,
  min: 25,
  max: 50,
  accuracy: 80,
  id: UNIT_SÄBELSCHWINGER_SÄS_ID,
  icon: UNIT_SÄBELSCHWINGER_SÄS_ICON,
  type: UnitBaseType.DEF,
  abr: 'SäS',
  rang: 221,
  splash: 1
};
const Schwertmeister: InternalEnemy = {
  name: 'Schwertmeister',
  hp: 500,
  ep: 55,
  min: 25,
  max: 50,
  accuracy: 80,
  id: UNIT_SCHWERTMEISTER_SM_ID,
  icon: UNIT_SCHWERTMEISTER_SM_ICON,
  type: UnitBaseType.DEF,
  abr: 'SM',
  rang: 222,
  splash: 1
};
const Reiter: InternalEnemy = {
  name: 'Reiter',
  hp: 50,
  ep: 22,
  min: 50,
  max: 100,
  accuracy: 80,
  id: UNIT_REITER_HM_ID,
  icon: UNIT_REITER_HM_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'HM',
  rang: 223,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Dünenschütze: InternalEnemy = {
  name: 'Dünenschütze',
  hp: 30,
  ep: 26,
  min: 100,
  max: 200,
  accuracy: 80,
  id: UNIT_DÜNENSCHÜTZE_DÜS_ID,
  icon: UNIT_DÜNENSCHÜTZE_DÜS_ICON,
  type: UnitBaseType.OFF,
  abr: 'DüS',
  rang: 224,
  splash: 1
};
const Wüstenschütze: InternalEnemy = {
  name: 'Wüstenschütze',
  hp: 80,
  ep: 15,
  min: 50,
  max: 100,
  accuracy: 80,
  id: UNIT_WÜSTENSCHÜTZE_WÜS_ID,
  icon: UNIT_WÜSTENSCHÜTZE_WÜS_ICON,
  type: UnitBaseType.OFF,
  abr: 'WüS',
  rang: 225,
  flank: true,
  splash: 1
};
const BerittenerBogenschützeBBS: InternalEnemy = {
  name: 'Berittener Bogenschütze',
  hp: 70,
  ep: 23,
  min: 35,
  max: 70,
  accuracy: 80,
  id: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ID,
  icon: UNIT_BERITTENERBOGENSCHÜTZE_BBS_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'BBS',
  rang: 226,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Steinkanone: InternalEnemy = {
  name: 'Steinkanone',
  hp: 100,
  ep: 36,
  min: 150,
  max: 300,
  accuracy: 80,
  id: UNIT_STEINKANONE_STK_ID,
  icon: UNIT_STEINKANONE_STK_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'StK',
  rang: 227,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Matrose: InternalEnemy = {
  name: 'Matrose',
  hp: 100,
  ep: 21,
  min: 50,
  max: 100,
  accuracy: 80,
  id: UNIT_MATROSE_MTR_ID,
  icon: UNIT_MATROSE_MTR_ICON,
  type: UnitBaseType.DEF,
  abr: 'Mtr',
  rang: 230,
  splash: 1
};
const KampfgestählterMatrose: InternalEnemy = {
  name: 'Kampfgestählter Matrose',
  hp: 200,
  ep: 26,
  min: 25,
  max: 50,
  accuracy: 80,
  id: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ID,
  icon: UNIT_KAMPFGESTÄHLTERMATROSE_KGM_ICON,
  type: UnitBaseType.DEF,
  abr: 'KgM',
  rang: 231,
  splash: 1
};
const BerittenerSeefahrer: InternalEnemy = {
  name: 'Berittener Seefahrer',
  hp: 50,
  ep: 17,
  min: 35,
  max: 70,
  accuracy: 80,
  id: UNIT_BERITTENERSEEFAHRER_BSF_ID,
  icon: UNIT_BERITTENERSEEFAHRER_BSF_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'BSf',
  rang: 232,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Bootsmann: InternalEnemy = {
  name: 'Bootsmann',
  hp: 40,
  ep: 15,
  min: 25,
  max: 50,
  accuracy: 80,
  id: UNIT_BOOTSMANN_BM_ID,
  icon: UNIT_BOOTSMANN_BM_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'BM',
  rang: 233,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Obermaat: InternalEnemy = {
  name: 'Obermaat',
  hp: 70,
  ep: 17,
  min: 35,
  max: 70,
  accuracy: 80,
  id: UNIT_OBERMAAT_OM_ID,
  icon: UNIT_OBERMAAT_OM_ICON,
  type: UnitBaseType.OFF,
  abr: 'OM',
  rang: 234,
  flank: true,
  splash: 1
};
const Kanoniermeister: InternalEnemy = {
  name: 'Kanoniermeister',
  hp: 100,
  ep: 27,
  min: 100,
  max: 200,
  accuracy: 80,
  id: UNIT_KANONIERMEISTER_KME_ID,
  icon: UNIT_KANONIERMEISTER_KME_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'KMe',
  rang: 235,
  initiative: InitiativeType.LAST,
  splash: 1
};
const StockschwingenderAffe: InternalEnemy = {
  name: 'Stockschwingender Affe',
  hp: 150,
  ep: 37,
  min: 90,
  max: 180,
  accuracy: 95,
  id: UNIT_STOCKSCHWINGENDERAFFE_SSA_ID,
  icon: UNIT_STOCKSCHWINGENDERAFFE_SSA_ICON,
  type: UnitBaseType.DEF,
  abr: 'SsA',
  rang: 240,
  splash: 1
};
const SteinwerfenderAffe: InternalEnemy = {
  name: 'Steinwerfender Affe',
  hp: 70,
  ep: 67,
  min: 125,
  max: 250,
  accuracy: 95,
  id: UNIT_STEINWERFENDERAFFE_SWA_ID,
  icon: UNIT_STEINWERFENDERAFFE_SWA_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'SwA',
  rang: 241,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Alpha_Affe: InternalEnemy = {
  name: 'Alpha_Affe',
  hp: 500,
  ep: 56,
  min: 35,
  max: 70,
  accuracy: 95,
  id: UNIT_ALPHA_AFFE_AA_ID,
  icon: UNIT_ALPHA_AFFE_AA_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'AA',
  rang: 242,
  initiative: InitiativeType.LAST,
  splash: 1
};
const NervöserAffe: InternalEnemy = {
  name: 'Nervöser Affe',
  hp: 100,
  ep: 40,
  min: 80,
  max: 160,
  accuracy: 95,
  id: UNIT_NERVÖSERAFFE_NA_ID,
  icon: UNIT_NERVÖSERAFFE_NA_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'NA',
  rang: 243,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Schlitzohr: InternalEnemy = {
  name: 'Schlitzohr',
  hp: 20,
  ep: 2,
  min: 15,
  max: 30,
  accuracy: 90,
  id: UNIT_SCHLITZOHR_SO_ID,
  icon: UNIT_SCHLITZOHR_SO_ICON,
  type: UnitBaseType.DEF,
  abr: 'So',
  rang: 250
};
const Vandale: InternalEnemy = {
  name: 'Vandale',
  hp: 50,
  ep: 9,
  min: 20,
  max: 40,
  accuracy: 90,
  id: UNIT_VANDALE_VND_ID,
  icon: UNIT_VANDALE_VND_ICON,
  type: UnitBaseType.DEF,
  abr: 'Vnd',
  rang: 251
};
const Rabauke: InternalEnemy = {
  name: 'Rabauke',
  hp: 90,
  ep: 10,
  min: 20,
  max: 40,
  accuracy: 90,
  id: UNIT_RABAUKE_RBK_ID,
  icon: UNIT_RABAUKE_RBK_ICON,
  type: UnitBaseType.DEF,
  abr: 'Rbk',
  rang: 252
};
const Schelm: InternalEnemy = {
  name: 'Schelm',
  hp: 20,
  ep: 9,
  min: 10,
  max: 20,
  accuracy: 80,
  id: UNIT_SCHELM_SM_ID,
  icon: UNIT_SCHELM_SM_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'Sm',
  rang: 253,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Rüpel: InternalEnemy = {
  name: 'Rüpel',
  hp: 10,
  ep: 5,
  min: 25,
  max: 50,
  accuracy: 90,
  id: UNIT_RÜPEL_RÜ_ID,
  icon: UNIT_RÜPEL_RÜ_ICON,
  type: UnitBaseType.OFF,
  abr: 'Rü',
  rang: 254
};
const Schurke: InternalEnemy = {
  name: 'Schurke',
  hp: 10,
  ep: 10,
  min: 40,
  max: 75,
  accuracy: 90,
  id: UNIT_SCHURKE_SU_ID,
  icon: UNIT_SCHURKE_SU_ICON,
  type: UnitBaseType.OFF,
  abr: 'Su',
  rang: 255
};
const Flegel: InternalEnemy = {
  name: 'Flegel',
  hp: 10,
  ep: 22,
  min: 50,
  max: 100,
  accuracy: 90,
  id: UNIT_FLEGEL_FL_ID,
  icon: UNIT_FLEGEL_FL_ICON,
  type: UnitBaseType.OFF,
  abr: 'Fl',
  rang: 256
};
const Nörgler: InternalEnemy = {
  name: 'Nörgler',
  hp: 120,
  ep: 20,
  min: 20,
  max: 40,
  accuracy: 90,
  id: UNIT_NÖRGLER_NÖ_ID,
  icon: UNIT_NÖRGLER_NÖ_ICON,
  type: UnitBaseType.DEF,
  abr: 'Nö',
  rang: 257
};
const Quengler: InternalEnemy = {
  name: 'Quengler',
  hp: 40,
  ep: 30,
  min: 70,
  max: 120,
  accuracy: 90,
  id: UNIT_QUENGLER_QU_ID,
  icon: UNIT_QUENGLER_QU_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'Qu',
  rang: 258,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Lump: InternalEnemy = {
  name: 'Lump',
  hp: 50,
  ep: 80,
  min: 25,
  max: 50,
  accuracy: 90,
  id: UNIT_LUMP_LU_ID,
  icon: UNIT_LUMP_LU_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'Lu',
  rang: 259,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Hirsch: InternalEnemy = {
  name: 'Hirsch',
  hp: 15,
  ep: 2,
  min: 5,
  max: 10,
  accuracy: 80,
  id: UNIT_HIRSCH_HI_ID,
  icon: UNIT_HIRSCH_HI_ICON,
  abr: 'Hi',
  rang: 260
};
const Froststeinbock: InternalEnemy = {
  name: 'Froststeinbock',
  hp: 250,
  ep: 79,
  min: 120,
  max: 150,
  accuracy: 85,
  id: UNIT_FROSTSTEINBOCK_FSB_ID,
  icon: UNIT_FROSTSTEINBOCK_FSB_ICON,
  type: UnitBaseType.DEF,
  abr: 'FSb',
  rang: 270,
  splash: 1
};
const Frostbär: InternalEnemy = {
  name: 'Frostbär',
  hp: 350,
  ep: 110,
  min: 160,
  max: 200,
  accuracy: 90,
  id: UNIT_FROSTBÄR_FBR_ID,
  icon: UNIT_FROSTBÄR_FBR_ICON,
  type: UnitBaseType.DEF,
  abr: 'FBr',
  rang: 271,
  splash: 1
};
const Frostfuchs: InternalEnemy = {
  name: 'Frostfuchs',
  hp: 75,
  ep: 53,
  min: 80,
  max: 100,
  accuracy: 90,
  id: UNIT_FROSTFUCHS_FFU_ID,
  icon: UNIT_FROSTFUCHS_FFU_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'FFu',
  rang: 272,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Frostadler: InternalEnemy = {
  name: 'Frostadler',
  hp: 100,
  ep: 77,
  min: 90,
  max: 150,
  accuracy: 95,
  id: UNIT_FROSTADLER_FAD_ID,
  icon: UNIT_FROSTADLER_FAD_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'FAd',
  rang: 273,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Frostwolf: InternalEnemy = {
  name: 'Frostwolf',
  hp: 100,
  ep: 68,
  min: 200,
  max: 250,
  accuracy: 85,
  id: UNIT_FROSTWOLF_FWO_ID,
  icon: UNIT_FROSTWOLF_FWO_ICON,
  type: UnitBaseType.OFF,
  abr: 'FWo',
  rang: 274,
  splash: 1
};
const Frostleopard: InternalEnemy = {
  name: 'Frostleopard',
  hp: 150,
  ep: 89,
  min: 240,
  max: 300,
  accuracy: 90,
  id: UNIT_FROSTLEOPARD_FLE_ID,
  icon: UNIT_FROSTLEOPARD_FLE_ICON,
  type: UnitBaseType.OFF,
  abr: 'FLe',
  rang: 275,
  splash: 1
};
const Frostriese: InternalEnemy = {
  name: 'Frostriese',
  hp: 500,
  ep: 170,
  min: 280,
  max: 350,
  accuracy: 90,
  id: UNIT_FROSTRIESE_FRI_ID,
  icon: UNIT_FROSTRIESE_FRI_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'FRi',
  rang: 276,
  initiative: InitiativeType.LAST,
  splash: 1
};
const RekrutF: InternalEnemy = {
  name: 'Rekrut F',
  hp: 40,
  ep: 2,
  min: 15,
  max: 30,
  accuracy: 80,
  id: UNIT_REKRUTF_ER_ID,
  icon: UNIT_REKRUTF_ER_ICON,
  type: UnitBaseType.DEF,
  abr: 'eR',
  rang: 280
};
const MilizF: InternalEnemy = {
  name: 'Miliz F',
  hp: 60,
  ep: 9,
  min: 20,
  max: 40,
  accuracy: 80,
  id: UNIT_MILIZF_EM_ID,
  icon: UNIT_MILIZF_EM_ICON,
  type: UnitBaseType.DEF,
  abr: 'eM',
  rang: 281
};
const SoldatF: InternalEnemy = {
  name: 'Soldat F',
  hp: 90,
  ep: 6,
  min: 20,
  max: 40,
  accuracy: 85,
  id: UNIT_SOLDATF_ES_ID,
  icon: UNIT_SOLDATF_ES_ICON,
  type: UnitBaseType.DEF,
  abr: 'eS',
  rang: 282
};
const ElitesoldatF: InternalEnemy = {
  name: 'Elitesoldat F',
  hp: 120,
  ep: 20,
  min: 20,
  max: 40,
  accuracy: 90,
  id: UNIT_ELITESOLDATF_EE_ID,
  icon: UNIT_ELITESOLDATF_EE_ICON,
  type: UnitBaseType.DEF,
  abr: 'eE',
  rang: 283
};
const ReitereiF: InternalEnemy = {
  name: 'Reiterei F',
  hp: 5,
  ep: 8,
  min: 5,
  max: 10,
  accuracy: 80,
  id: UNIT_REITEREIF_EC_ID,
  icon: UNIT_REITEREIF_EC_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'eC',
  rang: 284,
  initiative: InitiativeType.FIRST,
  flank: true
};
const BogenschützeF: InternalEnemy = {
  name: 'Bogenschütze F',
  hp: 10,
  ep: 3,
  min: 20,
  max: 40,
  accuracy: 80,
  id: UNIT_BOGENSCHÜTZEF_EB_ID,
  icon: UNIT_BOGENSCHÜTZEF_EB_ICON,
  type: UnitBaseType.OFF,
  abr: 'eB',
  rang: 285
};
const LangbogenschützeF: InternalEnemy = {
  name: 'Langbogenschütze F',
  hp: 10,
  ep: 20,
  min: 30,
  max: 60,
  accuracy: 80,
  id: UNIT_LANGBOGENSCHÜTZEF_ELB_ID,
  icon: UNIT_LANGBOGENSCHÜTZEF_ELB_ICON,
  type: UnitBaseType.OFF,
  abr: 'eLB',
  rang: 286
};
const ArmbrustschützeF: InternalEnemy = {
  name: 'Armbrustschütze F',
  hp: 10,
  ep: 20,
  min: 45,
  max: 90,
  accuracy: 80,
  id: UNIT_ARMBRUSTSCHÜTZEF_EA_ID,
  icon: UNIT_ARMBRUSTSCHÜTZEF_EA_ICON,
  type: UnitBaseType.OFF,
  abr: 'eA',
  rang: 287
};
const KanonierF: InternalEnemy = {
  name: 'Kanonier F',
  hp: 60,
  ep: 30,
  min: 60,
  max: 120,
  accuracy: 90,
  id: UNIT_KANONIERF_EK_ID,
  icon: UNIT_KANONIERF_EK_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'eK',
  rang: 288,
  initiative: InitiativeType.LAST
};
const Miniatur_Lehmgolem: InternalEnemy = {
  name: 'Miniatur_Lehmgolem',
  hp: 110,
  ep: 7,
  min: 20,
  max: 40,
  accuracy: 50,
  id: UNIT_MINIATUR_LEHMGOLEM_MCG_ID,
  icon: UNIT_MINIATUR_LEHMGOLEM_MCG_ICON,
  type: UnitBaseType.DEF,
  abr: 'MCG',
  rang: 289
};
const Miniatur_Felsgolem: InternalEnemy = {
  name: 'Miniatur_Felsgolem',
  hp: 150,
  ep: 10,
  min: 30,
  max: 60,
  accuracy: 50,
  id: UNIT_MINIATUR_FELSGOLEM_MRG_ID,
  icon: UNIT_MINIATUR_FELSGOLEM_MRG_ICON,
  type: UnitBaseType.DEF,
  abr: 'MRG',
  rang: 290
};
const Miniatur_Eisgolem: InternalEnemy = {
  name: 'Miniatur_Eisgolem',
  hp: 50,
  ep: 4,
  min: 15,
  max: 30,
  accuracy: 50,
  id: UNIT_MINIATUR_EISGOLEM_MIG_ID,
  icon: UNIT_MINIATUR_EISGOLEM_MIG_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'MIG',
  rang: 291,
  initiative: InitiativeType.FIRST,
  flank: true
};
const Miniatur_Lavagolem: InternalEnemy = {
  name: 'Miniatur_Lavagolem',
  hp: 120,
  ep: 11,
  min: 50,
  max: 100,
  accuracy: 60,
  id: UNIT_MINIATUR_LAVAGOLEM_MLG_ID,
  icon: UNIT_MINIATUR_LAVAGOLEM_MLG_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'MLG',
  rang: 292,
  initiative: InitiativeType.LAST,
  splash: 1
};
const BonabertiPeter: InternalEnemy = {
  name: 'Blonder Bogenschütze',
  hp: 70,
  ep: 25,
  min: 100,
  max: 150,
  accuracy: 80,
  id: UNIT_PETER_SPE_ID,
  icon: UNIT_PETER_SPE_ICON,
  type: UnitBaseType.OFF,
  abr: 'SPe',
  rang: 300,
  initiative: InitiativeType.NORMAL
};
const BonabertiBlueMan: InternalEnemy = {
  name: 'Verteidigungsbergarbeiter',
  hp: 150,
  ep: 20,
  min: 75,
  max: 100,
  accuracy: 80,
  id: UNIT_BLUE_MAN_BMA_ID,
  icon: UNIT_BLUE_MAN_BMA_ICON,
  type: UnitBaseType.DEF,
  abr: 'BMa',
  rang: 301,
  initiative: InitiativeType.NORMAL,
  splash: 1
};
const BonabertiBlackBeard: InternalEnemy = {
  name: 'Goldene Wache',
  hp: 280,
  ep: 55,
  min: 150,
  max: 220,
  accuracy: 85,
  id: UNIT_BLACK_BEARD_BBE_ID,
  icon: UNIT_BLACK_BEARD_BBE_ICON,
  type: UnitBaseType.HEAVY,
  abr: 'BBe',
  rang: 302,
  initiative: InitiativeType.LAST,
  splash: 1
};
const BonabertiGreenMan: InternalEnemy = {
  name: 'Rücksichtsloser Reiter',
  hp: 60,
  ep: 45,
  min: 130,
  max: 200,
  accuracy: 80,
  id: UNIT_GREEN_MAN_GMA_ID,
  icon: UNIT_GREEN_MAN_GMA_ICON,
  type: UnitBaseType.SWIFT,
  abr: 'GMa',
  rang: 303,
  initiative: InitiativeType.FIRST
};
const BonabertiGreenHood: InternalEnemy = {
  name: 'Standhafter Verteidiger',
  hp: 200,
  ep: 30,
  min: 90,
  max: 130,
  accuracy: 80,
  id: UNIT_GREEN_HOOD_GHO_ID,
  icon: UNIT_GREEN_HOOD_GHO_ICON,
  type: UnitBaseType.DEF,
  abr: 'GHo',
  rang: 304,
  initiative: InitiativeType.NORMAL,
  splash: 1
};
const BonabertiFrenchMan: InternalEnemy = {
  name: 'Raffinierter Räuberboss',
  hp: 6000,
  ep: 700,
  min: 2200,
  max: 3000,
  accuracy: 40,
  id: UNIT_FRENCH_MAN_ID,
  icon: UNIT_FRENCH_MAN_ICON,
  type: BossType.BOSS,
  abr: 'FMa',
  rang: 305,
  initiative: InitiativeType.FIRST,
  splash: 1,
  flank: true
};
const Stinktier: InternalEnemy = {
  name: 'Stinktier',
  hp: 5000,
  ep: 200,
  min: 1,
  max: 100,
  accuracy: 50,
  id: UNIT_STINKTIER_ST_ID,
  icon: UNIT_STINKTIER_ST_ICON,
  type: BossType.BOSS,
  abr: 'ST',
  rang: 500,
  initiative: InitiativeType.LAST,
  splash: 1
};
const EinäugigerBert: InternalEnemy = {
  name: 'Einäugiger Bert',
  hp: 6000,
  ep: 100,
  min: 300,
  max: 500,
  accuracy: 50,
  id: UNIT_EINÄUGIGERBERT_EB_ID,
  icon: UNIT_EINÄUGIGERBERT_EB_ICON,
  type: BossType.BOSS,
  abr: 'EB',
  rang: 501,
  initiative: InitiativeType.LAST,
  splash: 1
};
const SirRobin: InternalEnemy = {
  name: 'Sir Robin',
  hp: 12000,
  ep: 200,
  min: 200,
  max: 600,
  accuracy: 80,
  id: UNIT_SIRROBIN_ROB_ID,
  icon: UNIT_SIRROBIN_ROB_ICON,
  type: BossType.BOSS,
  abr: 'Rob',
  rang: 515,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const DickeBertha: InternalEnemy = {
  name: 'Dicke Bertha',
  hp: 40000,
  ep: 350,
  min: 50,
  max: 150,
  accuracy: 75,
  id: UNIT_DICKEBERTHA_DB_ID,
  icon: UNIT_DICKEBERTHA_DB_ICON,
  type: BossType.BOSS,
  abr: 'DB',
  rang: 516,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const DunklerHohepriester: InternalEnemy = {
  name: 'Dunkler Hohepriester',
  hp: 15000,
  ep: 350,
  min: 800,
  max: 1000,
  accuracy: 75,
  id: UNIT_DUNKLERHOHEPRIESTER_DHP_ID,
  icon: UNIT_DUNKLERHOHEPRIESTER_DHP_ICON,
  type: BossType.BOSS,
  abr: 'DHP',
  rang: 520,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Flammenprediger: InternalEnemy = {
  name: 'Flammenprediger',
  hp: 11000,
  ep: 250,
  min: 500,
  max: 700,
  accuracy: 75,
  id: UNIT_FLAMMENPREDIGER_POF_ID,
  icon: UNIT_FLAMMENPREDIGER_POF_ICON,
  type: BossType.BOSS,
  abr: 'PoF',
  rang: 521,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const RieseBogor: InternalEnemy = {
  name: 'Riese Bogor',
  hp: 90000,
  ep: 400,
  min: 100,
  max: 300,
  accuracy: 60,
  id: UNIT_RIESEBOGOR_RBO_ID,
  icon: UNIT_RIESEBOGOR_RBO_ICON,
  type: BossType.BOSS,
  abr: 'RBo',
  rang: 530,
  initiative: InitiativeType.LAST,
  splash: 1
};
const RieseGogor: InternalEnemy = {
  name: 'Riese Gogor',
  hp: 70000,
  ep: 350,
  min: 100,
  max: 250,
  accuracy: 80,
  id: UNIT_RIESEGOGOR_RGO_ID,
  icon: UNIT_RIESEGOGOR_RGO_ICON,
  type: BossType.BOSS,
  abr: 'RGo',
  rang: 531,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Einhorn: InternalEnemy = {
  name: 'Einhorn',
  hp: 30000,
  ep: 300,
  min: 250,
  max: 400,
  accuracy: 90,
  id: UNIT_EINHORN_EH_ID,
  icon: UNIT_EINHORN_EH_ICON,
  type: BossType.BOSS,
  abr: 'EH',
  rang: 532,
  splash: 1
};
const RasendesWildschwein: InternalEnemy = {
  name: 'Rasendes Wildschwein',
  hp: 50000,
  ep: 400,
  min: 200,
  max: 300,
  accuracy: 90,
  id: UNIT_RASENDESWILDSCHWEIN_RWS_ID,
  icon: UNIT_RASENDESWILDSCHWEIN_RWS_ICON,
  type: BossType.BOSS,
  abr: 'RWS',
  rang: 533,
  splash: 1
};
const BöserKönig: InternalEnemy = {
  name: 'Böser König',
  hp: 30000,
  ep: 250,
  min: 200,
  max: 300,
  accuracy: 80,
  id: UNIT_BÖSERKÖNIG_BK_ID,
  icon: UNIT_BÖSERKÖNIG_BK_ICON,
  type: BossType.BOSS,
  abr: 'BK',
  rang: 534,
  splash: 1
};
const Eisenfaust: InternalEnemy = {
  name: 'Eisenfaust',
  hp: 45000,
  ep: 300,
  min: 200,
  max: 250,
  accuracy: 85,
  id: UNIT_EISENFAUST_EF_ID,
  icon: UNIT_EISENFAUST_EF_ICON,
  type: BossType.BOSS,
  abr: 'EF',
  rang: 535,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const RiesigerBär: InternalEnemy = {
  name: 'Riesiger Bär',
  hp: 55000,
  ep: 600,
  min: 400,
  max: 750,
  accuracy: 60,
  id: UNIT_RIESIGERBÄR_RBÄ_ID,
  icon: UNIT_RIESIGERBÄR_RBÄ_ICON,
  type: BossType.BOSS,
  abr: 'RBä',
  rang: 536,
  initiative: InitiativeType.LAST,
  splash: 1
};
const RivalisierenderSchneider: InternalEnemy = {
  name: 'Rivalisierender Schneider',
  hp: 40000,
  ep: 350,
  min: 150,
  max: 250,
  accuracy: 75,
  id: UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ID,
  icon: UNIT_RIVALISIERENDERSCHNEIDER_SCHN_ICON,
  type: BossType.BOSS,
  abr: 'SCHN',
  rang: 537,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const SchwarzerStier: InternalEnemy = {
  name: 'Schwarzer Stier',
  hp: 60000,
  ep: 500,
  min: 250,
  max: 300,
  accuracy: 90,
  id: UNIT_SCHWARZERSTIER_SST_ID,
  icon: UNIT_SCHWARZERSTIER_SST_ICON,
  type: BossType.BOSS,
  abr: 'SSt',
  rang: 538,
  splash: 1
};
const DunklerZauberer: InternalEnemy = {
  name: 'Dunkler Zauberer',
  hp: 30000,
  ep: 1000,
  min: 2000,
  max: 2500,
  accuracy: 80,
  id: UNIT_DUNKLERZAUBERER_DZ_ID,
  icon: UNIT_DUNKLERZAUBERER_DZ_ICON,
  type: BossType.BOSS,
  abr: 'DZ',
  rang: 539,
  splash: 1
};
const VerlogeneZiege: InternalEnemy = {
  name: 'Verlogene Ziege',
  hp: 25000,
  ep: 100,
  min: 100,
  max: 150,
  accuracy: 85,
  id: UNIT_VERLOGENEZIEGE_VZ_ID,
  icon: UNIT_VERLOGENEZIEGE_VZ_ICON,
  type: BossType.BOSS,
  abr: 'VZ',
  rang: 540,
  flank: true,
  splash: 1
};
const Knüppel_Klaus: InternalEnemy = {
  name: 'Knüppel Klaus',
  hp: 40000,
  ep: 250,
  min: 200,
  max: 300,
  accuracy: 80,
  id: UNIT_KNÜPPEL_KLAUS_KKL_ID,
  icon: UNIT_KNÜPPEL_KLAUS_KKL_ICON,
  type: BossType.BOSS,
  abr: 'KKL',
  rang: 541,
  splash: 1
};
const Assassine: InternalEnemy = {
  name: 'Assassine',
  hp: 30000,
  ep: 350,
  min: 200,
  max: 300,
  accuracy: 80,
  id: UNIT_ASSASSINE_AS_ID,
  icon: UNIT_ASSASSINE_AS_ICON,
  type: BossType.BOSS,
  abr: 'AS',
  rang: 542,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const GierigerGastwirt: InternalEnemy = {
  name: 'Gieriger Gastwirt',
  hp: 50000,
  ep: 950,
  min: 1500,
  max: 2000,
  accuracy: 80,
  id: UNIT_GIERIGERGASTWIRT_GG_ID,
  icon: UNIT_GIERIGERGASTWIRT_GG_ICON,
  type: BossType.BOSS,
  abr: 'GG',
  rang: 543,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const Rattenfänger: InternalEnemy = {
  name: 'Rattenfänger',
  hp: 55000,
  ep: 1900,
  min: 200,
  max: 450,
  accuracy: 90,
  id: UNIT_RATTENFÄNGER_PIP_ID,
  icon: UNIT_RATTENFÄNGER_PIP_ICON,
  type: BossType.BOSS,
  abr: 'Pip',
  rang: 550,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const DerBürgermeister: InternalEnemy = {
  name: 'Der Bürgermeister',
  hp: 95000,
  ep: 3200,
  min: 400,
  max: 600,
  accuracy: 80,
  id: UNIT_DERBÜRGERMEISTER_MAY_ID,
  icon: UNIT_DERBÜRGERMEISTER_MAY_ICON,
  type: BossType.BOSS,
  abr: 'May',
  rang: 551,
  splash: 1
};
const Rattenkönig: InternalEnemy = {
  name: 'Rattenkönig',
  hp: 40000,
  ep: 1300,
  min: 200,
  max: 800,
  accuracy: 60,
  id: UNIT_RATTENKÖNIG_KOR_ID,
  icon: UNIT_RATTENKÖNIG_KOR_ICON,
  type: BossType.BOSS,
  abr: 'KoR',
  rang: 552,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Ilsebille: InternalEnemy = {
  name: 'Ilsebille',
  hp: 95000,
  ep: 3200,
  min: 400,
  max: 600,
  accuracy: 80,
  id: UNIT_ILSEBILLE_ILES_ID,
  icon: UNIT_ILSEBILLE_ILES_ICON,
  type: BossType.BOSS,
  abr: 'Iles',
  rang: 553,
  splash: 1
};
const königlicherJagdführer: InternalEnemy = {
  name: 'königlicher Jagdführer',
  hp: 45000,
  ep: 1500,
  min: 200,
  max: 700,
  accuracy: 90,
  id: UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ID,
  icon: UNIT_KÖNIGLICHERJAGDFÜHRER_KJA_ICON,
  type: BossType.BOSS,
  abr: 'KJA',
  rang: 554,
  initiative: InitiativeType.LAST,
  splash: 1
};
const BöseStiefmutter: InternalEnemy = {
  name: 'Böse Stiefmutter',
  hp: 95000,
  ep: 3200,
  min: 400,
  max: 600,
  accuracy: 80,
  id: UNIT_BÖSESTIEFMUTTER_BSM_ID,
  icon: UNIT_BÖSESTIEFMUTTER_BSM_ICON,
  type: BossType.BOSS,
  abr: 'bSM',
  rang: 555,
  splash: 1
};
const königlicherKapitän: InternalEnemy = {
  name: 'königlicher Kapitän',
  hp: 45000,
  ep: 1500,
  min: 300,
  max: 500,
  accuracy: 80,
  id: UNIT_KÖNIGLICHERKAPITÄN_RC_ID,
  icon: UNIT_KÖNIGLICHERKAPITÄN_RC_ICON,
  type: BossType.BOSS,
  abr: 'RC',
  rang: 556,
  splash: 1
};
const königlicherMoloch: InternalEnemy = {
  name: 'königlicher Moloch',
  hp: 70000,
  ep: 2300,
  min: 200,
  max: 1000,
  accuracy: 35,
  id: UNIT_KÖNIGLICHERMOLOCH_RJ_ID,
  icon: UNIT_KÖNIGLICHERMOLOCH_RJ_ICON,
  type: BossType.BOSS,
  abr: 'RJ',
  rang: 557,
  initiative: InitiativeType.LAST,
  splash: 1
};
const GewitzterRäuber: InternalEnemy = {
  name: 'Gewitzter Räuber',
  hp: 1000,
  ep: 52,
  min: 250,
  max: 500,
  accuracy: 80,
  id: UNIT_GEWITZTERRÄUBER_WITZR_ID,
  icon: UNIT_GEWITZTERRÄUBER_WITZR_ICON,
  type: BossType.BOSS,
  abr: 'WitzR',
  rang: 570,
  splash: 1
};
const KlugerRäuber: InternalEnemy = {
  name: 'Kluger Räuber',
  hp: 5000,
  ep: 200,
  min: 500,
  max: 1000,
  accuracy: 80,
  id: UNIT_KLUGERRÄUBER_KLUR_ID,
  icon: UNIT_KLUGERRÄUBER_KLUR_ICON,
  type: BossType.BOSS,
  abr: 'KluR',
  rang: 571,
  splash: 1
};
const MysteriöserRäuber: InternalEnemy = {
  name: 'Mysteriöser Räuber',
  hp: 20000,
  ep: 740,
  min: 1000,
  max: 2000,
  accuracy: 80,
  id: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ID,
  icon: UNIT_MYSTERIÖSERRÄUBER_MYSTR_ICON,
  type: BossType.BOSS,
  abr: 'MystR',
  rang: 572,
  splash: 1
};
const HeimtückischerRäuber: InternalEnemy = {
  name: 'Heimtückischer Räuber',
  hp: 50000,
  ep: 1700,
  min: 250,
  max: 500,
  accuracy: 80,
  id: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ID,
  icon: UNIT_HEIMTÜCKISCHERRÄUBER_HTR_ICON,
  type: BossType.BOSS,
  abr: 'HtR',
  rang: 573,
  splash: 1
};
const HochnäsigerRäuber: InternalEnemy = {
  name: 'Hochnäsiger Räuber',
  hp: 150000,
  ep: 5000,
  min: 500,
  max: 1000,
  accuracy: 80,
  id: UNIT_HOCHNÄSIGERRÄUBER_HNR_ID,
  icon: UNIT_HOCHNÄSIGERRÄUBER_HNR_ICON,
  type: BossType.BOSS,
  abr: 'HnR',
  rang: 574,
  splash: 1
};
const ErgrauterRäuber: InternalEnemy = {
  name: 'Ergrauter Räuber',
  hp: 500,
  ep: 54,
  min: 375,
  max: 750,
  accuracy: 80,
  id: UNIT_ERGRAUTERRÄUBER_GRAUR_ID,
  icon: UNIT_ERGRAUTERRÄUBER_GRAUR_ICON,
  type: BossType.BOSS,
  abr: 'GrauR',
  rang: 575,
  flank: true,
  splash: 1
};
const HinterlistigerRäuber: InternalEnemy = {
  name: 'Hinterlistiger Räuber',
  hp: 1000,
  ep: 62,
  min: 250,
  max: 500,
  accuracy: 80,
  id: UNIT_HINTERLISTIGERRÄUBER_HR_ID,
  icon: UNIT_HINTERLISTIGERRÄUBER_HR_ICON,
  type: BossType.BOSS,
  abr: 'HR',
  rang: 576,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const VernarbterRäuber: InternalEnemy = {
  name: 'Vernarbter Räuber',
  hp: 500,
  ep: 39,
  min: 150,
  max: 300,
  accuracy: 80,
  id: UNIT_VERNARBTERRÄUBER_NAR_ID,
  icon: UNIT_VERNARBTERRÄUBER_NAR_ICON,
  type: BossType.BOSS,
  abr: 'NaR',
  rang: 577,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const GierigerRäuber: InternalEnemy = {
  name: 'Gieriger Räuber',
  hp: 10000,
  ep: 470,
  min: 2500,
  max: 5000,
  accuracy: 80,
  id: UNIT_GIERIGERRÄUBER_GIER_ID,
  icon: UNIT_GIERIGERRÄUBER_GIER_ICON,
  type: BossType.BOSS,
  abr: 'GieR',
  rang: 578,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const EinfältigerRäuber: InternalEnemy = {
  name: 'Einfältiger Räuber',
  hp: 50000,
  ep: 1800,
  min: 2000,
  max: 4000,
  accuracy: 80,
  id: UNIT_EINFÄLTIGERRÄUBER_EINR_ID,
  icon: UNIT_EINFÄLTIGERRÄUBER_EINR_ICON,
  type: BossType.BOSS,
  abr: 'EinR',
  rang: 579,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Steuermann: InternalEnemy = {
  name: 'Steuermann',
  hp: 250000,
  ep: 8300,
  min: 250,
  max: 500,
  accuracy: 80,
  id: UNIT_STEUERMANN_STM_ID,
  icon: UNIT_STEUERMANN_STM_ICON,
  type: BossType.BOSS,
  abr: 'StM',
  rang: 590,
  splash: 1
};
const Meuterer: InternalEnemy = {
  name: 'Meuterer',
  hp: 5000,
  ep: 450,
  min: 2500,
  max: 5000,
  accuracy: 80,
  id: UNIT_MEUTERER_MEU_ID,
  icon: UNIT_MEUTERER_MEU_ICON,
  type: BossType.BOSS,
  abr: 'Meu',
  rang: 591,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Kapitän: InternalEnemy = {
  name: 'Kapitän',
  hp: 10000,
  ep: 560,
  min: 4000,
  max: 8000,
  accuracy: 80,
  id: UNIT_KAPITÄN_CAPT_ID,
  icon: UNIT_KAPITÄN_CAPT_ICON,
  type: BossType.BOSS,
  abr: 'Capt',
  rang: 592,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Räuberlord: InternalEnemy = {
  name: 'Räuberlord',
  hp: 30000,
  ep: 1400,
  min: 3500,
  max: 7000,
  accuracy: 90,
  id: UNIT_RÄUBERLORD_RL_ID,
  icon: UNIT_RÄUBERLORD_RL_ICON,
  type: BossType.BOSS,
  abr: 'RL',
  rang: 600,
  flank: true,
  splash: 1
};
const MürrischerPapagei: InternalEnemy = {
  name: 'Mürrischer Papagei',
  hp: 25000,
  ep: 1100,
  min: 2000,
  max: 4000,
  accuracy: 95,
  id: UNIT_MÜRRISCHERPAPAGEI_MP_ID,
  icon: UNIT_MÜRRISCHERPAPAGEI_MP_ICON,
  type: BossType.BOSS,
  abr: 'MP',
  rang: 601,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Oasen_Hüter: InternalEnemy = {
  name: 'Oasen_Hüter',
  hp: 40000,
  ep: 1700,
  min: 5000,
  max: 10000,
  accuracy: 90,
  id: UNIT_OASEN_HÜTER_OH_ID,
  icon: UNIT_OASEN_HÜTER_OH_ICON,
  type: BossType.BOSS,
  abr: 'OH',
  rang: 602,
  splash: 1
};
const Felsenwurm: InternalEnemy = {
  name: 'Felsenwurm',
  hp: 200000,
  ep: 6600,
  min: 150,
  max: 300,
  accuracy: 80,
  id: UNIT_FELSENWURM_FW_ID,
  icon: UNIT_FELSENWURM_FW_ICON,
  type: BossType.BOSS,
  abr: 'FW',
  rang: 603,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const Riesenfledermaus: InternalEnemy = {
  name: 'Riesenfledermaus',
  hp: 150000,
  ep: 5000,
  min: 450,
  max: 900,
  accuracy: 90,
  id: UNIT_RIESENFLEDERMAUS_RFM_ID,
  icon: UNIT_RIESENFLEDERMAUS_RFM_ICON,
  type: BossType.BOSS,
  abr: 'RFM',
  rang: 604,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const HungrigeBlüte: InternalEnemy = {
  name: 'Hungrige Blüte',
  hp: 10000,
  ep: 680,
  min: 2250,
  max: 4500,
  accuracy: 90,
  id: UNIT_HUNGRIGEBLÜTE_HUB_ID,
  icon: UNIT_HUNGRIGEBLÜTE_HUB_ICON,
  type: BossType.BOSS,
  abr: 'HuB',
  rang: 605,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const GrimmigeBlüte: InternalEnemy = {
  name: 'Grimmige Blüte',
  hp: 15000,
  ep: 930,
  min: 2750,
  max: 5500,
  accuracy: 90,
  id: UNIT_GRIMMIGEBLÜTE_GRB_ID,
  icon: UNIT_GRIMMIGEBLÜTE_GRB_ICON,
  type: BossType.BOSS,
  abr: 'GrB',
  rang: 606,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const KnolligeBlüte: InternalEnemy = {
  name: 'Knollige Blüte',
  hp: 20000,
  ep: 920,
  min: 3250,
  max: 6500,
  accuracy: 90,
  id: UNIT_KNOLLIGEBLÜTE_KNB_ID,
  icon: UNIT_KNOLLIGEBLÜTE_KNB_ICON,
  type: BossType.BOSS,
  abr: 'KnB',
  rang: 607,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const Bücherei_Golem: InternalEnemy = {
  name: 'Bücherei_Golem',
  hp: 70000,
  ep: 2500,
  min: 1750,
  max: 3500,
  accuracy: 90,
  id: UNIT_BÜCHEREI_GOLEM_BG_ID,
  icon: UNIT_BÜCHEREI_GOLEM_BG_ICON,
  type: BossType.BOSS,
  abr: 'BG',
  rang: 608,
  flank: true,
  splash: 1
};
const GroßeSteinstatue: InternalEnemy = {
  name: 'Große Steinstatue',
  hp: 400000,
  ep: 13000,
  min: 450,
  max: 1000,
  accuracy: 90,
  id: UNIT_GROSSESTEINSTATUE_GSTS_ID,
  icon: UNIT_GROSSESTEINSTATUE_GSTS_ICON,
  type: BossType.BOSS,
  abr: 'GStS',
  rang: 609,
  initiative: InitiativeType.LAST,
  splash: 1
};
const MoosbedeckteSteinstatue: InternalEnemy = {
  name: 'Moosbedeckte Steinstatue',
  hp: 500000,
  ep: 17000,
  min: 400,
  max: 800,
  accuracy: 90,
  id: UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ID,
  icon: UNIT_MOOSBEDECKTESTEINSTATUE_MSTS_ICON,
  type: BossType.BOSS,
  abr: 'MStS',
  rang: 610,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Riesenkanone: InternalEnemy = {
  name: 'Riesenkanone',
  hp: 35000,
  ep: 1600,
  min: 7500,
  max: 15000,
  accuracy: 85,
  id: UNIT_RIESENKANONE_RKA_ID,
  icon: UNIT_RIESENKANONE_RKA_ICON,
  type: BossType.BOSS,
  abr: 'RKa',
  rang: 611,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Informant: InternalEnemy = {
  name: 'Informant',
  hp: 50000,
  ep: 2000,
  min: 4500,
  max: 9000,
  accuracy: 90,
  id: UNIT_INFORMANT_INF_ID,
  icon: UNIT_INFORMANT_INF_ICON,
  type: BossType.BOSS,
  abr: 'Inf',
  rang: 613,
  splash: 1
};
const Riesenseeschlange: InternalEnemy = {
  name: 'Riesenseeschlange',
  hp: 700000,
  ep: 23000,
  min: 300,
  max: 600,
  accuracy: 80,
  id: UNIT_RIESENSEESCHLANGE_RSS_ID,
  icon: UNIT_RIESENSEESCHLANGE_RSS_ICON,
  type: BossType.BOSS,
  abr: 'RSS',
  rang: 614,
  initiative: InitiativeType.LAST,
  splash: 1
};
const MürrischeWache: InternalEnemy = {
  name: 'Mürrische Wache',
  hp: 25000,
  ep: 910,
  min: 500,
  max: 1000,
  accuracy: 90,
  id: UNIT_MÜRRISCHEWACHE_MÜW_ID,
  icon: UNIT_MÜRRISCHEWACHE_MÜW_ICON,
  type: BossType.BOSS,
  abr: 'MüW',
  rang: 615,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const WahnsinnigeWache: InternalEnemy = {
  name: 'Wahnsinnige Wache',
  hp: 50000,
  ep: 1700,
  min: 250,
  max: 500,
  accuracy: 90,
  id: UNIT_WAHNSINNIGEWACHE_WSW_ID,
  icon: UNIT_WAHNSINNIGEWACHE_WSW_ICON,
  type: BossType.BOSS,
  abr: 'WsW',
  rang: 616,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const ElChupa: InternalEnemy = {
  name: 'El Chupa',
  hp: 50000,
  ep: 700,
  min: 350,
  max: 500,
  accuracy: 90,
  id: UNIT_ELCHUPA_CHUP_ID,
  icon: UNIT_ELCHUPA_CHUP_ICON,
  type: BossType.BOSS,
  abr: 'Chup',
  rang: 630,
  initiative: InitiativeType.LAST,
  flank: true
};
const AncientDragon: InternalEnemy = {
  name: 'Ancient Dragon',
  hp: 50000,
  ep: 400,
  min: 500,
  max: 750,
  accuracy: 50,
  id: UNIT_ANCIENTDRAGON_AD_ID,
  icon: UNIT_ANCIENTDRAGON_AD_ICON,
  type: BossType.BOSS,
  abr: 'AD',
  rang: 631,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const DenisderDelinquent: InternalEnemy = {
  name: 'Denis der Delinquent',
  hp: 5000,
  ep: 1500,
  min: 100,
  max: 200,
  accuracy: 80,
  id: UNIT_DENISDERDELINQUENT_DEN_ID,
  icon: UNIT_DENISDERDELINQUENT_DEN_ICON,
  type: BossType.BOSS,
  abr: 'Den',
  rang: 640,
  initiative: InitiativeType.FIRST,
  flank: true
};
const AlexderQuerulant: InternalEnemy = {
  name: 'Alex der Querulant',
  hp: 17000,
  ep: 7500,
  min: 700,
  max: 1500,
  accuracy: 90,
  id: UNIT_ALEXDERQUERULANT_ALX_ID,
  icon: UNIT_ALEXDERQUERULANT_ALX_ICON,
  type: BossType.BOSS,
  abr: 'Alx',
  rang: 641,
  initiative: InitiativeType.LAST,
  splash: 1
};
const BobderHinterhältige: InternalEnemy = {
  name: 'Bob der Hinterhältige',
  hp: 35000,
  ep: 17500,
  min: 1250,
  max: 2500,
  accuracy: 70,
  id: UNIT_BOBDERHINTERHÄLTIGE_BOB_ID,
  icon: UNIT_BOBDERHINTERHÄLTIGE_BOB_ICON,
  type: BossType.BOSS,
  abr: 'Bob',
  rang: 642,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Croaker: InternalEnemy = {
  name: 'Croaker',
  hp: 10000,
  ep: 200,
  min: 500,
  max: 700,
  accuracy: 50,
  id: UNIT_CROAKER_CR_ID,
  icon: UNIT_CROAKER_CR_ICON,
  type: BossType.BOSS,
  abr: 'CR',
  rang: 650,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const GarrunderFallensteller: InternalEnemy = {
  name: 'Garrun der Fallensteller',
  hp: 6000,
  ep: 100,
  min: 300,
  max: 500,
  accuracy: 50,
  id: UNIT_GARRUNDERFALLENSTELLER_GDF_ID,
  icon: UNIT_GARRUNDERFALLENSTELLER_GDF_ICON,
  type: BossType.BOSS,
  abr: 'GdF',
  rang: 651,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Torwart: InternalEnemy = {
  name: 'Torwart',
  hp: 10000,
  ep: 200,
  min: 50,
  max: 150,
  accuracy: 75,
  id: UNIT_TORWART_TW_ID,
  icon: UNIT_TORWART_TW_ICON,
  type: BossType.BOSS,
  abr: 'TW',
  rang: 652,
  initiative: InitiativeType.LAST,
  splash: 1
};
const GeheimnisvollerSchamane: InternalEnemy = {
  name: 'Geheimnisvoller Schamane',
  hp: 9000,
  ep: 500,
  min: 200,
  max: 500,
  accuracy: 70,
  id: UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ID,
  icon: UNIT_GEHEIMNISVOLLERSCHAMANE_GVS_ICON,
  type: BossType.BOSS,
  abr: 'GvS',
  rang: 653,
  flank: true,
  splash: 1
};
const Stahlbart: InternalEnemy = {
  name: 'Stahlbart',
  hp: 30000,
  ep: 4900,
  min: 1500,
  max: 3000,
  accuracy: 50,
  id: UNIT_STAHLBART_STB_ID,
  icon: UNIT_STAHLBART_STB_ICON,
  type: BossType.BOSS,
  abr: 'StB',
  rang: 654,
  initiative: InitiativeType.LAST,
  splash: 1
};
const GigantischerAdler: InternalEnemy = {
  name: 'Gigantischer Adler',
  hp: 150000,
  ep: 26000,
  min: 6400,
  max: 6500,
  accuracy: 85,
  id: UNIT_GIGANTISCHERADLER_GAD_ID,
  icon: UNIT_GIGANTISCHERADLER_GAD_ICON,
  type: BossType.BOSS,
  abr: 'GAd',
  rang: 655,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Mammut: InternalEnemy = {
  name: 'Mammut',
  hp: 750000,
  ep: 76000,
  min: 1400,
  max: 1600,
  accuracy: 90,
  id: UNIT_MAMMUT_MAM_ID,
  icon: UNIT_MAMMUT_MAM_ICON,
  type: BossType.BOSS,
  abr: 'Mam',
  rang: 656,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const Winterschreck: InternalEnemy = {
  name: 'Winterschreck',
  hp: 45000,
  ep: 22000,
  min: 9500,
  max: 10000,
  accuracy: 75,
  id: UNIT_WINTERSCHRECK_WT_ID,
  icon: UNIT_WINTERSCHRECK_WT_ICON,
  type: BossType.BOSS,
  abr: 'WT',
  rang: 657,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Säbelzahntiger: InternalEnemy = {
  name: 'Säbelzahntiger',
  hp: 45000,
  ep: 34000,
  min: 17000,
  max: 18000,
  accuracy: 35,
  id: UNIT_SÄBELZAHNTIGER_SZT_ID,
  icon: UNIT_SÄBELZAHNTIGER_SZT_ICON,
  type: BossType.BOSS,
  abr: 'Szt',
  rang: 658,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const RiesigerSteinbock: InternalEnemy = {
  name: 'Riesiger Steinbock',
  hp: 80000,
  ep: 10000,
  min: 1000,
  max: 1500,
  accuracy: 50,
  id: UNIT_RIESIGERSTEINBOCK_ESB_ID,
  icon: UNIT_RIESIGERSTEINBOCK_ESB_ICON,
  type: BossType.BOSS,
  abr: 'ESb',
  rang: 659,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const GrausigerWolf: InternalEnemy = {
  name: 'Grausiger Wolf',
  hp: 10000,
  ep: 11000,
  min: 5000,
  max: 6000,
  accuracy: 50,
  id: UNIT_GRAUSIGERWOLF_GWO_ID,
  icon: UNIT_GRAUSIGERWOLF_GWO_ICON,
  type: BossType.BOSS,
  abr: 'GWo',
  rang: 660,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const GewaltigerElch: InternalEnemy = {
  name: 'Gewaltiger Elch',
  hp: 250000,
  ep: 29000,
  min: 5000,
  max: 6000,
  accuracy: 75,
  id: UNIT_GEWALTIGERELCH_ELK_ID,
  icon: UNIT_GEWALTIGERELCH_ELK_ICON,
  type: BossType.BOSS,
  abr: 'Elk',
  rang: 661,
  initiative: InitiativeType.LAST,
  splash: 1
};
const Risi4: InternalEnemy = {
  name: 'Risi 4',
  hp: 1000000,
  ep: 99000,
  min: 550,
  max: 600,
  accuracy: 95,
  id: UNIT_RISI4_RISE_ID,
  icon: UNIT_RISI4_RISE_ICON,
  type: BossType.BOSS,
  abr: 'Rise',
  rang: 662,
  initiative: InitiativeType.FIRST,
  flank: true,
  splash: 1
};
const Risi3: InternalEnemy = {
  name: 'Risi 3',
  hp: 1000000,
  ep: 99000,
  min: 550,
  max: 600,
  accuracy: 95,
  id: UNIT_RISI3_RISF_ID,
  icon: UNIT_RISI3_RISF_ICON,
  type: BossType.BOSS,
  abr: 'Risf',
  rang: 663,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const Risi2: InternalEnemy = {
  name: 'Risi 2',
  hp: 900000,
  ep: 89000,
  min: 550,
  max: 600,
  accuracy: 95,
  id: UNIT_RISI2_RISG_ID,
  icon: UNIT_RISI2_RISG_ICON,
  type: BossType.BOSS,
  abr: 'Risg',
  rang: 664,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const Risi1: InternalEnemy = {
  name: 'Risi 1',
  hp: 900000,
  ep: 89000,
  min: 500,
  max: 550,
  accuracy: 95,
  id: UNIT_RISI1_RISH_ID,
  icon: UNIT_RISI1_RISH_ICON,
  type: BossType.BOSS,
  abr: 'Rish',
  rang: 665,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const Risi0: InternalEnemy = {
  name: 'Risi 0',
  hp: 900000,
  ep: 89000,
  min: 500,
  max: 550,
  accuracy: 15,
  id: UNIT_RISI0_RISI_ID,
  icon: UNIT_RISI0_RISI_ICON,
  type: BossType.BOSS,
  abr: 'Risi',
  rang: 666,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};
const RäuberlordBjörn: InternalEnemy = {
  name: 'Räuberlord Björn',
  hp: 35000,
  ep: 6900,
  min: 3000,
  max: 4000,
  accuracy: 90,
  id: UNIT_RÄUBERLORDBJÖRN_BLB_ID,
  icon: UNIT_RÄUBERLORDBJÖRN_BLB_ICON,
  type: BossType.BOSS,
  abr: 'BLB',
  rang: 667,
  splash: 1
};
const VerrückterWissenschaftler: InternalEnemy = {
  name: 'Verrückter Wissenschaftler',
  hp: 30000,
  ep: 94,
  min: 750,
  max: 1000,
  accuracy: 85,
  id: UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ID,
  icon: UNIT_VERRÜCKTERWISSENSCHAFTLER_MADS_ICON,
  type: BossType.BOSS,
  abr: 'MadS',
  rang: 668,
  initiative: InitiativeType.LAST,
  splash: 1
};
const DunklerLord: InternalEnemy = {
  name: 'Dunkler Lord',
  hp: 50000,
  ep: 130,
  min: 300,
  max: 600,
  accuracy: 80,
  id: UNIT_DUNKLERLORD_DLORD_ID,
  icon: UNIT_DUNKLERLORD_DLORD_ICON,
  type: BossType.BOSS,
  abr: 'dLord',
  rang: 669,
  splash: 1
};
const Ribbitha4: InternalEnemy = {
  name: 'Ribbitha 4',
  hp: 30000,
  ep: 350,
  min: 700,
  max: 800,
  accuracy: 80,
  id: UNIT_RIBBITHA4_RIBA_ID,
  icon: UNIT_RIBBITHA4_RIBA_ICON,
  type: BossType.BOSS,
  abr: 'Riba',
  rang: 671,
  splash: 1
};
const Ribbitha7: InternalEnemy = {
  name: 'Ribbitha 7',
  hp: 45000,
  ep: 1150,
  min: 700,
  max: 800,
  accuracy: 80,
  id: UNIT_RIBBITHA7_RIBB_ID,
  icon: UNIT_RIBBITHA7_RIBB_ICON,
  type: BossType.BOSS,
  abr: 'Ribb',
  rang: 672,
  splash: 1
};
const Ribbitha10: InternalEnemy = {
  name: 'Ribbitha 10',
  hp: 65000,
  ep: 3595,
  min: 700,
  max: 800,
  accuracy: 80,
  id: UNIT_RIBBITHA10_RIBC_ID,
  icon: UNIT_RIBBITHA10_RIBC_ICON,
  type: BossType.BOSS,
  abr: 'Ribc',
  rang: 673,
  splash: 1
};
const Schatz: InternalEnemy = {
  name: 'Schatz',
  hp: 1,
  ep: 250,
  min: 0,
  max: 0,
  accuracy: 0,
  id: UNIT_SCHATZ_SCHAT_ID,
  icon: UNIT_SCHATZ_SCHAT_ICON,
  abr: 'Schat',
  rang: 800
};
const VerdorbeneRiesenfledermaus: InternalEnemy = {
  name: 'Verdorbene Riesenfledermaus',
  hp: 150000,
  ep: 60000,
  min: 5500,
  max: 7500,
  accuracy: 90,
  id: UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ID,
  icon: UNIT_VERDORBENERIESENFLEDERMAUS_VRF_ICON,
  type: BossType.BOSS,
  abr: 'VRf',
  rang: 670,
  initiative: InitiativeType.FIRST,
  splash: 1
};
const VerdorbenerFelswurm: InternalEnemy = {
  name: 'Verdorbener Felswurm',
  hp: 100000,
  ep: 66000,
  min: 3500,
  max: 5000,
  accuracy: 80,
  id: UNIT_VERDORBENERFELSWURM_VFW_ID,
  icon: UNIT_VERDORBENERFELSWURM_VFW_ICON,
  type: BossType.BOSS,
  abr: 'VFw',
  rang: 671,
  initiative: InitiativeType.LAST,
  flank: true,
  splash: 1
};

export function getEnemyUnitByExpression(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  expr = (unit: InternalEnemy) => true
): Enemy[] {
  const units = [
    Frostbärenmatriarchin,
    BonabertiBlackBeard,
    BonabertiBlueMan,
    BonabertiGreenMan,
    BonabertiGreenHood,
    BonabertiPeter,
    BonabertiFrenchMan,
    WilderOchse,
    Riesenluchs,
    WildeWaltraud,
    Chuck,
    Metallgebiss,
    Plünderer,
    Schläger,
    Wachhund,
    Raufbold,
    Steinwerfer,
    Waldläufer,
    Krähenfüße,
    Deckschrubber,
    Säbelrassler,
    VerrückterSmutje,
    Messerwerfer,
    Pistolenschütze,
    Maat,
    DesertierterRekrut,
    DesertierteMiliz,
    DesertierteReiterei,
    DesertierterSoldat,
    DesertierterElitesoldat,
    DesertierterBogenschütze,
    DesertierterLangbogenschütze,
    DesertierterArmbrustschütze,
    DesertierterKanonier,
    AusgeburtderNacht,
    Kultist,
    Sumpfhexe,
    Schattenschleicher,
    Fanatiker,
    DunklerPriester,
    Feuertänzer,
    TanzenderDerwisch,
    Nomade,
    Lanzenreiter,
    BerittenerBogenschützeBBS,
    BeritteneAmazone,
    Kataphrakt,
    BrüllenderStier,
    Kompositbogenschütze,
    Jomswikinger,
    Huskarl,
    Karl,
    Thrall,
    Walküre,
    Berserker,
    GrauerWolf,
    Stürmer,
    Abwehrspieler,
    Mittelfeldspieler,
    Stammesangehöriger,
    Schamane,
    Jaguar_Krieger,
    Wildschwein,
    Bär,
    Wolf,
    Alphawolf,
    Fuchs,
    Riese,
    KöniglicherRekrut,
    KöniglicheMiliz,
    KöniglicherBogenschütze,
    KöniglicherLangbogenschütze,
    KöniglicheReiterei,
    KöniglicherKanonier,
    EinfacherWilderer,
    Hasenjäger,
    Jagdhund,
    Klingenrassler,
    Säbelschwinger,
    Schwertmeister,
    Reiter,
    Dünenschütze,
    Wüstenschütze,
    BerittenerBogenschütze,
    Steinkanone,
    Matrose,
    KampfgestählterMatrose,
    BerittenerSeefahrer,
    Bootsmann,
    Obermaat,
    Kanoniermeister,
    StockschwingenderAffe,
    SteinwerfenderAffe,
    Alpha_Affe,
    NervöserAffe,
    Schlitzohr,
    Vandale,
    Rabauke,
    Schelm,
    Rüpel,
    Schurke,
    Flegel,
    Nörgler,
    Quengler,
    Lump,
    Hirsch,
    Froststeinbock,
    Frostbär,
    Frostfuchs,
    Frostadler,
    Frostwolf,
    Frostleopard,
    Frostriese,
    RekrutF,
    MilizF,
    SoldatF,
    ElitesoldatF,
    ReitereiF,
    BogenschützeF,
    LangbogenschützeF,
    ArmbrustschützeF,
    KanonierF,
    Miniatur_Lehmgolem,
    Miniatur_Felsgolem,
    Miniatur_Eisgolem,
    Miniatur_Lavagolem,
    Stinktier,
    EinäugigerBert,
    SirRobin,
    DickeBertha,
    DunklerHohepriester,
    Flammenprediger,
    RieseBogor,
    RieseGogor,
    Einhorn,
    RasendesWildschwein,
    BöserKönig,
    Eisenfaust,
    RiesigerBär,
    RivalisierenderSchneider,
    SchwarzerStier,
    DunklerZauberer,
    VerlogeneZiege,
    Knüppel_Klaus,
    Assassine,
    GierigerGastwirt,
    Rattenfänger,
    DerBürgermeister,
    Rattenkönig,
    Ilsebille,
    königlicherJagdführer,
    BöseStiefmutter,
    königlicherKapitän,
    königlicherMoloch,
    GewitzterRäuber,
    KlugerRäuber,
    MysteriöserRäuber,
    HeimtückischerRäuber,
    HochnäsigerRäuber,
    ErgrauterRäuber,
    HinterlistigerRäuber,
    VernarbterRäuber,
    GierigerRäuber,
    EinfältigerRäuber,
    Steuermann,
    Meuterer,
    Kapitän,
    Räuberlord,
    MürrischerPapagei,
    Oasen_Hüter,
    Felsenwurm,
    Riesenfledermaus,
    HungrigeBlüte,
    GrimmigeBlüte,
    KnolligeBlüte,
    Bücherei_Golem,
    GroßeSteinstatue,
    MoosbedeckteSteinstatue,
    Riesenkanone,
    Informant,
    Riesenseeschlange,
    MürrischeWache,
    WahnsinnigeWache,
    ElChupa,
    AncientDragon,
    DenisderDelinquent,
    AlexderQuerulant,
    BobderHinterhältige,
    Croaker,
    GarrunderFallensteller,
    Torwart,
    GeheimnisvollerSchamane,
    Stahlbart,
    GigantischerAdler,
    Mammut,
    Winterschreck,
    Säbelzahntiger,
    RiesigerSteinbock,
    GrausigerWolf,
    GewaltigerElch,
    Risi4,
    Risi3,
    Risi2,
    Risi1,
    Risi0,
    RäuberlordBjörn,
    VerrückterWissenschaftler,
    DunklerLord,
    Ribbitha4,
    Ribbitha7,
    Ribbitha10,
    Schatz,
    VerdorbeneRiesenfledermaus,
    VerdorbenerFelswurm
  ];
  return units.filter(expr).map(unit => ({
    ...unit,
    hpLeft: unit.hp,
    base: {
      hp: unit.hp,
      min: unit.min,
      max: unit.max,
      accuracy: unit.accuracy,
      ep: unit.ep
    }
  }));
}

export function getEnemyUnitById(id: EnemyId): Enemy {
  const myId = id;
  return getEnemyUnitByExpression(unit => unit.id === myId)[0];
}
