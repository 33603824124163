import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Adventure, AdventureId, getAllAdventures } from '../data-objects/adventures/Adventures';

const adventures = getAllAdventures();

interface Props {
  changeAdventure: (adventure: Adventure) => void;
}
function AdventureSelection({ changeAdventure, ...otherProps }: Props) {
  const { t } = useTranslation();

  const [nameFilter, setNameFilter] = useState('');
  const [themeFilter, setThemeFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [campaingFilter, setCampaignFilter] = useState('');
  const [translated, setTranslated] = useState<
    {
      name: string;
      theme: string;
      campaign: string;
      type: string;
      id: AdventureId;
    }[]
  >(null!);

  useEffect(
    () =>
      setTranslated(
        adventures.map(at => ({
          name: t(at.name),
          theme: t(at.theme),
          campaign: t(at.campaign),
          type: t(at.type),
          id: at.id
        }))
      ),
    [t]
  );

  let shownAdventures = adventures;
  if (nameFilter) {
    const filteredIds = translated
      .filter(translate => translate.name.toLowerCase().includes(nameFilter.toLowerCase()))
      .map(filtered => filtered.id);
    shownAdventures = shownAdventures.filter(at => filteredIds.includes(at.id));
  }
  if (typeFilter) {
    const filteredIds = translated
      .filter(translate => translate.type.toLowerCase().includes(typeFilter.toLowerCase()))
      .map(filtered => filtered.id);
    shownAdventures = shownAdventures.filter(at => filteredIds.includes(at.id));
  }
  if (themeFilter) {
    const filteredIds = translated
      .filter(translate => translate.theme.toLowerCase().includes(themeFilter.toLowerCase()))
      .map(filtered => filtered.id);
    shownAdventures = shownAdventures.filter(at => filteredIds.includes(at.id));
  }
  if (campaingFilter) {
    const filteredIds = translated
      .filter(translate => translate.campaign.toLowerCase().includes(campaingFilter.toLowerCase()))
      .map(filtered => filtered.id);
    shownAdventures = shownAdventures.filter(at => filteredIds.includes(at.id));
  }

  return (
    <div {...otherProps}>
      <div className='adventure-filter'>
        <div>
          <label htmlFor='at-name-filter'>Name:</label>
          <input id='at-name-filter' type='text' onChange={e => setNameFilter(e.target.value)} value={nameFilter} />
        </div>
        <div>
          <label htmlFor='at-type-filter'>Typ:</label>
          <input id='at-type-filter' type='text' onChange={e => setTypeFilter(e.target.value)} value={typeFilter} />
        </div>
        <div>
          <label htmlFor='at-theme-filter'>Thema:</label>
          <input type='text' onChange={e => setThemeFilter(e.target.value)} value={themeFilter} />
        </div>
        <div>
          <label htmlFor='at-campaign-filter'>Kampagne:</label>
          <input type='text' onChange={e => setCampaignFilter(e.target.value)} value={campaingFilter} />
        </div>
      </div>
      <div className='adventures'>
        {shownAdventures.map(adventure => (
          <motion.div
            layoutId={`${adventure.id}-${adventure.name}`}
            key={adventure.name}
            className='adventure-entry card-entry'
            onClick={() => changeAdventure(adventure)}>
            <img src={adventure.icon} alt={adventure.name} />
            <div className='adventure-name'>{t(adventure.name)}</div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default styled(AdventureSelection)`
  padding: 28px 0 0 0;

  .adventure-filter {
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      padding-right: 26px;
    }
  }

  .adventures {
    display: grid;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fit, minmax(140px, max-content));
    grid-auto-rows: auto;
    grid-column-gap: 15px;
    grid-row-gap: 10px;

    .card-entry:hover {
      box-shadow: var(--box-shadow-hover);
      background-color: var(--hover-background-color);
      color: var(--hover-foreground-color);
      transition: background-color 200ms;
    }
  }
`;
