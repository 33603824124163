import { GlobalEffects } from "../../data-objects/GlobalEffects";

export function globalEffectToLabel(effects?: GlobalEffects) {
  if (effects?.frost) {
    return "Frost";
  }
  if (effects?.nebel) {
    return "Nebel";
  }
  if (effects?.sonnenschein) {
    return "Sonnenschein";
  }
  if (effects?.wirbelsturm) {
    return "Wirbelsturm";
  }
  return "none";
}
