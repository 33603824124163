import { SearchWeather } from '../../app/store';
import { GlobalEffects, LazarettBuff } from '../../data-objects/GlobalEffects';
import { addWaveResult, startNewSimulation } from '../result/resultActions';
import { ShownResult } from '../result/util';
import {
  abortLockSearch,
  addMultipleResults,
  addSearchResult,
  finishLockSearch,
  startNewSearch
} from '../search-army/searchArmyActions';
import { SearchStatus } from '../search-army/SearchStatus';
import { changeAdventure } from '../simulationActions';
import { UIActionTypes } from './uiActionTypes';

const blockSearchState = 'lockSearchInput';
const attackSearchState = 'attackSearchInput';

export function registerSearchAmount(maxAmount: number) {
  return {
    type: UIActionTypes.REGISTER_SEARCH_SPACE,
    maxAmount
  } as const;
}

export function updateProgress(progress: number) {
  return {
    type: UIActionTypes.UPDATE_SEARCH_PROGRESS,
    progress
  } as const;
}

export function updateSearchState(status: SearchStatus) {
  return {
    type: UIActionTypes.SET_SEARCH_STATUS,
    status
  } as const;
}

export function toggleSelectedGenerals(generalIds: Array<number | string>, isAttack?: true) {
  return {
    type: UIActionTypes.TOGGLE_SELECTED_GENERALS,
    generalIds,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function toggleSelectedUnits(unitIds: Array<number>, isAttack?: true) {
  return {
    type: UIActionTypes.TOGGLE_SELECTED_UNITS,
    unitIds,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function toggleSelectedEffect(effect: keyof SearchWeather, isAttack?: true) {
  return {
    type: UIActionTypes.TOGGLE_SELECTED_EFFECT,
    effect,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function setLazarettEffect(lazarett: LazarettBuff, isAttack?: true) {
  return {
    type: UIActionTypes.SET_LAZARETT_EFFECT,
    lazarett,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function setKantineEffect(kantine: GlobalEffects['kantine'], isAttack?: true) {
  return {
    type: UIActionTypes.SET_KANTINE_EFFECT,
    kantine,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function updateValue(value: number, isAttack?: true) {
  return {
    type: UIActionTypes.UPDATE_VALUE,
    value,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function toggleKeepPreviousResults(isAttack?: true) {
  return {
    type: UIActionTypes.TOGGLE_KEEP_PREVIOUS,
    statePart: isAttack ? attackSearchState : blockSearchState
  } as const;
}

export function changeDisplayedResult(resultType: ShownResult) {
  return {
    type: UIActionTypes.CHANGE_DISPLAYED_RESULT,
    resultType
  } as const;
}

export type UIActions =
  | ReturnType<typeof registerSearchAmount>
  | ReturnType<typeof toggleSelectedGenerals>
  | ReturnType<typeof toggleSelectedUnits>
  | ReturnType<typeof toggleSelectedEffect>
  | ReturnType<typeof setLazarettEffect>
  | ReturnType<typeof setKantineEffect>
  | ReturnType<typeof updateValue>
  | ReturnType<typeof toggleKeepPreviousResults>
  | ReturnType<typeof updateProgress>
  | ReturnType<typeof updateSearchState>
  | ReturnType<typeof addMultipleResults>
  | ReturnType<typeof addSearchResult>
  | ReturnType<typeof changeDisplayedResult>
  | ReturnType<typeof abortLockSearch>
  | ReturnType<typeof finishLockSearch>
  | ReturnType<typeof startNewSimulation>
  | ReturnType<typeof addWaveResult>
  | ReturnType<typeof changeAdventure>
  | ReturnType<typeof startNewSearch>;
