import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useUpdatingCallbacks } from 'use-updating-callbacks';
import { SearchResult, SearchWeather } from '../app/store';
import LoadingSpinner from '../components/Basics/LoadingSpinner';
import Progressbar from '../components/Basics/Progressbar';
import { convertToNumber } from '../components/Basics/util';
import { MappedCustomGeneral } from '../components/Configurations/GeneralConfiguration';
import ConfirmDialog from '../components/layouts/ConfirmDialog';
import Popup from '../components/layouts/Popup';
import { toSelectedUnits } from '../components/units/UnitSelection';
import { CampUnitType, getAdventureById } from '../data-objects/adventures';
import { CampBuilding } from '../data-objects/CampTypes';
import { GlobalEffects, LazarettBuff } from '../data-objects/GlobalEffects';
import { General } from '../data-objects/units/Army';
import { EnemyId } from '../data-objects/units/Units';
import { useAttackSearchEngine, useSearchEngine } from '../hooks/useExampleWorker';
import { getCampFromAdventure } from '../redux/adventureUtil';
import * as actions from '../redux/search-army/searchArmyActions';
import { SearchStatus } from '../redux/search-army/SearchStatus';
import {
  useAttackSearchInput,
  useBlockPreferences,
  useCurrentSelectedAdventure,
  useGeneralsList,
  useIsSimulationRunning,
  useLockSearchInput,
  useMaxSearchSpace,
  useSearchProgress,
  useSearchStatus,
  useSelectedCamps,
  useSelectedEnemies,
  useUnitValues
} from '../redux/selectors';
import { createMappedGenerals, generalMap, mapToCombatGeneral } from '../redux/unitUtil';
import * as uiActions from '../redux/user-interface/uiActions';
import { BaseProps } from '../types/basics';
import { AttackSearchProps } from '../worker/attackSearchWorker';
import { PrepareSearchProps, SearchSingleBlock } from '../worker/finderWorker';
import { searchAttack } from './attack-utils/searchAttack';
import SearchParameterInput from './SearchParameterInput';
import { SearchCamp } from './utils';
import { searchLock } from './utils/searchLock';

const BLOCK_SEARCH_ICON = '/icons/misc/block_general.png';

interface Props extends BaseProps {}

enum SearchKind {
  ATTACK,
  LOCK
}

function FinderButton({ ...otherProps }: Props) {
  const cancelSearch = useRef({ cancel: false });
  const searchStatus = useSearchStatus();
  const [showChoice, setShowChoice] = useState(false);
  const [showConfiguration, setShowConfiguration] = useState<SearchKind | undefined>(undefined);
  const searchEngine = useSearchEngine();
  const dispatch = useDispatch();
  const generalsList = useGeneralsList();
  const selectedEnemies = useSelectedEnemies();
  const selectedCamps = useSelectedCamps();
  const selectedAdventure = useCurrentSelectedAdventure();
  const blockPreferences = useBlockPreferences();
  const { t } = useTranslation();
  const simulationRunning = useIsSimulationRunning();
  const lockSearchInput = useLockSearchInput();
  // aktueller Status: frei vs laeuft schon
  // preferences?

  const searchAttackEngine = useAttackSearchEngine();
  const attackSearchInput = useAttackSearchInput();
  const unitValues = useUnitValues();

  const isAttack = showConfiguration === SearchKind.ATTACK ? true : undefined;
  const searchInputToUse = isAttack ? attackSearchInput : lockSearchInput;

  const callbacks = useUpdatingCallbacks({
    onCloseConfigurationDialog: () => setShowConfiguration(undefined),
    toggleUnits: (unitIds: Array<number>) => dispatch(uiActions.toggleSelectedUnits(unitIds, isAttack)),
    toggleGenerals: (generalIds: Array<number | string>) =>
      dispatch(uiActions.toggleSelectedGenerals(generalIds, isAttack)),
    toggleEffect: (effect: keyof SearchWeather) => dispatch(uiActions.toggleSelectedEffect(effect, isAttack)),
    toggleLazarett: (stufe: LazarettBuff['stufe'], value: LazarettBuff['value']) =>
      dispatch(uiActions.setLazarettEffect({ stufe, value }, isAttack)),
    toggleKantine: (kantine: GlobalEffects['kantine']) => dispatch(uiActions.setKantineEffect(kantine, isAttack)),
    updateValue: (newValue: number) => dispatch(uiActions.updateValue(newValue, isAttack)),
    toggleKeepResults: () => dispatch(uiActions.toggleKeepPreviousResults(isAttack))
  });

  const label = useMemo(
    () => ({
      lockRunning: t('lock-running'),
      abortSearch: t('abort-search'),
      startSearch: t('start-search'),
      lock: t('lock'),
      attack: t('attack'),
      abortingSearch: t('aborting-search'),
      createCombinations: t('create-combinations'),
      lockHeader: t('Lock-Suche'),
      attackHeader: t('Angriff-Suche')
    }),
    [t]
  );

  const delayDuration = 0.3;
  const limitDurationTransition = { type: 'tween', duration: delayDuration };
  const delayTransition = { type: 'tween', delay: delayDuration };
  const variants = {
    toRight: { x: -50, opacity: 0, transition: limitDurationTransition },
    toLeft: { x: 50, opacity: 0, transition: limitDurationTransition },
    toCenter: { x: 0, scale: 1, opacity: 1, transition: limitDurationTransition },
    shrink: { scale: 0, opacity: 0, transition: limitDurationTransition },
    toRightDelay: { x: -50, opacity: 0, transition: delayTransition },
    toLeftDelay: { x: 50, opacity: 0, transition: delayTransition },
    toCenterDelay: { x: 0, scale: 1, opacity: 1, transition: delayTransition },
    shrinkDelay: { scale: 0, opacity: 0, transition: delayTransition }
  };

  const enemies = selectedEnemies.length ? selectedEnemies[0] : null;

  const adventure = selectedAdventure ? getAdventureById(selectedAdventure) : undefined;

  const camps = selectedCamps.map(camp => getCampFromAdventure(adventure, camp));

  const mappedGenerals = generalsList.map((list, index) => ({
    ...list,
    initiallyOpen: index === 0,
    generals: createMappedGenerals(list.generals)
  }));

  const customGeneralMap = mappedGenerals.reduce(
    (all, list) => ({
      ...all,
      ...list.generals.reduce((prev, gen) => ({ ...prev, [gen.uuid]: gen }), {})
    }),
    {} as { [x: string]: MappedCustomGeneral }
  );

  const combinedGeneralsMap: Record<string | number, General | MappedCustomGeneral> = {
    ...generalMap,
    ...customGeneralMap
  };

  const searchAction = async (
    selectedGenerals: (number | string)[],
    selectedUnits: number[],
    value: number,
    clearPreviousResults: boolean,
    selectedGlobalEffects: GlobalEffects[] = [{}]
  ) => {
    // Global guard to only start one search
    if (searchStatus !== SearchStatus.READY) {
      return;
    }
    if (selectedAdventure === null || enemies === null) {
      return;
    }

    const finderGenerals = selectedGenerals.map(genId => mapToCombatGeneral(combinedGeneralsMap[genId]));

    const attackCamps = selectedEnemies.map((enemies, idx) => {
      const banditCamp: {
        enemies: CampUnitType[];
        camp: CampBuilding;
      } = {
        enemies: Object.entries(enemies).map(([type, amount]) => ({
          amount: convertToNumber(amount),
          typeId: parseInt(type) as EnemyId
        })),
        camp: camps[idx]
      };

      return banditCamp;
    });

    // new actions
    const startNewSearch = (camps: SearchCamp[], clearPrevious: boolean) =>
      dispatch(actions.startNewSearch(selectedAdventure, camps, clearPrevious, isAttack));

    const prepareLockSearch = (args: PrepareSearchProps) => searchEngine.prepareSearch(args);
    const prepareAttackSearch = (args: PrepareSearchProps) => searchEngine.prepareAttackSearch(args);

    const registerSearchAmount = (value: number) => dispatch(uiActions.registerSearchAmount(value));

    const addMultipleResults = (general: General, results: SearchResult[]) =>
      dispatch(actions.addMultipleResults(general, results, selectedAdventure, attackCamps, isAttack));

    const addSearchResult = (general: General, result: SearchResult) => {
      dispatch(actions.addSearchResult(general, result, selectedAdventure, attackCamps, isAttack));
    };

    const finishSearch = () => dispatch(actions.finishLockSearch(isAttack));
    const shouldAbort = () => cancelSearch.current.cancel;
    const doAbort = () => dispatch(actions.abortLockSearch(isAttack));
    const updateProgress = (progress: number) => dispatch(uiActions.updateProgress(progress));

    const lockSearch = (props: SearchSingleBlock) => searchEngine.searchSingleBlock(props);

    const attackSearch = ({
      unit,
      weatherToUse: effect,
      general,
      generalCapacity,
      simulationRuns,
      timePerRound,
      currentStepSize
    }: AttackSearchProps) =>
      searchAttackEngine.searchAttack({
        unit,
        bandits: toSelectedUnits(selectedEnemies),
        weatherToUse: effect,
        camps,
        general,
        generalCapacity,
        simulationRuns,
        timePerRound,
        currentStepSize
      });

    if (showConfiguration === SearchKind.ATTACK) {
      startNewSearch(attackCamps, clearPreviousResults);
      return searchAttack(
        finderGenerals,
        selectedUnits,
        attackCamps,
        value,
        selectedGlobalEffects,
        blockPreferences,
        unitValues,
        attackSearch,
        prepareAttackSearch,
        registerSearchAmount,
        shouldAbort,
        doAbort,
        updateProgress,
        addSearchResult,
        addMultipleResults,
        finishSearch
      );
    } else {
      return searchLock(
        finderGenerals,
        selectedUnits,
        attackCamps[0],
        value,
        clearPreviousResults,
        selectedGlobalEffects,
        blockPreferences,
        lockSearch,
        startNewSearch,
        prepareLockSearch,
        registerSearchAmount,
        shouldAbort,
        doAbort,
        updateProgress,
        addSearchResult,
        addMultipleResults,
        finishSearch
      );
    }
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const startConfiguration = useCallback(
    (kind: SearchKind) => {
      if (!enemies || searchStatus !== SearchStatus.READY) {
        setShowConfirm(true);
        return;
      }
      setShowChoice(false);
      cancelSearch.current.cancel = false;
      setShowConfiguration(kind);
    },
    [enemies, searchStatus]
  );

  return (
    <div
      {...otherProps}
      onMouseEnter={() => {
        if (!simulationRunning && showConfiguration === undefined && !showConfirm) {
          setShowChoice(true);
        }
      }}
      onMouseLeave={() => setShowChoice(false)}>
      <AnimatePresence>
        {!showChoice && (
          <motion.div
            variants={variants}
            initial={false}
            animate='toCenter'
            exit='shrink'
            className='primary-button start-finder'
            onClick={() => {
              if (simulationRunning) {
                setShowConfirm(true);
              }
            }}>
            {searchStatus === SearchStatus.READY ? (
              <img src={BLOCK_SEARCH_ICON} alt='search' style={{ width: '30px', margin: 'auto 0' }} />
            ) : (
              <LoadingSpinner style={{ margin: 'auto 0' }} />
            )}
            <div className='search-button-text-string'>
              {searchStatus === SearchStatus.READY
                ? label.startSearch
                : searchStatus === SearchStatus.ABORTING
                ? label.abortingSearch
                : searchStatus === SearchStatus.PRE_ANALYSIS
                ? label.createCombinations
                : searchStatus === SearchStatus.SEARCHING
                ? 'Simuliere alle Kombinationen'
                : 'Verfeinere die Ergebnisse'}
            </div>
          </motion.div>
        )}
        {showChoice && (
          <motion.div
            key='attack-search-button'
            variants={variants}
            initial='toRightDelay'
            animate='toCenter'
            exit='toRight'
            className='attack-button'
            onClick={() => startConfiguration(SearchKind.ATTACK)}>
            <div>{label.attack}</div>
          </motion.div>
        )}
        {showChoice && (
          <motion.div
            key='lock-search-button'
            variants={variants}
            initial='toLeftDelay'
            animate='toCenter'
            exit='toLeft'
            className='lock-button'
            onClick={() => startConfiguration(SearchKind.LOCK)}>
            <div>{label.lock}</div>
          </motion.div>
        )}
      </AnimatePresence>
      <ConfirmDialog
        visible={showConfirm}
        text={t('lock-running')}
        explain={t('abort-search')}
        onAccept={() => {
          setTimeout(() => {
            setShowConfirm(false);
            cancelSearch.current.cancel = true;
            dispatch(uiActions.updateSearchState(SearchStatus.ABORTING));
          }, 1);
        }}
        onDeny={() => setTimeout(() => setShowConfirm(false), 1)}
      />
      <Popup visible={showConfiguration !== undefined} onClose={callbacks.onCloseConfigurationDialog}>
        <SearchParameterInput
          finishConfiguration={(value, selectedUnits, selectedGenerals, clearPreviousResults, globalEffects) => {
            searchAction(selectedGenerals, selectedUnits, value, clearPreviousResults, globalEffects);
            setTimeout(callbacks.onCloseConfigurationDialog, 1);
          }}
          toggleUnits={callbacks.toggleUnits}
          toggleEffect={callbacks.toggleEffect}
          toggleLazarett={callbacks.toggleLazarett}
          toggleKantine={callbacks.toggleKantine}
          toggleGenerals={callbacks.toggleGenerals}
          setValue={callbacks.updateValue}
          toggleKeepResults={callbacks.toggleKeepResults}
          headerLabel={isAttack ? label.attackHeader : label.lockHeader}
          hideValue={isAttack}
          enemies={selectedEnemies}
          camps={camps}
          {...searchInputToUse}
        />
      </Popup>
      <SearchProgressBar show={searchStatus !== SearchStatus.READY} />
    </div>
  );
}

interface SearchProgressProps {
  show: boolean;
}

function SearchProgressBar({ show }: SearchProgressProps) {
  const maxAmount = useMaxSearchSpace();
  const searchProgress = useSearchProgress();

  if (!show) {
    return null;
  }

  const completedProgress = maxAmount ? (searchProgress * 100) / maxAmount : 0;
  return <Progressbar completed={completedProgress} />;
}

export default styled(FinderButton)`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 46px;
  grid-gap: 4px;
  cursor: pointer;

  .start-finder {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  .attack-button,
  .lock-button {
    display: flex;
    grid-row: 1;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: var(--text-on-primary);

    & > * {
      margin: auto;
      pointer-events: none;
      cursor: default;
    }

    &:hover,
    & > div:hover {
      box-shadow: var(--box-shadow-hover);
      background-color: var(--hover-background-color);
      color: var(--hover-foreground-color);
      transition: background-color 200ms, color 200ms;
    }
  }

  .attack-button {
    grid-column: 1;
  }

  .lock-button {
    grid-column: 2;
  }

  ${Progressbar} {
    position: absolute;
    bottom: -20px;
  }
  .search-button-text-string {
  }
`;
