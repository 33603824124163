import { CampBuilding } from "../CampTypes";
import { EnemyId } from "../units/Units";

export const MISSION_TYPE = "Mission";
export const HEIMAT_TYPE = "Heimat";
export const MINI_MISSION_TYPE = "Mini-Mission";
export const KOOP_MISSION_TYPE = "Koop-Mission";
export const UNTERNEHMUNG_TYPE = "Unternehmung";

export const EREIGNIS_THEME = "Ereignis";
export const HALLOWEEN_THEME = "Halloween";
export const OSTERN_THEME = "Ostern";
export const APOKALYPSE_THEME = "Apokalypse";
export const RETRO_THEME = "Retro";
export const KLASSISCH_THEME = "Klassisch";
export const MÄRCHEN_THEME = "MÄrchen";
export const MYTHOS_THEME = "Mythos";
export const WEIHNACHTEN_THEME = "Weihnachten";

export type AdventureType =
  | typeof MISSION_TYPE
  | typeof HEIMAT_TYPE
  | typeof MINI_MISSION_TYPE
  | typeof KOOP_MISSION_TYPE
  | typeof UNTERNEHMUNG_TYPE;

export type AdventureTheme =
  | typeof EREIGNIS_THEME
  | typeof HALLOWEEN_THEME
  | typeof OSTERN_THEME
  | typeof APOKALYPSE_THEME
  | typeof RETRO_THEME
  | typeof KLASSISCH_THEME
  | typeof MÄRCHEN_THEME
  | typeof MYTHOS_THEME
  | typeof WEIHNACHTEN_THEME;

export interface CampUnitType {
  amount: number;
  typeId: EnemyId;
}
export interface CampType {
  sector: string;
  number: number;
  type: CampBuilding;
  art: string;
  boss: boolean;
  units: CampUnitType[];
}

export interface BaseAdventure {
  name: string;
  image: string;
  icon: string;
  campaign: string;
  type: AdventureType;
  theme: AdventureTheme;
  difficulty: number;
  allowsElite: boolean;
  camps: CampType[];
}
