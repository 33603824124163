import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { GeneralsList } from '../components/Configurations/GeneralConfiguration';
import { SelectedUnits, UserInputUnits } from '../components/units/UnitSelection';
import { AdventureId, CampType } from '../data-objects/adventures';
import { GlobalEffects } from '../data-objects/GlobalEffects';
import hufeisen from '../data-objects/hufeisen';
import { General, GeneralId } from '../data-objects/units/Army';
import { SkillTree } from '../data-objects/units/BaseUnitTypes';
import { EnemyId } from '../data-objects/units/Units';
import { AttackSearchSingleReport, DefinedAmount } from '../Finder/attack-utils';
import { BlockResult, SearchCamp } from '../Finder/utils';
import configurationReducer from '../redux/configuration/configurationReducer';
import resultReducer from '../redux/result/resultReducer';
import { ShownResult } from '../redux/result/util';
import searchArmyReducer from '../redux/search-army/searchArmyReducer';
import { SearchStatus } from '../redux/search-army/SearchStatus';
import simulationReducer from '../redux/simulationReducer';
import { Amount, SimulationLosses } from '../redux/simulationUtil';
import { unitDefaultValues } from '../redux/unitUtil';
import uiReducer from '../redux/user-interface/uiReducer';
import { loadState, saveState } from './storeUtil';

export interface DetailedResult {
  lossesMap: SimulationLosses;
  boolResult: number;
  campNo: number;
  simulations: number;
  weather: GlobalEffects;
  attackerWave: number;
  enemyWave: number;
}

export interface SimpleCampType {
  sector?: CampType['sector'];
  number?: CampType['number'];
}

export interface BlockPreferences {
  defaultBlockUnits: { elite: Array<number>; normal: Array<number> };
  defaultBlockGenerals: Array<number>;
  detailedBlockSimulations: number;
  searchValue: number;
  maxResults: number;
  hufeisen: keyof typeof hufeisen;
}

export interface ConfigurationState {
  unitValues: typeof unitDefaultValues;
  selectedGeneralsList: number;
  generalsList: Array<GeneralsList>;
  darkMode: boolean;
  blockPreferences: BlockPreferences;
}

export interface AttackSearchResult {
  battleResult: number;
  general: General;
  units: SelectedUnits;
  banditCamps: Array<SearchCamp>;
  weather: GlobalEffects;
  unitLosses: Record<number, DefinedAmount>;
  simulations: number;
  ep: Amount;
  details: Array<AttackSearchSingleReport>;
}

export type SearchResult = AttackSearchResult | (BlockResult & { weather?: GlobalEffects });

/**
 * Information included:
 *  Camp(s)
 *  General / 'compatible' general
 *  'compatible' skills
 *  global effects
 *  the full simulation result
 *
 *
 */
export interface HallOfFame {
  entries: Record<string /* camp */, CampEntry>;
}

export interface SearchWeather extends GlobalEffects {
  none?: boolean;
}
export interface SearchInput {
  selectedUnits: Array<number>;
  selectedGenerals: Array<number | string>;
  value: number;
  selectedEffects: SearchWeather;
  keepPreviousResults: boolean;
}

export interface UIState {
  search: {
    maxSearchSpace?: number;
    locksFound: number;
    progress: number;
    status: SearchStatus;
  };
  lockSearchInput: SearchInput;
  attackSearchInput: SearchInput;
  shownResult: ShownResult;
  simulationInProgress: boolean;
}

export interface CampEntry {
  adventureId: AdventureId;
  bandits: Array<SearchCamp>;
  simulations: Record<string /* skills and/or generalId ? */, GeneralResult>;
}

export interface GeneralResult {
  general: General;
  results: Array<SearchResult>;
}

export interface FinderState {
  found: Record<string, CampEntry>;
  attack: Record<string, CampEntry>;
  currentSearch?: { campKey: string; isAttack: boolean };
}

export interface HistoryState {}

export interface ResultState {
  waveResult: Array<DetailedResult>;
}

export interface SimulationState {
  selectedGenerals: Array<GeneralId | null>;
  selectedSkills: Array<SkillTree | null>;
  selectedUnits: Array<UserInputUnits>;
  showEliteUnits: Array<boolean>;
  availableEnemies: Array<Array<EnemyId>>;
  selectedEnemies: Array<UserInputUnits>;
  showCustomGenerals: Array<number>;
  highlightedGenerals: Array<string | number | null>;
  currentWave: number;
  currentEnemyWave: number;
  weatherEffects: GlobalEffects;
  // TODO: Remove these
  selectedAdventure: AdventureId | null;
  selectedCamps: Array<SimpleCampType | null>;
}

export interface AppState {
  ui: UIState;
  configuration: ConfigurationState;
  simulation: SimulationState;
  searchArmy: FinderState;
  result: ResultState;
  // history: HistoryState;
}

const preloadedState = loadState();

// "Root reducer"
const rootReducer = combineReducers({
  ui: uiReducer,
  simulation: simulationReducer,
  configuration: configurationReducer,
  result: resultReducer,
  searchArmy: searchArmyReducer
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: preloadedState
});

//TODO: Enable Persiting the state
store.subscribe(() => saveState(store.getState()));

export default store;
