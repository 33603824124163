import styled from 'styled-components';
import { SearchWeather } from '../app/store';
import { GlobalEffectSelection } from '../components/BuffSelection';
import { AdventureId } from '../data-objects/adventures';
import { GlobalEffects, LazarettBuff } from '../data-objects/GlobalEffects';
import { BaseProps } from '../types/basics';

interface Props extends BaseProps {
  selectedAdventure: AdventureId;
  effects: SearchWeather;
  toggleEffect: (weather: keyof SearchWeather) => void;
  toggleLazarett: (stufe: LazarettBuff['stufe'], value: LazarettBuff['value']) => void;
  toggleKantine: (value: GlobalEffects['kantine']) => void;
}

function SearchBuffSelection({
  selectedAdventure,
  effects,
  toggleEffect,
  toggleLazarett,
  toggleKantine,
  ...otherProps
}: Props) {
  return (
    <GlobalEffectSelection
      selectedAdventure={selectedAdventure}
      weatherEffects={effects}
      toggleWirbelsturm={() => toggleEffect('wirbelsturm')}
      toggleNebel={() => toggleEffect('nebel')}
      toggleSonnenschein={() => toggleEffect('sonnenschein')}
      toggleFrost={() => toggleEffect('frost')}
      toggleIce={() => toggleEffect('bk_ice')}
      toggleCold={() => toggleEffect('bk_cold')}
      toggleArmory={() => toggleEffect('bk_armory')}
      toggleHeadquarter={() => toggleEffect('bk_headquarter')}
      toggleEp={() => toggleEffect('bk_ep')}
      toggleRecovery={() => toggleEffect('bk_recovery')}
      toggleStorm={() => toggleEffect('bk_storm')}
      toggleSnow={() => toggleEffect('bk_snow')}
      toggleWater={() => toggleEffect('city_water')}
      toggleWind={() => toggleEffect('city_wind')}
      toggleMetal={() => toggleEffect('city_metal')}
      toggleStone={() => toggleEffect('city_stone')}
      toggleNature={() => toggleEffect('city_nature')}
      toggleFire={() => toggleEffect('city_fire')}
      toggleNone={() => toggleEffect('none')}
      lazarett_basis_1={() => toggleLazarett('basis', 1)}
      lazarett_basis_2={() => toggleLazarett('basis', 2)}
      lazarett_basis_3={() => toggleLazarett('basis', 3)}
      lazarett_enhanced_1={() => toggleLazarett('enhanced', 1)}
      lazarett_enhanced_2={() => toggleLazarett('enhanced', 2)}
      lazarett_enhanced_3={() => toggleLazarett('enhanced', 3)}
      lazarett_premium_1={() => toggleLazarett('premium', 1)}
      lazarett_premium_2={() => toggleLazarett('premium', 2)}
      lazarett_premium_3={() => toggleLazarett('premium', 3)}
      toggle_kantine_1={() => toggleKantine(1)}
      toggle_kantine_2={() => toggleKantine(2)}
      toggle_kantine_3={() => toggleKantine(3)}
      toggle_kantine_4={() => toggleKantine(4)}
      toggle_kantine_5={() => toggleKantine(5)}
      hideInfos
      {...otherProps}
    />
  );
}

export default styled(SearchBuffSelection)``;
