import range from 'lodash.range';
import { SelectedUnits } from '../../components/units/UnitSelection';
import { getNumberOfUnits } from '../../components/units/utils';
import { createArmyConfig, prepareRefinedConfig } from '../../worker/finderWorker';
import { FinderUnit } from '../utils';

const MAX_COMBINATIONS = 45000;

export function createAttackRefinements(units: Array<FinderUnit>, generalCapacity: number, previousStepSize: number) {
  const combinations = units.reduce((summedAmount, unit) => {
    const specifiedMax = unit.max ?? 0;
    const specifiedMin = unit.min ?? 0;
    const specifiedRange = specifiedMax - specifiedMin || 1;
    return summedAmount * specifiedRange;
  }, 1);
  if (!Number.isSafeInteger(combinations)) {
    return { configs: createArmyConfig(units, generalCapacity, [generalCapacity]), usedStepSize: 5 };
  }
  if (combinations < MAX_COMBINATIONS) {
    return prepareRefinedConfig({ finderUnits: units, allCapacities: [generalCapacity], unitStepSize: 1 });
  } else {
    if (previousStepSize === 3) {
      return { configs: [], usedStepSize: 1 };
    }
    return prepareRefinedConfig({ finderUnits: units, allCapacities: [generalCapacity], unitStepSize: 3 });
  }
}

export function createRefinements(
  units: Record<number, number>,
  generalCapacity: number,
  previousStepSize: number,
  useSpecificStepSize?: number
) {
  const newStepSize = getNewStepSize(useSpecificStepSize, previousStepSize);

  const variationRangeLower = Math.floor(previousStepSize / 2);
  const variationRangeUpper = Math.ceil(previousStepSize / 2);
  const newPossibilities = Object.entries(units).map(([id, amount]) =>
    range(
      // TODO: Maybe we should use Math.ceil(previousStepSize/2) here
      // But this also means, we need to rethink the factor calculation, as we also need to use
      // the division by 2 there.
      // The second sentence is currently unclear...
      amount - variationRangeLower + newStepSize,
      amount + variationRangeUpper,
      newStepSize
    ).map(newAmount => ({ id, newAmount }))
  );

  var result: SelectedUnits[] = [];
  for (var unitIdx = 0; unitIdx < newPossibilities.length; unitIdx++) {
    const tmpResult: SelectedUnits[] = [];
    for (var varIdx = 0; varIdx < newPossibilities[unitIdx].length; varIdx++) {
      const variation = newPossibilities[unitIdx][varIdx];
      const newEntry = { [variation.id]: variation.newAmount };
      if (unitIdx === 0) {
        tmpResult.push(newEntry);
      } else {
        result.forEach(entry => tmpResult.push({ ...entry, ...newEntry }));
      }
    }
    result = tmpResult;
  }

  return {
    stepSize: newStepSize,
    result: result.filter(unitCombinations => getNumberOfUnits(unitCombinations) <= generalCapacity)
  };
}

function getNewStepSize(useSpecificStepSize: number | undefined, previousStepSize: number) {
  if (useSpecificStepSize) {
    return useSpecificStepSize;
  } else {
    const factorsOfStep = factors(previousStepSize);
    if (factorsOfStep.length > 1) {
      if (factorsOfStep.length > 2) {
        return factorsOfStep[1];
      } else {
        return factorsOfStep[0];
      }
    }
  }
  return 1;
}

function factors(number: number) {
  return Array.from(Array(number + 1), (_, i) => i).filter(i => i !== 1 && number % i === 0);
}
