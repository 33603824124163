import { AttackSearchResult, DetailedResult } from '../../../app/store';
import { General } from '../../../data-objects/units/Army';
import { EnemyId } from '../../../data-objects/units/Units';
import { AttackSearchSingleReport, DefinedAmount } from '../../../Finder/attack-utils';
import { Amount, getLosses } from '../../../redux/simulationUtil';
import { computeEpForGeneral } from './computeEpForGeneral';

export function getLostBanditsFromResult(
  result: DetailedResult | AttackSearchSingleReport | AttackSearchResult,
  ourGeneral: General
) {
  if ('details' in result) {
    return { lostBandits: [], gainedExperience: { min: 0, avg: 0, max: 0 } };
  }

  const losses = 'losses' in result ? result.losses : result.lossesMap;
  const usedUnits = losses.usedUnits;

  const lostBandits: Array<{ amount: Amount; icon: string; rang: number }> = [];
  const gainedExperience = Object.keys(losses.bandits.combined).reduce<DefinedAmount>(
    (prev, unitId) => {
      const enemyId: EnemyId = parseInt(unitId) as EnemyId;
      const lossesAmount = losses.bandits.combined[enemyId];
      const usedUnitsDetail = losses.bandits.details[enemyId];
      if (lossesAmount === undefined || usedUnitsDetail === undefined) {
        return prev;
      }
      const enemyUnit = usedUnits.enemies[enemyId]?.unit;
      const unitEp = enemyUnit.base.ep;
      const banditLosses = getLosses(lossesAmount, usedUnitsDetail, result.simulations);
      // Store lost bandits to display
      lostBandits.push({
        amount: banditLosses,
        icon: enemyUnit.icon,
        rang: enemyUnit.rang
      });

      // Calculate gained experience
      const { min, max, avg } = banditLosses;

      const maxEp = (max ?? 0) * unitEp;
      const minEp = (min ?? 0) * unitEp;
      const avgEp = (avg ?? 0) * unitEp;
      return {
        min: prev.min + minEp,
        max: prev.max + maxEp,
        avg: prev.avg + avgEp
      };
    },
    { min: 0, max: 0, avg: 0 }
  );

  (['min', 'max', 'avg'] as const).forEach(attr => {
    gainedExperience[attr] = computeEpForGeneral(gainedExperience[attr], ourGeneral);
  });
  gainedExperience.min = Math.floor(gainedExperience.min);

  return { lostBandits, gainedExperience };
}
