import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useUpdatingCallbacks } from 'use-updating-callbacks';
import { CampBuilding } from '../data-objects/CampTypes';
import { General, GeneralId } from '../data-objects/units/Army';
import { SkillTree } from '../data-objects/units/BaseUnitTypes';
import FinderButton from '../Finder/FinderButton';
import { useSimulationEngine } from '../hooks/useExampleWorker';
import * as actions from '../redux/result/resultActions';
import {
  useGlobalEffects,
  useSelectedEnemies,
  useSelectedGenerals,
  useSelectedSkills,
  useSelectedUnits
} from '../redux/selectors';
import { startAttack, validateSetup } from '../redux/simulationUtil';
import ConfirmDialog from './layouts/ConfirmDialog';
import { SelectedUnits, toSelectedUnits, UserInputUnits } from './units/UnitSelection';

/*
function simulateAllCampsFromAdventureButtonAction() {
  const ourUnits = getSimulatableArmy();
  simulateAllCampsFromAdventure(
    skilledGeneral,
    ourUnits,
    getAdventureById(selectedAdventure),
    getWeatherEffects(),
    setSimResult
  );
}

function simulateAllButtonAction() {
  const ourUnits = getSimulatableArmy();
  simulateAllCamps(skilledGeneral, ourUnits, getWeatherEffects(), setSimResult);
}
*/

function hasUnits(userInput?: UserInputUnits) {
  const counted = Object.values(userInput ?? {}).reduce((prev, current) => prev + parseInt(`${current}`), 0);
  return counted > 0;
}

function filterEmpty(
  inputGenerals: Array<GeneralId | null>,
  inputSkills: Array<SkillTree | null>,
  inputUnits: Array<UserInputUnits>
) {
  const validGeneralIndex = inputGenerals.map((genId, index) => (genId ? index : -1));
  const validUnitIndex = inputUnits.map((userInput, index) => (hasUnits(userInput) ? index : -1));

  function filterValid<T>(input: Array<T>): Array<T> {
    return input.filter((_general, index) => validGeneralIndex.includes(index) && validUnitIndex.includes(index));
  }
  const generals = filterValid(inputGenerals) as Array<GeneralId>;
  const skills = filterValid(inputSkills);
  const units = filterValid(inputUnits);

  return { generals, skills, units };
}

interface SimulationButtonProps {
  selectedCamps: Array<CampBuilding>;
  className?: string;
}

function SimulationButton({ selectedCamps, ...otherProps }: SimulationButtonProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const simulationEngine = useSimulationEngine();
  const weatherEffects = useGlobalEffects();
  const selectedGenerals = useSelectedGenerals();
  const selectedUnits = useSelectedUnits();
  const selectedEnemies = useSelectedEnemies();
  const selectedSkills = useSelectedSkills();
  const callbacks = useUpdatingCallbacks({
    startAttack: () => {
      const { generals, skills, units } = filterEmpty(selectedGenerals, selectedSkills, selectedUnits);

      const validationError = validateSetup(generals, units, selectedEnemies);
      if (validationError) {
        setErrorMessage(validationError);
        return;
      }

      const attacker = toSelectedUnits(units);
      const defender = toSelectedUnits(selectedEnemies);

      dispatch(actions.startNewSimulation());
      startAttack({
        generals,
        skills,
        attacker,
        defender,
        camps: selectedCamps,
        weather: weatherEffects,
        setSimResult: (battleResult, losses, simulations, attackerWave, enemyWave) =>
          dispatch(
            actions.addWaveResult('Sim', battleResult, losses, weatherEffects, simulations, attackerWave, enemyWave)
          ),
        simulationEngine
      });
    },
    confirm: () => setErrorMessage(null)
  });

  return (
    <div {...otherProps}>
      <div className='sim-button'>
        <div className='primary-button' onClick={callbacks.startAttack}>
          <img style={{ margin: 'auto 0' }} src='/icons/misc/swords.png' alt='attack' />
          <div>Simulieren</div>
        </div>
      </div>
      <FinderButton />
      <ConfirmDialog
        visible={!!errorMessage}
        text={t('Simulation kann nicht gestartet werden')}
        explain={errorMessage ? t(errorMessage) : ''}
        onAccept={callbacks.confirm}
      />
      {/* 
      <div
        className="button"
        onClick={() => simulateAllCampsFromAdventureButtonAction()}
      >
        <img
          style={{ margin: "auto 0" }}
          src="/icons/misc/swords.png"
          alt="attack"
        />
        <div>Simuliere alle Lager des ATs</div>
      </div>
      <div className="button" onClick={() => simulateAllButtonAction()}>
        <img
          style={{ margin: "auto 0" }}
          src="/icons/misc/swords.png"
          alt="attack"
        />
        <div>Simuliere jedes Abenteuer</div>
      </div>
    */}
    </div>
  );
}

export default styled(SimulationButton)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  width: 100%;
  grid-gap: 16px;
`;
