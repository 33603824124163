import styled, { css } from 'styled-components';
import { BaseProps } from '../types/basics';

interface Props extends BaseProps {
  text?: string;
  unit?: { icon: string };
  labelAfter?: boolean;
}

function LabeledImage({ text, unit, labelAfter, ...otherProps }: Props) {
  if (unit === undefined) {
    return null;
  }

  return (
    <span {...otherProps}>
      {!labelAfter && text && <label htmlFor={`img-of-${text}`}>{text}</label>}
      <img id={`img-of-${text}`} src={unit.icon} alt='' draggable={false} />
      {labelAfter && text && <label htmlFor={`img-of-${text}`}>{text}</label>}
    </span>
  );
}

export default styled(LabeledImage)`
  display: flex;

  label {
    ${props =>
      props.labelAfter
        ? css`
            padding-left: 4px;
            text-align: left;
          `
        : css`
            text-align: right;
          `}
    margin: auto;
    // min-width: 3ch;
  }

  & img {
    ${props =>
      props.labelAfter
        ? null
        : css`
            padding-left: 4px;
          `}
    height: 100%;
  }
`;
