const NO_HUFEISEN_ICON = "/icons/buffs/ohne_hufeisen.png";
const BRONZE_HUFEISEN_ICON = "/icons/buffs/bronze_hufeisen.png";
const PLATIN_HUFEISEN_ICON = "/icons/buffs/platin_hufeisen.png";
const TITAN_HUFEISEN_ICON = "/icons/buffs/titan_hufeisen.png";
const OBSIDIAN_HUFEISEN_ICON = "/icons/buffs/obsidian_hufeisen.png";
const MYSTISCHES_HUFEISEN_ICON = "/icons/buffs/mystisches_hufeisen.png";

function getHufeisenValue(modifier: number, deepSnow?: boolean) {
  return 10 / (3 * (1 + (modifier - (deepSnow ? 50 : 0)) / 100));
}

const hufeisen = {
  ohne: {
    value: (deepSnow?: boolean) => getHufeisenValue(0, deepSnow),
    icon: NO_HUFEISEN_ICON,
  },
  bronze: {
    value: (deepSnow?: boolean) => getHufeisenValue(15, deepSnow),
    icon: BRONZE_HUFEISEN_ICON,
  },
  platin: {
    value: (deepSnow?: boolean) => getHufeisenValue(20, deepSnow),
    icon: PLATIN_HUFEISEN_ICON,
  },
  titan: {
    value: (deepSnow?: boolean) => getHufeisenValue(25, deepSnow),
    icon: TITAN_HUFEISEN_ICON,
  },
  obsidian: {
    value: (deepSnow?: boolean) => getHufeisenValue(30, deepSnow),
    icon: OBSIDIAN_HUFEISEN_ICON,
  },
  mystisches: {
    value: (deepSnow?: boolean) => getHufeisenValue(40, deepSnow),
    icon: MYSTISCHES_HUFEISEN_ICON,
  },
};

export default hufeisen;
